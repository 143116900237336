import AMadLib from "../../AMadLib";

export default class MLCycleICSStateCounty extends AMadLib
{
    constructor(props)
    {
        super(props);
    }

    buildTitle(cycleYear, pageType, display)
    {
        let title = "Total Contributions";
        if (pageType !== 'cycle')
        {
            const displayPrefix = " " + this.getDisplayPrefix(pageType) + " ";
            title += displayPrefix + display;
        }
        title += " by County for the " + cycleYear + " Elections";

        return title;
    }

    buildSubtitle(cycleYear, pageType, display)
    {
        let subtitle = "The total contributions from individuals, organizations, and political action committees (PAC)";
        if (pageType !== 'cycle')
        {
            const displayPrefix = " " + this.getDisplayPrefix(pageType) + " ";
            subtitle += displayPrefix + display;
        }
        subtitle += " by county for the " + cycleYear + " elections";

        return subtitle;
    }

    buildMapTitle(cycleYear, pageType, display)
    {
        return this.buildTitle(cycleYear, pageType, display);
    }

    buildContent(cycleYear, pageType, display, stats, data)
    {
        let cycleYearRange = (cycleYear - 1) + " - " + cycleYear;

        let totalAmount = 0;
        let totalIndivAmount = 0;
        let totalOrgAmount = 0;
        let totalPACAmount = 0;
        let topAmount = 0;
        let topAmountCounty = 0;
        let topAmountState = 0;

        if (data !== undefined)
        {
            for (const nextCounty of data)
            {
                totalAmount = totalAmount + nextCounty.amount;
                totalIndivAmount = totalIndivAmount + nextCounty.indivAmount;
                totalOrgAmount = totalOrgAmount + nextCounty.orgAmount;
                totalPACAmount = totalPACAmount + nextCounty.pacAmount;

                //Top State by Total Amount
                if (nextCounty.amount > topAmount)
                {
                    topAmount = nextCounty.amount;
                    topAmountCounty = nextCounty.county;
                    topAmountState = nextCounty.state;
                }
            }
        }

        let content = "During the " + cycleYearRange + " elections, the total amount of money donated ";
        if (pageType === "party")
        {
            content += " to the " + display;
        }
        else if (pageType === "politician")
        {
            content += " to the politician " + display;
        }
        else if (pageType === "office")
        {
            content += " for the " + display;
        }
        else if (pageType === "surname")
        {
            content += " from contributors with a surname of " + display;
        }
        else if (pageType === "committee")
        {
            content += " to the " + display;
        }
        else if (pageType === "occupation")
        {
            content += " from contributors with an occupation of " + display;
        }
        else if (pageType === "organization")
        {
            content += " from contributors associated to the " + display + " organization";
        }
        else if (pageType === "industry")
        {
            content += " from contributors associated to the " + display + " industry";
        }
        content += " from all counties was $";
        content += totalAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ". ";

        content += "The total amount from individual contributors was $" + totalIndivAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "The total amount from organization contributions was $" + totalOrgAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "The total amount from political action committees contributions was $" + totalPACAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += topAmountCounty + ", " + topAmountState + " was the highest contributing county by total amount, giving a total of $";
        content += topAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ".";

        return content;
    }

}
