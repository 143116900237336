import {Stack} from "@mui/material";
import {Carousel} from "react-responsive-carousel";
import OverlayImage from "../overlayImage";
import React from "react";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";

const HomePageCarousel = (props) => {
    return(
        <Stack>
            <Carousel autoFocus={true} showStatus={false} interval={5000} autoPlay={true} showArrows={true} showThumbs={false} infiniteLoop={true}>
                <OverlayImage
                    title={"Unlimited Money in Politics"}
                    subtitle={"Explore the individuals and organizations spending billions to influence American elections"}
                    imageSource={"images/home/summary_citizens_united_small.jpg"}
                    linkPath="/summary"
                    shapeColor='rgba(111,137,174, 0.75)'
                    shapeTop={0}
                    shapeLeft={80}
                    shapeWidth={300}
                    shapeHeight={300}
                />
                <OverlayImage
                    title={"Tracking the 2024 Fundraising by Candidate and Party"}
                    subtitle={"Explore the individuals, organizations, industries, and locations influencing the 2024 elections"}
                    imageSource={"images/home/cycle_2024.jpg"}
                    linkPath="/cycle/2024"
                    shapeTop={window.innerWidth > 1200 ? (-10):(0)}
                    shapeLeft={window.innerWidth > 1200 ? (850):(80)}
                    shapeWidth={window.innerWidth > 1200 ? (320):(300)}
                    shapeHeight={window.innerWidth > 1200 ? (320):(300)}
                />
                <OverlayImage
                    title={"Fundraising and Spending by the Democratic Party"}
                    subtitle={"Explore the people, corporations, unions, non-profits, and PACS influencing the Democratic Party"}
                    imageSource={"images/home/party_democratic.jpg"}
                    linkPath="/party/democratic"
                    shapeColor='rgba(111,137,174, 0.75)'
                    shapeLeft={window.innerWidth > 1200 ? (850):(80)}
                />
                <OverlayImage
                    title={"Fundraising and Spending by the Republican Party"}
                    subtitle={"Explore the people, corporations, industries, and PACS influencing the Republican Party"}
                    imageSource={"images/home/party_republican.jpg"}
                    linkPath="/party/republican"
                    shapeLeft={window.innerWidth > 1200 ? (480):(80)}
                />
                <OverlayImage
                    title={"Rise of the Internet Powered Political Action Committee (PAC)"}
                    subtitle={"Explore how ACTBLUE raised billions from millions on online donors"}
                    imageSource={"images/home/pac_actblue.jpg"}
                    linkPath="/committee/c00401224"
                    shapeRadiusPct="01%"
                    shapeColor='rgba(111,137,174, 0.75)'
                    shapeTop={window.innerWidth > 1200 ? (40):(0)}
                    shapeLeft={window.innerWidth > 1200 ? (400):(80)}
                    shapeWidth={window.innerWidth > 1200 ? (400):(300)}
                    shapeHeight={window.innerWidth > 1200 ? (220):(300)}
                />
                <OverlayImage
                    title={"Industries Influencing the Political Landscape"}
                    subtitle={"See how the Finance industry is reshaping American elections"}
                    imageSource={"images/home/industry_finance.jpg"}
                    linkPath="/industry/finance"
                    shapeRadiusPct="05%"
                    shapeTop={window.innerWidth > 1200 ? (30):(0)}
                    shapeLeft={window.innerWidth > 1200 ? (900):(80)}
                    shapeWidth={window.innerWidth > 1200 ? (220):(300)}
                    shapeHeight={window.innerWidth > 1200 ? (250):(300)}
                />
                <OverlayImage
                    title={"The National Influence on the 2020 Georgia Senate Races"}
                    subtitle={"See how over $300 million from across the nation influenced the 2020 Georgia Senate races"}
                    imageSource={"images/home/office_2020_ga_senate.jpg"}
                    linkPath="/cycle/2020/office/s-ga"
                    shapeColor='rgba(111,137,174, 0.75)'
                    shapeTop={window.innerWidth > 1200 ? (0):(0)}
                    shapeLeft={window.innerWidth > 1200 ? (220):(80)}
                    shapeWidth={window.innerWidth > 1200 ? (300):(300)}
                    shapeHeight={window.innerWidth > 1200 ? (300):(300)}
                />
            </Carousel>
        </Stack>
    )
};

export default HomePageCarousel;
