import React, {useEffect} from "react";
import {Link as RouterLink, useParams} from "react-router-dom";

import SiteHeader from "../../components/siteHeader";
import SiteFooter from "../../components/siteFooter";
import RollupICSTypeList from "../../components/ics/rollupICSTypeList";
import RollupUCSList from "../../components/ucs/rollupUCSList";
import RollupCETypeList from "../../components/ces/rollupCETypeList";
import RollupCycleList from "../../components/ics/rollupCycleList";
import RollupICSTotal from "../../components/ics/rollupICSTotal";
import RollupCETotal from "../../components/ces/rollupCETotal";
import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import ShareButton from "../../components/shareButton";
import RollupICSStateZipcodeList from "../../components/ics/rollupICSStateZipcodeList";
import ScrollToTop from "../../components/scrollToTop";
import MetaData from "../../components/metaData";
import * as DataLoader from "../../util/DataLoader";
import RollupCECycleList from "../../components/ces/rollupCECycleList";
import {madLibFactory} from "../../AppGlobal";
import RollupUCSOrg from "../../components/ucs/rollupUCSOrg";
import RollupUCSPAC from "../../components/ucs/rollupUCSPAC";

const CityRollup = () => {
    const currentPageType = "city";
    const {stateKey} = useParams();
    const {cityKey} = useParams();

    const [success, setSuccess] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [fields, setFields] = React.useState([]);
    const [stats, setStats] = React.useState([]);

    useEffect(() => {
        const urlSuffix = "state/" + stateKey + "/city/" + cityKey
        DataLoader.load(urlSuffix, (result, data) => {
            setLoaded(true)
            if (result === true)
            {
                setSuccess(true)
                setStats(data.statistics);
                setFields(data.fields);
            }
            else
            {
                setSuccess(false);
            }
        })
    }, [stateKey, cityKey])

    let metaTitle = "";
    let metaDescription = "";
    if (success === true)
    {
        const madLibsMeta = madLibFactory.getPageMetaRollup();

        metaTitle = madLibsMeta.buildTitle(currentPageType, fields.display, fields, stats);
        metaDescription = madLibsMeta.buildDescription(currentPageType, fields.display, fields, stats);
    }

    return (
        <div>
            <Stack paddingBottom={10}>
                <SiteHeader />
            </Stack>
            {
                loaded === false ?
                    (<Typography variant="h6">Building City Summary...</Typography>) :
                    (<div className="Page-content">
                        {
                            success === false ?
                                (
                                    <Typography variant="h6">Unable to build a City Summary for {cityKey}, {stateKey}</Typography>
                                ) :
                                (<React.Fragment>
                                    <MetaData
                                        title={metaTitle}
                                        description={metaDescription}
                                        url={window.location.href}
                                        image={"https://www.monecracy.com/images/logo.png"}/>

                                    <Stack direction="column" spacing={1} padding={0}>
                                        <Stack id="back-to-top-anchor" direction="row" alignItems="center" justifyContent="space-between" paddingTop={1}>
                                            <Breadcrumbs>
                                                <Link component={RouterLink}  to={"/"} underline={"hover"}>Home</Link>
                                                <Link component={RouterLink} to={"/totals"} underline={"hover"}>Totals</Link>
                                                <Link component={RouterLink}  to={"/states"} underline={"hover"}>States</Link>
                                                <Link component={RouterLink}  to={"/state/" + fields.stateKey} underline={"hover"}>{fields.stateDisplay}</Link>
                                                <Typography>{fields.display}</Typography>
                                            </Breadcrumbs>
                                            <ShareButton title={"Monecracy: " + fields.display} />
                                        </Stack>

                                        <RollupICSTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_total"
                                            display={fields.display}
                                        />
                                        <RollupCycleList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_cycle_totals"
                                            display={fields.display}
                                        />
                                        <RollupICSStateZipcodeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_zipcode_totals"
                                            stateKey={stateKey}
                                            display={fields.display}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_party_totals"
                                            statsTop={false}
                                            linkType="party"
                                            anchor="party_totals"
                                            display={fields.display}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_politicians_top"
                                            statsTop={true}
                                            linkType="politician"
                                            anchor="politician_totals"
                                            display={fields.display}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_committees_top"
                                            statsTop={true}
                                            linkType="committee"
                                            anchor="committee_totals"
                                            display={fields.display}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_offices_top"
                                            statsTop={true}
                                            linkType="office"
                                            anchor="office_totals"
                                            display={fields.display}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_organizations_top"
                                            statsTop={true}
                                            linkType="organization"
                                            anchor="organization_totals"
                                            display={fields.display}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_industries_top"
                                            statsTop={true}
                                            anchor="industry_totals"
                                            linkType="industry"
                                            display={fields.display}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_occupations_top"
                                            statsTop={true}
                                            linkType="occupation"
                                            anchor="occupation_totals"
                                            display={fields.display}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_surnames_top"
                                            statsTop={true}
                                            linkType="surname"
                                            anchor="surname_totals"
                                            display={fields.display}
                                        />
                                        <RollupUCSList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_indiv_top"
                                            display={fields.display}
                                            linkType={"contributor"}
                                        />
                                        <RollupUCSOrg
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_org_top"
                                            display={fields.display}
                                            linkType={"organization"}
                                        />
                                        <RollupUCSPAC
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_pac_top"
                                            display={fields.display}
                                            linkType={"committee"}
                                        />
                                        <RollupCETotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_total"
                                            display={fields.display}
                                        />
                                        <RollupCECycleList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_cycle_totals"
                                            display={fields.display}
                                        />
                                        <RollupCETypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_committees_top"
                                            linkType="committee"
                                            display={fields.display}
                                            anchor="committee_expenditure_totals"
                                        />
                                        <RollupCETypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_vendors_top"
                                            linkType="vendor"
                                            display={fields.display}
                                            anchor="vendor_expenditure_totals"
                                        />
                                    </Stack>

                                    <ScrollToTop />
                                </React.Fragment>)
                        }
                    </div>)
            }
            <SiteFooter/>
        </div>
    );
};

export default CityRollup;
