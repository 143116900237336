import React, {useEffect, useRef} from 'react';
import {Link as RouterLink, useNavigate, useSearchParams} from "react-router-dom";

import SiteHeader from '../components/siteHeader';
import SiteFooter from '../components/siteFooter';
import axios from "axios";
import SearchResults from "../components/searchResults";
import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";

const Search = () => {

    const searchInput = useRef(null);
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const [success, setSuccess] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [hits, setHits] = React.useState([]);

    useEffect(() => {
        const loadURL = process.env.REACT_APP_SEARCH_ROOT + "?q=" + searchParams.get("q") + "&size=100";
        console.log(loadURL);

        axios.get(loadURL)
            .then((res) => {
                setLoaded(true);
                if (res.data.hits === undefined)
                {
                    setSuccess(false);
                }
                else
                {
                    setSuccess(true);
                    setHits(res.data.hits);
                }
            },)
    }, [searchParams])

    const runSearch = () => {
        const query = searchInput.current.value;

        const searchPath = "?q=" + query + "&size=100";
        navigate(searchPath);
    }

    return (
        <div>
            <Stack paddingBottom={10}>
                <SiteHeader />
            </Stack>
            <Stack id="back-to-top-anchor" direction="row" alignItems="center" justifyContent="space-between" paddingTop={1} paddingBottom={1}>
                <Breadcrumbs>
                    <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                    <Typography>Search</Typography>
                </Breadcrumbs>
            </Stack>

            {/*<div className="Page-content">*/}
            {/*    <input*/}
            {/*        ref={searchInput}*/}
            {/*        type="text"*/}
            {/*        placeholder="Enter search condition"*/}
            {/*    />*/}
            {/*    <button onClick={runSearch}>Search</button>*/}
            {/*</div>*/}
            {
                loaded === false ?
                    (<h2>Searching....</h2>) :
                    (<div className="Page-content">
                        {
                            success === false ?
                                (<h2>Unable to find any matches for {searchParams.get("q")} </h2>) :
                                (<React.Fragment>
                                    <p>Found {hits.found} matches for {searchParams.get("q")}</p>
                                    <SearchResults results={hits.hit}/>
                                </React.Fragment>)
                        }
                    </div>)
            }
            <SiteFooter/>
        </div>
    );
};

export default Search;
