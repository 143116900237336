import React from "react";
import SiteHeader from "../components/siteHeader";
import SiteFooter from "../components/siteFooter";
import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import ScrollToTop from "../components/scrollToTop";
import {Link as RouterLink} from "react-router-dom";

const About = () => {
    return (
        <Stack spacing={1}>
            <Stack paddingBottom={10} id="back-to-top-anchor">
                <SiteHeader />
            </Stack>

            <Stack id="back-to-top-anchor" direction="row" alignItems="center" justifyContent="space-between" paddingTop={1} paddingBottom={1}>
                <Breadcrumbs>
                    <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                    <Typography>About Us</Typography>
                </Breadcrumbs>
            </Stack>

            <Stack direction="column" spacing={3} padding={0}>
                <Stack direction="column" spacing={0} padding={0}>
                    <Typography variant="h5" align="left">Monecracy is about Democracy in America</Typography>
                    <Typography variant="body2" align="left">
                        This site is about the growing influence of money in American elections.  We process public records provided by the Federal Election Commission (FEC) that contain hundreds of millions of detailed records on the contributions and spending of political action committees.  Our site classifies, categorizes, organizes, and visualizes these public records in a way that is easier to access and understand.
                    </Typography>
                </Stack>

                <Stack direction="column" spacing={0} padding={0}>
                    <Typography variant="h5" align="left">Monecracy is about Politics</Typography>
                    <Typography variant="body2" align="left">
                        Monecracy is about politicians, political parties, political action committees, and the system of electing the President and members of Congress.  Elected officials need contributions to run for election.  Every elected official has a base on donors, including individuals, corporations, unions, parties, and political action committees.  Our site provides profiles of the top contributors supporting every candidate and elected official.
                    </Typography>
                </Stack>

                <Stack direction="column" spacing={0} padding={0}>
                    <Typography variant="h5" align="left">Monecracy is about Citizens United</Typography>
                    <Typography variant="body2" align="left">
                        The Supreme Court ruling on Citizens United vs Federal Election Commission in 2010 has transformed the way politicians are elected in America.  This controversial and consequential ruling granted corporations and unions the same rights of political free speech as individuals.  One of the most important consequences of this ruling is the ability for organizations to conceal who owns and controls them by registering in states that do not disclose business ownership, such as Delaware, Wyoming, Nevada, New Mexico, and South Dakota.  This has introduced a way for unlimited political donations to influence the political process from anonymous sources, called “Dark Money”.
                    </Typography>
                </Stack>

                <Stack direction="column" spacing={0} padding={0}>
                    <Typography variant="h5" align="left">Monecracy is about Corporations, Unions, Non-Profits, and Associations</Typography>
                    <Typography variant="body2" align="left">
                        Monecracy is about corporations, non-profit organizations, unions, trade associations, lobbyists, and a wide range of other organizations and their political contributions to politicians, political committees, and political parties.  This site provides details on these organizations and their connection to the political parties, committees, and politicians they are influencing with their donations.  These profile pages detail the relationship between the private sector and the political system.
                    </Typography>
                </Stack>

                <Stack direction="column" spacing={0} padding={0}>
                    <Typography variant="h5" align="left">Monecracy is about People</Typography>
                    <Typography variant="body2" align="left">
                        Monecracy is about people from nearly every city in every state contributing money to have an influence on the government they form.  It is about the power of the wealthiest individuals to amplify their political speech, often tens of thousands of times louder than people with equal citizenship but far less influence.  The site highlights how a very small minority is using large political donations to exert influence on who is elected to nearly every office.
                    </Typography>
                </Stack>

                <Stack direction="column" spacing={0} padding={0}>
                    <Typography variant="h5" align="left">Monecracy is about Secrecy</Typography>
                    <Typography variant="body2" align="left">
                        Monecracy is about secrecy and the ability of wealthy organizations and individuals to spend billions of dollars to influence our political system without any public disclosure.
                    </Typography>
                </Stack>

                <Stack direction="column" spacing={0} padding={0}>
                    <Typography variant="h5" align="left">Monecracy is about Business</Typography>
                    <Typography variant="body2" align="left">
                        Monecracy is about the organizations, businesses, parties, and individuals making billions of dollars on the election process.  Elections in America have become big business, with billions of dollars being spent on political campaigns.  Our site profiles the businesses (Vendors) make money on the election process and profit from the unlimited spending created by the Citizens United ruling.
                    </Typography>
                </Stack>

                <Stack direction="column" spacing={0} padding={0}>
                    <Typography variant="h5" align="left">Monecracy is about Influence</Typography>
                    <Typography variant="body2" align="left">
                        Monecracy is about the people, corporations, unions, associations, lobbyists, and political action committees using money to influence elections.  Our site provides profiles of these donors, showing how much money they gave and which politicians, parties, and political action committees they support.  These donor profiles detail which individuals and organizations are using political contributions to influence elections, which parties and politicians they are supporting and opposing.
                    </Typography>
                </Stack>

                <Stack direction="column" spacing={0} padding={0}>
                    <Typography variant="h5" align="left">Monecracy is about Transparency</Typography>
                    <Typography variant="body2" align="left">
                        Monecracy is about transparency and making the public data from the Federal Election Commission (FEC) more accessible, easier to understand, and more insightful through visualizations and profile reports.
                    </Typography>
                </Stack>

                <Stack direction="column" spacing={0} padding={0}>
                    <Typography variant="h5" align="left">Monecracy is about Voting</Typography>
                    <Typography variant="body2" align="left">
                        Monecracy is about voting and helping people understand who is supporting and influencing the candidates for office.  It is about the organizations that are getting increasingly involved in funding candidates and detailing which candidates and parties those corporations, unions, and associations are supporting and opposing.
                    </Typography>
                </Stack>

                <Stack direction="column" spacing={0} padding={0}>
                    <Typography variant="h5" align="left">Monecracy is about Business & Brands</Typography>
                    <Typography variant="body2" align="left">
                        Monecracy is also about the businesses and brands you support with every dollar you spend.  It is about the businesses and the employees who work there, about their political activities, and their involvement or lack of involvement in the political process.  It is about unlimited money in politics and unlimited politics in money with every dollar you spend.
                    </Typography>
                </Stack>

                <Stack direction="column" spacing={0} padding={0}>
                    <Typography variant="h5" align="left">Our Data Sources</Typography>
                    <Typography variant="body2" align="left">
                        The primary data source used to compile the statistics on Monecracy come from the Federal Election Commission (FEC).
                        This publicly available data is provided by the FEC in a simple text format. Our systems process these text files to
                        compute billions of statistics and organize them into millions of profile pages that are easier to read, visualize, and understand.
                    </Typography>
                    <Link href="https://www.fec.gov/data/browse-data/?tab=bulk-data" target={"_blank"} rel={"noreferrer"}>FEC Bulk Data</Link><br />
                </Stack>

            </Stack>

            <ScrollToTop />

            <SiteFooter/>
        </Stack>
    );
};

export default About;
