import React from "react";

const ListTag = (props) => {
    return (
        <div className="list-tag">
            <h3>{"List Tags"}</h3>
            {
                props.data === undefined ?
                    (<span>No data available</span>):
                    (<div>
                            {
                                props.data.map((stat, index) => (
                                    <div>
                                        <p>{stat}</p>
                                    </div>
                                ))
                            }
                        </div>
                    )
            }
        </div>
    );
};

export default ListTag;