import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import SiteHeader from "../../components/siteHeader";
import SiteFooter from "../../components/siteFooter";
import TagAssociation from "../../components/tag/tagAssociation";
import * as DataLoader from "../../util/DataLoader";
import {Stack} from "@mui/material";

const TagViewer = () => {
    const {tagKey} = useParams();
    const [success, setSuccess] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [stats, setStats] = React.useState([]);

    useEffect(() => {
        const urlSuffix = "tag/" + tagKey;
        DataLoader.load(urlSuffix, (result, data) => {
            setLoaded(true)
            if (result === true)
            {
                setSuccess(true)
                setStats(data.statistics);
            }
            else
            {
                setSuccess(false);
            }
        })
    }, [tagKey])

    return (
        <div>
            <Stack paddingBottom={10}>
                <SiteHeader />
            </Stack>
            {
                loaded === false ?
                    (<h2>Loading data....</h2>) :
                    (<div className="Page-content">
                        {
                            success === false ?
                                (<h2>Unable to find the Tag for {tagKey}</h2>) :
                                (<React.Fragment>
                                    <h1>Tag Summary</h1>
                                    <p>Tag: {stats.tag}</p>
                                    <div dangerouslySetInnerHTML={{ __html: stats.description }} />
                                    <TagAssociation data={stats.associations}></TagAssociation>
                                </React.Fragment>)
                        }
                    </div>)
            }
            <SiteFooter/>
        </div>
    );
};

export default TagViewer;
