import AMadLib from "../../AMadLib";

export default class MLRollupCESCounty extends AMadLib
{
    constructor()
    {
        super();
    }

    buildTitle(pageType, display)
    {
        let title = "Top Counties by Total Committee Expenditures";

        return title
    }

    buildSubtitle(pageType, display)
    {
        let subtitle = "The top counties based on the total committee expenditures for all elections since 2008";

        return subtitle
    }

    buildChartTitle(pageType, display)
    {
        let title = "Top Counties by Total Committee Expenditures";

        return title
    }

    buildContent(pageType, display, stats, data)
    {
        let totalAmount = 0;
        let topAmount = 0;
        let topAmountDisplay = 0;

        if (data !== undefined) {
            for (const nextCounty of data) {
                totalAmount = totalAmount + nextCounty.amount;

                //Top State by Total Amount
                if (nextCounty.amount > topAmount) {
                    topAmount = nextCounty.amount;
                    topAmountDisplay = nextCounty.county;
                }
            }
        }

        let content;

        if (pageType === "summary")
        {
            content = "The total amount of money spent by committees on expenditures in the top 25 counties since 2008 is $"
        }
        if (pageType === "state")
        {
            content = "The total amount of money spent by committees on expenditures in the state of " + display + " from its counties since 2008 is $"
        }
        else if (pageType === "party")
        {
            content = "The total amount of money spent by committees on expenditures that support the " + display + " from the top 25 counties since 2008 is $"
        }
        else if (pageType === "politician")
        {
            content = "The total amount of money spent by committees on expenditures that support the politician " + display + " for federal elections from the top 25 counties since 2008 is $"
        }
        else if (pageType === "office")
        {
            content = "The total amount of money spent by committees on expenditures that support the office " + display + " for federal elections from the top 25 counties since 2008 is $"
        }
        else if (pageType === "committee")
        {
            content = "The total amount of money spent by committees on expenditures that support the committee " + display + " for federal elections from the top 25 counties since 2008 is $"
        }
        else if (pageType === "organization")
        {
            content = "The total amount of money spent by committees on expenditures that support the organization " + display + " for federal elections from the top 25 counties since 2008 is $"
        }

        content += totalAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ".";
        content += "With " + topAmountDisplay + " receiving the most from expenditures. ";

        return content;
    }

}
