import AMadLib from "../../AMadLib";

export default class MLRollupICSCounty extends AMadLib
{
    constructor()
    {
        super();
    }

    buildTitle(pageType, display)
    {
        let title = "Top Counties by Total Contributions";
        if (pageType !== 'summary')
        {
            const displayPrefix = " " + this.getDisplayPrefix(pageType) + " ";
            title += displayPrefix + display;
        }

        return title;
    }

    buildSubtitle(pageType, display)
    {
        let subtitle = "The top counties based on the total contributions from individuals, organizations, and political action committees (PAC)";
        if (pageType !== 'summary')
        {
            const displayPrefix = " " + this.getDisplayPrefix(pageType) + " ";
            subtitle += displayPrefix + display;
        }
        subtitle += " for all elections since 2008";

        return subtitle;
    }

    buildMapTitle(pageType, display)
    {
        return this.buildTitle(pageType, display);
    }

    buildContent(pageType, display, stats, data)
    {
        let totalAmount = 0;
        let totalIndivAmount = 0;
        let totalOrgAmount = 0;
        let totalPACAmount = 0;
        let topAmount = 0;
        let topAmountCounty = 0;
        let topAmountState = 0;

        for (const nextCounty of data)
        {
            totalAmount = totalAmount + nextCounty.amount;
            totalIndivAmount = totalIndivAmount + nextCounty.indivAmount;
            totalOrgAmount = totalOrgAmount + nextCounty.orgAmount;
            totalPACAmount = totalPACAmount + nextCounty.pacAmount;

            //Top State by Total Amount
            if (nextCounty.amount > topAmount)
            {
                topAmount = nextCounty.amount;
                topAmountCounty = nextCounty.county;
                topAmountState = nextCounty.state;
            }
        }

        let content;

        if (pageType === "summary")
        {
            content = "The total amount of money donated to federal elections from the top 25 counties since 2008 is $"
        }
        else if (pageType === "party")
        {
            content = "The total amount of money donated to the " + display + " for federal elections from the top 25 counties since 2008 is $"
        }
        else if (pageType === "politician")
        {
            content = "The total amount of money donated to the politician " + display + " for federal elections from the top 25 counties since 2008 is $"
        }
        else if (pageType === "office")
        {
            content = "The total amount of money donated to the office " + display + " for federal elections from the top 25 counties since 2008 is $"
        }
        else if (pageType === "surname")
        {
            content = "The total amount of money donated from the surname " + display + " for federal elections from the top 25 counties since 2008 is $"
        }
        else if (pageType === "committee")
        {
            content = "The total amount of money donated to the committee " + display + " for federal elections from the top 25 counties since 2008 is $"
        }
        else if (pageType === "occupation")
        {
            content = "The total amount of money donated from donors with the occupation of " + display + " for federal elections from the top 25 counties since 2008 is $"
        }
        else if (pageType === "organization")
        {
            content = "The total amount of money donated from the organization " + display + " for federal elections from the top 25 counties since 2008 is $"
        }
        else if (pageType === "industry")
        {
            content = "The total amount of money donated from the industry of " + display + " for federal elections from the top 25 counties since 2008 is $"
        }
        else
        {
            content = "The total amount of money from " + display + " donated to federal elections from the top 25 counties since 2008 is $"
        }

        content += totalAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ".";
        content += "Total amount from individual contributors is $" + totalIndivAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "Total amount from organization contributions is $" + totalOrgAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "Total amount from political actions committees contributions is $" + totalPACAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "With " + topAmountCounty + ", " + topAmountState + " being the highest contributing county by total amount. ";

        return content;
    }

}
