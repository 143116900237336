import React, {useEffect} from "react";
import {Link as RouterLink, useParams} from "react-router-dom";

import SiteHeader from "../../components/siteHeader";
import SiteFooter from "../../components/siteFooter";
import RollupUCSTypeList from "../../components/ucs/rollupUCSTypeList";
import RollupUCSCycleList from "../../components/ucs/rollupUCSCycleList";
import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import ShareButton from "../../components/shareButton";
import ScrollToTop from "../../components/scrollToTop";
import MetaData from "../../components/metaData";
import * as DataLoader from "../../util/DataLoader";
import RollupUCSTotal from "../../components/ucs/rollupUCSTotal";
import {madLibFactory} from "../../AppGlobal";

const ContributorRollup = () => {
    const currentPageType = 'contributor';
    const {stateKey} = useParams();
    const {zipcodeKey} = useParams();
    const {contributorKey} = useParams();

    const [success, setSuccess] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [fields, setFields] = React.useState([]);
    const [stats, setStats] = React.useState([]);

    useEffect(() => {
        const urlSuffix = "contributor/" + stateKey + "/" + zipcodeKey + "/" + contributorKey;
        DataLoader.load(urlSuffix, (result, data) => {
            setLoaded(true);
            if (result === true)
            {
                setSuccess(true)
                setStats(data.statistics);
                setFields(data.fields);
            }
            else
            {
                setSuccess(false);
            }
        })
    }, [stateKey, zipcodeKey, contributorKey])

    let metaTitle = "";
    let metaDescription = "";
    if (success === true)
    {
        const madLibsMeta = madLibFactory.getPageMetaRollup();

        metaTitle = madLibsMeta.buildTitle(currentPageType, fields.display, fields, stats);
        metaDescription = madLibsMeta.buildDescription(currentPageType, fields.display, fields, stats);
    }

    return (
        <div>
            <Stack paddingBottom={10}>
                <SiteHeader />
            </Stack>
            {
                loaded === false ?
                    (<Typography variant="h6">Building Contributor Summary...</Typography>) :
                    (<div align="Center">
                        {
                            success === false ?
                                (<Typography variant="h6">Unable to build a Contributor Summary for {contributorKey} in {stateKey}</Typography>) :
                                (<React.Fragment>
                                    <MetaData
                                        title={metaTitle}
                                        description={metaDescription}
                                        url={window.location.href}
                                        image={"https://www.monecracy.com/images/logo.png"}/>

                                    <Stack direction="column" spacing={1} padding={0}>
                                        <Stack id="back-to-top-anchor" direction="row" alignItems="center" justifyContent="space-between" paddingTop={1}>
                                            <Breadcrumbs>
                                                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                                                <Link component={RouterLink} to={"/totals"} underline={"hover"}>Totals</Link>
                                                <Link component={RouterLink} to={"/contributors"} underline={"hover"}>Contributors</Link>
                                                <Link component={RouterLink} to={"/state/" + fields.stateKey} underline={"hover"}>{fields.stateDisplay}</Link>
                                                <Link component={RouterLink} to={"/zipcode/" + fields.zipcodeKey} underline={"hover"}>{fields.zipcodeKey}</Link>
                                                <Typography>{fields.display}</Typography>
                                            </Breadcrumbs>
                                            <ShareButton title={"Monecracy: Contributor Summary for " + fields.display} />
                                        </Stack>

                                        <RollupUCSTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_total"
                                            display={fields.display}
                                        />
                                        <RollupUCSCycleList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_cycle_totals"
                                            display={fields.display}
                                        />
                                        <RollupUCSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_parties"
                                            linkType="party"
                                            anchor="party_totals"
                                            display={fields.display}
                                        />
                                        <RollupUCSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_committees"
                                            linkType="committee"
                                            anchor="committee_totals"
                                            display={fields.display}
                                        />
                                        <RollupUCSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_politicians"
                                            linkType="politician"
                                            anchor="politician_totals"
                                            display={fields.display}
                                        />
                                        <RollupUCSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_offices"
                                            linkType="office"
                                            anchor="office_totals"
                                            display={fields.display}
                                        />
                                        <RollupUCSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_organizations"
                                            linkType="organization"
                                            anchor="organization_totals"
                                            display={fields.display}
                                        />
                                        <RollupUCSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_occupations"
                                            linkType="occupation"
                                            anchor="occupation_totals"
                                            display={fields.display}
                                        />
                                        <RollupUCSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_industries"
                                            linkType="industry"
                                            anchor="industry_totals"
                                            display={fields.display}
                                        />
                                    </Stack>

                                    <ScrollToTop />
                                </React.Fragment>)
                        }
                    </div>)
            }
            <SiteFooter/>
        </div>
    );
};

export default ContributorRollup;
