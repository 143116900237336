import axios from "axios";

class MapData {

    getUS = async () => {
        return await axios.get("/data/usa.geo.json")
            .catch((reason) => {
                console.log(reason);
            });
    };

    getUSCounty = async () => {
        return await axios.get("/data/usa-county.geo.json")
            .catch((reason) => {
                console.log(reason);
            });
    };

    getUSFull = async () => {
        return await axios.get("/data/usa-full.geo.json")
            .catch((reason) => {
                console.log(reason);
            });
    };

    getUSState = async (stateKey) => {
        return await axios.get("/data/states/" + stateKey.toLowerCase() + ".geo.json")
            .catch((reason) => {
                console.log(reason);
            });
    };
}

export default MapData;
