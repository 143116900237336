import AMadLib from "../../AMadLib";
import {typeDetails} from "../../../AppGlobal";

export default class MLRollupCESType extends AMadLib
{
    constructor()
    {
        super();
    }

    buildTitle(pageType, linkType, display)
    {
        const linkTypeDetails = typeDetails[linkType];

        let title = "Top " + linkTypeDetails.displayPlural + " by Total Expenditures";

        return title;
    }

    buildSubtitle(pageType, linkType, display)
    {
        const linkTypeDetails = typeDetails[linkType];

        let subtitle = "The top ";
        subtitle += linkTypeDetails.displayPlural.toLowerCase();
        subtitle += " based on total committee expenditures for all elections since 2008";

        return subtitle;
    }

    buildChartTitle(pageType, linkType, display)
    {
        const linkTypeDetails = typeDetails[linkType];

        let chartTitle = "Top " + linkTypeDetails.displayPlural + " by Total Expenditures";

        return chartTitle;
    }

    buildContent(pageType, linkType, display, stats, data)
    {
        let totalAmount = 0;
        let topAmount = 0;
        let topAmountDisplay = 0;

        if (data !== undefined)
        {
            for (const nextType of data)
            {
                totalAmount = totalAmount + nextType.amount;

                //Top State by Total Amount
                if (nextType.amount > topAmount)
                {
                    topAmount = nextType.amount;
                    topAmountDisplay = nextType.display;
                }
            }
        }

        let content;

        if (pageType === "summary")
        {
            content = "The top 25 total committee expenditures to a " + linkType +  " since 2008 is $"
        }
        else if (pageType === "state")
        {
            content = "The top 25 total committee expenditures to a " + linkType +  " in the state of " + display + " since 2008 is $"
        }
        else if (pageType === "county")
        {
            content = "The top 25 total committee expenditures to a " + linkType +  " in " + display + " since 2008 is $"
        }
        else if (pageType === "city")
        {
            content = "The top 25 total committee expenditures to a " + linkType +  " in the city of " + display + " since 2008 is $"
        }
        else if (pageType === "zipcode")
        {
            content = "The top 25 total committee expenditures to a " + linkType +  " in the zipcode of " + display + " since 2008 is $"
        }
        else if (pageType === "party")
        {
            content = "The top 25 total committee expenditures to a " + linkType +  " in support of the " + display + " since 2008 is $"
        }
        else if (pageType === "politician")
        {
            content = "The top 25 total committee expenditures to a " + linkType +  " in support of the politician " + display + " since 2008 is $"
        }
        else if (pageType === "office")
        {
            content = "The top 25 total committee expenditures to a " + linkType +  " in support of the office " + display + " since 2008 is $"
        }
        else if (pageType === "committee")
        {
            content = "The top 25 total committee expenditures to a " + linkType +  " in support of the committee " + display + " since 2008 is $"
        }
        else if (pageType === "organization")
        {
            content = "The top 25 total committee expenditures to a " + linkType +  " in support of the organization " + display + " since 2008 is $"
        }
        else if (pageType === "vendor")
        {
            content = "The top 25 total committee expenditures to a " + linkType +  " in support of the vendor " + display + " since 2008 is $"
        }

        content += totalAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ".";
        content += "With the " + linkType + " " + topAmountDisplay + " receiving the most from expenditures. ";

        return content;
    }

}
