import AMadLib from "../../AMadLib";

export default class MLRollupICSSummary extends AMadLib
{
    constructor()
    {
        super();
    }

    buildTitle(pageType, display)
    {
        const displayPrefix = " " + this.getDisplayPrefix(pageType) + " ";

        let title = "Contribution Totals";

        if (pageType !== 'summary')
        {
            title += " " + displayPrefix + " " + display;
        }
        return title
    }

    buildContent(pageType, display, stats, data)
    {
        const avgContributorTotal = data.amount / data.contributors;
        const avgContributorAmount = data.amount / data.contributions;
        const avgContributorCount = data.contributions / data.contributors;

        const isSummary = pageType === 'summary';
        const isLocation = (pageType === 'state' || pageType === 'county' || pageType === 'city' || pageType === 'zipcode')

        let content = "Since 2008, a total of $"
            + data.amount.toLocaleString(undefined, { maximumFractionDigits: 0 })
            + " dollars has been donated ";

        let typeText = ""
        if (pageType === 'committee' || pageType === 'politician')
        {
            typeText = " to the " + pageType + " " + display + ".";
        }
        else if (pageType === 'office')
        {
            typeText = " to candidates running for the office of " + display + ".";
        }
        else if (pageType === 'party')
        {
            typeText = " to the " + display + ".";
        }
        else if (pageType === 'county')
        {
            typeText = " from " + display + ".";
        }
        else if (pageType === 'organization' || pageType === 'industry')
        {
            typeText = " from the " + display + " " + pageType.toLowerCase() + ".";
        }
        else if (pageType === 'surname')
        {
            typeText = " from individuals with the surname of " + display + ".";
        }
        else if (isSummary === false)
        {
            typeText = " from the " + pageType + " of " + display + "."
        }
        else
        {
            typeText = " to federal elections."
        }
        content += typeText

        content += " In this time, "
            + data.contributors.toLocaleString(undefined, { maximumFractionDigits: 0 })
            + " individuals, organizations, and political action committees (PAC) made a total of "
            + data.contributions.toLocaleString(undefined, { maximumFractionDigits: 0 })
            + " contributions."

        content += " The typical unique contributor (individual, organization, or PAC) made "
            + avgContributorCount.toLocaleString(undefined, { maximumFractionDigits: 0 })
            + " donations with an average of $"
            + avgContributorAmount.toLocaleString(undefined, { maximumFractionDigits: 0 })
            + " each. On average, each unique contributor gave a total of $"
            + avgContributorTotal.toLocaleString(undefined, { maximumFractionDigits: 0 })

        if (isLocation === true)
        {
            const pctNationalAvg = Math.round((avgContributorTotal / 4795) * 100) - 100
            const highAvg = pctNationalAvg > 0
            content += ", which is "
                + Math.abs(pctNationalAvg).toLocaleString(undefined, { maximumFractionDigits: 0 }) + "% "
                + (highAvg ? "higher": "lower")
                + " than the national average."
        }
        else {
            content += "."
        }

        return content;
    }

}
