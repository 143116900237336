import React from "react";
import SiteHeader from "../../components/siteHeader";
import SiteFooter from "../../components/siteFooter";
import {Box, Breadcrumbs, Button, Link, Stack, Tooltip, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";

const CycleRollupBrowser = () => {
    let imageIconSrc = "/images/cycle_icon.png";

    return (
        <Stack>
            <Stack paddingBottom={10}>
                <SiteHeader />
            </Stack>
                <Stack direction="column">
                    <Stack id="back-to-top-anchor" direction="row" alignItems="center" justifyContent="space-between" paddingTop={1}>
                        <Breadcrumbs>
                            <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                            <Link component={RouterLink} to={"/totals"} underline={"hover"}>Totals</Link>
                            <Typography>Cycles</Typography>
                        </Breadcrumbs>
                    </Stack>

                    <Stack id="back-to-top-anchor" justifyContent={"left"} direction={"row"} alignItems={"center"} paddingTop={2} paddingBottom={2} spacing={2}>
                        <img height={40} width={40} src={imageIconSrc} loading="lazy" />
                        <Typography variant={"h4"}>Election Cycles</Typography>
                        <Typography color={"gray"} variant={"caption"} paddingTop={.5}>{"NUMERIC LIST OF ALL AVAILABLE ELECTION CYCLES"}</Typography>
                    </Stack>
                    <Box sx={{borderTopStyle:'dotted', borderColor:"#383838"}} paddingBottom={2}></Box>

                    <Stack direction="column" alignItems="center" padding={2} spacing={3} sx={{backgroundColor: '#20273C'}}>
                        <Tooltip title={"https://www." + window.location.hostname + "/cycle/2024"}>
                            <Button variant="outlined" sx={{minWidth:'350px'}} component={RouterLink} to="/cycle/2024">2023-2024</Button>
                        </Tooltip>

                        <Tooltip title={"https://www." + window.location.hostname + "/cycle/2022"}>
                            <Button variant="outlined" sx={{width:'350px'}} component={RouterLink} to="/cycle/2022">2021-2022</Button>
                        </Tooltip>

                        <Tooltip title={"https://www." + window.location.hostname + "/cycle/2020"}>
                            <Button variant="outlined" sx={{width:'350px'}} component={RouterLink} to="/cycle/2020">2019-2020</Button>
                        </Tooltip>

                        <Tooltip title={"https://www." + window.location.hostname + "/cycle/2018"}>
                            <Button variant="outlined" sx={{width:'350px'}} component={RouterLink} to="/cycle/2018">2017-2018</Button>
                        </Tooltip>

                        <Tooltip title={"https://www." + window.location.hostname + "/cycle/2016"}>
                            <Button variant="outlined" sx={{width:'350px'}} component={RouterLink} to="/cycle/2016">2015-2016</Button>
                        </Tooltip>

                        <Tooltip title={"https://www." + window.location.hostname + "/cycle/2014"}>
                            <Button variant="outlined" sx={{width:'350px'}} component={RouterLink} to="/cycle/2014">2013-2014</Button>
                        </Tooltip>

                        <Tooltip title={"https://www." + window.location.hostname + "/cycle/2012"}>
                            <Button variant="outlined" sx={{width:'350px'}} component={RouterLink} to="/cycle/2012">2011-2012</Button>
                        </Tooltip>

                        <Tooltip title={"https://www." + window.location.hostname + "/cycle/2010"}>
                            <Button variant="outlined" sx={{width:'350px'}} component={RouterLink} to="/cycle/2010">2009-2010</Button>
                        </Tooltip>

                        <Tooltip title={"https://www." + window.location.hostname + "/cycle/2008"}>
                            <Button variant="outlined" sx={{width:'350px'}} component={RouterLink} to="/cycle/2008">2007-2008</Button>
                        </Tooltip>
                    </Stack>

                </Stack>
                <SiteFooter/>
        </Stack>
    );
};

export default CycleRollupBrowser;
