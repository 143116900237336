import React, {useEffect} from "react";
import {Link as RouterLink, useParams} from "react-router-dom";

import SiteHeader from "../../components/siteHeader";
import SiteFooter from "../../components/siteFooter";
import CETotal from "../../components/ces/ceTotal";
import CETypeList from "../../components/ces/ceTypeList";
import CTTypeList from "../../components/cts/ctTypeList";
import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import ShareButton from "../../components/shareButton";
import ScrollToTop from "../../components/scrollToTop";
import MetaData from "../../components/metaData";
import * as DataLoader from "../../util/DataLoader";
import {madLibFactory} from "../../AppGlobal";

const VendorCycle = () => {
    const currentPageType = "vendor";
    const {cycleYear} = useParams();
    const {vendorKey} = useParams();

    const [success, setSuccess] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [fields, setFields] = React.useState([]);
    const [stats, setStats] = React.useState([]);

    useEffect(() => {
        const urlSuffix = "cycle/" + cycleYear + "/vendor/" + vendorKey;
        DataLoader.load(urlSuffix, (result, data) => {
            setLoaded(true)
            if (result === true)
            {
                setSuccess(true)
                setStats(data.statistics);
                setFields(data.fields);
            }
            else
            {
                setSuccess(false);
            }
        })
    }, [cycleYear, vendorKey])

    let metaTitle = "";
    let metaDescription = "";
    if (success === true)
    {
        const madLibsMeta = madLibFactory.getPageMetaCycle();

        metaTitle = madLibsMeta.buildTitle(currentPageType, cycleYear, fields.display, fields, stats);
        metaDescription = madLibsMeta.buildDescription(currentPageType, cycleYear, fields.display, fields, stats);
    }

    return (
        <div>
            <Stack paddingBottom={10}>
                <SiteHeader />
            </Stack>
            {
                loaded === false ?
                    (<h2>Loading data....</h2>) :
                    (<div className="Page-content">
                        {
                            success === false ?
                                (<h2>Unable to find the Vendor for {vendorKey}</h2>) :
                                (<React.Fragment>
                                    <MetaData
                                        title={metaTitle}
                                        description={metaDescription}
                                        url={window.location.href}
                                        image={"https://www.monecracy.com/images/logo.png"}/>

                                    <Stack direction="column" spacing={1} padding={0}>
                                        <Stack id="back-to-top-anchor" direction="row" alignItems="center" justifyContent="space-between" paddingTop={1}>
                                            <Breadcrumbs>
                                                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                                                <Link component={RouterLink} to={"/totals"} underline={"hover"}>Totals</Link>
                                                <Link component={RouterLink} to={"/cycles"} underline={"hover"}>Cycles</Link>
                                                <Link component={RouterLink} to={"/cycle/" + cycleYear} underline={"hover"}>{cycleYear}</Link>
                                                <Link component={RouterLink} to={"/cycle/" + cycleYear + "/vendors"} underline={"hover"}>Vendors</Link>
                                                <Typography>{fields.display}</Typography>
                                            </Breadcrumbs>
                                            <ShareButton title={"Monecracy: " + fields.display + " " + cycleYear} />
                                        </Stack>

                                        <CETotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_total"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                        />

                                        <CETypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_parties"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                            linkType="party"
                                            anchor="party_expenditure_totals"
                                        />
                                        <CETypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_committees_top"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                            linkType="committee"
                                            anchor="committee_expenditure_totals"
                                        />
                                        <CETypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_politicians_top"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                            linkType="candidate"
                                            anchor="politician_expenditure_totals"
                                        />
                                        <CETypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_offices_top"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                            linkType="office"
                                            anchor="office_expenditure_totals"
                                        />
                                        <CETypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_organizations"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                            linkType="organization"
                                            anchor="organization_expenditure_totals"
                                        />
                                        <CTTypeList
                                            typeHeader="Committee"
                                            linkType="committee"
                                            cycleYear={cycleYear}
                                            title="Top Vendors by Committee Transactions"
                                            subtitle=""
                                            anchor="committee_expenditure_totals"
                                            pageType={"organization"}
                                            display={fields.display}
                                            data={stats.ct_committees_top}
                                        />
                                    </Stack>

                                    <ScrollToTop />
                                </React.Fragment>)
                        }
                    </div>)
            }
            <SiteFooter/>
        </div>
    );
};

export default VendorCycle;
