import React from "react";
import {Link as RouterLink, Link} from "react-router-dom";

const SearchResults = (props) => {

    return (
        <div className="search-results-list">
            {
                props.results === undefined ?
                    (<span>No results</span>):
                    (<div>
                        {
                            props.results.map((result, index) => (
                                <p><Link component={RouterLink} to={".." + result.fields.path}>{result.fields.display}</Link></p>
                                ))
                        }
                    </div>
                    )
            }
        </div>
    );
};

export default SearchResults;
