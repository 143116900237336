import {Box, Fade, Tooltip, useScrollTrigger} from "@mui/material";
import React from "react";

const ScrollToTop = (props) => {
    const { children} = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        // target: window ? window() : undefined,
        // disableHysteresis: true,
        // threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#back-to-top-anchor',
        );

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
                behavior: "smooth"
            });
        }
    };

    return (
        <Fade in={trigger}>
            {
                <Tooltip title={"Back to Top"}>
                    <Box
                        onClick={handleClick}
                        role="presentation"
                        sx={{ position: 'fixed', bottom: 16, right: 16 }}>
                        {children}
                        <img className={"clickable"} height={75} width={75} src="/images/to_top_icon.png" />
                    </Box>
                </Tooltip>
            }
        </Fade>
    );
}

export default ScrollToTop
