import AMadLib from "../../AMadLib";

export default class MLRollupCTSType extends AMadLib
{
    constructor()
    {
        super();

        this.registry = {
            ct_summary: {
                title: "Committee Expenditure Totals by Transaction Type",
                subtitle: "Total expenditures by transaction type for all elections since 2008",
                chartTitle: "Committee Expenditure Totals by Transaction Type",
            },
            ct_committees_top: {
                title: "Top Committees by Total Expenditures to Vendor",
                subtitle: "Showing the top committees based on total expenditures to the vendor",
                chartTitle: "Top Committees by Total Expenditures to Vendor",
            },
            ct_support_top: {
                title: "Top Candidates by Total Committee Expenditures Supporting Candidates Election",
                subtitle: "Showing the top candidates based on total expenditures by committee supporting their election",
                chartTitle: "Top Candidates by Total Committee Expenditures Supporting Candidates Election",
            },
            ct_oppose_top: {
                title: "Top Candidates by Total Committee Expenditures Opposing Candidates Election",
                subtitle: "Showing the top candidates based on total expenditures by committee opposing their election",
                chartTitle: "Top Candidates by Total Committee Expenditures Opposing Candidates Election",
            },
            ct_comm_cand_oppose: {
                title: "Top Candidates by Total Committee Expenditures on Communications Against a Candidate",
                subtitle: "",
                chartTitle: "Top Candidates by Total Committee Expenditures on Communications Against a Candidate",
            },
            ct_comm_cand_support: {
                title: "Top Candidates by Total Committee Expenditures on Communications Supporting a Candidate",
                subtitle: "",
                chartTitle: "Top Candidates by Total Committee Expenditures on Communications Supporting a Candidate",
            },
            ct_vendors_top: {
                title: "Top Vendors by Total Committee Expenditures",
                subtitle: "",
                chartTitle: "Top Vendors by Total Committee Expenditures",
            },
            ct_nit_contrib: {
                title: "Top Native Indian Tribes by Total Contributions to Committee",
                subtitle: "",
                chartTitle: "Top Native Indian Tribes by Total Contributions to Committee",
            },
            ct_contrib_to_nonaffilcomm_top: {
                title: "Top Non-Affiliated Committees by Total Contributions from Committee",
                subtitle: "",
                chartTitle: "Top Non-Affiliated Committees by Total Contributions from Committee",
            },
            ct_contrib_from_nonaffilcomm_top: {
                title: "Top Non-Affiliated Committees by Total Contributions to Committee",
                subtitle: "",
                chartTitle: "Top Non-Affiliated Committees by Total Contributions to Committee",
            },
            ct_contrib_from_regfiler_top: {
                title: "Top Registered Filers by Total Contributions to Committee",
                subtitle: "",
                chartTitle: "Top Registered Filers by Total Contributions to Committee",
            },
            ct_regfiler_contrib_top: {
                title: "Top Registered Filers by Total Contributions from Committee",
                subtitle: "",
                chartTitle: "Top Registered Filers by Total Contributions from Committee",
            },
            ct_transfer_out_affilcomm_top: {
                title: "Top Affiliated Committees by Total Transfers Out from Committee",
                subtitle: "",
                chartTitle: "Top Affiliated Committees by Total Transfers Out from Committee",
            },
            ct_transfer_to_affilcomm_top: {
                title: "Top Affiliated Committees by Total Transfers Out from Other Committee",
                subtitle: "",
                chartTitle: "Top Affiliated Committees by Total Transfers Out from Other Committee",
            },
            ct_contrib_from_jointfund_top: {
                title: "Top Joint Fundraising Committees by Total Contributions to Committee",
                subtitle: "",
                chartTitle: "Top Joint Fundraising Committees by Total Contributions to Committee",
            },
            ct_contrib_to_jointfund_top: {
                title: "Top Joint Fundraising Committees by Total Contributions from Committee",
                subtitle: "",
                chartTitle: "Top Joint Fundraising Committees by Total Contributions from Committee",
            },
            ct_hqacct_regfiler_top: {
                title: "Top Registered Filers by Total HQ Account Receipts to Committee",
                subtitle: "",
                chartTitle: "Top Registered Filers by Total HQ Account Receipts to Committee",
            },
            ct_regfiler_hqacct_top: {
                title: "Top Committees by Total Contributions To HQ Account",
                subtitle: "",
                chartTitle: "Top Committees by Total Contributions To HQ Account",
            },
            ct_conacct_transfer_in_top: {
                title: "Top Affiliated Committees by Total Convention Account Transfer In to Committee",
                subtitle: "",
                chartTitle: "Top Affiliated Committees by Total Convention Account Transfer In to Committee",
            },
            ct_conacct_transfer_to_top: {
                title: "Top Committees by Total Contributions To Convention Account",
                subtitle: "",
                chartTitle: "Top Committees by Total Contributions To Convention Account",
            },
            ct_inkind_to_regfiler_top: {
                title: "Top Registered Filers from In-Kind Contributions from Committee",
                subtitle: "",
                chartTitle: "Top Registered Filers from In-Kind Contributions from Committee",
            },
            ct_inkind_from_committee_top: {
                title: "Top Committees by Total In-Kind Contributions to Committee",
                subtitle: "",
                chartTitle: "Top Committees by Total In-Kind Contributions to Committee",
            },
            ct_recount_from_regfiler_top: {
                title: "Top Registered Filers by Total Recount Account Contributions to Committee",
                subtitle: "",
                chartTitle: "Top Registered Filers by Total Recount Account Contributions to Committee",
            },
            ct_recount_to_regfiler_top: {
                title: "Top Committees by Total Recount Account Contributions to Committee",
                subtitle: "",
                chartTitle: "Top Committees by Total Recount Account Contributions to Committee",
            },
            ct_inkind_from_regfiler_top: {
                title: "Top Registered Filers by Total In-Kind Contributions to Committee",
                subtitle: "",
                chartTitle: "Top Registered Filers by Total In-Kind Contributions to Committee",
            },
            ct_inkind_to_committee_top: {
                title: "Top Committees by Total In-Kind Contributions to Committee",
                subtitle: "",
                chartTitle: "Top Committees by Total In-Kind Contributions to Committee",
            },
            ct_refund_to_comm_top: {
                title: "Top Committees by Total Refunds from Committee",
                subtitle: "",
                chartTitle: "Top Committees by Total Refunds from Committee",
            },
            ct_refund_from_comm_top: {
                title: "Top Committees by Total Refunds to Committee",
                subtitle: "",
                chartTitle: "Top Committees by Total Refunds to Committee",
            },
            ct_conacct_from_regfiler_top: {
                title: "Top Registered Filers by Total Convention Account Receipts",
                subtitle: "",
                chartTitle: "Top Registered Filers by Total Convention Account Receipts",
            },
            ct_regfiler_to_conacct_top: {
                title: "Top Committees from Registered Filer To Convention Account",
                subtitle: "",
                chartTitle: "Top Committees from Registered Filer To Convention Account",
            },
            ct_hqacct_from_comm_joint_top: {
                title: "Top Joint Fundraising Committees by Total HQ Account Receipt",
                subtitle: "",
                chartTitle: "Top Joint Fundraising Committees by Total HQ Account Receipt",
            },
            ct_hqacct_to_comm_joint_top: {
                title: "Top Joint Fundraising Committees by Total Contributions From HQ Account",
                subtitle: "",
                chartTitle: "Top Joint Fundraising Committees by Total Contributions From HQ Account",
            },
            ct_recount_from_comm_joint_top: {
                title: "Top Joint Fundraising Committees by Total Recount Account Receipt",
                subtitle: "",
                chartTitle: "Top Joint Fundraising Committees by Total Recount Account Receipt",
            },
            ct_recount_to_comm_joint_top: {
                title: "Top Joint Fundraising Committees by Total Contributions From Recount Accounts",
                subtitle: "",
                chartTitle: "Top Joint Fundraising Committees by Total Contributions From Recount Accounts",
            },
            ct_conacct_refund_to_regfiler_top: {
                title: "Top Registered Filers by Total Convention Account Refund to Committee",
                subtitle: "",
                chartTitle: "Top Registered Filers by Total Convention Account Refund to Committee",
            },
            ct_refiler_refund_from_conacct_top: {
                title: "Top Registered Filers by Total Refund from Convention Account",
                subtitle: "",
                chartTitle: "Top Registered Filers by Total Refund from Convention Account",
            },
            ct_recount_refund_to_regfiler_top: {
                title: "Top Registered Filer by Total Recount Account Refund",
                subtitle: "",
                chartTitle: "Top Registered Filer by Total Recount Account Refund",
            },
            ct_refiler_refund_from_recount_top: {
                title: "Top Registered Filers by Totals Refunds from Recount Account",
                subtitle: "",
                chartTitle: "Top Registered Filers by Totals Refunds from Recount Account",
            },
            ct_recount_disbursement_to_top: {
                title: "Recount Account Disbursements",
                subtitle: "",
                chartTitle: "Recount Account Disbursements",
            },
            ct_recount_disbursement_from_top: {
                title: "Disbursements From Recount Account",
                subtitle: "",
                chartTitle: "Disbursements From Recount Account",
            },
            ct_loan_repay_refiler_top: {
                title: "Top Registered Filer by Total Repayments Of Loans from Committee",
                subtitle: "",
                chartTitle: "Top Registered Filer by Total Repayments Of Loans from Committee",
            },
            ct_regfiler_loan_payment_top: {
                title: "Top Committees by Total Loan Payments from Committee",
                subtitle: "",
                chartTitle: "Top Committees by Total Loan Payments from Committee",
            },
            ct_hqacct_refund_regfiler_top: {
                title: "Top Registered Filers by Total HQ Account Refund from Committee",
                subtitle: "",
                chartTitle: "Top Registered Filers by Total HQ Account Refund from Committee",
            },
            ct_regfiler_hqacct_refund_top: {
                title: "Top Registered Filers by Total HQ Refunds to Committee",
                subtitle: "",
                chartTitle: "Top Registered Filers by Total HQ Refunds to Committee",
            },
            ct_contrib_unreg_org_top: {
                title: "Top Unregistered Organizations by Total Contributions to Committee",
                subtitle: "",
                chartTitle: "Top Unregistered Organizations by Total Contributions to Committee",
            },
            ct_loan_org_top: {
                title: "Top Banks by Total Loans to Committee",
                subtitle: "",
                chartTitle: "Top Banks by Total Loans to Committee",
            },
            ct_loan_from_cand_top: {
                title: "Top Candidates by Total Loans to Committee",
                subtitle: "",
                chartTitle: "Top Candidates by Total Loans to Committee",
            },
            ct_loan_repay_cand_top: {
                title: "Top Candidates by Total Loans Repayments from Committee",
                subtitle: "",
                chartTitle: "Top Candidates by Total Loans Repayments from Committee",
            },
            ct_cand_coorparty_exp_top: {
                title: "Top Candidates by Total Committee Coordinated Party Expenditure",
                subtitle: "",
                chartTitle: "Top Candidates by Total Committee Coordinated Party Expenditure",
            },
            ct_loan_to_comm_top: {
                title: "Top Loans to Committees from Candidate",
                subtitle: "",
                chartTitle: "Top Loans to Committees from Candidate",
            },
            ct_loan_repay_comm_top: {
                title: "Top Candidate Loans Repayments from Committees",
                subtitle: "",
                chartTitle: "Top Candidate Loans Repayments from Committees",
            },
            ct_comm_coorparty_exp_top: {
                title: "Top Candidate Coordinated Party Expenditure  from Committees",
                subtitle: "",
                chartTitle: "Top Candidate Coordinated Party Expenditure  from Committees",
            },
            ct_committee_contrib: {
                title: "Top Committee Contributions",
                subtitle: "",
                chartTitle: "Top Committee Contributions",
            },
            ct_contrib_unreg_committee_top: {
                title: "Top Committee Contributions Unregistered Committee",
                subtitle: "",
                chartTitle: "Top Committee Contributions Unregistered Committee",
            },
            ct_loan_comm_top: {
                title: "Top Committee Loan to Committee",
                subtitle: "",
                chartTitle: "Top Committee Loan to Committee",
            }
        }
    }

    getRegistration(statKey)
    {
        return this.registry[statKey];
    }

    buildTitle(pageType, linkType, statKey, display)
    {
        const regEntry = this.registry[statKey];
        if (regEntry !== undefined)
        {
            return regEntry.title;
        }

        return "MISSING CTS TYPE TITLE";
    }

    buildSubtitle(pageType, linkType, statKey, display)
    {
        const regEntry = this.registry[statKey];
        if (regEntry !== undefined)
        {
            return regEntry.subtitle;
        }

        return "MISSING CTS TYPE SUBTITLE";
    }

    buildChartTitle(pageType, linkType, statKey, display)
    {
        const regEntry = this.registry[statKey];
        if (regEntry !== undefined)
        {
            return regEntry.chartTitle;
        }

        return "MISSING CTS TYPE CHART TITLE";
    }

    buildContent(pageType, linkType, statKey, display, stats, data)
    {
        switch (statKey)
        {
            case 'ct_summary':
                return this.#buildCTSummary(display, stats, data);
            case 'ct_committees_top':
            case 'ct_support_top':
            case 'ct_oppose_top':
            case 'ct_comm_cand_oppose':
            case 'ct_comm_cand_support':
            case 'ct_vendors_top':
            case 'ct_nit_contrib':
            case 'ct_contrib_to_nonaffilcomm_top':
            case 'ct_contrib_from_nonaffilcomm_top':
            case 'ct_contrib_from_regfiler_top':
            case 'ct_regfiler_contrib_top':
            case 'ct_transfer_out_affilcomm_top':
            case 'ct_transfer_to_affilcomm_top':
            case 'ct_contrib_from_jointfund_top':
            case 'ct_contrib_to_jointfund_top':
            case 'ct_hqacct_regfiler_top':
            case 'ct_regfiler_hqacct_top':
            case 'ct_conacct_transfer_in_top':
            case 'ct_conacct_transfer_to_top':
            case 'ct_inkind_to_regfiler_top':
            case 'ct_inkind_from_committee_top':
            case 'ct_recount_from_regfiler_top':
            case 'ct_recount_to_regfiler_top':
            case 'ct_inkind_from_regfiler_top':
            case 'ct_inkind_to_committee_top':
            case 'ct_refund_to_comm_top':
            case 'ct_refund_from_comm_top':
            case 'ct_conacct_from_regfiler_top':
            case 'ct_regfiler_to_conacct_top':
            case 'ct_hqacct_from_comm_joint_top':
            case 'ct_hqacct_to_comm_joint_top':
            case 'ct_recount_from_comm_joint_top':
            case 'ct_recount_to_comm_joint_top':
            case 'ct_conacct_refund_to_regfiler_top':
            case 'ct_refiler_refund_from_conacct_top':
            case 'ct_recount_refund_to_regfiler_top':
            case 'ct_refiler_refund_from_recount_top':
            case 'ct_recount_disbursement_to_top':
            case 'ct_recount_disbursement_from_top':
            case 'ct_loan_repay_refiler_top':
            case 'ct_regfiler_loan_payment_top':
            case 'ct_hqacct_refund_regfiler_top':
            case 'ct_regfiler_hqacct_refund_top':
            case 'ct_contrib_unreg_org_top':
            case 'ct_loan_org_top':
            case 'ct_loan_from_cand_top':
            case 'ct_loan_repay_cand_top':
            case 'ct_cand_coorparty_exp_top':
                return "";
        }

        return "MISSING CTS TYPE CONTENT";
    }

    #buildCTSummary(display, stats, data)
    {
        let content = "Summary of the total expenditures for the " + display;
        content += " for all election cycles since 2008, sub-totaled by transaction type."
        return content;

    }
}
