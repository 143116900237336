import React from "react";
import {Stack, Typography} from "@mui/material";
import {madLibFactory} from "../../AppGlobal";

const CETotal = (props) => {
    if (props.stats === undefined)
    {
        return;
    }

    let renderData = props.stats[props.statsKey];
    if (renderData === undefined)
    {
        renderData = {
            amount: 0,
            count: 0
        };
    }

    const madLibs = madLibFactory.getCycleCESSummary();

    const renderTitle = madLibs.buildTitle(props.cycleYear, props.pageType, props.display);
    const renderCaption = madLibs.buildContent(props.cycleYear, props.pageType, props.display, props.stats, renderData);

    return (
        <div align={"left"}>
            {
                renderData === undefined ?
                    (<span>No data available</span>):
                    (
                        <Stack>
                            <Stack padding={2} justifyContent="flex-start" spacing={1} sx={{ backgroundColor: '#20273C'}}>
                                <a name="expenditure_totals"></a>
                                <Typography variant="subtitle1">{renderTitle}</Typography>
                                <Typography variant="caption">{renderCaption}</Typography>
                                <Stack direction="row" justifyContent="space-between" spacing={2} useFlexGap flexWrap="wrap">
                                    <Stack spacing={0} justifyContent="flex-start">
                                        <Typography sx={{color: "gray"}} variant="subtitle2">AMOUNT</Typography>
                                        <Typography variant="subtitle1"><font color="#85bb65">$</font>{renderData.amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</Typography>
                                    </Stack>
                                    <Stack spacing={0}>
                                        <Typography sx={{color: "gray"}} variant="subtitle2">COUNT</Typography>
                                        <Typography variant="subtitle1">{renderData.count.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    )
            }
        </div>
    );
};

export default CETotal;
