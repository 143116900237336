import AMadLib from "../../AMadLib";

export default class MLCycleUCSOrg extends AMadLib
{
    constructor(props)
    {
        super(props);
    }

    buildTitle(cycleYear, pageType, display)
    {
        let title = "Top Organization Contributors";
        if (pageType !== 'cycle')
        {
            const displayPrefix = " " + this.getDisplayPrefix(pageType) + " ";
            title += displayPrefix + display;
        }
        title += " for the " + cycleYear + " Elections";

        return title;
    }

    buildSubtitle(cycleYear, pageType, display)
    {
        let subtitle = "The top organization contributors based on total amount from an individual, organization, or political action committee (PAC)";
        if (pageType !== 'cycle')
        {
            const displayPrefix = " " + this.getDisplayPrefix(pageType) + " ";

            subtitle += displayPrefix + display;
        }
        subtitle += " for the " + cycleYear + " Elections" ;

        return subtitle;
    }

    buildContent(cycleYear, pageType, display, stats, data)
    {
        let totalAmount = 0;
        let totalICAmount = 0;
        let totalCTAmount = 0;
        let topAmount = 0;
        let topAmountDisplay = 0;

        if (data !== undefined)
        {
            for (const nextContributor of data)
            {
                totalAmount = totalAmount + nextContributor.amount;
                totalICAmount = totalICAmount + nextContributor.ic_amount;
                totalCTAmount = totalCTAmount + nextContributor.ct_amount;

                //Top State by Total Amount
                if (nextContributor.amount > topAmount)
                {
                    topAmount = nextContributor.amount;
                    topAmountDisplay = nextContributor.display;
                }
            }
        }

        const totalAll = stats.ic_total.amount;
        const totalTopContributorPCT = (totalAmount * 100/ totalAll);

        let content = "The top organization contributors";

        let pageTypePhrase = "";

        if (pageType === "cycle")
        {
            pageTypePhrase = " to federal elections "
        }
        if (pageType === "state")
        {
            pageTypePhrase = " in the state of " + display;
        }
        if (pageType === "county")
        {
            pageTypePhrase = " in " + display;
        }
        if (pageType === "city")
        {
            pageTypePhrase = " in the city of " + display;
        }
        if (pageType === "zipcode")
        {
            pageTypePhrase = " in the zip code of " + display;
        }
        else if (pageType === "party")
        {
            pageTypePhrase = " supporting the " + display;
        }
        else if (pageType === "politician")
        {
            pageTypePhrase = " supporting the politician " + display;
        }
        else if (pageType === "office")
        {
            pageTypePhrase = " supporting candidates running for the office of " + display;
        }
        else if (pageType === "surname")
        {
            pageTypePhrase = " with the surname " + display;
        }
        else if (pageType === "committee")
        {
            pageTypePhrase = " supporting the " + display;
        }
        else if (pageType === "organization")
        {
            pageTypePhrase = " associated to the organization " + display;
        }
        else if (pageType === "industry")
        {
            pageTypePhrase = " who work in the " + display + " industry";
        }
        content += pageTypePhrase + " donated a total of $";
        content += totalAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " for the " + cycleYear + " elections. ";

        content += "The total amount donated from direct committee contributors was $" + totalICAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "The total amount donated from a joint fundraising transactions was $" + totalCTAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "With " + topAmountDisplay + " being the highest contributor by total amount. ";
        content += "In total, the top 25 contributors account for " + totalTopContributorPCT.toLocaleString(undefined, { maximumFractionDigits: 0 })
        content += "% of all donations " + pageTypePhrase + " in the " + cycleYear + " elections.";

        return content;
    }
}
