import React from "react";
import {Link, Stack, Typography} from "@mui/material";
import FollowUs from "./followUs";
import {Link as RouterLink} from "react-router-dom";

const SiteFooter = () => {
    return (
        <Stack alignItems={"center"} direction="row" justifyContent={"space-between"} width="100%" useFlexGap flexWrap="wrap"
               sx={{backgroundColor:"#20273C", flexDirection: { xs: "column", md: "row"}}} padding={2} marginTop={2} marginBottom={2}>
            <Typography paddingBottom={2} variant="subtitle2">Copyright &copy;2024, All rights reserved</Typography>

            <FollowUs useTitle={false} title={""}></FollowUs>

            <Stack direction="row" paddingTop={2} spacing={2} sx={{flexDirection: { xs: "column", md: "row"}}}>
                <Link></Link>
                <Link component={RouterLink} to="/privacy">Privacy Policy</Link>
                <Link component={RouterLink} to="/cookies">Cookie Policy</Link>
                <Link component={RouterLink} to="/terms">Terms of Use</Link>
                <Link component={RouterLink} to="/about">About</Link>
            </Stack>
        </Stack>
    );
};

export default SiteFooter;
