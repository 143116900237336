import React, {useEffect} from "react";
import {Link as RouterLink, useParams} from "react-router-dom";

import SiteHeader from "../../components/siteHeader";
import SiteFooter from "../../components/siteFooter";
import UCSTotal from "../../components/ucs/ucsTotal";
import UCSTypeList from "../../components/ucs/ucsTypeList";
import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import ShareButton from "../../components/shareButton";
import ScrollToTop from "../../components/scrollToTop";
import MetaData from "../../components/metaData";
import * as DataLoader from "../../util/DataLoader";
import {madLibFactory} from "../../AppGlobal";

const ContributorCycle = () => {
    const currentPageType = 'contributor';
    const {cycleYear} = useParams();
    const {stateKey} = useParams();
    const {zipcodeKey} = useParams();
    const {contributorKey} = useParams();

    const [success, setSuccess] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [fields, setFields] = React.useState([]);
    const [stats, setStats] = React.useState([]);

    useEffect(() => {
        const urlSuffix = "cycle/" + cycleYear + "/contributor/" + stateKey + "/" + zipcodeKey + "/" + contributorKey;
        DataLoader.load(urlSuffix, (result, data) => {
            setLoaded(true)
            if (result === true)
            {
                setSuccess(true)
                setStats(data.statistics);
                setFields(data.fields);
            }
            else
            {
                setSuccess(false);
            }
        })
    }, [cycleYear, stateKey, zipcodeKey, contributorKey])

    let metaTitle = "";
    let metaDescription = "";
    if (success === true)
    {
        const madLibsMeta = madLibFactory.getPageMetaCycle();

        metaTitle = madLibsMeta.buildTitle(currentPageType, cycleYear, fields.display, fields, stats);
        metaDescription = madLibsMeta.buildDescription(currentPageType, cycleYear, fields.display, fields, stats);
    }

    return (
        <div>
            <Stack paddingBottom={10}>
                <SiteHeader />
            </Stack>
            {
                loaded === false ?
                    (<Typography variant="h6">Building Contributor Summary for the {cycleYear} Elections...</Typography>) :
                    (<div className="Page-content">
                        {
                            success === false ?
                                (<Typography variant="h6">Unable to build a Contributor Summary for {contributorKey} in {stateKey} from zip code {zipcodeKey} for the {cycleYear} Elections</Typography>) :
                                (<React.Fragment>
                                    <MetaData
                                        title={metaTitle}
                                        description={metaDescription}
                                        url={window.location.href}
                                        image={"https://www.monecracy.com/images/logo.png"}/>

                                    <Stack direction="column" spacing={1} padding={0}>
                                        <Stack id="back-to-top-anchor" direction="row" alignItems="center" justifyContent="space-between" paddingTop={1}>
                                            <Breadcrumbs>
                                                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                                                <Link component={RouterLink} to={"/totals"} underline={"hover"}>Totals</Link>
                                                <Link component={RouterLink} to={"/cycles"} underline={"hover"}>Cycles</Link>
                                                <Link component={RouterLink} to={"/cycle/" + cycleYear} underline={"hover"}>{cycleYear}</Link>
                                                <Link component={RouterLink} to={"/cycle/" + cycleYear + "/contributors"} underline={"hover"}>Contributors</Link>
                                                <Link component={RouterLink} to={"/cycle/" + cycleYear + "/state/" + stateKey} underline={"hover"}>{fields.stateDisplay}</Link>
                                                <Link component={RouterLink} to={"/cycle/" + cycleYear + "/zipcode/" + zipcodeKey} underline={"hover"}>{zipcodeKey}</Link>
                                                <Typography>{fields.display}</Typography>
                                            </Breadcrumbs>
                                            <ShareButton title={"Monecracy: Contributor Summary for " + fields.display + " for the " + cycleYear + " Elections"} />
                                        </Stack>

                                        <UCSTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_total"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                        />
                                        <UCSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_parties"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                            linkType="party"
                                            anchor="party_totals"
                                        />
                                        <UCSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_committees"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                            linkType="committee"
                                            anchor="committee_totals"
                                        />
                                        <UCSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_politicians"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                            linkType="candidate"
                                            anchor="politician_totals"
                                        />
                                        <UCSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_offices"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                            linkType="office"
                                            anchor="office_totals"
                                        />
                                        <UCSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_organizations"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                            linkType="organization"
                                            anchor="organization_totals"
                                        />
                                        <UCSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_occupations"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                            linkType="occupation"
                                            anchor="occupation_totals"
                                        />
                                        <UCSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_industries"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                            linkType="industry"
                                            anchor="industry_totals"
                                        />
                                    </Stack>

                                    <ScrollToTop />
                                </React.Fragment>)
                        }
                    </div>)
            }
            <SiteFooter/>
        </div>
    );
};

export default ContributorCycle;
