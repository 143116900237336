import React from "react";
import SiteHeader from "../components/siteHeader";
import SiteFooter from "../components/siteFooter";
import {Box, Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import ScrollToTop from "../components/scrollToTop";
import {Link as RouterLink} from "react-router-dom";

const Privacy = () => {
    return (
        <div>
            <Stack paddingBottom={10} id="back-to-top-anchor">
                <SiteHeader />
            </Stack>

            <Stack id="back-to-top-anchor" direction="row" alignItems="center" justifyContent="space-between" paddingTop={1} paddingBottom={1}>
                <Breadcrumbs>
                    <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                    <Typography>Privacy Policy</Typography>
                </Breadcrumbs>
            </Stack>

            <div className="Page-content" align="left" style={{ marginLeft: 25, marginTop: 10, maxWidth: 1000}}>
                <Typography variant="h5">Privacy Policy</Typography>
                <Typography variant="body1">
                    <p>This Application collects some Personal Data from its Users.</p>
                </Typography>
                <br/>

                <Typography variant="subtitle1"><strong>Personal Data processed for the following purposes and using the following services:</strong></Typography>
                <br/>
                <Stack direction="row" spacing={10} marginLeft={5}>
                    <Box maxWidth={400}>
                        <Typography variant="h6" gutterBottom>Advertising</Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            <p>Google AdSense and Amazon Advertising</p>
                            <p>Personal Data: Trackers; Usage Data</p>
                        </Typography>
                        <br/>
                        <Typography variant="h6" gutterBottom>Commercial affiliation</Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            <p>Amazon Affiliation</p>
                            <p>Personal Data: Trackers; Usage Data</p>
                        </Typography>
                        <br/>
                        <Typography variant="h6" gutterBottom>Hosting and backend infrastructure</Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            <p>Amazon Web Services (AWS)</p>
                            <p>Personal Data: various types of Data as specified in the privacy policy of the service</p>
                        </Typography>
                    </Box>
                    <Box maxWidth={400}>
                        <Typography variant="h6" gutterBottom>Analytics</Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            <p>Google Analytics</p>
                            <p>Personal Data: Trackers; Usage Data</p>
                        </Typography>
                        <br/>
                        <Typography variant="h6" gutterBottom>Displaying content from external platforms</Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            <p>Google Fonts</p>
                            <p>Personal Data: Trackers; Usage Data</p>
                        </Typography>
                    </Box>
                </Stack>

                <br/>
                <Typography variant="subtitle1"><strong>Information on opting out of interest-based advertising</strong></Typography>
                <Typography variant="body1" gutterBottom>
                    <p>In addition to any opt-out feature provided by any of the services listed in this document, Users may learn more on how to generally opt out of interest-based advertising within the dedicated section of the Cookie Policy.</p>
                </Typography>

                <br/>
                <Typography variant="h6" gutterBottom>Owner and Data Controller</Typography>
                <Typography variant="subtitle2" gutterBottom>
                    Monecracy.com<br/>
                    166 Geary St<br/>
                    15th Fl #1625<br/>
                    San Francisco, CA 94108<br/>
                    <strong>Contact email:</strong> legal@monecracy.com<br/>
                </Typography>

                <Box textAlign="right">
                    <Typography variant="subtitle2" gutterBottom>Last Updated: May 23, 2023</Typography>
                    <Link href="/privacy-full">Full Privacy Policy</Link>
                </Box>
            </div>
            <ScrollToTop />
            <SiteFooter/>
        </div>
    );
};

export default Privacy;
