import React, {useEffect, useRef} from 'react';
import {Alert, Breadcrumbs, Button, Link, Snackbar, Stack, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import axios from "axios";
import SiteHeader from "../components/siteHeader";
import SiteFooter from "../components/siteFooter";
import {Link as RouterLink} from "react-router-dom";

const Contact = () => {

    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [messageSeverity, setMessageSeverity] = React.useState("success");

    const [messageErrorName, setMessageErrorName] = React.useState(false);
    const [helperTextName, setHelperTextName] = React.useState("");
    const [messageErrorEmail, setMessageErrorEmail] = React.useState(false);
    const [helperTextEmail, setHelperTextEmail] = React.useState("");
    const [messageErrorMessage, setMessageErrorMessage] = React.useState(false);
    const [helperTextMessage, setHelperTextMessage] = React.useState("");

    const textName = useRef();
    const textEmail = useRef();
    const textMessage = useRef();

    function handleClose(event, reason){
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarOpen(false);
    }

    function validateField(inField) {
        if (inField === undefined || inField === "" || inField.match(/^ *$/) !== null || inField === null)
        {
            return false
        }

        return true
    }

    function validateEmail(emailField){
        var reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (reg.test(emailField) === false)
        {
            return false;
        }

        return true;
    }

    function clearForm()
    {
        textName.current.value = '';
        textEmail.current.value = '';
        textMessage.current.value = '';
    }

    function sendEmail()
    {
        const name = textName.current.value;
        const email = textEmail.current.value;
        const message = textMessage.current.value;

        //Check for blank form
        if (validateField(name) === false && validateField(email) === false && validateField(message) === false)
        {
            setAlertMessage("Please fill out the form before submitting");
            setMessageSeverity("error");
            setSnackBarOpen(true);
            return;
        }

        //Validate name
        if (validateField(name) === false)
        {
            setMessageErrorName(true)
            setHelperTextName("Please enter a name")
            return;
        }
        else
        {
            setMessageErrorName(false)
            setHelperTextName("")
        }

        //Validate email
        if (validateField(email) === false)
        {
            setMessageErrorEmail(true)
            setHelperTextEmail("Please enter an email")
            return;
        }
        else
        {
            setMessageErrorEmail(false)
            setHelperTextEmail("")
        }

        //Validate message
        if (validateField(message) === false)
        {
            setMessageErrorMessage(true)
            setHelperTextMessage("Please enter a message")
            return;
        }
        else
        {
            setMessageErrorMessage(false)
            setHelperTextMessage("")
        }

        //Validate email address validity
        if (validateEmail(email) === false)
        {
            setMessageErrorEmail(true)
            setHelperTextEmail("Please enter a valid email address")
            return;
        }
        else
        {
            setMessageErrorEmail(false)
            setHelperTextEmail("")
        }

        let postBody = {
            "name":name,
            "email":email,
            "message":message
        };

        const apiEndpoint = process.env.REACT_APP_API_ROOT + "contact";

        axios.post(apiEndpoint,
            postBody,
            {})
            .then((response) => response.data)
            .then((value) => {
                console.log(value)

                setAlertMessage("Message Sent, Thank you");
                setMessageSeverity("success");
                setSnackBarOpen(true);
                clearForm();
            })
            .catch((reason) => {
                console.log('Contact POST Error: ' + reason)

                setAlertMessage("Message could not be sent");
                setMessageSeverity("error");
                setSnackBarOpen(true);
            });
    }

    useEffect(() => {
    }, [textName]);

    return(
        <div>
            <Stack paddingBottom={10}>
                <SiteHeader />
            </Stack>
            <Stack direction="column" spacing={1} padding={0}>
                <Stack id="back-to-top-anchor" direction="row" alignItems="center" justifyContent="space-between" paddingTop={1}>
                    <Breadcrumbs>
                        <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                        <Typography>Contact Us</Typography>
                    </Breadcrumbs>
                </Stack>
                <Typography variant="subtitle1">We look forward to hearing from you</Typography>
                <Stack alignContent={"center"} spacing={2}>
                    <TextField error={messageErrorName} helperText={helperTextName} inputRef={textName} type={"text"} id={"name"} label={"Name"} ></TextField>
                    <TextField error={messageErrorEmail} helperText={helperTextEmail} inputRef={textEmail} type={"text"} id={"email"} label={"Email"} ></TextField>
                    <TextField error={messageErrorMessage} helperText={helperTextMessage} inputRef={textMessage} type={"text"} id={"message"} label={"Message"} multiline={true} rows={8} ></TextField>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="center">
                    <Button type={"submit"} variant={"outlined"} onClick={sendEmail} sx={{ width: 150 }}>Submit</Button>
                </Stack>
                <Snackbar open={snackBarOpen} autoHideDuration={5000} onClose={handleClose}>
                    <Alert variant={"filled"} onClose={handleClose} severity={messageSeverity}>{alertMessage}</Alert>
                </Snackbar>
            </Stack>
            <SiteFooter />
        </div>
    );

};

export default Contact;
