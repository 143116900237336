import {formatCurrency} from "../../App";

export default class MLPageMetaRollup {
    constructor() {
    }

    buildTitle(pageType, display, fields, stats) {
        var title = "";
        if (pageType === "vendor")
        {
            title = display + " Contributions from Committees Since 2008."
        }
        else
        {
            title = display + " Political Contributions Since 2008."
        }

        return title;
    }

    buildDescription(pageType, display, fields, stats) {
        let description = display;
        const resultsCount = 5;

        var organizations = [];
        var orgCount = 0;
        var contributors = [];
        var contribCount = 0;

        if (pageType === "vendor")
        {
            if (stats.ce_organizations !== undefined)
            {
                stats.ce_organizations.map(nextEntry => {
                    orgCount++;
                    if (orgCount > resultsCount)
                    {
                        return;
                    }

                    organizations.push(nextEntry["display"])
                });

                description = "Reports on " + formatCurrency(stats.ce_total.amount) + " in donations from companies to " + display + " including "
                    + organizations.join(", ");

                return description;
            }
        }
        else if (pageType === "contributor")
        {
            if (stats.uc_organizations !== undefined)
            {
                stats.uc_organizations.map(nextEntry => {
                    orgCount++;
                    if (orgCount > resultsCount)
                    {
                        return;
                    }

                    organizations.push(nextEntry["display"])
                });

                description = "Reports on " + formatCurrency(stats.uc_total.amount) + " in donations from organizations associated to " + display + " including "
                    + organizations.join(", ");

                return description;
            }
        }
        else if (pageType === "organization")
        {
            if (stats.uc_rollup_top !== undefined)
            {
                stats.uc_rollup_top.map(nextEntry => {
                    contribCount++;
                    if (contribCount > resultsCount)
                    {
                        return;
                    }

                    contributors.push(nextEntry["display"])
                });

                description = "Reports on " + formatCurrency(stats.ic_total.amount) + " in donations from individuals associated to " + display + " including "
                    + contributors.join(", ")

                return description;
            }
        }
        else
        {
            if (stats.ic_organizations_top !== undefined)
            {
                stats.ic_organizations_top.map(nextEntry => {
                    orgCount++;
                    if (orgCount > resultsCount)
                    {
                        return;
                    }

                    organizations.push(nextEntry["display"])
                });
            }

            if (stats.uc_rollup_top !== undefined)
            {
                stats.uc_rollup_top.map(nextEntry => {
                    contribCount++;
                    if (contribCount > resultsCount)
                    {
                        return;
                    }

                    contributors.push(nextEntry["display"])
                });
            }

            if (stats.ic_total === undefined)
            {
                return
            }
            if (pageType === "party" || pageType === "committee")
            {
                description = "Reports on " + formatCurrency(stats.ic_total.amount) + " in donations from individuals and companies associated to the " + display + " including "
                    + organizations.join(", ") + " "
                    + contributors.join(", ")

                return description;
            }
            else if (pageType === "politician" || pageType === "office")
            {
                description = "Reports on " + formatCurrency(stats.ic_total.amount) + " in donations from individuals and companies in support of " + display + " including "
                    + organizations.join(", ") + " "
                    + contributors.join(", ")

                return description;
            }
            else if(pageType === "industry")
            {
                description = "Reports on " + formatCurrency(stats.ic_total.amount) + " in donations from individuals and companies in the " + display + " industry including "
                    + organizations.join(", ") + " "
                    + contributors.join(", ")

                return description;
            }
            else if(pageType === "occupation")
            {
                description = "Reports on " + formatCurrency(stats.ic_total.amount) + " in donations from individuals and companies with the occupation of " + display + " including "
                    + organizations.join(", ") + " "
                    + contributors.join(", ")

                return description;
            }
            else if(pageType === "surname")
            {
                description = "Reports on " + formatCurrency(stats.ic_total.amount) + " in donations from individuals and companies associated to the surname " + display + " including "
                    + organizations.join(", ") + " "
                    + contributors.join(", ")

                return description;
            }
            else
            {
                description = "Reports on " + formatCurrency(stats.ic_total.amount) + " in donations from individuals and companies in " + display + " including "
                    + organizations.join(", ") + " "
                    + contributors.join(", ")

                return description;
            }
        }
    }
}
