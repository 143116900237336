import AMadLib from "../../AMadLib";

export default class MLRollupICSState extends AMadLib
{
    constructor()
    {
        super();
    }

    buildTitle(pageType, display)
    {
        let title = "Total Contributions";

        if (pageType !== 'summary')
        {
            const displayPrefix = " " + this.getDisplayPrefix(pageType) + " ";
            title += displayPrefix + display;
        }
        title += " by State";

        return title;
    }

    buildSubtitle(pageType, display)
    {
        let subtitle = "The total contributions from individuals, organizations, and political action committees (PAC)";
        if (pageType !== 'summary')
        {
            const displayPrefix = " " + this.getDisplayPrefix(pageType) + " ";
            subtitle += displayPrefix + display;
        }
        subtitle += " by state for all elections since 2008";

        return subtitle;
    }

    buildMapTitle(pageType, display)
    {
        return this.buildTitle(pageType, display);
    }

    buildContent(pageType, display, stats, data)
    {
        let totalAmount = 0;
        let totalIndivAmount = 0;
        let totalOrgAmount = 0;
        let totalPACAmount = 0;
        let topAmount = 0;
        let topAmountDisplay = 0;

        for (const nextState of data)
        {
            totalAmount = totalAmount + nextState.amount;
            totalIndivAmount = totalIndivAmount + nextState.indivAmount;
            totalOrgAmount = totalOrgAmount + nextState.orgAmount;
            totalPACAmount = totalPACAmount + nextState.pacAmount;

            //Top State by Total Amount
            if (nextState.amount > topAmount)
            {
                topAmount = nextState.amount;
                topAmountDisplay = nextState.state;
            }
        }

        let content;

        if (pageType === "summary")
        {
            content = "The total amount of money donated to federal elections from all states since 2008 is $"
        }
        else if (pageType === "party")
        {
            content = "The total amount of money donated to the " + display + " for federal elections from all states since 2008 is $"
        }
        else if (pageType === "politician")
        {
            content = "The total amount of money donated to the politician " + display + " for federal elections from all states since 2008 is $"
        }
        else if (pageType === "office")
        {
            content = "The total amount of money donated to the office " + display + " for federal elections from all states since 2008 is $"
        }
        else if (pageType === "surname")
        {
            content = "The total amount of money donated from the surname " + display + " for federal elections from all states since 2008 is $"
        }
        else if (pageType === "committee")
        {
            content = "The total amount of money donated to the committee " + display + " for federal elections from all states since 2008 is $"
        }
        else if (pageType === "occupation")
        {
            content = "The total amount of money donated from donors with the occupation of " + display + " for federal elections from all states since 2008 is $"
        }
        else if (pageType === "organization")
        {
            content = "The total amount of money donated from the organization " + display + " for federal elections from all states since 2008 is $"
        }
        else if (pageType === "industry")
        {
            content = "The total amount of money donated from the industry of " + display + " for federal elections from all states since 2008 is $"
        }
        else
        {
            content = "The total amount of money from " + display + " donated to federal elections from all states since 2008 is $"
        }

        content += totalAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ".";
        content += "Total amount from individual contributors is $" + totalIndivAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "Total amount from organization contributions is $" + totalOrgAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "Total amount from political actions committees contributions is $" + totalPACAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "With " + topAmountDisplay + " being the highest contributing state by total amount. ";

        return content;
    }

}
