import AMadLib from "../../AMadLib";

export default class MLRollupUCSTotal extends AMadLib
{
    constructor()
    {
        super();
    }

    buildTitle(pageType, display)
    {
        let title = "Total Contributions from " + display;

        return title;
    }

    buildContent(pageType, display, stats, data)
    {
        //Total donations
        let content = "The total amount donated by " + display;
        content += " to all elections since 2008 is $"
        content += data.amount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ". "

        //Average donation
        content += "This contributor has made a total of ";
        content += data.contributions.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " donations, with an average contribution of $"
        const avgAmount = data.amount / data.contributions;
        content += avgAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ". ";

        const avgNational = avgAmount * 100/ 4795;
        const moreLess = (avgNational < 100 ? "less": "more")
        content += "This contributor donates ";
        content += avgNational.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += "% " + moreLess + " than the average contributor.";

        return content;
    }
}
