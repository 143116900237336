import React from "react";
import {useNavigate} from "react-router-dom";
import {Stack, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import ToggleChart from "../toggleChart";
import RollupTableHeader from "../rollupTableHeader";
import {buildCESColumns} from "../../util/DataGridMaker";
import {madLibFactory} from "../../AppGlobal";

const RollupCECycleList = (props) => {

    let navigate = useNavigate();

    const [sortModel, setSortModel] = React.useState([
        {
            field: 'display',
            sort: 'desc',
        },
    ]);

    if (props.stats === undefined)
    {
        return;
    }

    const renderData = props.stats[props.statsKey];

    const madLibs = madLibFactory.getRollupCESCycle();

    const renderTitle = madLibs.buildTitle(props.pageType, props.display);
    const renderSubtitle = madLibs.buildSubtitle(props.pageType, props.display);
    const renderCaption = madLibs.buildContent(props.pageType, props.display, props.stats, renderData);

    const chartWidth = (window.innerWidth > 1000 ? 1175: window.innerWidth - 20);
    const imageIconSrc = "/images/cycle_icon.png"

    const columns = buildCESColumns('display', 'Year', 150, 175);

    const handleOnCellClick = (params) => {
        navigate("/cycle/" + params.row.typeKey);
    };

    return (
        <Stack align="center" paddingTop={"5px"} paddingBottom={"5px"}>
            <RollupTableHeader
                title={renderTitle} subtitle={renderSubtitle}
                imgSource={imageIconSrc}
                anchor="cycle_expenditure_totals"
                showAllType="cycles" showAllTitle="Show All Cycles"/>
            <Stack direction="column" sx={{ alignItems: "center", backgroundColor: '#20273C', padding: 2}}>
                {
                    renderData === undefined ?
                        (<span>No data available</span>):
                        (
                            <Stack width={'100%'}>
                                <Typography align={"left"} variant="caption">{renderCaption}</Typography>
                                <ToggleChart width={chartWidth} height={500}
                                             title="Totals by Cycle Year"
                                             seriesName="Cycle Year"
                                             chartData={renderData}
                                             plotField="amount"
                                             displayField="display"
                                             isCES={true}
                                />
                                <DataGrid
                                    disableColumnMenu={true}
                                    getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
                                    sx={{ width:"100%", border: 0}}
                                    getRowId={(row) => row.display}
                                    rows={renderData}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    hideFooterPagination={false}
                                    sortModel={sortModel}
                                    sortingOrder ={['asc', 'desc']}
                                    onSortModelChange={(model) => setSortModel(model)}
                                    autoHeight={true}
                                    onRowClick={handleOnCellClick}
                                />
                            </Stack>
                        )
                }
            </Stack>
        </Stack>
    );
};

export default RollupCECycleList;
