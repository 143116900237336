import AMadLib from "../../AMadLib";
import {typeDetails} from "../../../AppGlobal";

export default class MLCycleCESType extends AMadLib
{
    constructor()
    {
        super();
    }

    buildTitle(cycleYear, pageType, linkType, display)
    {
        const linkTypeDetails = typeDetails[linkType];

        let title = "Top " + linkTypeDetails.displayPlural + " by Total Expenditures";
        if (pageType !== 'cycle')
        {
            const expensePrefix = this.getExpensePrefix(pageType);
            title += expensePrefix + display;
        }
        title += " for the " + cycleYear + " Elections";

        return title;
    }

    buildSubtitle(cycleYear, pageType, linkType, display)
    {
        const linkTypeDetails = typeDetails[linkType];

        let subtitle = "The top ";
        subtitle += linkTypeDetails.displayPlural.toLowerCase();
        subtitle += " based on total expenditures";
        subtitle += " for the " + cycleYear + " elections.";

        return subtitle;
    }

    buildChartTitle(cycleYear, pageType, linkType, display)
    {
        return this.buildTitle(cycleYear, pageType, linkType, display);
    }

    buildContent(cycleYear, pageType, linkType, display, stats, data)
    {
        const linkTypeDetails = typeDetails[linkType];

        let totalAmount = 0;
        let topAmount = 0;
        let topAmountDisplay = 0;

        if (data !== undefined)
        {
            for (const nextType of data)
            {
                totalAmount = totalAmount + nextType.amount;

                //Top State by Total Amount
                if (nextType.amount > topAmount)
                {
                    topAmount = nextType.amount;
                    topAmountDisplay = nextType.display;
                }
            }
        }

        let topAmountPct = (topAmount * 100) / totalAmount;

        const linkVerb = (linkType === "committee" ? "spending": "receiving");
        const linkDirection = (linkType === "committee" ? "by": "to");

        let content = "The total expenditures (spending)";

        if (pageType === "cycle")
        {
            content += " " + linkDirection + " ";
        }
        else if (pageType === "party")
        {
            content += " for all committees affiliated with the " + display;
        }
        else if (pageType === "politician")
        {
            content += " for all committees supporting the election of the politician " + display;
        }
        else if (pageType === "office")
        {
            content += " for all committees on elections for the office of " + display;
        }
        else if (pageType === "committee")
        {
            content += " for the " + display;
        }
        else if (pageType === "organization")
        {
            content += " for all committees associated to the " + display + " organization"
        }

        if (linkType === "committee")
        {
            content += " the top " + linkTypeDetails.displayPlural.toLowerCase();
        }
        else if (linkType === "vendor")
        {
            content += " the top " + linkTypeDetails.displayPlural.toLowerCase();
        }
        content += " for the " + cycleYear + " elections was $"

        content += totalAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ". ";

        if (linkType === "committee")
        {
            content += "The " + linkTypeDetails.display.toLowerCase() + " with the highest expenditures was " + topAmountDisplay;
        }
        else if (linkType === "vendor")
        {
            content += "The " + linkTypeDetails.display.toLowerCase() + " that received the most revenue from committee expenditures was " + topAmountDisplay;
        }

        content += ", " + linkVerb + " a total of $";
        content += topAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ", which represents ";
        content += topAmountPct.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += "% of total spending in the " + cycleYear + " elections.";

        return content;
    }

}
