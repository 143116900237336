export default class AMadLib
{
    constructor()
    {
    }

    calculateRenderWidth(offset)
    {
        return (window.innerWidth > 1000 ? 1175: window.innerWidth - offset);;
    }

    calculateRenderHeight(renderData, topList, itemHeight)
    {
        let height = 0;

        if (renderData !== undefined)
        {
            if (topList === true)
            {
                height = itemHeight * 25;
            }
            else
            {
                height = (renderData.length * itemHeight) + 50;
                //Return a minimum of 300 to show chart elements on small lists
                height = Math.max(300, height)
            }
        }

        return height;
    }

    isLocation(pageType)
    {
        return (pageType === 'state' || pageType === 'county' || pageType === 'city' || pageType === 'zipcode');
    }

    getDisplayPrefix(pageType)
    {
        const locationPage = this.isLocation(pageType);
        if (locationPage === true)
        {
            return "from";
        }
        else if (pageType === 'cycle')
        {
            return "for the "
        }
        else if (pageType === 'candidate'
            || pageType === 'politician')
        {
            return "to "
        }
        else if (pageType === 'committee')
        {
            return "to the "
        }
        else if (pageType === 'office')
        {
            return "for the "
        }
        else if (pageType === 'party')
        {
            return "to the "
        }
        else if (pageType === 'surname')
        {
            return "with the Surname of "
        }

        return "from";
    }

    getDisplayPhrase(pageType, display)
    {
        if (pageType === "summary")
        {
            return "";
        }
        else if (pageType === "cycle")
        {
            return display + " Cycle";
        }
        else if (pageType === "state")
        {
            return  "from the state of " + display;
        }
        else if (pageType === "county")
        {
            return "from " + display;
        }
        else if (pageType === "city")
        {
            return "from the city of " + display;
        }
        else if (pageType === "zipcode")
        {
            return "from the zipcode of " + display;
        }
        else if (pageType === "party")
        {
            return "to the " + display + " party";
        }
        else if (pageType === "politician")
        {
            return "to the politician " + display;
        }
        else if (pageType === "office")
        {
            return "for the office of " + display;
        }
        else if (pageType === "surname")
        {
            return "from contributors with the surname of " + display;
        }
        else if (pageType === "committee")
        {
            return "to the " + display + " committee";
        }
        else if (pageType === "organization")
        {
            return "from the organization " + display;
        }
        else if (pageType === "industry")
        {
            return "from the " + display + " industry";
        }

        return "MISSING";
    }

    getExpensePrefix(pageType)
    {
        const locationPage = this.isLocation(pageType);
        if (locationPage === true)
        {
            return " in ";
        }
        else if (pageType === 'cycle')
        {
            return " for the "
        }
        else if (pageType === 'candidate'
            || pageType === 'politician')
        {
            return " by "
        }
        else if (pageType === 'committee')
        {
            return " from the "
        }
        else if (pageType === 'office')
        {
            return " for the "
        }
        else if (pageType === 'party')
        {
            return " by the "
        }

        return " from ";
    }
}
