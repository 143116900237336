import AMadLib from "../../AMadLib";

export default class MLRollupUCSCycle extends AMadLib
{
    constructor()
    {
        super();
    }

    buildTitle(pageType, display)
    {
        let title = "Total Contributions from " + display;
        title += " by Election Cycle"

        return title;
    }

    buildSubtitle(pageType, display)
    {
        let subtitle = "The total contributions made by " + display;
        subtitle += " for all elections since 2008";

        return subtitle;
    }

    buildChartTitle(pageType, display)
    {
        return this.buildTitle(pageType, display);
    }

    buildContent(pageType, display, stats, data)
    {
        let totalAmount = 0;
        let topAmount = 0;
        let topAmountType = 0;

        if (data !== undefined)
        {
            for (const nextType of data)
            {
                totalAmount = totalAmount + nextType.amount;

                //Top State by Total Amount
                if (nextType.amount > topAmount)
                {
                    topAmount = nextType.amount;
                    topAmountType = nextType.display;
                }
            }
        }
        const topAmountPct = (topAmount * 100)/totalAmount;

        let content;
        content = "The total amount of money donated by " + display;
        content += " in all elections since 2008 is $"
        content += totalAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ". ";

        content += "The " + topAmountType + " election had the highest totals contributions, donating $";
        content += topAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ", which is " + topAmountPct.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += "% of the total amount donated by this contributor.";

        return content;
    }
}
