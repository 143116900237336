import {Box, Card, CardMedia, Stack, Typography} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";

const OverlayImage = ({
    title,
    subtitle,
    linkPath,
    imageSource,
    imageWidth,
    imageHeight = 300,
    shapeColor = 'rgba(211,0,36,0.75)',
    shapeRadiusPct = '50%',
    shapeTop = -20,
    shapeLeft = 50,
    shapeWidth = 350,
    shapeHeight = 350,
    shapePadding = 3
}) => {

    const navigate = useNavigate();

    function navigateToLink()
    {
        navigate(linkPath);
    }

    return(
        <Card sx={{
            position: 'relative'
        }}>
            <CardMedia title={title} image={imageSource} onClick={navigateToLink} sx={{
                width: imageWidth,
                height: imageHeight
            }} />
            <Stack onClick={navigateToLink}
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={1.5}
                sx={{
                    position: 'absolute',
                    borderRadius: shapeRadiusPct,
                    top: shapeTop,
                    left: shapeLeft,
                    width: shapeWidth,
                    height: shapeHeight,
                    padding: shapePadding,
                    backgroundColor: shapeColor
                }}>
                <Typography variant="h7" sx={{
                    fontWeight: 800,
                    fontFamily: 'Arial'
                }}>{title.toUpperCase()}</Typography>
                <Box sx={{
                    width: 200,
                    height: 10,
                    borderRadius: 1,
                    backgroundColor: 'rgba(255,255,255,0.75)'
                }} />
                <Typography variant="subtitle2" sx={{
                    fontFamily: 'Arial'
                }}>{subtitle}</Typography>
            </Stack>
            {/*<Stack>*/}
            {/*    <Link to={props.link}><img src={props.imageSource}/></Link>*/}
            {/*</Stack>*/}
        </Card>
    )
};

export default OverlayImage;
