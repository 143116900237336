import React, {useEffect} from "react";
import {Link as RouterLink, useParams} from "react-router-dom";

import SiteHeader from "../../components/siteHeader";
import SiteFooter from "../../components/siteFooter";
import RollupCycleList from "../../components/ics/rollupCycleList";
import RollupICSStateList from "../../components/ics/rollupICSStateList";
import RollupICSCountyList from "../../components/ics/rollupICSCountyList";
import RollupICSCityList from "../../components/ics/rollupICSCityList";
import RollupICSZipcodeList from "../../components/ics/rollupICSZipcodeList";
import RollupICSTypeList from "../../components/ics/rollupICSTypeList";
import RollupUCSList from "../../components/ucs/rollupUCSList";
import RollupCEStateList from "../../components/ces/rollupCEStateList";
import RollupCECountyList from "../../components/ces/rollupCECountyList";
import RollupCECityList from "../../components/ces/rollupCECityList";
import RollupCETypeList from "../../components/ces/rollupCETypeList";
import RollupICSTotal from "../../components/ics/rollupICSTotal";
import RollupCETotal from "../../components/ces/rollupCETotal";
import RollupCTTypeList from "../../components/cts/rollupCTTypeList";
import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import ShareButton from "../../components/shareButton";
import ScrollToTop from "../../components/scrollToTop";
import MetaData from "../../components/metaData";
import * as DataLoader from "../../util/DataLoader";
import RollupCTTotal from "../../components/cts/rollupCTTotal";
import RollupCECycleList from "../../components/ces/rollupCECycleList";
import {madLibFactory} from "../../AppGlobal";
import RollupUCSOrg from "../../components/ucs/rollupUCSOrg";
import RollupUCSPAC from "../../components/ucs/rollupUCSPAC";

const OrganizationRollup = () => {
    const currentPageType = "organization";
    const {organizationKey} = useParams();

    const [success, setSuccess] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [fields, setFields] = React.useState([]);
    const [stats, setStats] = React.useState([]);

    useEffect(() => {
        const urlSuffix = "organization/" + organizationKey;
        DataLoader.load(urlSuffix, (result, data) => {
            setLoaded(true);
            if (result === true)
            {
                setSuccess(true)
                setStats(data.statistics);
                setFields(data.fields);
            }
            else
            {
                setSuccess(false);
            }
        })
    }, [organizationKey])

    let metaTitle = "";
    let metaDescription = "";
    if (success === true)
    {
        const madLibsMeta = madLibFactory.getPageMetaRollup();

        metaTitle = madLibsMeta.buildTitle(currentPageType, fields.display, fields, stats);
        metaDescription = madLibsMeta.buildDescription(currentPageType, fields.display, fields, stats);
    }

    return (
        <div>
            <Stack paddingBottom={10}>
                <SiteHeader />
            </Stack>
            {
                loaded === false ?
                    (<Typography variant="h6">Building Organization Summary...</Typography>) :
                    (<div className="Page-content">
                        {
                            success === false ?
                                (<Typography variant="h6">Unable to build an Organization Summary for {organizationKey}</Typography>) :
                                (<React.Fragment>
                                    <MetaData
                                        title={metaTitle}
                                        description={metaDescription}
                                        url={window.location.href}
                                        image={"https://www.monecracy.com/images/logo.png"}/>

                                    <Stack direction="column" spacing={1} padding={0}>
                                        <Stack id="back-to-top-anchor" direction="row" alignItems="center" justifyContent="space-between" paddingTop={1}>
                                            <Breadcrumbs>
                                                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                                                <Link component={RouterLink} to={"/totals"} underline={"hover"}>Totals</Link>
                                                <Link component={RouterLink} to={"/organizations"} underline={"hover"}>Organizations</Link>
                                                <Typography>{fields.display}</Typography>
                                            </Breadcrumbs>
                                            <ShareButton title={"Monecracy: " + fields.display} />
                                        </Stack>

                                        <RollupICSTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_total"
                                            display={fields.display}
                                        />
                                        <RollupCycleList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_cycle_totals"
                                            display={fields.display}
                                        />
                                        <RollupICSStateList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_states"
                                            display={fields.display}
                                        />
                                        <RollupICSCountyList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_counties_top"
                                            display={fields.display}
                                        />
                                        <RollupICSCityList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_cities_top"
                                            display={fields.display}
                                        />
                                        <RollupICSZipcodeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_zipcodes_top"
                                            display={fields.display}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_parties"
                                            statsTop={false}
                                            linkType="party"
                                            anchor="party_totals"
                                            display={fields.display}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_politicians_top"
                                            statsTop={true}
                                            linkType="politician"
                                            anchor="politician_totals"
                                            display={fields.display}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_offices_top"
                                            statsTop={true}
                                            linkType="office"
                                            anchor="office_totals"
                                            display={fields.display}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_committees_top"
                                            statsTop={true}
                                            linkType="committee"
                                            anchor="committee_totals"
                                            display={fields.display}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_industries_top"
                                            statsTop={true}
                                            linkType="industry"
                                            anchor="industry_totals"
                                            display={fields.display}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_occupations_top"
                                            statsTop={true}
                                            linkType="occupation"
                                            anchor="occupation_totals"
                                            display={fields.display}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_surnames_top"
                                            statsTop={true}
                                            linkType="surname"
                                            anchor="surname_totals"
                                            display={fields.display}
                                        />
                                        <RollupUCSList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_indiv_top"
                                            display={fields.display}
                                            linkType={"contributor"}
                                        />
                                        <RollupUCSOrg
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_org_top"
                                            display={fields.display}
                                            linkType={"organization"}
                                        />
                                        <RollupUCSPAC
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_pac_top"
                                            display={fields.display}
                                            linkType={"committee"}
                                        />
                                        <RollupCETotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_total"
                                            display={fields.display}
                                        />
                                        <RollupCECycleList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_cycle_totals"
                                            display={fields.display}
                                        />
                                        <RollupCEStateList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_states"
                                            display={fields.display}
                                        />
                                        <RollupCECountyList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_county_top"
                                            display={fields.display}
                                        />
                                        <RollupCECityList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_city_top"
                                            display={fields.display}
                                        />
                                        <RollupCETypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_committees_top"
                                            linkType="committee"
                                            display={fields.display}
                                            anchor="committee_expenditure_totals"
                                        />
                                        <RollupCETypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_vendors_top"
                                            linkType="vendor"
                                            display={fields.display}
                                            anchor="vendor_expenditure_totals"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_committee_contrib"
                                            typeHeader="Organization"
                                            linkType="organization"
                                            title="Top Contributions from Native Indian Tribes to Committees"
                                            subtitle="Top Contributions from Native Indian Tribes to Committees"
                                            anchor=""
                                            display={fields.display}
                                        />
                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_unreg_committee_total"
                                            title="Total Unregistered Organization Contributions to Committees"
                                            subtitle=""
                                            anchor=""
                                        />
                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_unreg_committee_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            title="Top Unregistered Organization Contributions to Committees"
                                            subtitle=""
                                            anchor=""
                                            display={fields.display}
                                        />
                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_loan_comm_total"
                                            title="Total Organization Loans to Committees"
                                            subtitle=""
                                            anchor=""
                                        />
                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_loan_comm_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            title="Top Organization Loans to Committees"
                                            subtitle=""
                                            anchor=""
                                            display={fields.display}
                                        />
                                    </Stack>

                                    <ScrollToTop />
                                </React.Fragment>)
                        }
                    </div>)
            }
            <SiteFooter/>
        </div>
    );
};

export default OrganizationRollup;
