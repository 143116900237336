import React from "react";
import SiteHeader from "../../components/siteHeader";
import SiteFooter from "../../components/siteFooter";
import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import ContributorAlphaBrowser from "../../components/contributorAlphaBrowser";
import {Link as RouterLink} from "react-router-dom";
import MetaData from "../../components/metaData";

const ContributorRollupBrowser = () => {
    return (
        <div>
            <Stack paddingBottom={10}>
                <SiteHeader />
            </Stack>
            <div className="Page-content">
                <MetaData
                    title={"Contributor Browser"}
                    description={"Browse by all Contributors"}
                    url={window.location.href}
                    image={"https://www.monecracy.com/images/logo.png"}/>
                <Stack direction="row" alignItems="center" justifyContent="space-between" paddingTop={1}>
                    <Breadcrumbs>
                        <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                        <Link component={RouterLink} to={"/totals"} underline={"hover"}>Totals</Link>
                        <Typography>Contributors</Typography>
                    </Breadcrumbs>
                </Stack>
                <ContributorAlphaBrowser cycleYear={0} title="Browse Contributors" typeHeader={"Contributors"} type={"contributor"} service={"contributors"} linkPath={"../contributor"} />
            </div>
            <SiteFooter/>
        </div>
    );
};

export default ContributorRollupBrowser;
