import {Stack, Typography} from "@mui/material";
import React, {useEffect, useMemo} from "react";
import * as DataLoader from "../../util/DataLoader";

import SiteHeader from "../../components/siteHeader";
import SiteFooter from "../../components/siteFooter";
import HomePageCarousel from "../../components/home/HomePageCarousel";
import CardViewer from "../../components/cards/CardViewer";
import {homeCard} from "../../components/cards/AppCards";
import ScrollToTop from "../../components/scrollToTop";

const Home = () => {
    const currentPageType = "home";

    const [success, setSuccess] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [sections, setSections] = React.useState([]);

    useEffect(() => {
        DataLoader.loadHome("home", (result, data) => {
            setLoaded(true)
            if (result === true)
            {
                setSuccess(true)
                setSections(data.sections);
            }
            else
            {
                setSuccess(false);
            }
        })
    }, []);

    const dataSets = useMemo(() => {

        const results = [];
        for (const nextKey in sections)
        {
            const nextSet = sections[nextKey];
            results.push(nextSet);
        }
        return results;
    }, [sections]);

    return(
        <Stack>
            <Stack paddingBottom={10} id="back-to-top-anchor">
                <SiteHeader />
            </Stack>
            {
                loaded === false ?
                    (
                        <Typography variant="h6">Building Home Page</Typography>) :
                    (
                        <Stack spacing={1}>

                            <HomePageCarousel />

                            <Stack spacing={1}>
                            {
                                dataSets.map((nextSet, pos) => {
                                    return (<CardViewer
                                        key={nextSet.key}
                                        dataset={nextSet}
                                        renderer={homeCard}
                                    />)
                                })
                            }
                            </Stack>

                            <ScrollToTop />
                        </Stack>
                    )
            }
            <SiteFooter/>
        </Stack>
    );
};

export default Home
