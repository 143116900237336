import React from "react";
import SiteHeader from "../../components/siteHeader";
import SiteFooter from "../../components/siteFooter";
import AlphaBrowser from "../../components/alphaBrowser";
import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";

const TagBrowser = () => {
    return (
        <div>
            <Stack paddingBottom={10}>
                <SiteHeader />
            </Stack>
            <div className="Page-content">
                <Stack direction="row" alignItems="center" justifyContent="space-between" paddingTop={1}>
                    <Breadcrumbs>
                        <Link href={"/"} underline={"hover"}>Home</Link>
                        <Typography>Tags</Typography>
                    </Breadcrumbs>
                </Stack>
                <AlphaBrowser cycleYear={0} title="Browse by Tag" typeHeader={"Tags"} type={"tag"} service={"tags"} linkPath={"../tag"} />
            </div>
            <SiteFooter/>
        </div>
    );
};

export default TagBrowser;
