import React from 'react';
import ReactGA from 'react-ga4';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {createTheme, responsiveFontSizes, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import About from "./pages/about";
import Privacy from "./pages/privacy";
import Search from './pages/search';
import Terms from "./pages/terms";

import CityCycle from './pages/city/cityCycle';
import CityRollup from './pages/city/cityRollup';
import CommitteeCycle from './pages/committee/committeeCycle';
import CommitteeCycleBrowser from "./pages/committee/committeeCycleBrowser";
import CommitteeRollup from './pages/committee/committeeRollup';
import CommitteeRollupBrowser from './pages/committee/committeeRollupBrowser';
import ContributorCycle from './pages/contributor/contributorCycle';
import ContributorCycleBrowser from "./pages/contributor/contributorCycleBrowser";
import ContributorRollup from './pages/contributor/contributorRollup';
import ContributorRollupBrowser from './pages/contributor/contributorRollupBrowser';
import CountyCycle from './pages/county/countyCycle';
import CountyRollup from './pages/county/countyRollup';
import ArticleBrowser from "./pages/article/articleBrowser";
import ArticleViewer from "./pages/article/articleViewer";
import OccupationCycle from './pages/occupation/occupationCycle';
import OccupationCycleBrowser from "./pages/occupation/occupationCycleBrowser";
import OccupationRollup from './pages/occupation/occupationRollup';
import OccupationRollupBrowser from './pages/occupation/occupationRollupBrowser';
import CycleRollup from './pages/cycle/cycleRollup';
import CycleRollupBrowser from './pages/cycle/cycleRollupBrowser';
import OfficeRollup from "./pages/office/officeRollup";
import OfficeRollupBrowser from "./pages/office/officeRollupBrowser";
import OfficeCycle from "./pages/office/officeCycle";
import OfficeCycleBrowser from "./pages/office/officeCycleBrowser";
import OrganizationCycle from './pages/organization/organizationCycle';
import OrganizationCycleBrowser from "./pages/organization/organizationCycleBrowser";
import OrganizationRollup from './pages/organization/organizationRollup';
import OrganizationRollupBrowser from './pages/organization/organizationRollupBrowser';
import PartyCycle from './pages/party/partyCycle';
import PartyCycleBrowser from "./pages/party/partyCycleBrowser";
import PartyRollup from './pages/party/partyRollup';
import PartyRollupBrowser from './pages/party/partyRollupBrowser';
import PoliticianCycle from "./pages/politician/politicianCycle";
import PoliticianRollup from "./pages/politician/politicianRollup";
import PoliticianRollupBrowser from "./pages/politician/politicianRollupBrowser";
import StateCycle from './pages/state/stateCycle';
import StateCycleBrowser from "./pages/state/stateCycleBrowser";
import StateRollup from './pages/state/stateRollup';
import StateRollupBrowser from './pages/state/stateRollupBrowser';
import SurnameCycle from './pages/surname/surnameCycle';
import SurnameCycleBrowser from "./pages/surname/surnameCycleBrowser";
import SurnameRollup from './pages/surname/surnameRollup';
import SurnameRollupBrowser from './pages/surname/surnameRollupBrowser';
import TagBrowser from "./pages/tag/tagBrowser";
import TagViewer from "./pages/tag/tagViewer";
import VendorCycle from './pages/vendor/vendorCycle';
import VendorCycleBrowser from "./pages/vendor/vendorCycleBrowser";
import VendorRollup from './pages/vendor/vendorRollup';
import VendorRollupBrowser from './pages/vendor/vendorRollupBrowser';
import ZipcodeCycle from './pages/zipcode/zipcodeCycle';
import ZipcodeCycleBrowser from "./pages/zipcode/zipcodeCycleBrowser";
import ZipcodeRollup from './pages/zipcode/zipcodeRollup';
import ZipcodeRollupBrowser from './pages/zipcode/zipcodeRollupBrowser';
import IndustryCycleBrowser from "./pages/industry/industryCycleBrowser";
import IndustryCycle from "./pages/industry/industryCycle";
import IndustryRollupBrowser from "./pages/industry/industryRollupBrowser";
import IndustryRollup from "./pages/industry/industryRollup";
import Cookies from "./pages/cookies";
import PrivacyFull from "./pages/privacyFull";
import Contact from "./pages/contact";
import Totals from "./pages/totals/totals";
import Home from "./pages/home/home";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

let darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: '#171C2B',
    },
    typography: {
        fontSize: 16,
    }
});
darkTheme = responsiveFontSizes(darkTheme);

export function formatNumber(count) {
    return `${count.toLocaleString(undefined, { maximumFractionDigits:0})}`
}

export function formatCurrency(amount) {
    return `$${amount.toLocaleString(undefined, { maximumFractionDigits:0})}`
}

function App() {

  return (
      <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <div className="App">
              <div className="App-content">
                  <Router>
                      <Routes>
                          <Route exact path='/' element={<Home />} />
                          <Route path='/totals' element={<Totals />} />
                          <Route path='/contact' element={<Contact/>} />
                          <Route path='/about' element={<About/>} />
                          <Route path='/privacy' element={<Privacy/>} />
                          <Route path='/privacy-full' element={<PrivacyFull/>} />
                          <Route path='/search' element={<Search />} />
                          <Route path='/terms' element={<Terms/>} />
                          <Route path='/cookies' element={<Cookies/>} />
                          <Route path='/summary' element={<Totals />} />
                          <Route path='/committees' element={<CommitteeRollupBrowser/>} />
                          <Route path='/committee/:committeeKey' element={<CommitteeRollup/>} />
                          <Route path='/contributors' element={<ContributorRollupBrowser/>} />
                          <Route path='/contributor/:stateKey/:zipcodeKey/:contributorKey' element={<ContributorRollup/>} />
                          <Route path='/cycles' element={<CycleRollupBrowser/>} />
                          <Route path='/cycle/:cycleYear' element={<CycleRollup/>} />
                          <Route path='/cycle/:cycleYear/state/:stateKey' element={<StateCycle/>} />
                          <Route path='/cycle/:cycleYear/states' element={<StateCycleBrowser/>} />
                          <Route path='/cycle/:cycleYear/state/:stateKey/county/:countyKey' element={<CountyCycle/>} />
                          <Route path='/cycle/:cycleYear/state/:stateKey/city/:cityKey' element={<CityCycle/>} />
                          <Route path='/cycle/:cycleYear/zipcode/:zipKey' element={<ZipcodeCycle/>} />
                          <Route path='/cycle/:cycleYear/zipcodes' element={<ZipcodeCycleBrowser/>} />
                          <Route path='/cycle/:cycleYear/candidate/:officeKey/:politicianKey' element={<PoliticianCycle/>} />
                          <Route path='/cycle/:cycleYear/committee/:committeeKey' element={<CommitteeCycle/>} />
                          <Route path='/cycle/:cycleYear/committees' element={<CommitteeCycleBrowser/>} />
                          <Route path='/cycle/:cycleYear/contributor/:stateKey/:zipcodeKey/:contributorKey' element={<ContributorCycle/>} />
                          <Route path='/cycle/:cycleYear/contributors' element={<ContributorCycleBrowser/>} />
                          <Route path='/cycle/:cycleYear/industry/:industryKey' element={<IndustryCycle/>} />
                          <Route path='/cycle/:cycleYear/industries' element={<IndustryCycleBrowser/>} />
                          <Route path='/cycle/:cycleYear/occupation/:occupationKey' element={<OccupationCycle/>} />
                          <Route path='/cycle/:cycleYear/occupations' element={<OccupationCycleBrowser/>} />
                          <Route path='/cycle/:cycleYear/office/:officeKey' element={<OfficeCycle/>} />
                          <Route path='/cycle/:cycleYear/offices' element={<OfficeCycleBrowser/>} />
                          <Route path='/cycle/:cycleYear/organization/:organizationKey' element={<OrganizationCycle/>} />
                          <Route path='/cycle/:cycleYear/organizations' element={<OrganizationCycleBrowser/>} />
                          <Route path='/cycle/:cycleYear/party/:partyKey' element={<PartyCycle/>} />
                          <Route path='/cycle/:cycleYear/parties' element={<PartyCycleBrowser/>} />
                          <Route path='/cycle/:cycleYear/surname/:surnameKey' element={<SurnameCycle/>} />
                          <Route path='/cycle/:cycleYear/surnames' element={<SurnameCycleBrowser/>} />
                          <Route path='/cycle/:cycleYear/vendor/:vendorKey' element={<VendorCycle/>} />
                          <Route path='/cycle/:cycleYear/vendors' element={<VendorCycleBrowser/>} />
                          <Route path='/industries' element={<IndustryRollupBrowser/>} />
                          <Route path='/industry/:industryKey' element={<IndustryRollup/>} />
                          <Route path='/articles' element={<ArticleBrowser/>} />
                          <Route path='/article/:articleKey' element={<ArticleViewer/>} />
                          <Route path='/occupations' element={<OccupationRollupBrowser/>} />
                          <Route path='/occupation/:occupationKey' element={<OccupationRollup/>} />
                          <Route path='/offices' element={<OfficeRollupBrowser/>} />
                          <Route path='/office/:officeKey' element={<OfficeRollup/>} />
                          <Route path='/organizations' element={<OrganizationRollupBrowser/>} />
                          <Route path='/organization/:organizationKey' element={<OrganizationRollup/>} />
                          <Route path='/parties' element={<PartyRollupBrowser/>} />
                          <Route path='/party/:partyKey' element={<PartyRollup/>} />
                          <Route path='/politicians' element={<PoliticianRollupBrowser/>} />
                          <Route path='/politician/:politicianKey' element={<PoliticianRollup/>} />
                          <Route path='/states' element={<StateRollupBrowser/>} />
                          <Route path='/state/:stateKey' element={<StateRollup/>} />
                          <Route path='/state/:stateKey/city/:cityKey' element={<CityRollup/>} />
                          <Route path='/state/:stateKey/county/:countyKey' element={<CountyRollup/>} />
                          <Route path='/surnames' element={<SurnameRollupBrowser/>} />
                          <Route path='/surname/:surnameKey' element={<SurnameRollup/>} />
                          <Route path='/tags' element={<TagBrowser/>} />
                          <Route path='/tag/:tagKey' element={<TagViewer/>} />
                          <Route path='/vendors' element={<VendorRollupBrowser/>} />
                          <Route path='/vendor/:vendorKey' element={<VendorRollup/>} />
                          <Route path='/zipcodes' element={<ZipcodeRollupBrowser/>} />
                          <Route path='/zipcode/:zipcodeKey' element={<ZipcodeRollup/>} />
                      </Routes>
                  </Router>
              </div>
          </div>
      </ThemeProvider>
  );
}

export default App;
