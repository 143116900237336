import {Link, Stack, Typography} from "@mui/material";
import React from "react";
import {Link as RouterLink} from "react-router-dom";

const RollupTableHeader = (props) => {
    return(
        <Stack direction="column">
            <a name={props.anchor}></a>
            <Stack direction="row" alignItems="center" justifyContent="space-between" paddingTop={2}>
                <Stack direction="row" alignItems="center">
                    <img height={32} width={32} src={props.imgSource} alt="" loading="lazy" />
                    <Typography sx={{paddingLeft:1}} align="left" variant="subtitle1">{ props.title }</Typography>
                </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" paddingTop={1} paddingBottom={1}>
                <Typography color="gray" align="left" variant="caption">{ props.subtitle }</Typography>
                {
                    props.showAllType !== "counties" && props.showAllType !== "cities"?
                        (<Link variant="subtitle2" component={RouterLink} to={"/" + props.showAllType} >{props.showAllTitle}</Link>):(<div></div>)
                }
            </Stack>
        </Stack>
    )
};

export default RollupTableHeader;
