import React, {useEffect} from "react";
import {Link as RouterLink, useParams} from "react-router-dom";

import SiteHeader from "../../components/siteHeader";
import SiteFooter from "../../components/siteFooter";
import RollupCETypeList from "../../components/ces/rollupCETypeList";
import RollupCECycleList from "../../components/ces/rollupCECycleList";
import RollupCETotal from "../../components/ces/rollupCETotal";
import RollupCTTypeList from "../../components/cts/rollupCTTypeList";
import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import ShareButton from "../../components/shareButton";
import ScrollToTop from "../../components/scrollToTop";
import MetaData from "../../components/metaData";
import * as DataLoader from "../../util/DataLoader";
import {madLibFactory} from "../../AppGlobal";

const VendorRollup = () => {
    const currentPageType = "vendor";
    const {vendorKey} = useParams();

    const [success, setSuccess] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [fields, setFields] = React.useState([]);
    const [stats, setStats] = React.useState([]);

    useEffect(() => {
        const urlSuffix = "vendor/" + vendorKey;
        DataLoader.load(urlSuffix, (result, data) => {
            setLoaded(true);
            if (result === true)
            {
                setSuccess(true)
                setStats(data.statistics);
                setFields(data.fields);
            }
            else
            {
                setSuccess(false);
            }
        })
    }, [vendorKey])

    let metaTitle = "";
    let metaDescription = "";
    if (success === true)
    {
        const madLibsMeta = madLibFactory.getPageMetaRollup();

        metaTitle = madLibsMeta.buildTitle(currentPageType, fields.display, fields, stats);
        metaDescription = madLibsMeta.buildDescription(currentPageType, fields.display, fields, stats);
    }

    return (
        <div>
            <Stack paddingBottom={10}>
                <SiteHeader />
            </Stack>
            {
                loaded === false ?
                    (<Typography variant="h6">Building Vendor Summary...</Typography>) :
                    (<div className="Page-content">
                        {
                            success === false ?
                                (<Typography variant="h6">Unable to build a Vendor Summary for {vendorKey}</Typography>) :
                                (<React.Fragment>
                                    <MetaData
                                        title={metaTitle}
                                        description={metaDescription}
                                        url={window.location.href}
                                        image={"https://www.monecracy.com/images/logo.png"}/>

                                    <Stack direction="column" spacing={1} padding={0}>
                                        <Stack id="back-to-top-anchor" direction="row" alignItems="center" justifyContent="space-between" paddingTop={1}>
                                            <Breadcrumbs>
                                                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                                                <Link component={RouterLink} to={"/totals"} underline={"hover"}>Totals</Link>
                                                <Link component={RouterLink} to={"/vendors"} underline={"hover"}>Vendors</Link>
                                                <Typography>{fields.display}</Typography>
                                            </Breadcrumbs>
                                            <ShareButton title={"Monecracy: " + fields.display} />
                                        </Stack>

                                        <RollupCETotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_total"
                                            display={fields.display}
                                        />
                                        <RollupCECycleList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_cycle_totals"
                                            display={fields.display}
                                        />
                                        <RollupCETypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_parties"
                                            linkType="party"
                                            display={fields.display}
                                            anchor="party_expenditure_totals"
                                        />
                                        <RollupCETypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_committees_top"
                                            linkType="committee"
                                            display={fields.display}
                                            anchor="committee_expenditure_totals"
                                        />
                                        <RollupCETypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_candidates"
                                            linkType="politician"
                                            display={fields.display}
                                            anchor="politician_expenditure_totals"
                                        />
                                        <RollupCETypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_offices_top"
                                            linkType="office"
                                            display={fields.display}
                                            anchor="office_expenditure_totals"
                                        />
                                        <RollupCETypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_organizations"
                                            linkType="organization"
                                            display={fields.display}
                                            anchor="organization_expenditure_totals"
                                        />
                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_committees_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="committee_expenditure_totals"
                                            display={fields.display}
                                        />
                                    </Stack>

                                    <ScrollToTop />
                                </React.Fragment>)
                        }
                    </div>)
            }
            <SiteFooter/>
        </div>
    );
};

export default VendorRollup;
