import React from "react";
import {Stack, Typography} from "@mui/material";
import {madLibFactory} from "../../AppGlobal";

const RollupCETotal = (props) => {

    if (props.stats === undefined)
    {
        return;
    }

    let renderData = props.stats[props.statsKey];
    if (renderData === undefined)
    {
        renderData = {
          amount: 0,
          count: 0
        };
    }

    const madLibs = madLibFactory.getRollupCESSummary();

    const renderTitle = madLibs.buildTitle(props.pageType, props.display);
    const renderCaption = madLibs.buildContent(props.pageType, props.display, props.stats, renderData);

    return (
        <Stack align="left" paddingTop={"5px"} paddingBottom={"5px"}>
            {
                renderData === undefined ?
                    (<span>No data available</span>):
                    (
                        <Stack direction={"column"}>
                            <Stack padding={2} spacing={1} sx={{ backgroundColor: '#20273C', justifyContent: 'flex-start'}}>
                                <a name="expenditure_totals"></a>
                                <Typography variant="h6">{renderTitle}</Typography>
                                <Typography variant="caption">{renderCaption}</Typography>
                                <Stack direction="row" sx={{justifyContent: 'space-evenly'}}>
                                    <Stack spacing={0} justifyContent="flex-start">
                                        <Typography sx={{color: "gray"}} variant="subtitle1">AMOUNT</Typography>
                                        <Typography variant="h5"><font color="#85bb65">$</font>{renderData.amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</Typography>
                                    </Stack>
                                    <Stack spacing={0}>
                                        <Typography sx={{color: "gray"}} variant="subtitle1">COUNT</Typography>
                                        <Typography variant="h5">{renderData.count.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    )
            }
        </Stack>
    );
};

export default RollupCETotal;
