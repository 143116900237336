import AMadLib from "../../AMadLib";
import {typeDetails} from "../../../AppGlobal";

export default class MLCycleUCSType extends AMadLib
{
    constructor()
    {
        super();
    }

    buildTitle(cycleYear, pageType, linkType, display)
    {
        const linkTypeDetails = typeDetails[linkType];

        let title = "Total Contributions from " + display;
        title += " by " + linkTypeDetails.display;
        title += " for the " + cycleYear + " Elections";

        return title;
    }

    buildSubtitle(cycleYear, pageType, linkType, display)
    {
        const linkTypeDetails = typeDetails[linkType];

        let subtitle = "The total contributions made by " + display + " by ";
        if (linkType === 'organization' || linkType === 'occupation' || linkType === 'industry')
        {
            subtitle += "associated "
        }
        subtitle += linkTypeDetails.display.toLowerCase();

        subtitle += " for the " + cycleYear + " elections";

        return subtitle
    }

    buildChartTitle(cycleYear, pageType, linkType, display)
    {
        return this.buildTitle(cycleYear, pageType, linkType, display);
    }

    buildContent(cycleYear, pageType, linkType, display, stats, data)
    {
        let totalAmount = 0;
        let topAmount = 0;
        let topAmountType = 0;

        if (data !== undefined)
        {
            for (const nextType of data)
            {
                totalAmount = totalAmount + nextType.amount;

                //Top State by Total Amount
                if (nextType.amount > topAmount)
                {
                    topAmount = nextType.amount;
                    topAmountType = nextType.display;
                }
            }
        }
        const topAmountPct = (topAmount * 100)/totalAmount;

        const linkTypeDetails = typeDetails[linkType];

        let content = "The total amount of money donated by " + display;
        if (linkType === 'organization' || linkType === 'occupation' || linkType === 'industry')
        {
            content += " from an associated "
        }
        else
        {
            content += " to "
        }
        content += linkTypeDetails.display.toLowerCase();

        content += " in the " + cycleYear + " elections was $"
        content += totalAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ". ";

        content += topAmountType + " has the most contributions, receiving $";
        content += topAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ", which is " + topAmountPct.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += "% of the total amount donated by this contributor in this election year.";

        return content;
    }
}
