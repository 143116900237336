import AMadLib from "../../AMadLib";

export default class MLCycleICSState extends AMadLib
{
    constructor(props)
    {
        super(props);
    }

    buildTitle(cycleYear, pageType, display)
    {
        let title = "Total Contributions";

        if (pageType !== 'cycle')
        {
            const displayPrefix = " " + this.getDisplayPrefix(pageType) + " ";
            title += displayPrefix + display;
        }
        title += " by State for the " + cycleYear + " Elections";

        return title;
    }

    buildSubtitle(cycleYear, pageType, display)
    {
        let subtitle = "The total contributions from individuals, organizations, and political action committees (PAC)";
        if (pageType !== 'cycle')
        {
            const displayPrefix = " " + this.getDisplayPrefix(pageType) + " ";
            subtitle += displayPrefix + display;
        }
        subtitle += " by state for the " + cycleYear + " elections";

        return subtitle;
    }

    buildMapTitle(cycleYear, pageType, display)
    {
        return this.buildTitle(cycleYear, pageType, display);
    }

    buildContent(cycleYear, pageType, display, stats, data)
    {
        let cycleYearRange = (cycleYear - 1) + " - " + cycleYear;

        let totalAmount = 0;
        let totalIndivAmount = 0;
        let totalOrgAmount = 0;
        let totalPACAmount = 0;
        let topAmount = 0;
        let topAmountDisplay = 0;

        if (data !== undefined)
        {
            for (const nextState of data)
            {
                totalAmount = totalAmount + nextState.amount;
                totalIndivAmount = totalIndivAmount + nextState.indivAmount;
                totalOrgAmount = totalOrgAmount + nextState.orgAmount;
                totalPACAmount = totalPACAmount + nextState.pacAmount;

                //Top State by Total Amount
                if (nextState.amount > topAmount)
                {
                    topAmount = nextState.amount;
                    topAmountDisplay = nextState.state;
                }
            }
        }

        let content;

        if (pageType === "cycle")
        {
            content = "During the " + cycleYearRange + " elections, the total amount of money donated to federal elections from all states was $"
        }
        else if (pageType === "party")
        {
            content = "During the " + cycleYearRange + " elections, the total amount of money donated to the " + display + " for federal elections from all states was $"
        }
        else if (pageType === "politician")
        {
            content = "During the " + cycleYearRange + " elections, the total amount of money donated to the politician " + display + " for federal elections from all states was $"
        }
        else if (pageType === "office")
        {
            content = "During the " + cycleYearRange + " elections, the total amount of money donated to the office " + display + " for federal elections from all states was $"
        }
        else if (pageType === "surname")
        {
            content = "During the " + cycleYearRange + " elections, the total amount of money donated from the surname " + display + " for federal elections from all states was $"
        }
        else if (pageType === "committee")
        {
            content = "During the " + cycleYearRange + " elections, the total amount of money donated to the committee " + display + " for federal elections from all states was $"
        }
        else if (pageType === "occupation")
        {
            content = "During the " + cycleYearRange + " elections, the total amount of money donated from donors with the occupation of " + display + " for federal elections from all states was $"
        }
        else if (pageType === "organization")
        {
            content = "During the " + cycleYearRange + " elections, the total amount of money donated from the organization " + display + " for federal elections from all states was $"
        }
        else if (pageType === "industry")
        {
            content = "During the " + cycleYearRange + " elections, the total amount of money donated from the industry of " + display + " for federal elections from all states was $"
        }

        content += totalAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ".";
        content += "The total amount donated from individual contributors was $" + totalIndivAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "The total amount donated from organizations was $" + totalOrgAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "The total amount donated from political action committees was $" + totalPACAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += topAmountDisplay + " was the highest contributing state by total amount, giving a total of $";
        content += topAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ".";

        return content;
    }

}
