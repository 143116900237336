import React, {useEffect} from "react";
import {Box, Stack, ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";
// import proj4 from 'proj4';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import highchartsAccessibility from "highcharts/modules/accessibility";
import exportingOption from "highcharts/modules/exporting";
import offlineOption from "highcharts/modules/offline-exporting";
import {
    buildNationalBubblesCES,
    buildNationalColorCES,
    buildNationalCountyCES,
    buildNationalMarkersCES,
    buildStateBubblesCES,
    buildStateCountyCES,
    buildStateMarkersCES
} from "../util/MapMaker";
import MapData from "../util/mapData";
import {useNavigate} from "react-router-dom";

require('highcharts/modules/map')(Highcharts);
highchartsAccessibility(Highcharts);
exportingOption(Highcharts);
offlineOption(Highcharts);

const MapViewer = (props) => {

    const [plotAmountSelected, setPlotAmountSelected] = React.useState(true);
    const [plotCountSelected, setPlotCountSelected] = React.useState(false);

    let [mapLoaded, setMapLoaded] = React.useState(false);
    let [mapShapes, setMapShapes] = React.useState(undefined);
    let [plotField, setPlotField] = React.useState('amount');
    let [plotCurrency, setPlotCurrency] = React.useState(true);
    let [plotTitle, setPlotTitle] = React.useState("");

    let navigate = useNavigate();

    //Load the shapes for the map based on the type
    let mapData = new MapData();
    if (mapLoaded === false) {
        if (props.mapType === 'national-ces') {
            mapData.getUS().then((response) => {
                setMapShapes(response.data);
                setMapLoaded(true);
            });
        }
        else if (props.mapType === 'national-county-ces') {
            mapData.getUSCounty().then((response) => {
                // console.log(response)
                setMapShapes(response.data);
                setMapLoaded(true);
            });
        }
        else if (props.mapType === 'national-markers-ces' || props.mapType === 'national-bubbles-ces') {
            mapData.getUSFull().then((response) => {
                setMapShapes(response.data);
                setMapLoaded(true);
            });
        }
        else if (props.mapType === 'state-county-ces' || props.mapType === 'state-markers-ces' || props.mapType === 'state-bubbles-ces')
        {
            mapData.getUSState(props.mapState).then((response) => {
                setMapShapes(response.data);
                setMapLoaded(true);
            });
        }
    }

    const plotOptions = {
        amount:{
            title: "Total money spent by committees (PAC) contributions",
            currency: true
        },
        count :{
            title: "Total number of contributions",
            currency: false
        },
    }

    const changePlotField = (changePlot) =>
    {
        clearPlotFieldSelection()

        const plotSettings = plotOptions[changePlot];
        setPlotCurrency(plotSettings.currency);
        setPlotTitle(plotSettings.title);

        if (changePlot === "amount")
        {
            setPlotAmountSelected(true)
        }
        if (changePlot === "count")
        {
            setPlotCountSelected(true)
        }

        setPlotField(changePlot);
    }

    function handleClick(entry) {
        navigate(entry.link);
    }

    const loadMapOptions = () => {

        //Set the title and subTitle when still waiting on map data to load
        if (mapLoaded === false)
        {
            return {
                title: {
                    align: 'left',
                    text: props.mapTitle,
                    style: {
                        color: "#fff",
                        fontSize: '14px',
                        fontFamily: 'Nunito'
                    }
                },
                subtitle: {
                    align: 'left',
                    text: "No map available",
                    style: {
                        color: "gray",
                        fontSize: '12px',
                        fontFamily: 'Nunito'
                    }
                }
            };
        }

        let mapOptions;
        let displayField;

        if (props.mapType === 'national-ces')
        {
            displayField = "state";
            mapOptions = buildNationalColorCES(props.mapTitle, plotTitle, mapShapes, props.mapData, plotField, plotCurrency, displayField);
        }
        else if (props.mapType === 'national-county-ces')
        {
            displayField = "county";
            mapOptions = buildNationalCountyCES(props.mapTitle, plotTitle, mapShapes, props.mapData, plotField, plotCurrency, displayField);
        }
        else if (props.mapType === 'national-markers-ces')
        {
            displayField = props.linkType;
            mapOptions = buildNationalMarkersCES(props.mapTitle, plotTitle, mapShapes, props.mapData, plotField, plotCurrency, displayField, props.linkType, props.linkField);
        }
        else if (props.mapType === 'national-bubbles-ces')
        {
            displayField = props.linkType;
            mapOptions = buildNationalBubblesCES(props.mapTitle, plotTitle, mapShapes, props.mapData, plotField, plotCurrency, displayField, props.linkType, props.linkField);
        }
        else if (props.mapType === 'state-county-ces')
        {
            displayField = "county";
            mapOptions = buildStateCountyCES(props.mapTitle, plotTitle, mapShapes, props.mapData, plotField, plotCurrency, displayField);
        }
        else if (props.mapType === 'state-markers-ces')
        {
            displayField = props.linkType;
            mapOptions = buildStateMarkersCES(props.mapTitle, plotTitle, mapShapes, props.mapData, plotField, plotCurrency, displayField, props.linkType, props.linkField);
        }
        else if (props.mapType === 'state-bubbles-ces')
        {
            displayField = props.linkType;
            mapOptions = buildStateBubblesCES(props.mapTitle, plotTitle, mapShapes, props.mapData, plotField, plotCurrency, displayField, props.linkType, props.linkField);
        }

//        mapOptions.chart.width = props.width;
        mapOptions.chart.width = null;
        mapOptions.chart.height = props.height;

        mapOptions.plotOptions = {
            series:{
                point:{
                    events:{
                        click: function() {
                            handleClick(this);
                        }
                    }
                }
            }
        }

        return mapOptions;
    }

    const clearPlotFieldSelection = () =>
    {
        setPlotAmountSelected(false)
        setPlotCountSelected(false)
    }

    useEffect(() => {
        setPlotTitle("Total money spent committees (PAC) contributions");
        setPlotField("amount");
        setPlotCurrency(true);
    }, []);

    return (
        <Stack direction="column" alignItems="flex-start" justifyContent={"space-between"}>
            <Stack direction="column" alignItems="stretch" justifyContent="flex-start" width="100%">
                {
                    mapShapes === 'undefined' ? (<span>Map not available</span>):
                        (<HighchartsReact
                        highcharts={Highcharts}
                        constructorType={"mapChart"}
                        options={loadMapOptions()}
                    />)
                }
            </Stack>
            <Box sx={{ backgroundColor: '#20273C'}} width="100%">
                <Stack direction="row" alignItems="flex-start" justifyContent="space-between" width={props.width} padding={1}>
                    {
                        ((props.showPlotOptions === undefined || props.showPlotOptions === false) ? (<span></span>):
                            (
                                <ToggleButtonGroup variant="contained" aria-label="outlined button group">
                                    <Tooltip title="By Total Amount ($)">
                                        <ToggleButton sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} value='amount' onClick={event => changePlotField('amount')}
                                                      style={{backgroundColor: plotAmountSelected ? "green" : "transparent"}}>
                                            <img height={16} width={16} src="/images/plot_amount.png"/>
                                        </ToggleButton>
                                    </Tooltip>
                                    <Tooltip title="By Contributions">
                                        <ToggleButton sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} value='count' onClick={event => changePlotField('count')}
                                                      style={{backgroundColor: plotCountSelected ? "green" : "transparent"}}>
                                            <img height={16} width={16} src="/images/plot_contributions.png"/>
                                        </ToggleButton>
                                    </Tooltip>
                                </ToggleButtonGroup>
                            )
                        )
                    }
                </Stack></Box>
        </Stack>
    );
};

export default MapViewer;
