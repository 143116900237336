import HomePageCard from "./HomePageCard";
import React from "react";

export function homeCard(key, nextEntry)
{
    return (
        <HomePageCard
            key={key}
            title={nextEntry.title}
            type={nextEntry.type}
            subtitle={nextEntry.subtitle}
            path={nextEntry.path}
            imageURL={nextEntry.imageURL}
            summary={nextEntry.summary}
        />
    );
}
