import AMadLib from "../../AMadLib";

export default class MLRollupUCSOrg extends AMadLib
{
    constructor()
    {
        super();
    }

    buildTitle(pageType, display)
    {
        let title = "Top Organization Contributors";
        if (pageType !== 'summary')
        {
            const displayPrefix = " " + this.getDisplayPrefix(pageType) + " ";
            title += displayPrefix + display;
        }

        return title;
    }

    buildSubtitle(pageType, display)
    {
        let subtitle = "The top organizations based on total amount donated from contributors";
        if (pageType !== 'summary')
        {
            const displayPrefix = " " + this.getDisplayPrefix(pageType) + " ";

            subtitle += displayPrefix + display;
        }
        subtitle += " for all elections since 2008";

        return subtitle;
    }

    buildContent(pageType, display, stats, data)
    {
        let totalAmount = 0;
        let totalICAmount = 0;
        let totalCTAmount = 0;
        let topAmount = 0;
        let topAmountDisplay = 0;

        if (data !== undefined)
        {
            for (const nextContributor of data)
            {
                totalAmount = totalAmount + nextContributor.amount;
                totalICAmount = totalICAmount + nextContributor.ic_amount;
                totalCTAmount = totalCTAmount + nextContributor.ct_amount;

                //Top State by Total Amount
                if (nextContributor.amount > topAmount)
                {
                    topAmount = nextContributor.amount;
                    topAmountDisplay = nextContributor.display;
                }
            }
        }

        const totalAll = stats.ic_total.amount;
        const totalTopContributorPCT = (totalAmount * 100/ totalAll);

        let content = "The top organizations associated to contributors";

        if (pageType === "summary")
        {
            content += " to federal elections "
        }
        if (pageType === "state")
        {
            content += " in the state of " + display;
        }
        if (pageType === "county")
        {
            content += " in " + display;
        }
        if (pageType === "city")
        {
            content += " in the city of " + display;
        }
        if (pageType === "zipcode")
        {
            content += " in the zipcode of " + display;
        }
        else if (pageType === "party")
        {
            content += " supporting the " + display;
        }
        else if (pageType === "politician")
        {
            content += " supporting the politician " + display;
        }
        else if (pageType === "office")
        {
            content += " supporting candidates running for the office of " + display;
        }
        else if (pageType === "surname")
        {
            content += " with the surname " + display;
        }
        else if (pageType === "committee")
        {
            content += " supporting the " + display;
        }
        else if (pageType === "organization")
        {
            content += " associated to the organization " + display;
        }
        else if (pageType === "industry")
        {
            content += " who work in the " + display + " industry";
        }
        content += " donated a total of $"
        content += totalAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ". ";

        content += "The total amount from individual contributors is $" + totalICAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "The total amount from committee transactions is $" + totalCTAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "With " + topAmountDisplay + " being the highest contributor by total amount. ";
        content += "In total, the top 25 contributors account for " + totalTopContributorPCT.toLocaleString(undefined, { maximumFractionDigits: 0 })
        content += "% of all donations " + this.getDisplayPhrase(pageType, display) + " since 2008.";

        return content;
    }
}
