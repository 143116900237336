import React from "react";

const ArticleSection = (props) => {
    return (
        <div className="article-section">
            <h3>{"Article Sections"}</h3>
            {
                props.data === undefined ?
                    (<span>No data available</span>):
                    (<div>
                            {
                                props.data.map((stat, index) => (
                                    <div>
                                        <h4>{stat.type}</h4><br/>
                                        <p>{stat.sequence}</p>
                                        <p>{stat.details}</p>
                                        <p>{stat.renderer}</p>
                                    </div>
                                ))
                            }
                        </div>
                    )
            }
        </div>
    );
};

export default ArticleSection;
