import AMadLib from "../../AMadLib";

export default class MLCycleUCSTotal extends AMadLib
{
    constructor()
    {
        super();
    }

    buildTitle(cycleYear, pageType, display)
    {
        let title = "Total Contributions from " + display;
        title += " for the " + cycleYear + " Elections";

        return title;
    }

    buildContent(cycleYear, pageType, display, stats, data)
    {
        //Total donations
        let content = "The total amount donated by " + display;
        content += " for the " + cycleYear + " elections was $";
        content += data.amount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ". "

        //Average donation
        content += "This contributor made a total of ";
        content += data.contributions.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " donations, with an average contribution of $"
        const avgAmount = data.amount / data.contributions;
        content += avgAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ".";

        return content;
    }
}
