import React from "react";

const ArticleReference = (props) => {
    return (
        <div className="article-reference">
            <h3>{"Article References"}</h3>
            {
                props.data === undefined ?
                    (<span>No data available</span>):
                    (<div>
                            {
                                props.data.map((stat, index) => (
                                    <div>
                                        <h4>{stat.title}</h4><br/>
                                        <a href={stat.URL} rel='noopener'>{stat.URL}</a><br/>
                                        <span>{stat.description}</span>
                                    </div>
                                ))
                            }
                        </div>
                    )
            }
        </div>
    );
};

export default ArticleReference;
