import {formatCurrency, formatNumber} from "../App";
import {getStateCode} from "./MapCommon";
import Highcharts from "highcharts";
import highchartsAccessibility from "highcharts/modules/accessibility";
import proj4 from 'proj4';
import mapTooltipFix from './mapTooltipFix';
import sign from "proj4/lib/common/sign";

Highcharts.win.proj4 = proj4;
mapTooltipFix(Highcharts);

highchartsAccessibility(Highcharts);

function checkEntry(inEntry){
    if (inEntry.latitude === 0 || inEntry.longitude === 0 || inEntry.amount <= 0 || inEntry.count <= 0 ||
            inEntry.contributions <= 0 || inEntry.contributors <= 0)
    {
        return false;
    }

    return true
}

export function buildNationalColor(title, subtitle, mapShapes, mapData, plotField, isPlottingCurrency, displayField)
{
    if (mapShapes === undefined)
    {
        return {};
    }

    let minAmount = -1;
    let maxAmount = -1;
    mapData.forEach(function (stateTotal) {
        if (checkEntry(stateTotal) === false)
        {
            return
        }

        //Exclude territories
        if (getStateCode(stateTotal["state"]) !== "XX")
        {
            let nextAmount = stateTotal[plotField];
            if (minAmount === -1 || minAmount > nextAmount)
            {
                minAmount = nextAmount;
            }
            if (maxAmount === -1 || maxAmount < nextAmount)
            {
                maxAmount = nextAmount;
            }
        }
    });

    let renderData = [];
    mapData.forEach(function (nextEntry) {
        //Check for negative number, and skip. Throws map off
        //sign() = 1=positive, -1=negative, 0=zero
        if (sign(nextEntry["amount"]) === -1)
        {
            return
        }

        if (checkEntry(nextEntry) === false)
        {
            return
        }

        renderData.push({
            stateKey: nextEntry["stateKey"],
            stateCode: getStateCode(nextEntry["state"]),
            joinKey: nextEntry[displayField],
            name: nextEntry[displayField],
            value: nextEntry[plotField],
            amount: formatCurrency(nextEntry["amount"]),
            contributors: formatNumber(nextEntry["contributors"]),
            contributions: formatNumber(nextEntry["contributions"]),
            link: "/state/" + nextEntry["stateKey"]
        });
    });

    let options = {
        chart: {
            animation: false
        },
        colorAxis: {
            min: minAmount,
            type: 'logarithmic',
            minColor: 'rgba(25,200,100,0.25)',
            maxColor: '#19c864',
            stops: [
                [0, 'rgba(25,200,100,0.25)'],
                [1, '#19c864']
            ]
        },
        series: [
            {
                data: renderData,
                name: "United States",
                mapData: mapShapes,
                joinBy: ['name', 'name'],
                dataLabels: {
                    enabled: true,
                    formatter:function() {
                        return this.point.stateCode
                    }
                },
                states: {
                    hover: {
                        borderWidth: 1
                    }
                },
                tooltip: {
                    useHTML: true,
                    pointFormat: '<b>{point.name}</b><br/>Amount: {point.amount}<br/>Contributors: {point.contributors}<br/>Contributions: {point.contributions}'
                }
            }, {
                // The connector lines
                type: 'mapline',
                data: Highcharts.geojson(mapShapes, 'mapline'),
                color: 'silver',
                accessibility: {
                    enabled: false
                }
            }],
    };

    applyDefaults(options, title, subtitle, false);

    return options;
}

export function buildNationalColorCES(title, subtitle, mapShapes, mapData, plotField, isPlottingCurrency, displayField)
{
    if (mapShapes === undefined)
    {
        return {};
    }

    let minAmount = -1;
    let maxAmount = -1;
    mapData.forEach(function (stateTotal) {
        if (checkEntry(stateTotal) === false)
        {
            return
        }
        //Exclude territories
        if (getStateCode(stateTotal["state"]) !== "XX")
        {
            let nextAmount = stateTotal[plotField];
            if (minAmount === -1 || minAmount > nextAmount)
            {
                minAmount = nextAmount;
            }
            if (maxAmount === -1 || maxAmount < nextAmount)
            {
                maxAmount = nextAmount;
            }
        }
    });

    let renderData = [];
    mapData.forEach(function (nextEntry) {
        //Check for negative number, and skip. Throws map off
        //sign() = 1=positive, -1=negative, 0=zero
        if (sign(nextEntry["amount"]) === -1)
        {
            return
        }

        if (checkEntry(nextEntry) === false)
        {
            return
        }

        renderData.push({
            stateKey: nextEntry["stateKey"],
            stateCode: getStateCode(nextEntry["state"]),
            joinKey: nextEntry[displayField],
            name: nextEntry[displayField],
            value: nextEntry[plotField],
            amount: formatCurrency(nextEntry["amount"]),
            count: formatNumber(nextEntry["count"]),
            link: "/state/" + nextEntry["stateKey"]
        });
    });

    let options = {
        chart: {
            animation: false
        },
        colorAxis: {
            min: minAmount,
            type: 'logarithmic',
            minColor: 'rgba(25,200,100,0.25)',
            maxColor: '#19c864',
            stops: [
                [0, 'rgba(25,200,100,0.25)'],
                [1, '#19c864']
            ]
        },
        series: [
            {
                data: renderData,
                name: "United States",
                mapData: mapShapes,
                joinBy: ['name', 'name'],
                dataLabels: {
                    enabled: true,
                    formatter:function() {
                        return this.point.stateCode
                    }
                },
                states: {
                    hover: {
                        borderWidth: 1
                    }
                },
                tooltip: {
                    useHTML: true,
                    pointFormat: '<b>{point.name}</b><br/>Amount: {point.amount}<br/>Contributions: {point.count}'
                }
            }, {
                // The connector lines
                type: 'mapline',
                data: Highcharts.geojson(mapShapes, 'mapline'),
                color: 'silver',
                accessibility: {
                    enabled: false
                }
            }],
    };

    applyDefaults(options, title, subtitle, false);

    return options;
}

export function buildNationalBubbles(title, subtitle, mapShapes, mapData, plotField, isPlottingCurrency, displayField, linkType, linkField)
{
    if (mapShapes === undefined)
    {
        return {};
    }

    let markerData = loadMarkerData(mapData, displayField, linkType, linkField);

    let options = {
        chart: {
            animation: false
        },
        tooltip: {
            headerFormat: '',
            useHTML: true,
            pointFormat: '<b>{point.name}</b><br/>Amount: {point.amount}<br/>Contributors: {point.contributors}<br/>Contributions: {point.contributions}'
        },
        series: [
            {
                name: "United States",
                mapData: mapShapes,
                borderColor: '#A0A0A0',
                nullColor: 'rgba(100, 100, 100, 0.01)',
                showInLegend: false
            },
            {
                type: 'mapbubble',
                name: 'Markers',
                nullColor: 'rgba(255, 0, 0, 0.30)',
                color: Highcharts.getOptions().colors[2],
                showInLegend: false,
                minSize: 4,
                maxSize: '12%',
                data: markerData,
                tooltip: {
                    headerFormat: '',
                    useHTML: true,
                    pointFormat: '<b>{point.name}</b><br/>Amount: {point.amount}<br/>Contributors: {point.contributors}<br/>Contributions: {point.contributions}'
                }
            }
        ],
    };

    applyDefaults(options, title, subtitle, true);

    return options;
}

export function buildNationalBubblesCES(title, subtitle, mapShapes, mapData, plotField, isPlottingCurrency, displayField, linkType, linkField)
{
    if (mapShapes === undefined)
    {
        return {};
    }

    let markerData = loadMarkerDataCES(mapData, displayField, linkType, linkField);

    let options = {
        chart: {
            animation: false
        },
        tooltip: {
            headerFormat: '',
            useHTML: true,
            pointFormat: '<b>{point.name}</b><br/>Amount: {point.amount}<br/>Contributors: {point.contributors}<br/>Contributions: {point.contributions}'
        },
        series: [
            {
                name: "United States",
                mapData: mapShapes,
                borderColor: '#A0A0A0',
                nullColor: 'rgba(100, 100, 100, 0.01)',
                showInLegend: false
            },
            {
                type: 'mapbubble',
                name: 'Markers',
                nullColor: 'rgba(255, 0, 0, 0.30)',
                color: Highcharts.getOptions().colors[2],
                showInLegend: false,
                minSize: 4,
                maxSize: '12%',
                data: markerData,
                tooltip: {
                    headerFormat: '',
                    useHTML: true,
                    pointFormat: '<b>{point.name}</b><br/>Amount: {point.amount}<br/>Contributors: {point.contributors}<br/>Contributions: {point.contributions}'
                }
            }
        ],
    };

    applyDefaults(options, title, subtitle, true);

    return options;
}

export function buildNationalCounty(title, subtitle, mapShapes, mapData, plotField, isPlottingCurrency, displayField)
{
    if (mapShapes === undefined)
    {
        return {};
    }

    mapShapes.features.forEach(function (nextFeature) {
        let nextID = nextFeature.id;
        if (nextID !== undefined)
        {
            let stateCode = nextID.substring(3, 5);
            let countyKey = nextFeature.properties.name.toLowerCase().replaceAll(" ", "-");
            let joinKey = stateCode + ":" + countyKey;

            nextFeature.properties["stateCode"] = stateCode;
            nextFeature.properties["countyKey"] = nextFeature.properties.name.toLowerCase().replaceAll(" ", "-");
            nextFeature.properties["countyNumber"] = nextID.substring(6);
            nextFeature.properties["joinKey"] = joinKey;
        }
    });

    let minAmount = -1;
    let maxAmount = -1;
    mapData.forEach(function (stateTotal) {
        if (checkEntry(stateTotal) === false)
        {
            return
        }

        //Exclude territories
        if (getStateCode(stateTotal["state"]) !== "XX")
        {
            let nextAmount = stateTotal[plotField];
            if (minAmount === -1 || minAmount > nextAmount)
            {
                minAmount = nextAmount;
            }
            if (maxAmount === -1 || maxAmount < nextAmount)
            {
                maxAmount = nextAmount;
            }
        }
    });

    let renderData = [];
    mapData.forEach(function (nextEntry) {
        if (checkEntry(nextEntry) === false)
        {
            return
        }

        let stateCode = getStateCode(nextEntry["state"]);
        let joinKey = stateCode + ":" + nextEntry["countyKey"];

        renderData.push({
            stateKey: nextEntry["stateKey"],
            stateCode: stateCode,
            countyKey: nextEntry["countyKey"],
            county: nextEntry["county"],
            joinKey: joinKey,
            name: nextEntry[displayField],
            value: nextEntry[plotField],
            amount: formatCurrency(nextEntry["amount"]),
            contributors: formatNumber(nextEntry["contributors"]),
            contributions: formatNumber(nextEntry["contributions"]),
            link: "/state/" + nextEntry["stateKey"] + "/county/" + nextEntry["countyKey"]
        });
    });

    let options = {
        chart: {
            animation: false
        },
        colorAxis: {
            min: minAmount,
            type: 'logarithmic',
            minColor: 'rgba(25,200,100,0.25)',
            maxColor: '#19c864',
            stops: [
                [0, 'rgba(25,200,100,0.25)'],
                [1, '#19c864']
            ]
        },
        series: [
            {
                data: renderData,
                name: "United States",
                mapData: mapShapes,
                joinBy: ['joinKey', 'joinKey'],
                dataLabels: {
                    enabled: false
                },
                states: {
                    hover: {
                        borderWidth: 1
                    }
                },
                tooltip: {
                    useHTML: true,
                    pointFormat: '<b>{point.name}, {point.stateCode}</b><br/>Amount: {point.amount}<br/>Contributors: {point.contributors}<br/>Contributions: {point.contributions}'
                }
            }],
    };

    applyDefaults(options, title, subtitle, true);

    return options;
}

export function buildNationalCountyCES(title, subtitle, mapShapes, mapData, plotField, isPlottingCurrency, displayField)
{
    if (mapShapes === undefined)
    {
        return {};
    }

    mapShapes.features.forEach(function (nextFeature) {
        let nextID = nextFeature.id;
        if (nextID !== undefined)
        {
            let stateCode = nextID.substring(3, 5);
            let countyKey = nextFeature.properties.name.toLowerCase().replaceAll(" ", "-");
            let joinKey = stateCode + ":" + countyKey;

            nextFeature.properties["stateCode"] = stateCode;
            nextFeature.properties["countyKey"] = nextFeature.properties.name.toLowerCase().replaceAll(" ", "-");
            nextFeature.properties["countyNumber"] = nextID.substring(6);
            nextFeature.properties["joinKey"] = joinKey;
        }
    });

    let minAmount = -1;
    let maxAmount = -1;
    mapData.forEach(function (stateTotal) {
        if (checkEntry(stateTotal) === false)
        {
            return
        }

        //Exclude territories
        if (getStateCode(stateTotal["state"]) !== "XX")
        {
            let nextAmount = stateTotal[plotField];
            if (minAmount === -1 || minAmount > nextAmount)
            {
                minAmount = nextAmount;
            }
            if (maxAmount === -1 || maxAmount < nextAmount)
            {
                maxAmount = nextAmount;
            }
        }
    });

    let renderData = [];
    mapData.forEach(function (nextEntry) {
        if (checkEntry(nextEntry) === false)
        {
            return
        }

        let stateCode = getStateCode(nextEntry["state"]);
        let joinKey = stateCode + ":" + nextEntry["countyKey"];

        renderData.push({
            stateKey: nextEntry["stateKey"],
            stateCode: stateCode,
            countyKey: nextEntry["countyKey"],
            county: nextEntry["county"],
            joinKey: joinKey,
            name: nextEntry[displayField],
            value: nextEntry[plotField],
            amount: formatCurrency(nextEntry["amount"]),
            count: formatNumber(nextEntry["count"]),
            link: "/state/" + nextEntry["stateKey"] + "/county/" + nextEntry["countyKey"]
        });
    });

    let options = {
        chart: {
            animation: false
        },
        colorAxis: {
            min: minAmount,
            type: 'logarithmic',
            minColor: 'rgba(25,200,100,0.25)',
            maxColor: '#19c864',
            stops: [
                [0, 'rgba(25,200,100,0.25)'],
                [1, '#19c864']
            ]
        },
        series: [
            {
                data: renderData,
                name: "United States",
                mapData: mapShapes,
                joinBy: ['joinKey', 'joinKey'],
                dataLabels: {
                    enabled: false
                },
                states: {
                    hover: {
                        borderWidth: 1
                    }
                },
                tooltip: {
                    useHTML: true,
                    pointFormat: '<b>{point.name}, {point.stateCode}</b><br/>Amount: {point.amount}<br/>Contributions: {point.count}'
                }
            }],
    };

    applyDefaults(options, title, subtitle, true);

    return options;
}

export function buildNationalMarkers(title, subtitle, mapShapes, mapData, plotField, isPlottingCurrency, displayField, linkType, linkField)
{
    if (mapShapes === undefined)
    {
        return {};
    }

    let markerData = loadMarkerData(mapData, displayField, linkType, linkField);

    let options = {
        chart: {
            animation: false
        },
        tooltip: {
            headerFormat: '',
            pointFormat: '<b>{point.name}</b><br/>Amount: {point.amount}<br/>Contributors: {point.contributors}<br/>Contributions: {point.contributions}'
        },
        series: [
            {
                name: "United States",
                mapData: mapShapes,
                borderColor: '#A0A0A0',
                nullColor: 'rgba(100, 100, 100, 0.01)',
                showInLegend: false
            },
            {
                type: 'mappoint',
                name: 'Markers',
                nullColor: 'rgba(255, 0, 0, 0.30)',
                color: Highcharts.getOptions().colors[5],
                showInLegend: false,
                data: markerData
            }],
    };

    applyDefaults(options, title, subtitle, true);

    return options;
}

export function buildNationalMarkersCES(title, subtitle, mapShapes, mapData, plotField, isPlottingCurrency, displayField, linkType, linkField)
{
    if (mapShapes === undefined)
    {
        return {};
    }

    let markerData = loadMarkerDataCES(mapData, displayField, linkType, linkField);

    let options = {
        chart: {
            animation: false
        },
        tooltip: {
            headerFormat: '',
            pointFormat: '<b>{point.name}</b><br/>Amount: {point.amount}<br/>Contributions: {point.count}'
        },
        series: [
            {
                name: "United States",
                mapData: mapShapes,
                borderColor: '#A0A0A0',
                nullColor: 'rgba(100, 100, 100, 0.01)',
                showInLegend: false
            },
            {
                type: 'mappoint',
                name: 'Markers',
                nullColor: 'rgba(255, 0, 0, 0.30)',
                color: Highcharts.getOptions().colors[5],
                showInLegend: false,
                data: markerData
            }],
    };

    applyDefaults(options, title, subtitle, true);

    return options;
}

export function buildStateBubbles(title, subtitle, mapShapes, mapData, plotField, isPlottingCurrency, displayField, linkType, linkField)
{
    if (mapShapes === undefined)
    {
        return {};
    }

    let markerData = loadMarkerData(mapData, displayField, linkType, linkField);

    let options = {
        chart: {
            animation: false
        },
        tooltip: {
            headerFormat: '',
            pointFormat: '<b>{point.name}</b><br/>Amount: {point.amount}<br/>Contributors: {point.contributors}<br/>Contributions: {point.contributions}'
        },
        series: [
            {
                name: "United States",
                mapData: mapShapes,
                borderColor: '#A0A0A0',
                nullColor: 'rgba(100, 100, 100, 0.01)',
                showInLegend: false
            },
            {
                type: 'mapbubble',
                name: 'Markers',
                nullColor: 'rgba(255, 0, 0, 0.30)',
                color: Highcharts.getOptions().colors[2],
                showInLegend: false,
                minSize: 4,
                maxSize: '12%',
                data: markerData
            }
        ],
    };

    applyDefaults(options, title, subtitle, true);

    return options;
}

export function buildStateBubblesCES(title, subtitle, mapShapes, mapData, plotField, isPlottingCurrency, displayField, linkType, linkField)
{
    if (mapShapes === undefined)
    {
        return {};
    }

    let markerData = loadMarkerDataCES(mapData, displayField, linkType, linkField);

    let options = {
        chart: {
            animation: false
        },
        tooltip: {
            headerFormat: '',
            pointFormat: '<b>{point.name}</b><br/>Amount: {point.amount}<br/>Contributors: {point.contributors}<br/>Contributions: {point.contributions}'
        },
        series: [
            {
                name: "United States",
                mapData: mapShapes,
                borderColor: '#A0A0A0',
                nullColor: 'rgba(100, 100, 100, 0.01)',
                showInLegend: false
            },
            {
                type: 'mapbubble',
                name: 'Markers',
                nullColor: 'rgba(255, 0, 0, 0.30)',
                color: Highcharts.getOptions().colors[2],
                showInLegend: false,
                minSize: 4,
                maxSize: '12%',
                data: markerData
            }
        ],
    };

    applyDefaults(options, title, subtitle, true);

    return options;
}

export function buildStateCounty(title, subtitle, mapShapes, mapData, plotField, isPlottingCurrency, displayField)
{
    if (mapShapes === undefined)
    {
        return {};
    }

    let minAmount = -1;
    let maxAmount = -1;
    mapData.forEach(function (stateTotal) {
        if (checkEntry(stateTotal) === false)
        {
            return
        }

        //Exclude territories
        if (getStateCode(stateTotal["state"]) !== "XX")
        {
            let nextAmount = stateTotal[plotField];
            if (minAmount === -1 || minAmount > nextAmount)
            {
                minAmount = nextAmount;
            }
            if (maxAmount === -1 || maxAmount < nextAmount)
            {
                maxAmount = nextAmount;
            }
        }
    });

    let renderData = [];
    mapData.forEach(function (nextEntry) {
        if (checkEntry(nextEntry) === false)
        {
            return
        }

        renderData.push({
            stateKey: nextEntry["stateKey"],
            stateCode: getStateCode(nextEntry["state"]),
            countyKey: nextEntry["countyKey"],
            joinKey: nextEntry["county"].replaceAll(" County", "").replaceAll(" Parish", ""),
            name: nextEntry[displayField],
            value: nextEntry[plotField],
            amount: formatCurrency(nextEntry["amount"]),
            contributors: formatNumber(nextEntry["contributors"]),
            contributions: formatNumber(nextEntry["contributions"]),
            link: "/state/" + nextEntry["stateKey"] + "/county/" + nextEntry["countyKey"]
        });
    });


    let options = {
        chart: {
            animation: false,
        },
        colorAxis: {
            // min: minAmount,
            min: minAmount <= 0 ? (1) : (minAmount),
            type: 'logarithmic',
            minColor: 'rgba(25,200,100,0.25)',
            maxColor: '#19c864',
            stops: [
                [0, 'rgba(25,200,100,0.25)'],
                [1, '#19c864']
            ]
        },
        series: [
            {
                data: renderData,
                name: "United States",
                mapData: mapShapes,
                joinBy: ['name', 'joinKey'],
                dataLabels: {
                    enabled: false
                },
                states: {
                    hover: {
                        borderWidth: 1
                    }
                },
                tooltip: {
                    useHTML: true,
                    pointFormat: '<b>{point.name}</b><br/>Amount: {point.amount}<br/>Contributors: {point.contributors}<br/>Contributions: {point.contributions}'
                }
            }],
    };

    applyDefaults(options, title, subtitle, false);

    return options;
}

export function buildStateCountyCES(title, subtitle, mapShapes, mapData, plotField, isPlottingCurrency, displayField)
{
    if (mapShapes === undefined)
    {
        return {};
    }

    let minAmount = -1;
    let maxAmount = -1;
    mapData.forEach(function (stateTotal) {
        //Check for zero values
        if (checkEntry(stateTotal) === false)
        {
            return
        }

        //Exclude territories
        if (getStateCode(stateTotal["state"]) !== "XX")
        {
            let nextAmount = stateTotal[plotField];
            if (minAmount === -1 || minAmount > nextAmount)
            {
                minAmount = nextAmount;
            }
            if (maxAmount === -1 || maxAmount < nextAmount)
            {
                maxAmount = nextAmount;
            }
        }
    });

    let renderData = [];
    mapData.forEach(function (nextEntry) {
        if (checkEntry(nextEntry) === false)
        {
            return
        }

        renderData.push({
            stateKey: nextEntry["stateKey"],
            stateCode: getStateCode(nextEntry["state"]),
            countyKey: nextEntry["countyKey"],
            joinKey: nextEntry["county"].replaceAll(" County", "").replaceAll(" Parish", ""),
            name: nextEntry[displayField],
            value: nextEntry[plotField],
            amount: formatCurrency(nextEntry["amount"]),
            count: formatNumber(nextEntry["count"]),
            link: "/state/" + nextEntry["stateKey"] + "/county/" + nextEntry["countyKey"]
        });
    });


    let options = {
        chart: {
            animation: false,
        },
        colorAxis: {
            // min: minAmount,
            min: minAmount <= 0 ? (1) : (minAmount),
            type: 'logarithmic',
            minColor: 'rgba(25,200,100,0.25)',
            maxColor: '#19c864',
            stops: [
                [0, 'rgba(25,200,100,0.25)'],
                [1, '#19c864']
            ]
        },
        series: [
            {
                data: renderData,
                name: "United States",
                mapData: mapShapes,
                joinBy: ['name', 'joinKey'],
                dataLabels: {
                    enabled: false
                },
                states: {
                    hover: {
                        borderWidth: 1
                    }
                },
                tooltip: {
                    useHTML: true,
                    pointFormat: '<b>{point.name}</b><br/>Amount: {point.amount}<br/>Contributions: {point.count}'
                }
            }],
    };

    applyDefaults(options, title, subtitle, false);

    return options;
}

export function buildStateMarkers(title, subtitle, mapShapes, mapData, plotField, isPlottingCurrency, displayField, linkType, linkField)
{
    if (mapShapes === undefined)
    {
        return {};
    }

    let markerData = loadMarkerData(mapData, displayField, linkType, linkField);

    let options = {
        chart: {
            animation: false
        },
        tooltip: {
            headerFormat: '',
            pointFormat: '<b>{point.name}</b><br/>Amount: {point.amount}<br/>Contributors: {point.contributors}<br/>Contributions: {point.contributions}'
        },
        series: [
            {
                name: "United States",
                mapData: mapShapes,
                borderColor: '#A0A0A0',
                nullColor: 'rgba(100, 100, 100, 0.01)',
                showInLegend: false
            },
            {
                type: 'mappoint',
                name: 'Markers',
                nullColor: 'rgba(255, 0, 0, 0.30)',
                color: Highcharts.getOptions().colors[5],
                showInLegend: false,
                data: markerData
            }
        ],
    };

    applyDefaults(options, title, subtitle, true);

    return options;
}

export function buildStateMarkersCES(title, subtitle, mapShapes, mapData, plotField, isPlottingCurrency, displayField, linkType, linkField)
{
    if (mapShapes === undefined)
    {
        return {};
    }

    let markerData = loadMarkerDataCES(mapData, displayField, linkType, linkField);

    let options = {
        chart: {
            animation: false
        },
        tooltip: {
            headerFormat: '',
            pointFormat: '<b>{point.name}</b><br/>Amount: {point.amount}<br/>Contributors: {point.contributors}<br/>Contributions: {point.contributions}'
        },
        series: [
            {
                name: "United States",
                mapData: mapShapes,
                borderColor: '#A0A0A0',
                nullColor: 'rgba(100, 100, 100, 0.01)',
                showInLegend: false
            },
            {
                type: 'mappoint',
                name: 'Markers',
                nullColor: 'rgba(255, 0, 0, 0.30)',
                color: Highcharts.getOptions().colors[5],
                showInLegend: false,
                data: markerData
            }
        ],
    };

    applyDefaults(options, title, subtitle, true);

    return options;
}

function applyDefaults(options, title, subtitle, allowMapNavig) {

    options.title = {
        align: 'left',
        text: title,
        style: {
            color: "#fff",
            fontSize: '14px',
            fontFamily: 'Nunito'
        }
    };

    options.subtitle = {
        align: 'left',
        text: subtitle,
        style: {
            color: "gray",
            fontSize: '12px',
            fontFamily: 'Nunito'
        }
    };

    options.legend = {
        enabled: false,
        itemStyle: {
            color: '#fff'
        }

    };

    options.mapNavigation = {
        enabled: allowMapNavig,
        enableMouseWheelZoom: false
    };

    options.exporting = {
        buttons: {
            contextButton: {
                theme: {
                    fill: '#000000'
                }
            }
        },
        chartOptions: {
            chart: {
                backgroundColor: '#20273C',
                events: {
                    load: function(event) {
                        // let placeX = event.target.chartWidth - 150;
                        // let placeY = 10;
                        let placeX = 5;
                        let placeY = event.target.chartHeight - 35;
                        this.renderer.image('/images/logo.png', placeX, placeY, 132.5, 30).add();
                    }
                },
                // plotBackgroundImage: '/images/logo.png'
            },
            //Show the credits when outputting an image
            credits: {
                mapText: "",
                text: "www.monecracy.com",
                href: "https://www.monecracy.com",
                style: {
                    fontSize: '14px'
                }
            }
        },
        fallbackToExportServer: false
    };

    //Hide the credits when rendering
    options.credits = {
        mapText: "",
        text: "",
        href: "",
        style: {
            fontSize: '0px'
        }
    };
}

function loadMarkerData(mapData, displayField, linkType, linkField) {
    let markerData = [];
    mapData.forEach(function (nextEntry) {
        if (checkEntry(nextEntry) === false)
        {
            return
        }

        let relatedLink;
        if (linkType === 'zipcode') {
            relatedLink = "/zipcode/" + nextEntry[linkField]
        }
        else {
            relatedLink = "/state/" + nextEntry["stateKey"] + "/" + linkType + "/" + nextEntry[linkField]
        }
        markerData.push({
            name: nextEntry[displayField],
            lat: parseFloat(nextEntry.latitude),
            lon: parseFloat(nextEntry.longitude),
            z: nextEntry["amount"],
            amount: formatCurrency(nextEntry["amount"]),
            contributors: formatNumber(nextEntry["contributors"]),
            contributions: formatNumber(nextEntry["contributions"]),
            link: relatedLink
        });
    });

    return markerData;
}

function loadMarkerDataCES(mapData, displayField, linkType, linkField) {
    let markerData = [];
    mapData.forEach(function (nextEntry) {
        if (checkEntry(nextEntry) === false)
        {
            return
        }

        let relatedLink;
        if (linkType === 'zipcode') {
            relatedLink = "/zipcode/" + nextEntry[linkField]
        }
        else {
            relatedLink = "/state/" + nextEntry["stateKey"] + "/" + linkType + "/" + nextEntry[linkField]
        }
        markerData.push({
            name: nextEntry[displayField],
            lat: parseFloat(nextEntry.latitude),
            lon: parseFloat(nextEntry.longitude),
            z: nextEntry["amount"],
            amount: formatCurrency(nextEntry["amount"]),
            count: formatNumber(nextEntry["count"]),
            link: relatedLink
        });
    });

    return markerData;
}
