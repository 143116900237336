import React from "react";
import {useNavigate} from "react-router-dom";
import {Stack, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import CycleTableHeader from "../cycleTableHeader";
import {madLibFactory, typeDetails} from "../../AppGlobal";
import {buildUCSColumns} from "../../util/DataGridMaker";
import ToggleChart from "../toggleChart";

const UCSList = (props) => {
    let navigate = useNavigate();

    const [sortModel, setSortModel] = React.useState([
        {
            field: 'amount',
            sort: 'desc',
        },
    ]);

    if (props.stats === undefined)
    {
        return;
    }

    const renderData = props.stats[props.statsKey];

    const madLibs = madLibFactory.getCycleUCS();

    const renderTitle = madLibs.buildTitle(props.cycleYear, props.pageType, props.display);
    const renderSubtitle = madLibs.buildSubtitle(props.cycleYear, props.pageType, props.display);
    const renderCaption = madLibs.buildContent(props.cycleYear, props.pageType, props.display, props.stats, renderData);
    const renderWidth = madLibs.calculateRenderWidth(20);
    const renderHeight = madLibs.calculateRenderHeight(renderData, props.statsTop, 30);
    const linkTypeDetails = typeDetails[props.linkType];
    const renderChartSeries = linkTypeDetails.display;

    const imageIconSrc = "/images/contributor_icon.png";

    const columns = buildUCSColumns(250, 300);

    const handleOnCellClick = (params) => {
        navigate("/cycle/" + props.cycleYear + "/contributor/" + params.row.stateKey + "/" + params.row.zipcodeKey + "/" + params.row.typeKey);
    };

    return (
        <Stack align="center" paddingTop={"5px"} paddingBottom={"5px"}>
            <CycleTableHeader
                title={renderTitle}
                subtitle={renderSubtitle}
                imgSource={imageIconSrc}
                cycleYear={props.cycleYear}
                anchor="contributor_totals"
                showAllType="contributors"
                showAllTitle="Show All Contributors"
            />
            <Stack direction="column" sx={{ backgroundColor: '#20273C', padding: 2}}>
            {
                renderData === undefined ?
                    (<span>No data available</span>):
                    (
                        <Stack>
                            <Typography align={"left"} variant="caption">{renderCaption}</Typography>
                            <ToggleChart width={renderWidth} height={renderHeight}
                                         title={"Top Contributors"}
                                         seriesName={renderChartSeries}
                                         chartData={renderData}
                                         plotField="amount"
                                         displayField="display"
                                         isUCS={true}
                            />
                            <DataGrid
                                disableColumnMenu={true}
                                getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
                                sx={{ color:"white", border: 0}}
                                getRowId={(row) => row.typeKey}
                                rows={renderData}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                                hideFooterPagination={false}
                                sortModel={sortModel}
                                onSortModelChange={(model) => setSortModel(model)}
                                autoHeight={true}
                                onRowClick={handleOnCellClick}
                            />
                        </Stack>
                    )
            }
            </Stack>
        </Stack>
    );
};

export default UCSList;
