import React from "react";
import {Stack, Typography} from "@mui/material";
import {madLibFactory} from "../../AppGlobal";

const RollupCTTotal = (props) => {

    if (props.stats === undefined)
    {
        return;
    }

    let renderData = props.stats[props.statsKey];
    if (renderData === undefined)
    {
        renderData = {
            amount: 0,
            count: 0
        }
    }

    const madLibs = madLibFactory.getRollupCTSTotal();

    const renderTitle = madLibs.buildTitle(props.pageType, props.statsKey, props.display);
    const renderCaption = madLibs.buildContent(props.pageType, props.statsKey, props.display, props.stats, renderData);

    return (
        <Stack align={"left"} paddingTop={"5px"} paddingBottom={"5px"}>
            {
                renderData === undefined ?
                    (<span>No transactions</span>):
                    (
                        <Stack padding={2} spacing={1} justifyContent="flex-start" sx={{ backgroundColor: '#20273C'}}>
                            <a name={props.anchor}></a>
                            <Typography variant="subtitle1">{renderTitle}</Typography>
                            <Typography align={"left"} variant="caption">{renderCaption}</Typography>
                            <Stack direction="row" justifyContent="space-between" spacing={2} useFlexGap flexWrap="wrap">
                                <Stack spacing={0} justifyContent="flex-start">
                                    <Typography sx={{color: "gray"}} variant="subtitle2">AMOUNT</Typography>
                                    <Typography variant="subtitle1"><font color="#85bb65">$</font>{renderData.amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</Typography>
                                </Stack>
                                <Stack spacing={0}>
                                    <Typography sx={{color: "gray"}} variant="subtitle2">TRANSACTIONS</Typography>
                                    <Typography variant="subtitle1">{renderData.count.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    )
            }
        </Stack>
    );
};

export default RollupCTTotal;
