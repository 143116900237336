import AMadLib from "../../AMadLib";
import {typeDetails} from "../../../AppGlobal";

export default class MLRollupICSType extends AMadLib
{
    constructor()
    {
        super();
    }

    buildTitle(pageType, linkType, statsTop, display)
    {
        const linkTypeDetails = typeDetails[linkType];
        const displayPrefix = " " + this.getDisplayPrefix(pageType) + " ";

        let title = '';

        if (statsTop)
        {
            title = 'Top ' + linkTypeDetails.displayPlural;
            title += ' by Total Contributions'
            if (pageType !== 'summary')
            {
                title += displayPrefix + display;
            }
        }
        else
        {
            title = 'Total Contributions';
            if (pageType !== 'summary')
            {
                title += displayPrefix + display;
            }
            title += ' by ' + linkTypeDetails.display;
        }

        return title;
    }

    buildSubtitle(pageType, linkType, statsTop, display)
    {
        const linkTypeDetails = typeDetails[linkType];
        const displayPrefix = " " + this.getDisplayPrefix(pageType) + " ";

        let subtitle = '';
        if (statsTop)
        {
            subtitle = "The top " + linkTypeDetails.displayPlural.toLowerCase();
            subtitle += " by total contributions from individuals, organizations, and political action committees (PAC) ";
            if (pageType !== 'summary')
            {
                subtitle += displayPrefix + display;
            }
            subtitle += " for all elections since 2008";
        }
        else
        {
            subtitle = "The total contributions from individuals, organizations, and political action committees (PAC) ";
            if (pageType !== 'summary')
            {
                subtitle += displayPrefix + display;
            }
            subtitle += " by " + linkTypeDetails.display.toLowerCase();
            subtitle += " for all elections since 2008";
        }

        return subtitle;
    }

    buildChartTitle(pageType, linkType, statsTop, display)
    {
        return this.buildTitle(pageType, linkType, statsTop, display);
    }

    buildContent(pageType, linkType, statsTop, display, stats, data)
    {
        let totalAmount = 0;
        let totalIndivAmount = 0;
        let totalOrgAmount = 0;
        let totalPACAmount = 0;
        let topAmount = 0;
        let topAmountType = 0;

        if (data !== undefined)
        {
            for (const nextType of data)
            {
                totalAmount = totalAmount + nextType.amount;
                totalIndivAmount = totalIndivAmount + nextType.indivAmount;
                totalOrgAmount = totalOrgAmount + nextType.orgAmount;
                totalPACAmount = totalPACAmount + nextType.pacAmount;

                //Top State by Total Amount
                if (nextType.amount > topAmount)
                {
                    topAmount = nextType.amount;
                    topAmountType = nextType.display;
                }
            }
        }

        let content;

        if (pageType === "summary")
        {
            if (linkType === "party")
            {
                content = "The total amount of money donated to political parties in federal elections since 2008 is $"
            }
            else if (linkType === "politician")
            {
                content = "The total amount of money donated to the top 25 politicians in federal elections since 2008 is $"
            }
            else if (linkType === "office")
            {
                content = "The total amount of money donated to the top 25 offices in federal elections since 2008 is $"
            }
            else if (linkType === "surname")
            {
                content = "The total amount of money donated from the top 25 surnames in federal elections since 2008 is $"
            }
            else if (linkType === "committee")
            {
                content = "The total amount of money donated to the top 25 committees in federal elections since 2008 is $"
            }
            else if (linkType === "occupation")
            {
                content = "The total amount of money donated from the top 25 occupations in federal elections since 2008 is $"
            }
            else if (linkType === "organization")
            {
                content = "The total amount of money donated from the top 25 organizations in federal elections since 2008 is $"
            }
            else if (linkType === "industry")
            {
                content = "The total amount of money donated from the top 25 industries in federal elections since 2008 is $"
            }
            else
            {
                content = "The total amount of money from " + display + " donated to federal elections from the top 25 since 2008 is $"
            }
        }
        else
        {
            if (pageType === "state")
            {
                content = "The total amount of money donated from the state of " + display + " in support of a " + linkType +  " since 2008 is $"
            }
            if (pageType === "county")
            {
                content = "The total amount of money donated from the county of " + display + " in support of a " + linkType +  " since 2008 is $"
            }
            if (pageType === "city")
            {
                content = "The total amount of money donated from the city of " + display + " in support of a " + linkType +  " since 2008 is $"
            }
            if (pageType === "zipcode")
            {
                content = "The total amount of money donated from the zipcode of " + display + " in support of a " + linkType +  " since 2008 is $"
            }
            else if (pageType === "party")
            {
                content = "The total amount of money donated to the " + display + " in support of a " + linkType +  " since 2008 is $"
            }
            else if (pageType === "politician")
            {
                content = "The total amount of money donated to the politician " + display + " in support of a " + linkType +  " since 2008 is $"
            }
            else if (pageType === "office")
            {
                content = "The total amount of money donated to the office for " + display + " in support of a " + linkType +  " since 2008 is $"
            }
            else if (pageType === "surname")
            {
                content = "The total amount of money donated from the surname " + display + " in support of a " + linkType +  " since 2008 is $"
            }
            else if (pageType === "committee")
            {
                content = "The total amount of money donated from the committee " + display + " in support of a " + linkType +  " since 2008 is $"
            }
            else if (pageType === "occupation")
            {
                content = "The total amount of money donated from the occupation " + display + " in support of a " + linkType +  " since 2008 is $"
            }
            else if (pageType === "organization")
            {
                content = "The total amount of money donated from the organization " + display + " in support of a " + linkType +  " since 2008 is $"
            }
            else if (pageType === "industry")
            {
                content = "The total amount of money donated to " + display + " in support of a " + linkType +  " since 2008 is $"
            }
        }

        if (linkType === "surname" || linkType === "occupation" || linkType === "industry")
        {
            content += totalAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
            content += ".";
            content += "Total amount from individual contributors is $" + totalIndivAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
            content += "Total amount from organization contributions is $" + totalOrgAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
            content += "Total amount from political actions committees contributions is $" + totalPACAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
            content += "With " + topAmountType + " being the highest " + linkType + " contributed from by total amount. ";
        }
        else
        {
            content += totalAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
            content += ".";
            content += "Total amount from individual contributors is $" + totalIndivAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
            content += "Total amount from organization contributions is $" + totalOrgAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
            content += "Total amount from political actions committees contributions is $" + totalPACAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
            content += "With " + topAmountType + " being the highest " + linkType + " contributed to by total amount. ";
        }

        return content;
    }
}
