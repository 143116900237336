import AMadLib from "../../AMadLib";

export default class MLCycleICSStateCity extends AMadLib
{
    constructor()
    {
        super();
    }

    buildTitle(cycleYear, pageType, display)
    {
        let title = "";
        if (pageType === 'state')
        {
            title = "Top Cities in " + display + " by Total Contributions";
        }
        else
        {
            title = "Total Contributions in " + display + " by City";
        }
        title += " for the " + cycleYear + " Elections"

        return title
    }

    buildSubtitle(cycleYear, pageType, display)
    {
        let subtitle = "The top cities in " + display;
        subtitle += " based on the total contributions from individuals, organizations, and political action committees (PAC)";
        subtitle += " for the " + cycleYear + " Elections"

        return subtitle
    }

    buildMapTitle(cycleYear, pageType, display)
    {
        return this.buildTitle(cycleYear, pageType, display);
    }

    buildContent(cycleYear, pageType, display, stats, data)
    {
        let totalAmount = 0;
        let totalIndivAmount = 0;
        let totalOrgAmount = 0;
        let totalPACAmount = 0;
        let topAmount = 0;
        let topAmountDisplay = 0;

        if (data !== undefined)
        {
            for (const nextCity of data)
            {
                totalAmount = totalAmount + nextCity.amount;
                totalIndivAmount = totalIndivAmount + nextCity.indivAmount;
                totalOrgAmount = totalOrgAmount + nextCity.orgAmount;
                totalPACAmount = totalPACAmount + nextCity.pacAmount;

                //Top State by Total Amount
                if (nextCity.amount > topAmount)
                {
                    topAmount = nextCity.amount;
                    topAmountDisplay = nextCity.city;
                }
            }
        }

        let content;
        if (pageType === "state")
        {
            content = "The total amount of money donated to federal elections from the top 25 cities in the state of " + display;
        }
        if (pageType === "county")
        {
            content = "The total amount of money donated to federal elections from the top 25 cities in " + display;
        }
        content += " in the " + cycleYear + " elections was $"

        content += totalAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ".";
        content += "The total amount donated from individual contributors was $" + totalIndivAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "The total amount donated from organizations was $" + totalOrgAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "The total amount donated from political action committees was $" + totalPACAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += topAmountDisplay + " has the highest contributions by total amount, donating a total of $";
        content += topAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + "."

        return content;
    }

}
