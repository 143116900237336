import {Box, ClickAwayListener, Fade, Link, List, Paper, Popper, Stack, Typography} from "@mui/material";
import React from "react";

const FollowUs = (props) => {
    const [openShare, setOpenShare] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const canBeOpen = openShare && Boolean(anchorEl);
    const id = canBeOpen ? 'spring-popper' : undefined;
    const imageHeight = 25;
    const imageWidth = 25;

    const handleShareCLick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenShare((previousOpen) => !previousOpen);
    };

    const handleClickAway = () => {
        setOpenShare(false);
    };

    return(
        <Stack direction={"column"} alignItems={"center"} spacing={1}>
            <Popper id={id} open={openShare} anchorEl={anchorEl} placement={"top-start"} transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps}>
                        <Box>
                            <Paper sx={{backgroundColor:"black"}}>
                                <ClickAwayListener onClickAway={handleClickAway}>
                                    <Stack direction={"column"}>
                                        <List dense={true}>
                                            <Link href="https://www.disqus.com/monecracy" target={"_blank"} rel={"noreferrer"} className="social">
                                                <img height={imageHeight} width={imageWidth} src="/images/socialLogos/disqus_icon.png" title="Disqus" />
                                            </Link>

                                            <Link href="https://www.tiktok.com/@monecracy" target={"_blank"} rel={"noreferrer"} className="social">
                                                <img height={imageHeight} width={imageWidth} src="/images/socialLogos/tiktok_icon.png" title="Tiktok" />
                                            </Link>

                                            <Link href="https://www.snapchat.com/add/monecracy" target={"_blank"} rel={"noreferrer"} className="social">
                                                <img height={imageHeight} width={imageWidth} src="/images/socialLogos/snapchat_icon.png" title="Snapchat" />
                                            </Link>

                                            <Link href="https://wa.me/14156300572" target={"_blank"} rel={"noreferrer"} className="social">
                                                <img height={imageHeight} width={imageWidth} src="/images/socialLogos/whatsapp_icon.png" title="WhatsApp" />
                                            </Link>

                                            <Link href="https://www.truthsocial.com/@monecracy" target={"_blank"} rel={"noreferrer"} className="social">
                                                <img height={imageHeight} width={imageWidth} src="/images/socialLogos/truth_social_icon.png" title="Truth Social" />
                                            </Link>

                                            <Link href="https://www.instagram.com/monecracy" target={"_blank"} rel={"noreferrer"} className="social">
                                                <img height={imageHeight} width={imageWidth} src="/images/socialLogos/instagram_icon.png" title="Instagram" />
                                            </Link>
                                        </List>

                                        <List>
                                            <Link href="https://mastodon.social/@monecracy" target={"_blank"} rel={"noreferrer"} className="social">
                                                <img height={imageHeight} width={imageWidth} src="/images/socialLogos/mastodon_icon.png" title="Mastodon" />
                                            </Link>

                                            <Link href="https://www.gab.com/monecracy" target={"_blank"} rel={"noreferrer"} className="social">
                                                <img height={imageHeight} width={imageWidth} src="/images/socialLogos/gab_icon.png" title="Gab" />
                                            </Link>

                                            <Link href="https://www.pinterest.com/monecracy" target={"_blank"} rel={"noreferrer"} className="social">
                                                <img height={imageHeight} width={imageWidth} src="/images/socialLogos/pinterest_logo.png" title="Pinterest" />
                                            </Link>

                                            <Link href="https://www.gettr.com/user/monecracy" target={"_blank"} rel={"noreferrer"} className="social">
                                                <img height={imageHeight} width={imageWidth} src="/images/socialLogos/gettr_icon.png" title="Gettr" />
                                            </Link>

                                            <Link href="https://www.t.me/monecracy" target={"_blank"} rel={"noreferrer"} className="social">
                                                <img height={imageHeight} width={imageWidth} src="/images/socialLogos/telegram_icon.png" title="Telegram" />
                                            </Link>

                                            <Link href="https://www.tumblr.com/monecracy" target={"_blank"} rel={"noreferrer"} className="social">
                                                <img height={imageHeight} width={imageWidth} src="/images/socialLogos/tumblr_icon.png" title="Tumblr" />
                                            </Link>
                                        </List>
                                    </Stack>
                                </ClickAwayListener>
                            </Paper>
                        </Box>
                    </Fade>
                )}
            </Popper>
            {
                props.useTitle === true ?
                    (<Typography>{props.title}</Typography>):
                    ("")
            }
            <Stack direction={"row"} justifyContent={"space-evenly"}>
                {/*TWITTER*/}
                <Link href="https://www.twitter.com/monecracyusa" target={"_blank"} rel={"noreferrer"} className="social">
                    <img height={imageHeight} width={imageWidth} src="/images/socialLogos/twitter_icon.png" title="Twitter" />
                </Link>

                {/*FACEBOOK*/}
                <Link href="https://www.facebook.com/100089733815522" target={"_blank"} rel={"noreferrer"} className="social">
                    <img height={imageHeight} width={imageWidth} src="/images/socialLogos/facebook_icon.png" title="Facebook" />
                </Link>

                {/*YOUTUBE*/}
                <Link href="https://www.youtube.com/monecracy" target={"_blank"} rel={"noreferrer"} className="social">
                    <img height={imageHeight} width={imageWidth} src="/images/socialLogos/youtube_icon.png" title="Youtube" />
                </Link>

                {/*REDDIT*/}
                <Link href="https://www.reddit.com/u/monecracy" target={"_blank"} rel={"noreferrer"} className="social">
                    <img height={imageHeight} width={imageWidth} src="/images/socialLogos/reddit_icon.png" title="Reddit" />
                </Link>

                <Box paddingLeft={1}>
                    <img onClick={handleShareCLick} className={"clickable"} height={25} width={25} src="/images/menu_icon.png" />
                </Box>
            </Stack>
        </Stack>
    )
};

export default FollowUs;
