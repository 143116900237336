import {Stack, Typography} from "@mui/material";
import {Link} from "react-router-dom";

const HomePageCard = ({
    path,
    imageURL,
    title,
    subtitle,
    summary
  }) => {

    return(
        <Stack width={290} padding={1}
               sx={{backgroundColor: '#141428', borderRadius: 2}}>
            <Link to={path} style={{ textDecoration: 'none', color: 'inherit'}}>
                <Stack direction={"row"} spacing={2}>
                    <img height={64} width={64} alt={title} src={imageURL} />
                    <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0} width="100%">
                        <Typography variant="subtitle2">{title}</Typography>
                        <Typography variant="caption" noWrap>{subtitle}</Typography>
                        {/*<Typography variant="caption" noWrap>{summary}</Typography>*/}
                    </Stack>
                </Stack>
            </Link>
        </Stack>
    )
};

export default HomePageCard;
