import React, {useEffect} from "react";
import {Box, Stack, ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import highchartsAccessibility from "highcharts/modules/accessibility";
import exportingOption from "highcharts/modules/exporting";
import offlineOption from "highcharts/modules/offline-exporting";
import {buildArea, buildBar, buildColumn, buildLine, buildPackedBubble, buildPie} from "../util/ChartMaker";

require('highcharts/highcharts-more.js')(Highcharts);
highchartsAccessibility(Highcharts);
exportingOption(Highcharts);
offlineOption(Highcharts);

const ToggleChart = (props) => {

    const [chartType, setChartType] = React.useState('line');
    const [plotField, setPlotField] = React.useState('amount');
    const [plotCurrency, setPlotCurrency] = React.useState(true);
    const [plotTitle, setPlotTitle] = React.useState("");
    const [chartSubTitle, setChartSubTitle] = React.useState("");

    const [plotAmountSelected, setPlotAmountSelected] = React.useState(true);
    const [plotContributorsSelected, setPlotContributorsSelected] = React.useState(false);
    const [plotContributionsSelected, setPlotContributionsSelected] = React.useState(false);

    const [chartTypeAreaSelected, setChartTypeAreaSelected] = React.useState(false);
    const [chartTypeBarSelected, setChartTypeBarSelected] = React.useState(true);
    const [chartTypeColumnSelected, setChartTypeColumnSelected] = React.useState(false);
    const [chartTypeLineSelected, setChartTypeLineSelected] = React.useState(false);
    const [chartTypePieSelected, setChartTypePieSelected] = React.useState(false);
    const [chartTypeBubbleSelected, setChartTypeBubbleSelected] = React.useState(false);

    const changePlotField = (changePlot) =>
    {
        clearPlotFieldSelection()

        if (changePlot === "amount")
        {
            setPlotCurrency(true);
            setPlotTitle("Amount");
            setPlotField("amount");
            setChartSubTitle("By total amount contributed");
            setPlotAmountSelected(!plotAmountSelected)
        }
        else if (changePlot === "contributors")
        {
            setPlotCurrency(false);
            setPlotTitle("Contributors");
            setPlotField("contributors");
            setChartSubTitle("By total number of contributors");
            setPlotContributorsSelected(!plotContributorsSelected)
        }
        if (changePlot === "contributions") {
            setPlotCurrency(false);
            setPlotTitle("Contributions");
            setPlotField("contributions");
            setChartSubTitle("By total number of contributions");
            setPlotContributionsSelected(!plotContributionsSelected)
        }

        setPlotField(changePlot);
    }

    const loadChartOptions = () =>
    {
        let chartOptions;

        if (chartType === 'area')
        {
            chartOptions = buildArea(props.title, chartSubTitle, props.seriesName, plotTitle, props.chartData, plotField, plotCurrency, props.displayField, props.isCES, props.isUCS);
        }
        else if (chartType === 'bar')
        {
            chartOptions = buildBar(props.title, chartSubTitle, props.seriesName, plotTitle, props.chartData, plotField, plotCurrency, props.displayField, props.isCES, props.isUCS);
        }
        else if (chartType === 'column')
        {
            chartOptions = buildColumn(props.title, chartSubTitle, props.seriesName, plotTitle, props.chartData, plotField, plotCurrency, props.displayField, props.isCES, props.isUCS);
        }
        else if (chartType === 'line')
        {
            chartOptions = buildLine(props.title, chartSubTitle, props.seriesName, plotTitle, props.chartData, plotField, plotCurrency, props.displayField, props.isCES, props.isUCS);
        }
        else if (chartType === 'pie')
        {
            chartOptions = buildPie(props.title, chartSubTitle, props.seriesName, plotTitle, props.chartData, plotField, plotCurrency, props.displayField, props.isCES, props.isUCS);
        }
        else if (chartType === 'bubble')
        {
            chartOptions = buildPackedBubble(props.title, props.seriesName, plotTitle, props.chartData, plotField, plotCurrency, props.displayField, props.isCES, props.isUCS);
        }

//        chartOptions.chart.width = props.width;
        chartOptions.chart.width = null;
        chartOptions.chart.height = props.height;

        return chartOptions;
    }

    const setChartView = (inType) =>
    {
        clearChartTypeSelection()

        setChartType(inType)

        if (inType === "area")
        {
            setChartTypeAreaSelected(!chartTypeAreaSelected)
        }
        else if (inType === "bar")
        {
            setChartTypeBarSelected(!chartTypeBarSelected)
        }
        else if (inType === "column")
        {
            setChartTypeColumnSelected(!chartTypeColumnSelected)
        }
        else if (inType === "line")
        {
            setChartTypeLineSelected(!chartTypeLineSelected)
        }
        else if (inType === "pie")
        {
            setChartTypePieSelected(!chartTypePieSelected)
        }
        else
        {
            setChartTypeBubbleSelected(!chartTypeBubbleSelected)
        }
    }

    const clearPlotFieldSelection = () =>
    {
        setPlotAmountSelected(false)
        setPlotContributorsSelected(false)
        setPlotContributionsSelected(false)
    }

    const clearChartTypeSelection = () =>
    {
        setChartTypeAreaSelected(false)
        setChartTypeBarSelected(false)
        setChartTypeColumnSelected(false)
        setChartTypeLineSelected(false)
        setChartTypePieSelected(false)
        setChartTypeBubbleSelected(false)
    }

    useEffect(() => {
        setChartType('bar');
        setPlotTitle("Amount");
        setPlotField("amount");
        setPlotCurrency(true);
        setChartSubTitle("By total amount contributed");
    }, []);

    return (
        <Stack direction="column" alignItems="flex-start" justifyContent={"space-between"}>
            <Stack direction="column" alignItems="stretch" justifyContent="flex-start" width="100%">
                <HighchartsReact
                    highcharts={Highcharts}
                    options={loadChartOptions()}
                />
            </Stack>
            <Box sx={{ backgroundColor: '#20273C'}}>
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" width="100%" padding={1} sx={{flexDirection: { xs: "column", md: "row"}}}>
                <Stack paddingRight={1} paddingBottom={1}>
                    <ToggleButtonGroup variant="contained" aria-label="outlined button group">
                        <Tooltip title="Area">
                            <ToggleButton sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} value='area' onClick={event => setChartView('area')}
                                          style={{backgroundColor: chartTypeAreaSelected ? "green" : "transparent"}}>
                                <img height={16} width={16} src="/images/chart_area.png"/>
                            </ToggleButton>
                        </Tooltip>
                        <Tooltip title="Bar">
                            <ToggleButton sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} value='bar' onClick={event => setChartView('bar')}
                                          style={{backgroundColor: chartTypeBarSelected ? "green" : "transparent"}}>
                                <img height={16} width={16} src="/images/chart_bar.png"/>
                            </ToggleButton>
                        </Tooltip>
                        <Tooltip title="Column">
                            <ToggleButton sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} value='column' onClick={event => setChartView('column')}
                                          style={{backgroundColor: chartTypeColumnSelected ? "green" : "transparent"}}>
                                <img height={16} width={16} src="/images/chart_column.png" />
                            </ToggleButton>
                        </Tooltip>
                        <Tooltip title="Line">
                            <ToggleButton sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} value='line' onClick={event => setChartView('line')}
                                          style={{backgroundColor: chartTypeLineSelected ? "green" : "transparent"}}>
                                <img height={16} width={16} src="/images/chart_line.png"/>
                            </ToggleButton>
                        </Tooltip>
                        <Tooltip title="Pie">
                            <ToggleButton sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} value='pie' onClick={event => setChartView('pie')}
                                          style={{backgroundColor: chartTypePieSelected ? "green" : "transparent"}}>
                                <img height={16} width={16} src="/images/chart_pie.png"/>
                            </ToggleButton>
                        </Tooltip>
                        <Tooltip title="Bubble">
                            <ToggleButton sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} value='bubble' onClick={event => setChartView('bubble')}
                                          style={{backgroundColor: chartTypeBubbleSelected ? "green" : "transparent"}}>
                                <img height={16} width={16} src="/images/chart_bubble.png"/>
                            </ToggleButton>
                        </Tooltip>
                    </ToggleButtonGroup>
                </Stack>

                <Stack>
                    {
                        props.isUCS === true || props.isCES === true ?
                            (
                                <ToggleButtonGroup variant="contained" aria-label="outlined button group">
                                    <Tooltip title="By Total Amount">
                                        <ToggleButton sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} value='amount' onClick={event => changePlotField('amount')}
                                                      style={{backgroundColor: plotAmountSelected ? "green" : "transparent"}}>
                                            <img height={16} width={16} src="/images/plot_amount.png"/>
                                        </ToggleButton>
                                    </Tooltip>
                                    <Tooltip title="By Total Contributions">
                                        <ToggleButton sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} value='contributions' onClick={event => changePlotField('contributions')}
                                                      style={{backgroundColor: plotContributionsSelected ? "green" : "transparent"}}>
                                            <img height={16} width={16} src="/images/plot_contributions.png"/>
                                        </ToggleButton>
                                    </Tooltip>
                                </ToggleButtonGroup>
                            ):
                            (
                                <ToggleButtonGroup variant="contained" aria-label="outlined button group">
                                    <Tooltip title="By Total Amount">
                                        <ToggleButton sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} value='amount' onClick={event => changePlotField('amount')}
                                                      style={{backgroundColor: plotAmountSelected ? "green" : "transparent"}}>
                                            <img height={16} width={16} src="/images/plot_amount.png"/>
                                        </ToggleButton>
                                    </Tooltip>
                                    <Tooltip title="By Total Contributors">
                                        <ToggleButton sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} value='contributors' onClick={event => changePlotField('contributors')}
                                                      style={{backgroundColor: plotContributorsSelected ? "green" : "transparent"}}>
                                            <img height={16} width={16} src="/images/plot_contributors.png"/>
                                        </ToggleButton>
                                    </Tooltip>
                                    <Tooltip title="By Total Contributions">
                                        <ToggleButton sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} value='contributions' onClick={event => changePlotField('contributions')}
                                                      style={{backgroundColor: plotContributionsSelected ? "green" : "transparent"}}>
                                            <img height={16} width={16} src="/images/plot_contributions.png"/>
                                        </ToggleButton>
                                    </Tooltip>
                                </ToggleButtonGroup>
                            )
                    }
                </Stack>
            </Stack>
            </Box>
        </Stack>
    );
};

export default ToggleChart;
