import React, {useEffect} from "react";
import {Link as RouterLink, useParams} from "react-router-dom";

import SiteHeader from "../../components/siteHeader";
import SiteFooter from "../../components/siteFooter";
import ICSTotal from "../../components/ics/icsTotal";
import ICSTypeList from "../../components/ics/icsTypeList";
import UCSList from "../../components/ucs/ucsList";
import CETotal from "../../components/ces/ceTotal";
import CETypeList from "../../components/ces/ceTypeList";
import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import ShareButton from "../../components/shareButton";
import ScrollToTop from "../../components/scrollToTop";
import MetaData from "../../components/metaData";
import * as DataLoader from "../../util/DataLoader";
import ICSStateZipcodeList from "../../components/ics/ICSStateZipcodeList";
import {madLibFactory} from "../../AppGlobal";
import UCSListOrg from "../../components/ucs/ucsListOrg";
import UCSListPAC from "../../components/ucs/ucsListPAC";

const CityCycle = () => {
    const currentPageType = "city";
    const {cycleYear} = useParams();
    const {stateKey} = useParams();
    const {cityKey} = useParams();

    const [success, setSuccess] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [fields, setFields] = React.useState([]);
    const [stats, setStats] = React.useState([]);

    useEffect(() => {
        const urlSuffix = "cycle/" + cycleYear + "/state/" + stateKey + "/city/" + cityKey;
        DataLoader.load(urlSuffix, (result, data) => {
            setLoaded(true);
            if (result === true)
            {
                setSuccess(true)
                setStats(data.statistics);
                setFields(data.fields);
            }
            else
            {
                setSuccess(false);
            }
        })
    }, [cycleYear, stateKey, cityKey])

    let metaTitle = "";
    let metaDescription = "";
    if (success === true)
    {
        const madLibsMeta = madLibFactory.getPageMetaCycle();

        metaTitle = madLibsMeta.buildTitle(currentPageType, cycleYear, fields.display, fields, stats);
        metaDescription = madLibsMeta.buildDescription(currentPageType, cycleYear, fields.display, fields, stats);
    }

    return (
        <div>
            <Stack paddingBottom={10}>
                <SiteHeader />
            </Stack>
            {
                loaded === false ?
                    (<Typography variant="h6">Building City Summary for the {cycleYear} Elections...</Typography>) :
                    (<div className="Page-content">
                        {
                            success === false ?
                                (<Typography variant="h6">Unable to build a City Summary for {cityKey}, {stateKey} for the {cycleYear} Elections</Typography>) :
                                (<React.Fragment>
                                    <MetaData
                                        title={metaTitle}
                                        description={metaDescription}
                                        url={window.location.href}
                                        image={"https://www.monecracy.com/images/logo.png"}/>

                                    <Stack direction="column" spacing={1} padding={0}>
                                        <Stack id="back-to-top-anchor" direction="row" alignItems="center" justifyContent="space-between" paddingTop={1}>
                                            <Breadcrumbs>
                                                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                                                <Link component={RouterLink} to={"/totals"} underline={"hover"}>Totals</Link>
                                                <Link component={RouterLink} to={"/cycles"} underline={"hover"}>Cycles</Link>
                                                <Link component={RouterLink} to={"/cycle/" + cycleYear} underline={"hover"}>{cycleYear}</Link>
                                                <Link component={RouterLink} to={"/cycle/" + cycleYear + "/states"} underline={"hover"}>States</Link>
                                                <Link component={RouterLink} to={"/cycle/" + cycleYear + "/state/" + fields.stateKey} underline={"hover"}>{fields.stateDisplay}</Link>
                                                <Typography>{fields.display}</Typography>
                                            </Breadcrumbs>
                                            <ShareButton title={"Monecracy: City Summary for " + fields.display + ", " + fields.stateDisplay  + " in the " + cycleYear + " Elections"} />
                                        </Stack>

                                        <ICSTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_total"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                        />
                                        <ICSStateZipcodeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_zipcode_totals"
                                            stateKey={stateKey}
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                        />
                                        <ICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_party_totals"
                                            cycleYear={cycleYear}
                                            statsTop={false}
                                            anchor="party_totals"
                                            linkType="party"
                                            display={fields.display}
                                        />
                                        <ICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_candidates_top"
                                            cycleYear={cycleYear}
                                            statsTop={true}
                                            anchor="politician_totals"
                                            linkType="candidate"
                                            display={fields.display}
                                        />
                                        <ICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_committees_top"
                                            cycleYear={cycleYear}
                                            statsTop={true}
                                            anchor="committee_totals"
                                            linkType="committee"
                                            display={fields.display}
                                        />
                                        <ICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_offices_top"
                                            cycleYear={cycleYear}
                                            statsTop={true}
                                            anchor="office_totals"
                                            linkType="office"
                                            display={fields.display}
                                        />
                                        <ICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_organizations_top"
                                            cycleYear={cycleYear}
                                            statsTop={true}
                                            anchor="organization_totals"
                                            linkType="organization"
                                            display={fields.display}
                                        />
                                        <ICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_industries_top"
                                            cycleYear={cycleYear}
                                            statsTop={true}
                                            anchor="industry_totals"
                                            linkType="industry"
                                            display={fields.display}
                                        />
                                        <ICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_occupations_top"
                                            cycleYear={cycleYear}
                                            statsTop={true}
                                            anchor="occupation_totals"
                                            linkType="occupation"
                                            display={fields.display}
                                        />
                                        <ICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_surnames_top"
                                            cycleYear={cycleYear}
                                            statsTop={true}
                                            anchor="surname_totals"
                                            linkType="surname"
                                            display={fields.display}
                                        />
                                        <UCSList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_indiv_top"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                            linkType={"contributor"}
                                        />
                                        <UCSListOrg
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_org_top"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                            linkType={"organization"}
                                        />
                                        <UCSListPAC
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_pac_top"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                            linkType={"organization"}
                                        />
                                        <CETotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_total"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                        />
                                        <CETypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_committees_top"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                            linkType="committee"
                                            anchor="committee_expenditure_totals"
                                        />
                                        <CETypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_vendors_top"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                            linkType="vendor"
                                            anchor="vendor_expenditure_totals"
                                        />
                                    </Stack>

                                    <ScrollToTop />
                                </React.Fragment>)
                        }
                    </div>)
            }
            <SiteFooter/>
        </div>
    );
};

export default CityCycle;
