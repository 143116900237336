import React from "react";
import {useNavigate} from "react-router-dom";
import {Stack, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import CycleTableHeader from "../cycleTableHeader";
import ToggleChart from "../toggleChart";
import {madLibFactory, typeDetails} from "../../AppGlobal";
import {buildCTSColumns} from "../../util/DataGridMaker";

const CTTypeList = (props) => {

    let navigate = useNavigate();

    const [sortModel, setSortModel] = React.useState([
        {
            field: 'amount',
            sort: 'desc',
        },
    ]);

    if (props.stats === undefined)
    {
        return;
    }

    const renderData = props.stats[props.statsKey];

    const madLibs = madLibFactory.getCycleCTSType();

    const registration = madLibs.getRegistration(props.statsKey);

    const linkTypeDetails = typeDetails[props.linkType];

    const renderTitle = madLibs.buildTitle(props.cycleYear, props.pageType, props.linkType, props.statsKey, props.display);
    const renderSubtitle = madLibs.buildSubtitle(props.cycleYear, props.pageType, props.linkType, props.statsKey, props.display);
    const renderChartTitle = madLibs.buildChartTitle(props.cycleYear, props.pageType, props.linkType, props.statsKey, props.display);
    const renderCaption = madLibs.buildContent(props.cycleYear, props.pageType, props.linkType, props.statsKey, props.display, props.stats, renderData);

    const chartWidth = (window.innerWidth > 1000 ? 1175: window.innerWidth - 20);
    const imageIconSrc = linkTypeDetails.iconPath;

    const header = registration !== undefined ? registration.header: 'Details';

    const columns = buildCTSColumns('display', props.typeHeader, 500, 700);

    const handleOnCellClick = (params) => {
        navigate("/cycle/" + props.cycleYear + "/" + props.linkType + "/" + params.row.typeKey);
    };

    return (
        <Stack align="center" paddingTop={"5px"} paddingBottom={"5px"}>
            <CycleTableHeader
                imgSource={imageIconSrc}
                title={renderTitle}
                subtitle={renderSubtitle}
                anchor={props.anchor}
                cycleYear={props.cycleYear}
            />
            <Stack direction="column" sx={{ backgroundColor: '#20273C', padding: 2}}>
                {/*<Typography align="left" variant={"body2"}>{props.title}</Typography>*/}
            {
                renderData === undefined ?
                    (<span>No data available</span>):
                    (
                        <Stack>
                            <Typography align={"left"} variant="caption">{renderCaption}</Typography>
                            <ToggleChart width={chartWidth} height={500}
                                         title={renderChartTitle}
                                         seriesName="Total"
                                         chartData={renderData}
                                         plotField="amount"
                                         displayField="display"
                                         isCES={true}
                            />
                            <DataGrid
                                disableColumnMenu={true}
                                getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
                                sx={{ color:"white", flex: 1, border: 0}}
                                getRowId={(row) => row.display}
                                rows={renderData}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                                hideFooterPagination={false}
                                sortModel={sortModel}
                                sortingOrder ={['asc', 'desc']}
                                onSortModelChange={(model) => setSortModel(model)}
                                autoHeight={true}
                                onRowClick={handleOnCellClick}
                            />
                        </Stack>
                    )
            }
            </Stack>
        </Stack>
    );
};

export default CTTypeList;
