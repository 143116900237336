import React, {useEffect} from "react";
import {Link as RouterLink, useParams} from "react-router-dom";

import SiteHeader from "../../components/siteHeader";
import SiteFooter from "../../components/siteFooter";
import ICSTotal from "../../components/ics/icsTotal";
import ICSStateList from "../../components/ics/icsStateList";
import ICSCountyList from "../../components/ics/icsCountyList";
import ICSCityList from "../../components/ics/icsCityList";
import ICSZipcodeList from "../../components/ics/icsZipcodeList";
import ICSTypeList from "../../components/ics/icsTypeList";
import UCSList from "../../components/ucs/ucsList";
import CEStateList from "../../components/ces/ceStateList";
import CETypeList from "../../components/ces/ceTypeList";
import CETotal from "../../components/ces/ceTotal";
import CTTotal from "../../components/cts/ctTotal";
import CTTypeList from "../../components/cts/ctTypeList";
import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import ShareButton from "../../components/shareButton";
import ScrollToTop from "../../components/scrollToTop";
import MetaData from "../../components/metaData";
import * as DataLoader from "../../util/DataLoader";
import {madLibFactory} from "../../AppGlobal";
import UCSListOrg from "../../components/ucs/ucsListOrg";
import UCSListPAC from "../../components/ucs/ucsListPAC";

//TODO: Missing CE County, City, Zipcode page stats

const CommitteeCycle = () => {
    const currentPageType = "committee";
    const {cycleYear} = useParams();
    const {committeeKey} = useParams();

    const [success, setSuccess] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [fields, setFields] = React.useState([]);
    const [stats, setStats] = React.useState([]);

    useEffect(() => {
        const urlSuffix = "cycle/" + cycleYear + "/committee/" + committeeKey;
        DataLoader.load(urlSuffix, (result, data) => {
            setLoaded(true);
            if (result === true)
            {
                setSuccess(true)
                setStats(data.statistics);
                setFields(data.fields);
            }
            else
            {
                setSuccess(false);
            }
        })
    }, [cycleYear, committeeKey])

    let metaTitle = "";
    let metaDescription = "";
    if (success === true)
    {
        const madLibsMeta = madLibFactory.getPageMetaCycle();

        metaTitle = madLibsMeta.buildTitle(currentPageType, cycleYear, fields.display, fields, stats);
        metaDescription = madLibsMeta.buildDescription(currentPageType, cycleYear, fields.display, fields, stats);
    }

    return (
        <div>
            <Stack paddingBottom={10}>
                <SiteHeader />
            </Stack>
            {
                loaded === false ?
                    (<Typography variant="h6">Building Committee Summary for the {cycleYear} Elections...</Typography>) :
                    (<div className="Page-content">
                        {
                            success === false ?
                                (<Typography variant="h6">Unable to build a Committee Summary for {committeeKey} for the {cycleYear} Elections</Typography>) :
                                (<React.Fragment>
                                    <MetaData
                                        title={metaTitle}
                                        description={metaDescription}
                                        url={window.location.href}
                                        image={"https://www.monecracy.com/images/logo.png"}/>

                                    <Stack direction="column" spacing={1} padding={0}>
                                        <Stack id="back-to-top-anchor" direction="row" alignItems="center" justifyContent="space-between" paddingTop={1}>
                                            <Breadcrumbs>
                                                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                                                <Link component={RouterLink} to={"/totals"} underline={"hover"}>Totals</Link>
                                                <Link component={RouterLink} to={"/cycles"} underline={"hover"}>Cycles</Link>
                                                <Link component={RouterLink} to={"/cycle/" + cycleYear} underline={"hover"}>{cycleYear}</Link>
                                                <Link component={RouterLink} to={"/cycle/" + cycleYear + "/committees"} underline={"hover"}>Committees</Link>
                                                <Typography>{fields.display}</Typography>
                                            </Breadcrumbs>
                                            <ShareButton title={"Monecracy: Committee Summary for " + fields.display + " for the " + cycleYear + " Elections"} />
                                        </Stack>

                                        <ICSTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_total"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                        />
                                        <ICSStateList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_states"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                        />
                                        <ICSCountyList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_counties_top"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                        />
                                        <ICSCityList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_cities_top"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                        />
                                        <ICSZipcodeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_zipcodes_top"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                        />
                                        <ICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_parties"
                                            cycleYear={cycleYear}
                                            statsTop={false}
                                            anchor="party_totals"
                                            linkType="party"
                                            display={fields.display}
                                        />
                                        <ICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_politicians_top"
                                            cycleYear={cycleYear}
                                            statsTop={true}
                                            anchor="politician_totals"
                                            linkType="candidate"
                                            display={fields.display}
                                        />
                                        <ICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_offices_top"
                                            cycleYear={cycleYear}
                                            statsTop={true}
                                            anchor="office_totals"
                                            linkType="office"
                                            display={fields.display}
                                        />
                                        <ICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_organizations_top"
                                            cycleYear={cycleYear}
                                            statsTop={true}
                                            anchor="organization_totals"
                                            linkType="organization"
                                            display={fields.display}
                                        />
                                        <ICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_industries_top"
                                            cycleYear={cycleYear}
                                            statsTop={true}
                                            anchor="industry_totals"
                                            linkType="industry"
                                            display={fields.display}
                                        />
                                        <ICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_occupations_top"
                                            cycleYear={cycleYear}
                                            statsTop={true}
                                            anchor="occupation_totals"
                                            linkType="occupation"
                                            display={fields.display}
                                        />
                                        <ICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_surnames_top"
                                            cycleYear={cycleYear}
                                            statsTop={true}
                                            anchor="surname_totals"
                                            linkType="surname"
                                            display={fields.display}
                                        />
                                        <UCSList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_indiv_top"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                            linkType={"contributor"}
                                        />
                                        <UCSListOrg
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_org_top"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                            linkType={"organization"}
                                        />
                                        <UCSListPAC
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_pac_top"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                            linkType={"organization"}
                                        />
                                        <CETotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_total"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                        />
                                        <CEStateList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_states"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                        />
                                        {/*<CECountyList*/}
                                        {/*    pageType={currentPageType}*/}
                                        {/*    stats={stats}*/}
                                        {/*    statsKey="ce_counties_top"*/}
                                        {/*    cycleYear={cycleYear}*/}
                                        {/*    display={fields.display}*/}
                                        {/*/>*/}
                                        {/*<CECityList*/}
                                        {/*    pageType={currentPageType}*/}
                                        {/*    stats={stats}*/}
                                        {/*    statsKey="ce_cities_top"*/}
                                        {/*    cycleYear={cycleYear}*/}
                                        {/*    display={fields.display}*/}
                                        {/*/>*/}
                                        <CETypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_vendors_top"
                                            cycleYear={cycleYear}
                                            display={fields.display}
                                            linkType="vendor"
                                            anchor="vendor_expenditure_totals"
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_summary"
                                            linkType="committee"
                                            typeHeader="Transaction Type"
                                            anchor="transaction_totals"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_support_total"
                                            display={fields.display}
                                            anchor="transaction_supporting_candidate_totals"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_oppose_total"
                                            display={fields.display}
                                            anchor="transaction_opposing_candidate_totals"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_support_top"
                                            typeHeader="Candidate"
                                            linkType="office"
                                            anchor="transaction_supporting_candidate_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_oppose_top"
                                            typeHeader="Candidate"
                                            linkType="office"
                                            anchor="transaction_oppose_candidate_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_comm_cand_oppose"
                                            typeHeader="Candidate"
                                            linkType="office"
                                            anchor="transaction_communication_against_candidate"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_comm_cand_support"
                                            typeHeader="Candidate"
                                            linkType="office"
                                            anchor="transaction_communication_support_candidate"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_vendors_top"
                                            typeHeader="Vendor"
                                            linkType="vendor"
                                            anchor="transaction_with_vendors"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_nit_contrib"
                                            typeHeader="Organization"
                                            linkType="organization"
                                            anchor="transaction_native_tribes"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_to_nonaffilcomm_total"
                                            display={fields.display}
                                            anchor="transaction_contribution_to_non-affiliated_committee"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_to_nonaffilcomm_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_contribution_to_non-affiliated_committee_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_from_nonaffilcomm_total"
                                            display={fields.display}
                                            anchor="transaction_contribution_from_nonaffiliated_committee"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_from_nonaffilcomm_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_contribution_from_non-affiliated_committee_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_from_regfiler_total"
                                            display={fields.display}
                                            anchor="transaction_contribution_from_register_filer"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_from_regfiler_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_contribution_from_register_filer_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_regfiler_contrib_total"
                                            display={fields.display}
                                            anchor="transaction_register_filer_to_committee"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_regfiler_contrib_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_register_filer_to_committee_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_transfer_out_affilcomm_total"
                                            display={fields.display}
                                            anchor="transaction_out_to_affiliated_committee"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_transfer_out_affilcomm_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_out_to_affiliated_committee_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_transfer_to_affilcomm_total"
                                            display={fields.display}
                                            anchor="transaction_out_to_affiliated_by_committee"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_transfer_to_affilcomm_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_out_to_affiliated_by_committee_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_from_jointfund_total"
                                            display={fields.display}
                                            anchor="transaction_from_joint_fundraising_committee"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_from_jointfund_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_from_joint_fundraising_committee_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_to_jointfund_total"
                                            display={fields.display}
                                            anchor="transaction_to_joint_fundraising_committee"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_to_jointfund_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_to_joint_fundraising_committee_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_hqacct_regfiler_total"
                                            display={fields.display}
                                            anchor="transaction_hq_account_from_filer"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_hqacct_regfiler_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_hq_account_from_filer_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_regfiler_hqacct_total"
                                            display={fields.display}
                                            anchor="transaction_to_hq_account"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_regfiler_hqacct_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_to_hq_account_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_conacct_transfer_in_total"
                                            display={fields.display}
                                            anchor="transaction_convention_account_from_committee"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_conacct_transfer_in_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_convention_account_from_committee_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_conacct_transfer_to_total"
                                            display={fields.display}
                                            anchor="transaction_to_convention_account"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_conacct_transfer_to_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_to_convention_account_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_inkind_to_regfiler_total"
                                            display={fields.display}
                                            anchor="transaction_in_kind_to_registered_filer"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_inkind_to_regfiler_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_in_kind_to_registered_filer_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_inkind_from_committee_total"
                                            display={fields.display}
                                            anchor="transaction_in_kind_from_committee"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_inkind_from_committee_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_in_kind_from_committee_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_from_regfiler_total"
                                            display={fields.display}
                                            anchor="transaction_recount_account_from_filer"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_from_regfiler_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_recount_account_from_filer_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_to_regfiler_total"
                                            display={fields.display}
                                            anchor="transaction_recount_account_to_committee"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_to_regfiler_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_recount_account_to_committee_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_inkind_from_regfiler_total"
                                            display={fields.display}
                                            anchor="transaction_in_kind_from_filer"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_inkind_from_regfiler_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_in_kind_from_filer_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_inkind_to_committee_total"
                                            display={fields.display}
                                            anchor="transaction_in_kind_to_committee"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            cycleYear={cycleYear}
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_inkind_to_committee_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_in_kind_to_committee_top"
                                            display={fields.display}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_refund_to_comm_total"
                                            display={fields.display}
                                            anchor="transaction_refund_to_committee"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_refund_to_comm_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_refund_to_committee_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_refund_from_comm_total"
                                            display={fields.display}
                                            anchor="transaction_refund_from_committee"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_refund_from_comm_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_refund_from_committee_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_conacct_from_regfiler_total"
                                            display={fields.display}
                                            anchor="transaction_convention_account_receipt_from_filer"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_conacct_from_regfiler_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_convention_account_receipt_from_filer_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_regfiler_to_conacct_total"
                                            display={fields.display}
                                            anchor="transaction_register_filer_to_convention_account"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_regfiler_to_conacct_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_register_filer_to_convention_account_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_hqacct_from_comm_joint_total"
                                            display={fields.display}
                                            anchor="transaction_hq_receipt_from_joint_fundraising"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_hqacct_from_comm_joint_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_hq_receipt_from_joint_fundraising_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_hqacct_to_comm_joint_total"
                                            display={fields.display}
                                            anchor="transaction_joint_fundraising_from_hq"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_hqacct_to_comm_joint_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_joint_fundraising_from_hq_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_from_comm_joint_total"
                                            display={fields.display}
                                            anchor="transaction_recount_account_from_joint_fundraising"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_from_comm_joint_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_recount_account_from_joint_fundraising_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_to_comm_joint_total"
                                            display={fields.display}
                                            anchor="transaction_joint_fundraising_from_recount_account"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_to_comm_joint_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_joint_fundraising_from_recount_account_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_conacct_refund_to_regfiler_total"
                                            display={fields.display}
                                            anchor="transaction_convention_account_refund_to_filer"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_conacct_refund_to_regfiler_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_convention_account_refund_to_filer_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_refiler_refund_from_conacct_total"
                                            display={fields.display}
                                            anchor="transaction_refund_to_filer_from_convention_account"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_refiler_refund_from_conacct_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_refund_to_filer_from_convention_account_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_refund_to_regfiler_total"
                                            display={fields.display}
                                            anchor="transaction_recount_account_refund_to_registered_filer"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_refund_to_regfiler_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_recount_account_refund_to_registered_filer_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_refiler_refund_from_recount_total"
                                            display={fields.display}
                                            anchor="transaction_refund_to_filer_from_recount_account"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_refiler_refund_from_recount_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_refund_to_filer_from_recount_account_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_disbursement_to_total"
                                            display={fields.display}
                                            anchor="transaction_recount_account_disbursements"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_disbursement_to_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_recount_account_disbursements_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_disbursement_from_total"
                                            display={fields.display}
                                            anchor="transaction_disbursements_from_recount_account"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_disbursement_from_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_disbursements_from_recount_account_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_loan_repay_regfiler_total"
                                            display={fields.display}
                                            anchor="transaction_repayments_to_filer"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_loan_repay_refiler_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_repayments_to_filer_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_regfiler_loan_payment_total"
                                            display={fields.display}
                                            anchor="transaction_loan_payment"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_regfiler_loan_payment_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_loan_payment_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_hqacct_refund_regfiler_total"
                                            display={fields.display}
                                            anchor="transaction_hq_refund_to_filer"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_hqacct_refund_regfiler_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_hq_refund_to_filer_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_regfiler_hqacct_refund_total"
                                            display={fields.display}
                                            anchor="transaction_filer_to_hq_refund"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_regfiler_hqacct_refund_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_filer_to_hq_refund_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_unreg_org_total"
                                            display={fields.display}
                                            anchor="transaction_committee_contribution_from_unregister_organization"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_unreg_org_top"
                                            typeHeader="Organization"
                                            linkType="organization"
                                            anchor="transaction_committee_contribution_from_unregister_organization_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_loan_org_total"
                                            display={fields.display}
                                            anchor="transaction_committee_loan_from_bank"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_loan_org_top"
                                            typeHeader="Organization"
                                            linkType="organization"
                                            anchor="transaction_committee_loan_from_bank_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_loan_from_cand_total"
                                            display={fields.display}
                                            anchor="transaction_committee_loan_from_candidate"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_loan_from_cand_top"
                                            typeHeader="Candidate"
                                            linkType="office"
                                            anchor="transaction_committee_loan_from_candidate_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_loan_repay_cand_total"
                                            display={fields.display}
                                            anchor="transaction_committee_loan_repay_to_candidate"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_loan_repay_cand_top"
                                            typeHeader="Candidate"
                                            linkType="office"
                                            anchor="transaction_committee_loan_repay_to_candidate_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                        <CTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_cand_coorparty_exp_total"
                                            display={fields.display}
                                            anchor="transaction_committee_coordinated_party_expenditure"
                                            cycleYear={cycleYear}
                                        />

                                        <CTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_cand_coorparty_exp_top"
                                            typeHeader="Candidate"
                                            linkType="office"
                                            anchor="transaction_committee_coordinated_party_expenditure_top"
                                            display={fields.display}
                                            cycleYear={cycleYear}
                                        />

                                    </Stack>

                                    <ScrollToTop />
                                </React.Fragment>)
                        }
                    </div>)
            }
            <SiteFooter/>
        </div>
    );
};

export default CommitteeCycle;
