import AMadLib from "../../AMadLib";

export default class MLCycleCESZipcode extends AMadLib
{
    constructor()
    {
        super();
    }

    buildTitle(cycleYear, pageType, display)
    {
        let title = "Top Zip Codes by Total Committee Expenditures";
        if (pageType !== 'cycle')
        {
            const expensePrefix = this.getExpensePrefix(pageType);
            title += expensePrefix + display;
        }
        title += " for the " + cycleYear + " Elections";

        return title
    }

    buildSubtitle(cycleYear, pageType, display)
    {
        let subtitle = "The top zip codes based on the total committee expenditures";
        subtitle += " for the " + cycleYear + " elections.";

        return subtitle;
    }

    buildChartTitle(cycleYear, pageType, display)
    {
        return this.buildTitle(cycleYear, pageType, display);
    }

    buildContent(cycleYear, pageType, display, stats, data)
    {
        let totalAmount = 0;
        let topAmount = 0;
        let topAmountDisplay = 0;

        if (data !== undefined) {
            for (const nextCounty of data) {
                totalAmount = totalAmount + nextCounty.amount;

                //Top State by Total Amount
                if (nextCounty.amount > topAmount) {
                    topAmount = nextCounty.amount;
                    topAmountDisplay = nextCounty.county;
                }
            }
        }

        let topAmountPct = (topAmount * 100) / totalAmount;

        let content = "The total expenditures (spending)";

        if (pageType === "cycle")
        {
            content += " for all committees"
        }
        else if (pageType === "party")
        {
            content += " for all committees affiliated with the " + display;
        }
        else if (pageType === "politician")
        {
            content += " for all committees supporting the election of the politician " + display;
        }
        else if (pageType === "office")
        {
            content += " for all committees on elections for the office of " + display;
        }
        else if (pageType === "committee")
        {
            content += " for the " + display;
        }
        else if (pageType === "organization")
        {
            content += " for all committees associated to the " + display + " organization"
        }
        content += " in the top 25 zip codes for the " + cycleYear + " elections was $"

        content += totalAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ". ";
        content += "The zip code that received the most revenue from committee expenditures was " + topAmountDisplay;
        content += ", receiving a total of $";
        content += topAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ", which represents ";
        content += topAmountPct.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += "% of total spending in the " + cycleYear + " elections.";

        return content;
    }

}
