import React from "react";
import SiteHeader from "../components/siteHeader";
import SiteFooter from "../components/siteFooter";
import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import ScrollToTop from "../components/scrollToTop";
import {Link as RouterLink} from "react-router-dom";

const Privacy = () => {
    return (
        <div>
            <Stack paddingBottom={10} id="back-to-top-anchor">
                <SiteHeader />
            </Stack>

            <Stack id="back-to-top-anchor" direction="row" alignItems="center" justifyContent="space-between" paddingTop={1} paddingBottom={1}>
                <Breadcrumbs>
                    <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                    <Typography>Cookie Policy</Typography>
                </Breadcrumbs>
            </Stack>

            <div className="Page-content" align="left" style={{ marginLeft: 25, marginTop: 10, maxWidth: 1000}}>

                <Typography variant="h5" gutterBottom>Cookie Policy</Typography>
                <Typography variant="body1">
                    <p>This document informs Users about the technologies that help this Application to achieve the purposes described below. Such technologies allow the Owner to access and store information (for example by using a Cookie) or use resources (for example by running a script) on a User’s device as they interact with this Application.</p>
                    <p>For simplicity, all such technologies are defined as "Trackers" within this document – unless there is a reason to differentiate.</p>
                    <p>For example, while Cookies can be used on both web and mobile browsers, it would be inaccurate to talk about Cookies in the context of mobile apps as they are a browser-based Tracker. For this reason, within this document, the term Cookies is only used where it is specifically meant to indicate that particular type of Tracker.</p>
                    <p>Some of the purposes for which Trackers are used may also require the User's consent, depending on the applicable law. Whenever consent is given, it can be freely withdrawn at any time following the instructions provided in this document.</p>
                    <p>This Application uses Trackers managed directly by the Owner (so-called “first-party” Trackers) and Trackers that enable services provided by a third-party (so-called “third-party” Trackers). Unless otherwise specified within this document, third-party providers may access the Trackers managed by them.</p>
                    <p>The validity and expiration periods of Cookies and other similar Trackers may vary depending on the lifetime set by the Owner or the relevant provider. Some of them expire upon termination of the User’s browsing session.</p>
                    <p>In addition to what’s specified in the descriptions within each of the categories below, Users may find more precise and updated information regarding lifetime specification as well as any other relevant information — such as the presence of other Trackers — in the linked privacy policies of the respective third-party providers or by contacting the Owner.</p>
                </Typography>
                <br/>


                <Typography variant="h5" gutterBottom>
                    Activities strictly necessary for the operation of this Application and delivery of the Service
                </Typography>
                <Typography variant="body1">
                    <p>This Application uses so-called “technical” Cookies and other similar Trackers to carry out
                        activities that are strictly necessary for the operation or delivery of the Service.</p>
                </Typography>
                <br/>

                <Typography variant="h5" gutterBottom>Other activities involving the use of Trackers</Typography>
                <Typography variant="body1">
                    <Typography variant="h6" gutterBottom>Experience enhancement</Typography>
                    <p>This Application uses Trackers to provide a personalized user experience by improving the quality of
                        preference management options, and by enabling interaction with external networks and platforms.</p>
                </Typography>
                <br/>

                <Typography variant="h5" gutterBottom>Displaying content from external platforms</Typography>
                <Typography variant="body1">
                    <p>This type of service allows you to view content hosted on external platforms directly from the
                        pages of this Application and interact with them.<br/>
                        This type of service might still collect web traffic data for the pages where the service is
                        installed, even when Users do not use it.</p>
                    <Typography variant="h6" gutterBottom>Google Fonts (Google LLC)</Typography>
                    <div className="wrap">
                        <p>Google Fonts is a typeface visualization service provided by Google LLC that allows this
                            Application to incorporate content of this kind on its pages.</p>
                    </div>
                    <p>Personal Data processed: Trackers and Usage Data.</p>
                    <p>Place of processing: United States &ndash;  <Link href="https://policies.google.com/privacy" target={"_blank"} rel={"noreferrer noopener"}>Privacy Policy</Link>. </p>
                    <Typography variant="h6" gutterBottom>Measurement</Typography>
                    <p>This Application uses Trackers to measure traffic and analyze User behavior with the goal of
                        improving the Service.</p>
                </Typography>
                <br/>

                <Typography variant="h5" gutterBottom>Analytics</Typography>
                <Typography variant="body1">
                    <p>The services contained in this section enable the Owner to monitor and analyze web traffic and
                        can be used to keep track of User behavior.</p>
                    <Typography variant="h6" gutterBottom>Google Analytics (Google LLC)</Typography>
                    <div className="wrap">
                        <p>Google Analytics is a web analysis service provided by Google LLC (“Google”). Google utilizes
                            the Data collected to track and examine the use of this Application, to prepare reports on
                            its activities and share them with other Google services.<br/>
                            Google may use the Data collected to contextualize and personalize the ads of its own
                            advertising network.</p>
                    </div>
                    <p>Personal Data processed: Trackers and Usage Data.</p>
                    <p>Place of processing: United States &ndash;  <Link href="https://policies.google.com/privacy" target={"_blank"} rel={"noreferrer noopener"}>Privacy Policy</Link> &ndash;
                        <Link href="https://tools.google.com/dlpage/gaoptout?hl=en" onClick="javascript:return tryFunc('tryGaOptOut',{href:'https://tools.google.com/dlpage/gaoptout?hl=en'})" target={"_blank"}>Opt Out</Link>.
                    </p>
                    <p> Storage duration: <ul>
                        <li>AMP_TOKEN: 1 hour</li>
                        <li>_ga: 2 years</li>
                        <li>_gac*: 3 months</li>
                        <li>_gat: 1 minute</li>
                        <li>_gid: 1 day</li>
                    </ul></p>
                    <Typography variant="h6" gutterBottom>Targeting &amp; Advertising</Typography>
                    <p>This Application uses Trackers to deliver personalized marketing content based on User behavior and
                        to operate, serve and track ads.</p>
                    <p>Some of the advertising services used by the Owner adhere to the
                        <Link target={"_blank"} rel={"noreferrer noopener nofollow"} href="https://iabeurope.eu/transparency-consent-framework/">IAB Transparency and Consent Framework</Link>
                        , an initiative that facilitates responsible privacy practices
                        across the digital advertising industry - providing Users with enhanced transparency and control
                        over how their data are used for advertising tracking purposes. Users can customize their
                        advertising preferences at any time by accessing the advertising preferences panel from within the
                        cookie notice or via the relevant link on this Application.</p>
                    <p>This Application participates in the IAB Europe Transparency & Consent Framework and complies with
                        its Specifications and Policies. This Application uses iubenda (identification number 123) as a
                        Consent Management Platform.</p>
                </Typography>
                <br/>


                <Typography variant="h5" gutterBottom>Advertising</Typography>
                <Typography variant="body1">
                    <p>This type of service allows User Data to be utilized for advertising communication purposes.
                        These communications are displayed in the form of banners and other advertisements on this
                        Application, possibly based on User interests.<br/>
                        This does not mean that all Personal Data are used for this purpose. Information and conditions
                        of use are shown below. <br/>
                        Some of the services listed below may use Trackers to identify Users or they may use the
                        behavioral retargeting technique, i.e. displaying ads tailored to the User’s interests and
                        behavior, including those detected outside this Application. For more information, please check
                        the privacy policies of the relevant services.<br/>
                        Services of this kind usually offer the possibility to opt out of such tracking. In addition to
                        any opt-out feature offered by any of the services below, Users may learn more on how to
                        generally opt out of interest-based advertising within the dedicated section "How to opt-out of
                        interest-based advertising" in this document.</p>
                    <Typography variant="h6" gutterBottom>Google AdSense (Google LLC)</Typography>
                    <div className="wrap">
                        <p>Google AdSense is an advertising service provided by Google LLC. This service uses the
                            “DoubleClick” Cookie, which tracks use of this Application and User behavior concerning ads,
                            products and services offered.<br/>
                            Users may decide to disable all the DoubleClick Cookies by going to:
                            <Link href="https://adssettings.google.com/authenticated?hl=en" target={"_blank"} rel={"noreferrer"}>Google Ad Settings</Link>.</p>
                        <p>In order to understand Google's use of data, consult
                            <Link href="https://policies.google.com/technologies/partner-sites" target={"_blank"} rel={"noreferrer"}>Google's partner policy</Link>.
                        </p>
                    </div>
                    <p>Personal Data processed: Trackers and Usage Data.</p>
                    <p>Place of processing: United States &ndash;
                        <Link href="https://policies.google.com/privacy" target={"_blank"} rel={"noreferrer noopener"}>Privacy Policy</Link> &ndash;
                        <Link href="https://adssettings.google.com/authenticated" target={"_blank"} rel={"noreferrer"}>Opt Out</Link>. </p>
                    <p> Storage duration: <ul>
                        <li>AID: 2 years</li>
                        <li>ANID: 2 years</li>
                        <li>Conversion: 3 months</li>
                        <li>DSID: 14 days</li>
                        <li>FCNEC: 1 year</li>
                        <li>FLC: 10 seconds</li>
                        <li>FPAU: 3 months</li>
                        <li>FPGCLAW: 3 months</li>
                        <li>FPGCLDC: 3 months</li>
                        <li>FPGCLGB: 3 months</li>
                        <li>IDE: 2 years</li>
                        <li>NID: 6 months</li>
                        <li>RUL: 1 year</li>
                        <li>TAID: 14 days</li>
                        <li>__gads: 2 years</li>
                        <li>__gsas: 2 years</li>
                        <li>_gac_: 3 months</li>
                        <li>_gac_gb_: 3 months</li>
                        <li>_gcl_au: 3 months</li>
                        <li>_gcl_aw: 3 months</li>
                        <li>_gcl_dc: 3 months</li>
                        <li>_gcl_gb: 3 months</li>
                        <li>_gcl_gf: 3 months</li>
                        <li>_gcl_ha: 3 months</li>
                        <li>id: 2 years</li>
                        <li>test_cookie: 15 minutes</li>
                    </ul></p>
                    <Typography variant="h6" gutterBottom>Amazon Advertising (Amazon)</Typography>
                    <div className="wrap">
                        <p>Amazon Advertising is an advertising service provided by Amazon.</p>
                    </div>
                    <p>Personal Data processed: Trackers and Usage Data.</p>
                    <p>Place of processing: United States &ndash;
                        <Link href="https://www.amazon.co.uk/gp/help/customer/display.html?nodeId=201909010" target={"_blank"} rel={"noreferrer noopener"}>Privacy Policy</Link> &ndash;
                        <Link href="https://www.amazon.com/gp/help/customer/display.html?nodeId=202075050" target={"_blank"} rel={"noreferrer"}>Opt out</Link>. </p>
                    <p> Storage duration: <ul>
                        <li>ad-id: 10 months</li>
                        <li>amzn-token: 7 days</li>
                        <li>vendor-id: 1 month</li>
                    </ul></p>
                </Typography>
                <br/>


                <Typography variant="h5" gutterBottom>Commercial affiliation</Typography>
                <Typography variant="body1">
                    <p>This type of service allows this Application to display advertisements for third-party products
                        or services. Ads can be displayed either as advertising links or as banners using various kinds
                        of graphics.<br/>
                        Clicks on the icon or banner posted on the Application are tracked by the third-party services
                        listed below, and are shared with this Application.<br/>
                        For details of which data are collected, please refer to the privacy policy of each service.</p>
                    <Typography variant="h6" gutterBottom>Amazon Affiliation (Amazon)</Typography>
                    <div className="wrap">
                        <p>Amazon Affiliation is a commercial affiliation service provided by Amazon.com Inc.</p>
                    </div>
                    <p>Personal Data processed: Trackers and Usage Data.</p>
                    <p>Place of processing: United States &ndash;
                        <Link href="https://www.amazon.com/gp/help/customer/display.html/ref=hp_rel_topic?ie=UTF8&nodeId=468496" target={"_blank"} rel={"noreferrer noopener"}>Privacy Policy</Link>.
                    </p>
                </Typography>
                <br/>


                <Typography variant="h5" gutterBottom>How to manage preferences and provide or withdraw consent</Typography>
                <Typography variant="body1">
                    <p>There are various ways to manage Tracker related preferences and to provide and withdraw consent,
                        where relevant:</p>
                    <p>Users can manage preferences related to Trackers from directly within their own device settings, for
                        example, by preventing the use or storage of Trackers.</p>
                    <p>Additionally, whenever the use of Trackers is based on consent, Users can provide or withdraw such
                        consent by setting their preferences within the cookie notice or by updating such preferences
                        accordingly via the relevant consent-preferences widget, if available.</p>
                    <p>It is also possible, via relevant browser or device features, to delete previously stored Trackers,
                        including those used to remember the User’s initial consent.</p>
                    <p>Other Trackers in the browser’s local memory may be cleared by deleting the browsing history.</p>
                    <p>With regard to any third-party Trackers, Users can manage their preferences and withdraw their
                        consent via the related opt-out link (where provided), by using the means indicated in the third
                        party's privacy policy, or by contacting the third party.</p>
                    <Typography variant="h6" gutterBottom>Locating Tracker Settings</Typography>
                    <p>Users can, for example, find information about how to manage Cookies in the most commonly used
                        browsers at the following addresses:</p>
                    <ul>
                        <li><Link target={"_blank"} rel={"noopener nofollow"}
                                  href="https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies">Google Chrome</Link>
                        </li>
                        <li><Link target={"_blank"} rel={"noopener nofollow"}
                                  href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Mozilla
                            Firefox</Link></li>
                        <li><Link target={"_blank"} rel={"noopener nofollow"}
                                  href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/">Apple
                            Safari</Link></li>
                        <li><Link target={"_blank"} rel={"noopener nofollow"}
                                  href="http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies">Microsoft
                            Internet Explorer</Link></li>
                        <li><Link target={"_blank"} rel={"noopener nofollow"}
                                  href="https://support.microsoft.com/en-us/help/4027947">Microsoft Edge</Link></li>
                        <li><Link target={"_blank"} rel={"noopener nofollow"}
                                  href="https://support.brave.com/hc/en-us/articles/360022806212-How-do-I-use-Shields-while-browsing">Brave</Link>
                        </li>
                        <li><Link target={"_blank"} rel={"noopener nofollow"}
                                  href="https://help.opera.com/en/latest/web-preferences/#cookies">Opera</Link></li>
                    </ul>
                    <p>Users may also manage certain categories of Trackers used on mobile apps by opting out through
                        relevant device settings such as the device advertising settings for mobile devices, or tracking
                        settings in general (Users may open the device settings and look for the relevant setting).</p>
                    <Typography variant="h6" gutterBottom>How to opt out of interest-based advertising</Typography>
                    <p>Notwithstanding the above, Users may follow the instructions provided by
                        <Link target={"_blank"} rel={"noreferrer nofollow"} href="http://www.youronlinechoices.eu/">YourOnlineChoices</Link> (EU), the
                        <Link target={"_blank"} rel={"noopener nofollow"} href="https://thenai.org/about-online-advertising/">Network Advertising Initiative</Link> (US) and the
                        <Link target={"_blank"} rel={"noopener nofollow"} href="https://www.aboutads.info/consumers/">Digital Advertising Alliance</Link> (US),
                        <Link target={"_blank"} rel={"noopener nofollow"} href="https://youradchoices.ca/understanding-online-advertising/">DAAC</Link> (Canada),
                        <Link target={"_blank"} rel={"noopener nofollow"} href="http://www.ddai.info/optout">DDAI</Link>
                        (Japan) or other similar services. Such initiatives allow Users to select their tracking preferences for most
                        of the advertising tools. The Owner thus recommends that Users make use of these resources in
                        addition to the information provided in this document.
                    </p>
                    <p>
                        The Digital Advertising Alliance offers an application called
                        <Link target={"_blank"} rel={"noopener nofollow"} href="https://youradchoices.com/appchoices">AppChoices</Link>
                        that helps Users to control interest-based advertising on mobile apps.
                    </p>
                    <Typography variant="h6" gutterBottom>Consequences of denying consent</Typography>
                    <p>Users are free to decide whether or not to grant consent. However, please note that Trackers help
                        this Application to provide a better experience and advanced functionalities to Users (in line with
                        the purposes outlined in this document). Therefore, in the absence of the User's consent, the Owner
                        may be unable to provide related features.</p>
                    <Typography variant="h6" gutterBottom>Owner and Data Controller</Typography>
                    <Typography variant="subtitle2" gutterBottom>
                        Monecracy.com<br/>
                        166 Geary St<br/>
                        15th Fl #1625<br/>
                        San Francisco, CA 94108<br/>
                        <strong>Contact email:</strong> legal@monecracy.com<br/>
                    </Typography>
                    <p>Since the use of third-party Trackers through this Application cannot be fully controlled by the
                        Owner, any specific references to third-party Trackers are to be considered indicative. In order to
                        obtain complete information, Users are kindly requested to consult the privacy policies of the
                        respective third-party services listed in this document.</p>
                    <p>Given the objective complexity surrounding tracking technologies, Users are encouraged to contact the
                        Owner should they wish to receive any further information on the use of such technologies by this
                        Application.</p>
                </Typography>
                <br/>


                <Typography variant="h5" gutterBottom>Definitions and legal references</Typography>
                <Typography variant="body1">
                    <Typography variant="h6" gutterBottom>Personal Data (or Data)</Typography>
                    <p>Any information that directly, indirectly, or in connection with other information — including a
                        personal identification number — allows for the identification or identifiability of a natural
                        person.</p>
                    <Typography variant="h6" gutterBottom>Usage Data</Typography>
                    <p>Information collected automatically through this Application (or third-party services employed in
                        this Application), which can include: the IP addresses or domain names of the computers utilized
                        by the Users who use this Application, the URI addresses (Uniform Resource Identifier), the time
                        of the request, the method utilized to submit the request to the server, the size of the file
                        received in response, the numerical code indicating the status of the server's answer
                        (successful outcome, error, etc.), the country of origin, the features of the browser and the
                        operating system utilized by the User, the various time details per visit (e.g., the time spent
                        on each page within the Application) and the details about the path followed within the
                        Application with special reference to the sequence of pages visited, and other parameters about
                        the device operating system and/or the User's IT environment.</p>
                    <Typography variant="h6" gutterBottom>User</Typography>
                    <p>The individual using this Application who, unless otherwise specified, coincides with the Data
                        Subject.</p>
                    <Typography variant="h6" gutterBottom>Data Subject</Typography>
                    <p>The natural person to whom the Personal Data refers.</p>
                    <Typography variant="h6" gutterBottom>Data Processor (or Data Supervisor)</Typography>
                    <p>The natural or legal person, public authority, agency or other body which processes Personal Data
                        on behalf of the Controller, as described in this privacy policy.</p>
                    <Typography variant="h6" gutterBottom>Data Controller (or Owner)</Typography>
                    <p>The natural or legal person, public authority, agency or other body which, alone or jointly with
                        others, determines the purposes and means of the processing of Personal Data, including the
                        security measures concerning the operation and use of this Application. The Data Controller,
                        unless otherwise specified, is the Owner of this Application.</p>
                    <Typography variant="h6" gutterBottom>This Application</Typography>
                    <p>The means by which the Personal Data of the User is collected and processed.</p>
                    <Typography variant="h6" gutterBottom>Service</Typography>
                    <p>The service provided by this Application as described in the relative terms (if available) and on
                        this site/application.</p>
                    <Typography variant="h6" gutterBottom>European Union (or EU)</Typography>
                    <p>Unless otherwise specified, all references made within this document to the European Union
                        include all current member states to the European Union and the European Economic Area.</p>
                    <Typography variant="h6" gutterBottom>Cookie</Typography>
                    <p>Cookies are Trackers consisting of small sets of data stored in the User&#39;s browser.</p>
                    <Typography variant="h6" gutterBottom>Tracker</Typography>
                    <p>Tracker indicates any technology - e.g Cookies, unique identifiers, web beacons, embedded
                        scripts, e-tags and fingerprinting - that enables the tracking of Users, for example by
                        accessing or storing information on the User’s device.</p>
                    <hr/>
                    <Typography variant="h6" gutterBottom>Legal information</Typography>
                    <p>This privacy statement has been prepared based on provisions of multiple legislations, including
                        Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).</p>
                    <p>This privacy policy relates solely to this Application, if not stated otherwise within this
                        document.</p>
                </Typography>
                <br/>


                </div>
            <ScrollToTop />
            <SiteFooter/>
        </div>
    );
};

export default Privacy;
