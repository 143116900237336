import {Link as RouterLink, useParams} from "react-router-dom";
import SiteHeader from "../../components/siteHeader";
import SiteFooter from "../../components/siteFooter";
import ZipBrowser from "../../components/zipBrowser";
import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import MetaData from "../../components/metaData";
import React from "react";

const ZipcodeCycleBrowser = () => {
    const {cycleYear} = useParams();

    return (
        <div>
            <Stack paddingBottom={10}>
                <SiteHeader />
            </Stack>
            <div className="Page-content">
                <MetaData
                    title={"Zipcodes Browser " + cycleYear}
                    description={"Browse by all Zipcodes for the " + cycleYear + " election cycle"}
                    url={window.location.href}
                    image={"https://www.monecracy.com/images/logo.png"}/>
                <Stack direction="row" alignItems="center" justifyContent="space-between" paddingTop={1}>
                    <Breadcrumbs>
                        <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                        <Link component={RouterLink} to={"/totals"} underline={"hover"}>Totals</Link>
                        <Link component={RouterLink} to={"/cycles"} underline={"hover"}>Cycles</Link>
                        <Link component={RouterLink} to={"/cycle/" + cycleYear} underline={"hover"}>{cycleYear}</Link>
                        <Typography>Zipcodes</Typography>
                    </Breadcrumbs>
                </Stack>
                <ZipBrowser cycleYear={cycleYear} title="Browse Zip Codes" typeHeader={"Zipcodes"} type={"zipcode"} linkPath={"../zipcode"} />
            </div>
            <SiteFooter/>
        </div>
    );
};

export default ZipcodeCycleBrowser;
