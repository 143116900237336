import React from "react";
import {Stack, Typography} from "@mui/material";
import {madLibFactory} from "../../AppGlobal";

const UCSTotal = (props) =>
{
    if (props.stats === undefined)
    {
        return;
    }

    if (props.stats === undefined)
    {
        return;
    }

    let renderData = props.stats[props.statsKey];

    if (renderData === undefined)
    {
        renderData = {
            amount: 0,
            contributors: 0,
            contributions: 0
        };
    }

    const madLibs = madLibFactory.getCycleUCSTotal();

    const renderTitle = madLibs.buildTitle(props.cycleYear, props.pageType, props.display);
    const renderCaption = madLibs.buildContent(props.cycleYear, props.pageType, props.display, props.stats, renderData);

    const renderWidth = madLibs.calculateRenderWidth(20);

    return (
        <Stack align={"left"} paddingTop={"5px"} paddingBottom={"5px"}>
            <Stack padding={2} spacing={1} sx={{ backgroundColor: '#20273C', justifyContent: 'flex-start'}}>
                <a name={props.anchor}></a>
                <Typography variant="h6">{renderTitle}</Typography>
                <Typography variant="caption">{renderCaption}</Typography>
                <Stack direction={"row"} justifyContent={"space-evenly"}>
                    <Stack spacing={0} justifyContent="flex-start">
                        <Typography sx={{color: "gray"}} variant="subtitle1">AMOUNT</Typography>
                        <Typography variant="h5"><font color="#85bb65">$</font>{renderData.amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</Typography>
                    </Stack>
                    <Stack spacing={0}>
                        <Typography sx={{color: "gray"}} variant="subtitle1">CONTRIBUTIONS</Typography>
                        <Typography variant="h5">{renderData.contributions.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default UCSTotal;
