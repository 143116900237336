import React, {useEffect} from "react";

import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import * as DataLoader from "../../util/DataLoader";
import {Link as RouterLink} from "react-router-dom";
import SiteHeader from "../../components/siteHeader";
import MetaData from "../../components/metaData";
import ShareButton from "../../components/shareButton";
import RollupICSTotal from "../../components/ics/rollupICSTotal";
import RollupCycleList from "../../components/ics/rollupCycleList";
import RollupICSStateList from "../../components/ics/rollupICSStateList";
import RollupICSCountyList from "../../components/ics/rollupICSCountyList";
import RollupICSCityList from "../../components/ics/rollupICSCityList";
import RollupICSZipcodeList from "../../components/ics/rollupICSZipcodeList";
import RollupICSTypeList from "../../components/ics/rollupICSTypeList";
import RollupUCSList from "../../components/ucs/rollupUCSList";
import RollupUCSOrg from "../../components/ucs/rollupUCSOrg";
import RollupUCSPAC from "../../components/ucs/rollupUCSPAC";
import RollupCETotal from "../../components/ces/rollupCETotal";
import RollupCECycleList from "../../components/ces/rollupCECycleList";
import RollupCEStateList from "../../components/ces/rollupCEStateList";
import RollupCECountyList from "../../components/ces/rollupCECountyList";
import RollupCECityList from "../../components/ces/rollupCECityList";
import RollupCETypeList from "../../components/ces/rollupCETypeList";
import ScrollToTop from "../../components/scrollToTop";
import SiteFooter from "../../components/siteFooter";

const Totals = () => {
    const currentPageType = "summary";

    const [success, setSuccess] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);

    const [fields, setFields] = React.useState([]);
    const [stats, setStats] = React.useState([]);

    useEffect(() => {
        DataLoader.load("summary", (result, data) => {
            setLoaded(true)
            if (result === true)
            {
                setSuccess(true)
                setStats(data.statistics);
                setFields(data.fields);
            }
            else
            {
                setSuccess(false);
            }
        })
    }, [])


    return (
        <div>
            <Stack paddingBottom={10}>
                <SiteHeader />
            </Stack>
            {
                loaded === false ?
                    (<Typography variant="h6">Building Summary for All Elections since 2008...</Typography>) :
                    (<div>
                        {
                            success === false?
                                (<Typography variant="h6">Unable to build the Summary for All Elections</Typography>) :
                                (<React.Fragment>
                                    <MetaData
                                        title={"Total Political Contributions Since 2008"}
                                        description={"Statistics and detailed reports on hundreds of millions of political contributions since 2008"}
                                        url={window.location.href}
                                        image={"https://www.monecracy.com/images/logo.png"} />
                                    <Stack direction="column" spacing={1} padding={0}>
                                        <Stack id="back-to-top-anchor" direction="row" alignItems="center" justifyContent="space-between" paddingTop={1}>
                                            <Breadcrumbs>
                                                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                                                <Typography>Totals for All Elections Since 2008</Typography>
                                            </Breadcrumbs>
                                            <ShareButton title="Monecracy: Unlimited Money in Politics" />
                                        </Stack>
                                        {/*<GoogleBanner />*/}
                                        <RollupICSTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_total"
                                            display={fields.display}
                                        />
                                        {/*<HomeTopArticles*/}
                                        {/*    title="Articles" />*/}
                                        <RollupCycleList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_cycle_totals"
                                            display={fields.display}
                                        />
                                        <RollupICSStateList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_states"
                                            display={"Summary"}
                                        />
                                        <RollupICSCountyList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_counties_top"
                                            display={"Summary"}
                                        />
                                        <RollupICSCityList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_cities_top"
                                            display={"Summary"}
                                        />
                                        <RollupICSZipcodeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_zipcodes_top"
                                            display={"Summary"}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_parties"
                                            statsTop={false}
                                            anchor="party_totals"
                                            linkType="party"
                                            display={"summary"}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_politicians_top"
                                            statsTop={true}
                                            anchor="politician_totals"
                                            linkType="politician"
                                            display={"summary"}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_offices_top"
                                            statsTop={true}
                                            anchor="office_totals"
                                            linkType="office"
                                            display={"summary"}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_committees_top"
                                            statsTop={true}
                                            anchor="committee_totals"
                                            linkType="committee"
                                            display={"summary"}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_organizations_top"
                                            statsTop={true}
                                            anchor="organization_totals"
                                            linkType="organization"
                                            display={"summary"}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_industries_top"
                                            statsTop={true}
                                            anchor="industry_totals"
                                            linkType="industry"
                                            display={"summary"}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_occupations_top"
                                            statsTop={true}
                                            anchor="occupation_totals"
                                            linkType="occupation"
                                            display={"summary"}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_surnames_top"
                                            statsTop={true}
                                            anchor="surname_totals"
                                            linkType="surname"
                                            display={"summary"}
                                        />
                                        <RollupUCSList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_indiv_top"
                                            display={"summary"}
                                            linkType={"summary"}
                                        />
                                        <RollupUCSOrg
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_org_top"
                                            display={"summary"}
                                            linkType={"summary"}
                                        />
                                        <RollupUCSPAC
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_pac_top"
                                            display={"summary"}
                                            linkType={"summary"}
                                        />
                                        <RollupCETotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_total"
                                            display={"summary"}
                                        />
                                        <RollupCECycleList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_cycle_totals"
                                            display="summary"
                                        />
                                        <RollupCEStateList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_states"
                                            seriesName="State"
                                            display={fields.display}
                                        />
                                        <RollupCECountyList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_counties_top"
                                            display={fields.display}
                                        />
                                        <RollupCECityList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_cities_top"
                                            display={fields.display}
                                        />
                                        <RollupCETypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_committees_top"
                                            linkType="committee"
                                            display={fields.display}
                                            anchor="committee_expenditure_totals"
                                        />
                                        <RollupCETypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_vendors_top"
                                            linkType="vendor"
                                            display={fields.display}
                                            anchor="vendor_expenditure_totals"
                                        />
                                    </Stack>

                                    <ScrollToTop />
                                </React.Fragment>
                                )
                        }
                    </div>)
            }
            <SiteFooter/>
        </div>
    );
};

export default Totals;
