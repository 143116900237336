import MadLibFactory from "./madlibs/MadLibFactory";

export const madLibFactory = new MadLibFactory();

export const typeDetails = {
    summary: {
        display: "Summary",
        displayPlural: "Summary",
        linkPrefix: "",
        hasBrowser: false,
        iconPath: '/images/vendor_icon.png'
    },
    cycle: {
        display: "Cycle",
        displayPlural: "Cycles",
        linkPrefix: "cycles",
        hasBrowser: true,
        iconPath: '/images/cycle_icon.png'
    },
    state: {
        display: "State",
        displayPlural: "States",
        linkPrefix: "states",
        hasBrowser: true,
        iconPath: '/images/state_icon.png'
    },
    county: {
        display: "County",
        displayPlural: "Counties",
        linkPrefix: "",
        hasBrowser: false,
        iconPath: '/images/county_icon.png'
    },
    city: {
        display: "City",
        displayPlural: "Cities",
        linkPrefix: "",
        hasBrowser: false,
        iconPath: '/images/city_icon.png'
    },
    zipcode: {
        display: "Zip Code",
        displayPlural: "Zip Codes",
        linkPrefix: "",
        hasBrowser: true,
        iconPath: '/images/zipcode_icon.png'
    },
    party: {
        display: "Party",
        displayPlural: "Parties",
        linkPrefix: "parties",
        hasBrowser: true,
        iconPath: '/images/party_icon.png'
    },
    candidate: {
        display: "Candidate",
        displayPlural: "Candidates",
        linkPrefix: "candidate",
        hasBrowser: true,
        iconPath: '/images/politician_icon.png'
    },
    politician: {
        display: "Politician",
        displayPlural: "Politicians",
        linkPrefix: "politicians",
        hasBrowser: true,
        iconPath: '/images/politician_icon.png'
    },
    office: {
        display: "Office",
        displayPlural: "Offices",
        linkPrefix: "offices",
        hasBrowser: true,
        iconPath: '/images/office_icon.png'
    },
    committee: {
        display: "Committee",
        displayPlural: "Committees",
        linkPrefix: "committees",
        hasBrowser: true,
        iconPath: '/images/committee_icon.png'
    },
    industry: {
        display: "Industry",
        displayPlural: "Industries",
        linkPrefix: "industries",
        hasBrowser: true,
        iconPath: '/images/industry_icon.png'
    },
    organization: {
        display: "Organization",
        displayPlural: "Organizations",
        linkPrefix: "organizations",
        hasBrowser: true,
        iconPath: '/images/organization_icon.png'
    },
    occupation: {
        display: "Occupation",
        displayPlural: "Occupations",
        linkPrefix: "occupations",
        hasBrowser: true,
        iconPath: '/images/occupation_icon.png'
    },
    surname: {
        display: "Surname",
        displayPlural: "Surnames",
        linkPrefix: "surnames",
        hasBrowser: true,
        iconPath: '/images/surname_icon.png'
    },
    contributor: {
        display: "Contributor",
        displayPlural: "Contributors",
        linkPrefix: "contributors",
        hasBrowser: true,
        iconPath: '/images/contributor_icon.png'
    },
    vendor: {
        display: "Vendor",
        displayPlural: "Vendors",
        linkPrefix: "vendors",
        hasBrowser: true,
        iconPath: '/images/vendor_icon.png'
    }
}
