import React from "react";

const TagAssociation = (props) => {
    return (
        <div className="tag-association">
            <h3>{"Tag Associations"}</h3>
            {
                props.data === undefined ?
                    (<span>No data available</span>):
                    (<div>
                            {
                                props.data.map((stat, index) => (
                                    <div>
                                        <p>{stat.type}</p>
                                        <p>{stat.key}</p>
                                        <p>{stat.display}</p>
                                        <p>{stat.path}</p>
                                        <p>{stat.cycle}</p>
                                        <p>{stat.cycleYear}</p>
                                        <p>{stat.totals}</p>
                                        <p>{stat.amount}</p>
                                        <p>{stat.contributions}</p>
                                        <p>{stat.contributors}</p>
                                    </div>
                                ))
                            }
                        </div>
                    )
            }
        </div>
    );
};

export default TagAssociation;