import React from "react";
import SiteHeader from "../components/siteHeader";
import SiteFooter from "../components/siteFooter";
import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import ScrollToTop from "../components/scrollToTop";
import {Link as RouterLink} from "react-router-dom";

const PrivacyFull = () => {
    return (
        <div>
            <Stack paddingBottom={10} id="back-to-top-anchor">
                <SiteHeader />
            </Stack>

            <Stack id="back-to-top-anchor" direction="row" alignItems="center" justifyContent="space-between" paddingTop={1} paddingBottom={1}>
                <Breadcrumbs>
                    <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                    <Typography>Privacy Policy (Full Version)</Typography>
                </Breadcrumbs>
            </Stack>

            <div className="Page-content"  align="left" style={{ marginLeft: 25, marginTop: 10, maxWidth: 1000}}>

                <Typography variant="h5">Privacy Policy</Typography>
                <Typography variant="subtitle1" gutterBottom>Last Updated: May 23, 2023</Typography>
                <Typography variant="body1" gutterBottom>
                    <p>This Application collects some Personal Data from its Users.</p>
                    <p>Users may be subject to different protection standards and broader standards may therefore apply to some. In order to learn more about the protection criteria, Users can refer to the applicability section.</p>
                    <p>This document can be printed for reference by using the print command in the settings of any browser.</p>
                </Typography>
                <br/>


                <Typography variant="h5">Types of Data collected</Typography>
                <Typography variant="body1" gutterBottom>
                    <p>Among the types of Personal Data that this Application collects, by itself or through third parties, there are: Trackers; Usage Data.</p>
                    <p>Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.<br />Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Application.<br />Unless specified otherwise, all Data requested by this Application is mandatory and failure to provide this Data may make it impossible for this Application to provide its services. In cases where this Application specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service.<br />Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.<br />Any use of Cookies – or of other tracking tools — by this Application or by the owners of third-party services used by this Application serves the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy, if available.</p>
                    <p>Users are responsible for any third-party Personal Data obtained, published or shared through this Application and confirm that they have the third party's consent to provide the Data to the Owner.</p>
                </Typography>
                <br/>


                <Typography variant="h5">Mode and place of processing the Data</Typography>
                <Typography variant="body1" gutterBottom>
                    <Typography variant="h6">Methods of processing</Typography>
                    <p>The Owner takes appropriate security measures to prevent unauthorized access, disclosure,
                        modification, or unauthorized destruction of the Data.<br/>The Data processing is carried out using
                        computers and/or IT enabled tools, following organizational procedures and modes strictly related to
                        the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to
                        certain types of persons in charge, involved with the operation of this Application (administration,
                        sales, marketing, legal, system administration) or external parties (such as third-party technical
                        service providers, mail carriers, hosting providers, IT companies, communications agencies)
                        appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be
                        requested from the Owner at any time.</p>
                </Typography>
                <br/>


                <Typography variant="h5">Legal basis of processing</Typography>
                <Typography variant="body1" gutterBottom>
                    <p>The Owner may process Personal Data relating to Users if one of the following applies:</p>
                    <ul>
                        <li>Users have given their consent for one or more specific purposes. Note: Under some legislations
                            the Owner may be allowed to process Personal Data until the User objects to such processing
                            (“opt-out”), without having to rely on consent or any other of the following legal bases. This,
                            however, does not apply, whenever the processing of Personal Data is subject to European data
                            protection law;
                        </li>
                        <li>provision of Data is necessary for the performance of an agreement with the User and/or for any
                            pre-contractual obligations thereof;
                        </li>
                        <li>processing is necessary for compliance with a legal obligation to which the Owner is subject;
                        </li>
                        <li>processing is related to a task that is carried out in the public interest or in the exercise of
                            official authority vested in the Owner;
                        </li>
                        <li>processing is necessary for the purposes of the legitimate interests pursued by the Owner or by
                            a third party.
                        </li>
                    </ul>
                    <p>In any case, the Owner will gladly help to clarify the specific legal basis that applies to the
                        processing, and in particular whether the provision of Personal Data is a statutory or contractual
                        requirement, or a requirement necessary to enter into a contract. </p>
                    <Typography variant="h6">Place</Typography>
                    <p>The Data is processed at the Owner's operating offices and in any other places where the parties
                        involved in the processing are located.<br/>
                        Depending on the User's location, data transfers may involve transferring the User's Data to a
                        country other than their own. To find out more about the place of processing of such transferred
                        Data, Users can check the section containing details about the processing of Personal Data.
                    </p>
                    <p>If broader protection standards are applicable, Users are also entitled to learn about the legal
                        basis of Data transfers to a country outside the European Union or to any international organization
                        governed by public international law or set up by two or more countries, such as the UN, and about
                        the security measures taken by the Owner to safeguard their Data.<br/>
                        If any such transfer takes place, Users can find out more by checking the relevant sections of this
                        document or inquire with the Owner using the information provided in the contact section.
                    </p>
                    <Typography variant="h6">Retention time</Typography>
                    <p>Personal Data shall be processed and stored for as long as required by the purpose they have been
                        collected for.</p>
                    <p>Therefore:</p>
                    <ul>
                        <li>Personal Data collected for purposes related to the performance of a contract between the Owner
                            and the User shall be retained until such contract has been fully performed.
                        </li>
                        <li>Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained
                            as long as needed to fulfill such purposes. Users may find specific information regarding the
                            legitimate interests pursued by the Owner within the relevant sections of this document or by
                            contacting the Owner.
                        </li>
                    </ul>
                    <p>The Owner may be allowed to retain Personal Data for a longer period whenever the User has given
                        consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be
                        obliged to retain Personal Data for a longer period whenever required to do so for the performance
                        of a legal obligation or upon order of an authority.<br/>
                        Once the retention period expires, Personal Data shall be deleted. Therefore, the right of access,
                        the right to erasure, the right to rectification and the right to data portability cannot be
                        enforced after expiration of the retention period.
                    </p>
                </Typography>
                <br/>


                <Typography variant="h5">The purposes of processing</Typography>
                <Typography variant="body1" gutterBottom>
                    <p>
                        The Data concerning the User is collected to allow the Owner to provide its Service, comply with its legal obligations, respond to enforcement requests, protect its rights and interests (or those of its Users or third parties), detect any malicious or fraudulent activity, as well as the following:
                        Advertising, Analytics, Displaying content from external platforms, Hosting and backend infrastructure and Commercial affiliation.
                    </p>
                    <p>For specific information about the Personal Data used for each purpose, the User may refer to the section “Detailed information on the processing of Personal Data”.</p>
                </Typography>
                <br/>


                <Typography variant="h5">Detailed information on the processing of Personal Data</Typography>
                <Typography variant="body1" gutterBottom>
                    <p>Personal Data is collected for the following purposes and using the following services:</p>
                    <Typography variant="h6">Advertising</Typography>
                    <p>This type of service allows User Data to be utilized for advertising communication purposes.
                        These communications are displayed in the form of banners and other advertisements on this
                        Application, possibly based on User interests.<br/>
                        This does not mean that all Personal Data are used for this purpose. Information and conditions
                        of use are shown below. <br/>
                        Some of the services listed below may use Trackers to identify Users or they may use the
                        behavioral retargeting technique, i.e. displaying ads tailored to the User’s interests and
                        behavior, including those detected outside this Application. For more information, please check
                        the privacy policies of the relevant services.<br/>
                        Services of this kind usually offer the possibility to opt out of such tracking. In addition to
                        any opt-out feature offered by any of the services below, Users may learn more on how to
                        generally opt out of interest-based advertising within the dedicated section "How to opt-out of
                        interest-based advertising" in this document.</p>
                    <Typography variant="subtitle1">Google AdSense (Google LLC)</Typography>
                    <div className="wrap">
                        <p>Google AdSense is an advertising service provided by Google LLC. This service uses the “DoubleClick” Cookie, which tracks use of this Application and User behavior concerning ads, products and services offered.<br/>
                            Users may decide to disable all the DoubleClick Cookies by going to: <Link href="https://adssettings.google.com/authenticated?hl=en">Google Ad Settings</Link>.</p>
                        <p>In order to understand Google's use of data, consult <Link href="https://policies.google.com/technologies/partner-sites">Google's partner policy</Link>.</p>
                    </div>
                    <p>Personal Data processed: Trackers; Usage Data.</p>
                    <p>Place of processing: United States &ndash;
                        <Link href="https://policies.google.com/privacy" target={"_blank"} rel={"noreferrer noopener"}>Privacy Policy</Link> &ndash;
                        <Link href="https://adssettings.google.com/authenticated">Opt Out</Link>. </p>
                    <Typography variant="subtitle1">Amazon Advertising (Amazon)</Typography>
                    <div className="wrap">
                        <p>Amazon Advertising is an advertising service provided by Amazon.</p>
                    </div>
                    <p>Personal Data processed: Trackers; Usage Data.</p>
                    <p>Place of processing: United States &ndash;
                        <Link href="https://www.amazon.co.uk/gp/help/customer/display.html?nodeId=201909010" target={"_blank"} rel={"noreferrer noopener"}>Privacy Policy</Link> &ndash;
                        <Link href="https://www.amazon.com/gp/help/customer/display.html?nodeId=202075050">Opt out</Link>.
                    </p>
                    <Typography variant="h6">Analytics</Typography>
                    <p>The services contained in this section enable the Owner to monitor and analyze web traffic and
                        can be used to keep track of User behavior.</p>
                    <Typography variant="subtitle1">Google Analytics (Google LLC)</Typography>
                    <div className="wrap">
                        <p>Google Analytics is a web analysis service provided by Google LLC (“Google”). Google utilizes
                            the Data collected to track and examine the use of this Application, to prepare reports on
                            its activities and share them with other Google services.<br/>
                            Google may use the Data collected to contextualize and personalize the ads of its own
                            advertising network.</p>
                    </div>
                    <p>Personal Data processed: Trackers; Usage Data.</p>
                    <p>Place of processing: United States &ndash;
                        <Link href="https://policies.google.com/privacy" target={"_blank"} rel={"noreferrer noopener"}>Privacy Policy</Link> &ndash;
                        <Link href="https://tools.google.com/dlpage/gaoptout?hl=en" onClick="javascript:return tryFunc('tryGaOptOut',{href:'https://tools.google.com/dlpage/gaoptout?hl=en'})" target={"_blank"} rel={"noreferrer"}>Opt Out</Link>. </p>
                    <Typography variant="h6">Commercial affiliation</Typography>
                    <p>This type of service allows this Application to display advertisements for third-party products
                        or services. Ads can be displayed either as advertising links or as banners using various kinds
                        of graphics.<br/>
                        Clicks on the icon or banner posted on the Application are tracked by the third-party services
                        listed below, and are shared with this Application.<br/>
                        For details of which data are collected, please refer to the privacy policy of each service.</p>
                    <Typography variant="subtitle1">Amazon Affiliation (Amazon)</Typography>
                    <div className="wrap">
                        <p>Amazon Affiliation is a commercial affiliation service provided by Amazon.com Inc.</p>
                    </div>
                    <p>Personal Data processed: Trackers; Usage Data.</p>
                    <p>Place of processing: United States &ndash;  <Link href="https://www.amazon.com/gp/help/customer/display.html/ref=hp_rel_topic?ie=UTF8&nodeId=468496" target={"_blank"} rel={"noreferrer noopener"}>Privacy Policy</Link>. </p>
                    <Typography variant="h6">Displaying content from external platforms</Typography>
                    <p>This type of service allows you to view content hosted on external platforms directly from the
                        pages of this Application and interact with them.<br/>
                        This type of service might still collect web traffic data for the pages where the service is
                        installed, even when Users do not use it.</p>
                    <Typography variant="subtitle1">Google Fonts (Google LLC)</Typography>
                    <div className="wrap">
                        <p>Google Fonts is a typeface visualization service provided by Google LLC that allows this
                            Application to incorporate content of this kind on its pages.</p>
                    </div>
                    <p>Personal Data processed: Trackers; Usage Data.</p>
                    <p>Place of processing: United States &ndash;  <Link href="https://policies.google.com/privacy" target={"_blank"} rel={"noreferrer"}>Privacy Policy</Link>. </p>
                </Typography>
                <br/>

                <Typography variant="h5">Hosting and backend infrastructure</Typography>
                <Typography variant="body1" gutterBottom>
                    <p>This type of service has the purpose of hosting Data and files that enable this Application to
                        run and be distributed as well as to provide a ready-made infrastructure to run specific
                        features or parts of this Application.</p>
                    <p>Some services among those listed below, if any, may work through geographically distributed
                        servers, making it difficult to determine the actual location where the Personal Data are
                        stored.</p>
                    <Typography variant="subtitle1">Amazon Web Services (AWS) (Amazon Web Services, Inc.)</Typography>
                    <div className="wrap">
                        <p>Amazon Web Services (AWS) is a hosting and backend service provided by Amazon Web Services,
                            Inc.</p>
                    </div>
                    <p>Personal Data processed: various types of Data as specified in the privacy policy of the
                        service.</p>
                    <p>Place of processing: United States &ndash;
                        <Link href="https://aws.amazon.com/compliance/data-privacy-faq/" target={"_blank"} rel={"noreferrer"}>Privacy Policy</Link>. </p>
                </Typography>
                <br/>


                <Typography variant="h5">Information on opting out of interest-based advertising</Typography>
                <Typography variant="body1" gutterBottom>
                    <p>
                        In addition to any opt-out feature provided by
                        any of the services listed in this document,  Users may learn more on
                        how to generally opt out of interest-based advertising within the
                        dedicated section of the Cookie Policy.
                    </p>
                </Typography>
                <br/>


                <Typography variant="h5">The rights of Users</Typography>
                <Typography variant="body1" gutterBottom>
                    <p>Users may exercise certain rights regarding their Data processed by the Owner.</p>
                    <p>Users entitled to broader protection standards may exercise any of the rights described below. In all
                        other cases, Users may inquire with the Owner to find out which rights apply to them.</p>
                    <p>In particular, Users have the right to do the following, to the extent permitted by law:</p>
                    <ul>
                        <li><strong>Withdraw their consent at any time.</strong> Users have the right to withdraw consent
                            where they have previously given their consent to the processing of their Personal Data.
                        </li>
                        <li><strong>Object to processing of their Data.</strong> Users have the right to object to the
                            processing of their Data if the processing is carried out on a legal basis other than consent.
                            Further details are provided in the dedicated section below.
                        </li>
                        <li><strong>Access their Data.</strong> Users have the right to learn if Data is being processed by
                            the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of
                            the Data undergoing processing.
                        </li>
                        <li><strong>Verify and seek rectification.</strong> Users have the right to verify the accuracy of
                            their Data and ask for it to be updated or corrected.
                        </li>
                        <li><strong>Restrict the processing of their Data.</strong> Users have the right to restrict the
                            processing of their Data. In this case, the Owner will not process their Data for any purpose
                            other than storing it.
                        </li>
                        <li><strong>Have their Personal Data deleted or otherwise removed.</strong> Users have the right to
                            obtain the erasure of their Data from the Owner.
                        </li>
                        <li><strong>Receive their Data and have it transferred to another controller.</strong> Users have
                            the right to receive their Data in a structured, commonly used and machine readable format and,
                            if technically feasible, to have it transmitted to another controller without any hindrance.
                        </li>
                        <li><strong>Lodge a complaint.</strong> Users have the right to bring a claim before their competent
                            data protection authority.
                        </li>
                    </ul>
                    <Typography variant="h6">Details about the right to object to processing</Typography>
                    <p>Where Personal Data is processed for a public interest, in the exercise of an official authority
                        vested in the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may
                        object to such processing by providing a ground related to their particular situation to justify the
                        objection.</p>
                    <p>Users must know that, however, should their Personal Data be processed for direct marketing purposes,
                        they can object to that processing at any time, free of charge and without providing any
                        justification. Where the User objects to processing for direct marketing purposes, the Personal Data
                        will no longer be processed for such purposes. To learn whether the Owner is processing Personal
                        Data for direct marketing purposes, Users may refer to the relevant sections of this document. </p>
                </Typography>
                <br/>

                <Typography variant="h5">How to exercise these rights</Typography>
                <Typography variant="body1" gutterBottom>
                    <p>Any requests to exercise User rights can be directed to the Owner through the contact details
                        provided in this document. These requests can be exercised free of charge and will be answered by
                        the Owner as early as possible and always within one month, providing Users with the information
                        required by law. Any rectification or erasure of Personal Data or restriction of processing will be
                        communicated by the Owner to each recipient, if any, to whom the Personal Data has been disclosed
                        unless this proves impossible or involves disproportionate effort. At the Users’ request, the Owner
                        will inform them about those recipients.</p>
                </Typography>
                <br/>


                <Typography variant="h5">Applicability of broader protection standards</Typography>
                <Typography variant="body1" gutterBottom>
                    <p>While most provisions of this document concern all Users, some provisions expressly only apply if the processing of Personal Data is subject to broader protection standards.</p>
                    <p>Such broader protection standards apply when the processing:</p>
                    <ul>
                        <li>is performed by an Owner based within the EU;</li>
                        <li>concerns the Personal Data of Users who are in the EU and is related to the offering of paid or unpaid goods or services, to such Users;</li>
                        <li>concerns the Personal Data of Users who are in the EU and allows the Owner to monitor such Users’ behavior taking place in the EU.</li>
                    </ul>
                </Typography>
                <br/>

                <Typography variant="h5">Cookie Policy</Typography>
                <Typography variant="body1" gutterBottom>
                    <p>This Application uses Trackers. To learn more, the User may consult the <Link href="/cookies" title="Cookie Policy">Cookie Policy</Link>.</p>
                </Typography>
                <br/>


                <Typography variant="h5">Additional information about Data collection and processing</Typography>
                <Typography variant="body1" gutterBottom>
                    <Typography variant="h6">Legal action</Typography>
                    <p>The User's Personal Data may be used for legal purposes by the Owner in Court or in the stages
                        leading to possible legal action arising from improper use of this Application or the related
                        Services.<br/>The User declares to be aware that the Owner may be required to reveal personal data
                        upon request of public authorities.</p>
                    <Typography variant="h6">Additional information about User's Personal Data</Typography>
                    <p>In addition to the information contained in this privacy policy, this Application may provide the
                        User with additional and contextual information concerning particular Services or the collection and
                        processing of Personal Data upon request.</p>
                    <Typography variant="h6">System logs and maintenance</Typography>
                    <p>For operation and maintenance purposes, this Application and any third-party services may collect
                        files that record interaction with this Application (System logs) or use other Personal Data (such
                        as the IP Address) for this purpose.</p>
                    <Typography variant="h6">Information not contained in this policy</Typography>
                    <p>More details concerning the collection or processing of Personal Data may be requested from the Owner
                        at any time. Please see the contact information at the beginning of this document.</p>
                    <Typography variant="h6">How “Do Not Track” requests are handled</Typography>
                    <p>This Application does not support “Do Not Track” requests.<br/>To determine whether any of the
                        third-party services it uses honor the “Do Not Track” requests, please read their privacy policies.
                    </p>
                    <Typography variant="h6">Changes to this privacy policy</Typography>
                    <p>The Owner reserves the right to make changes to this privacy policy at any time by notifying its
                        Users on this page and possibly within this Application and/or - as far as technically and legally
                        feasible - sending a notice to Users via any contact information available to the Owner. It is
                        strongly recommended to check this page often, referring to the date of the last modification listed
                        at the bottom. <br/><br/>
                        Should the changes affect processing activities performed on the basis of the User’s consent, the
                        Owner shall collect new consent from the User, where required.</p>
                </Typography>
                <br/>

                <Typography variant="h5">Definitions and legal references</Typography>
                <Typography variant="body1" gutterBottom>
                    <Typography variant="subtitle1">Personal Data (or Data)</Typography>
                    <p>Any information that directly, indirectly, or in connection with other information — including a
                        personal identification number — allows for the identification or identifiability of a natural
                        person.</p>
                    <Typography variant="subtitle1">Usage Data</Typography>
                    <p>Information collected automatically through this Application (or third-party services employed in
                        this Application), which can include: the IP addresses or domain names of the computers utilized
                        by the Users who use this Application, the URI addresses (Uniform Resource Identifier), the time
                        of the request, the method utilized to submit the request to the server, the size of the file
                        received in response, the numerical code indicating the status of the server's answer
                        (successful outcome, error, etc.), the country of origin, the features of the browser and the
                        operating system utilized by the User, the various time details per visit (e.g., the time spent
                        on each page within the Application) and the details about the path followed within the
                        Application with special reference to the sequence of pages visited, and other parameters about
                        the device operating system and/or the User's IT environment.</p>
                    <Typography variant="subtitle1">User</Typography>
                    <p>The individual using this Application who, unless otherwise specified, coincides with the Data
                        Subject.</p>
                    <Typography variant="subtitle1">Data Subject</Typography>
                    <p>The natural person to whom the Personal Data refers.</p>
                    <Typography variant="subtitle1">Data Processor (or Data Supervisor)</Typography>
                    <p>The natural or legal person, public authority, agency or other body which processes Personal Data
                        on behalf of the Controller, as described in this privacy policy.</p>
                    <Typography variant="subtitle1">Data Controller (or Owner)</Typography>
                    <p>The natural or legal person, public authority, agency or other body which, alone or jointly with
                        others, determines the purposes and means of the processing of Personal Data, including the
                        security measures concerning the operation and use of this Application. The Data Controller,
                        unless otherwise specified, is the Owner of this Application.</p>
                    <Typography variant="subtitle1">This Application</Typography>
                    <p>The means by which the Personal Data of the User is collected and processed.</p>
                    <Typography variant="subtitle1">Service</Typography>
                    <p>The service provided by this Application as described in the relative terms (if available) and on
                        this site/application.</p>
                    <Typography variant="subtitle1">European Union (or EU)</Typography>
                    <p>Unless otherwise specified, all references made within this document to the European Union
                        include all current member states to the European Union and the European Economic Area.</p>
                    <Typography variant="subtitle1">Cookie</Typography>
                    <p>Cookies are Trackers consisting of small sets of data stored in the User's browser.</p>
                    <Typography variant="subtitle1">Tracker</Typography>
                    <p>Tracker indicates any technology - e.g Cookies, unique identifiers, web beacons, embedded
                        scripts, e-tags and fingerprinting - that enables the tracking of Users, for example by
                        accessing or storing information on the User’s device.</p>
                    <hr/>
                    <Typography variant="subtitle1">Legal information</Typography>
                    <p>This privacy statement has been prepared based on provisions of multiple legislations, including
                        Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).</p>
                    <p>This privacy policy relates solely to this Application, if not stated otherwise within this
                        document.</p>
                </Typography>
                <br/>

                <Typography variant="h5" gutterBottom>Owner and Data Controller</Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Monecracy.com<br/>
                    166 Geary St<br/>
                    15th Fl #1625<br/>
                    San Francisco, CA 94108<br/>
                    <strong>Contact email:</strong> legal@monecracy.com<br/>
                </Typography>

            </div>
            <ScrollToTop />
            <SiteFooter/>
        </div>
    );
};

export default PrivacyFull;
