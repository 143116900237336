import React from "react";
import {
    Alert,
    Box,
    Button,
    ClickAwayListener,
    Fade,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Snackbar,
    Stack
} from "@mui/material";

const ShareButton = (props) => {
    const [openShare, setOpenShare] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const canBeOpen = openShare && Boolean(anchorEl);
    const id = canBeOpen ? 'spring-popper' : undefined;

    const [snackBarOpen, setSnackBarOpen] = React.useState(false);

    const handleShareCLick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenShare((previousOpen) => !previousOpen);
    };

    const openSocialLink = (socialLink) => {
        window.open(socialLink, "_blank")
    }

    const handleClickAway = () => {
        setOpenShare(false);
    };

    const handleShare = (e) => {
        e.preventDefault()

        const ahref = window.location.href
        const encodedAhref = encodeURIComponent(ahref)
        let link;

        switch (e.currentTarget.id) {
            case "facebook":
                link = `https://www.facebook.com/sharer/sharer.php?u=${ahref}`
                // link = `https://www.facebook.com/sharer/sharer.php?u=${ahref}&quote=${props.title.replaceAll(" ", "+")}`
                // link = `https://www.facebook.com/dialog/feed?app_id=145634995501000link=${ahref}`
                console.log(link)
                openSocialLink(link)
                setOpenShare(false);
                break

            case "twitter":
                // http://twitter.com/share?text=text goes here&url=http://url goes here&hashtags=hashtag1,hashtag2,hashtag3
                link = `https://twitter.com/intent/tweet?url=${encodedAhref}&text=${props.title} - `
                console.log(link)
                openSocialLink(link)
                setOpenShare(false);
                break

            case "reddit":
                link = `https://www.reddit.com/submit?url=${encodedAhref}&title=${props.title.replaceAll(" ", "%20")}`
                console.log(link)
                openSocialLink(link)
                setOpenShare(false);
                break

            case "copy":
                navigator.clipboard.writeText(ahref)
                setOpenShare(false);
                setSnackBarOpen(true)
                break

            default:
                break
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarOpen(false);
    };

    return(
        <Stack justifyContent={"right"} direction="row" padding={"5px"}>
            <ClickAwayListener onClickAway={handleClickAway}>
                <Box justifyContent={"right"}>
                    <Button sx={{border:1}} aria-describedby={id} type="button" onClick={handleShareCLick}>
                        <img height={16} width={16} src="/images/share_icon.png"></img>&nbsp;Share
                    </Button>
                    <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" sx={{backgroundColor:"green", width: '100%'}}>
                            Link Copied to Clipboard
                        </Alert>
                    </Snackbar>
                    <Popper id={id} open={openShare} anchorEl={anchorEl} transition>
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps}>
                                <Box>
                                    <Paper sx={{backgroundColor:"black"}}>
                                        <List dense={true}>
                                            <ListItemButton
                                                id="facebook"
                                                onClick={handleShare}>
                                                    <ListItemIcon>
                                                        <img height={16} width={16} src="/images/socialLogos/facebook_icon.png"/>
                                                    </ListItemIcon>
                                                    <ListItemText primary="Facebook" />
                                            </ListItemButton>

                                            <ListItemButton
                                                id="twitter"
                                                onClick={handleShare}>
                                                    <ListItemIcon>
                                                        <img height={16} width={16} src="/images/socialLogos/twitter_icon.png"/>
                                                    </ListItemIcon>
                                                    <ListItemText primary="Twitter" />
                                            </ListItemButton>

                                            <ListItemButton
                                                id="reddit"
                                                onClick={handleShare}>
                                                    <ListItemIcon>
                                                        <img height={16} width={16} src="/images/socialLogos/reddit_icon.png"/>
                                                    </ListItemIcon>
                                                    <ListItemText primary="Reddit" />
                                            </ListItemButton>

                                            <ListItemButton
                                                id="copy"
                                                onClick={handleShare}>
                                                    <ListItemIcon>
                                                        <img height={16} width={16} src="/images/link_icon.png"/>
                                                    </ListItemIcon>
                                                    <ListItemText primary="Copy Link" />
                                            </ListItemButton>
                                        </List>
                                    </Paper>
                                </Box>
                            </Fade>
                        )}
                    </Popper>
                </Box>
            </ClickAwayListener>
        </Stack>
    );

};

export default ShareButton;
