import AMadLib from "../../AMadLib";

export default class MLCycleCESSummary extends AMadLib
{
    constructor()
    {
        super();
    }

    buildTitle(cycleYear, pageType, display)
    {
        let title = "Committee Expenditure Totals";

        if (pageType !== 'cycle')
        {
            const expensePrefix = this.getExpensePrefix(pageType);
            title += expensePrefix + display;
        }
        title += " for the " + cycleYear + " Elections";
        return title;
    }

    buildContent(cycleYear, pageType, display, stats, data)
    {
        const isSummary = pageType === 'cycle';

        let content = "In the " + cycleYear + " elections, a total of $"
            + data.amount.toLocaleString(undefined, { maximumFractionDigits: 0 })
            + " was spent ";

        let typeText = "";
        if (pageType === 'committee' || pageType === 'politician')
        {
            typeText = " by the " + pageType + " " + display + ".";
        }
        else if (pageType === 'office')
        {
            typeText = " by candidates running for the office of " + display + ".";
        }
        else if (pageType === 'party')
        {
            typeText = " by the " + display + ".";
        }
        else if (pageType === 'county')
        {
            typeText = " in " + display + ".";
        }
        else if (pageType === 'organization' || pageType === 'industry')
        {
            typeText = " from the " + display + " " + pageType.toLowerCase() + ".";
        }
        else if (isSummary === false)
        {
            typeText = " by the " + pageType + " of " + display + "."
        }
        else
        {
            typeText = " on federal elections."
        }
        content += typeText

        return content;
    }

}
