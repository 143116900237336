import AMadLib from "../../AMadLib";
import {typeDetails} from "../../../AppGlobal";

export default class MLCycleICSType extends AMadLib
{
    constructor(props)
    {
        super(props);
    }

    buildTitle(cycleYear, pageType, linkType, statsTop, display)
    {
        const linkTypeDetails = typeDetails[linkType];
        const displayPrefix = " " + this.getDisplayPrefix(pageType) + " ";

        let title = '';

        if (statsTop)
        {
            title = 'Top ' + linkTypeDetails.displayPlural;
            title += ' by Total Contributions'
            if (pageType !== 'cycle')
            {
                title += displayPrefix + display;
            }
        }
        else
        {
            title = 'Total Contributions';
            if (pageType !== 'cycle')
            {
                title += displayPrefix + display;
            }
            title += ' by ' + linkTypeDetails.display;
        }
        title += " for the " + cycleYear + " Elections";

        return title;
    }

    buildSubtitle(cycleYear, pageType, linkType, statsTop, display)
    {
        const linkTypeDetails = typeDetails[linkType];
        const displayPrefix = " " + this.getDisplayPrefix(pageType) + " ";

        let subtitle = '';
        if (statsTop)
        {
            subtitle = "The top " + linkTypeDetails.displayPlural.toLowerCase();
            subtitle += " by total contributions from individuals, organizations, and political action committees (PAC) ";
            if (pageType !== 'cycle')
            {
                subtitle += displayPrefix + display;
            }
        }
        else
        {
            subtitle = "The total contributions from individuals, organizations, and political action committees (PAC) ";
            if (pageType !== 'cycle')
            {
                subtitle += displayPrefix + display;
            }
            subtitle += " by " + linkTypeDetails.display.toLowerCase();
        }
        subtitle += " for the " + cycleYear + " elections";

        return subtitle;
    }

    buildChartTitle(cycleYear, pageType, linkType, statsTop, display)
    {
        return this.buildTitle(cycleYear, pageType, linkType, statsTop, display);
    }

    buildContent(cycleYear, pageType, linkType, statsTop, display, stats, data)
    {
        let totalAmount = 0;
        let totalIndivAmount = 0;
        let totalOrgAmount = 0;
        let totalPACAmount = 0;
        let topAmount = 0;
        let topAmountType = 0;

        if (data !== undefined)
        {
            for (const nextType of data)
            {
                totalAmount = totalAmount + nextType.amount;
                totalIndivAmount = totalIndivAmount + nextType.indivAmount;
                totalOrgAmount = totalOrgAmount + nextType.orgAmount;
                totalPACAmount = totalPACAmount + nextType.pacAmount;

                //Top State by Total Amount
                if (nextType.amount > topAmount)
                {
                    topAmount = nextType.amount;
                    topAmountType = nextType.display;
                }
            }
        }

        const linkTypeDetails = typeDetails[linkType];

        let content = "The total amount of money donated";

        if (pageType === "state")
        {
            content += " from the state of " + display;
        }
        else if (pageType === "county")
        {
            content += " from the county of " + display;
        }
        else if (pageType === "city")
        {
            content += " from the city of " + display;
        }
        else if (pageType === "zipcode")
        {
            content += " from the zip code " + display;
        }
        else if (pageType === "party")
        {
            content += " to the " + display;
        }
        else if (pageType === "politician")
        {
            content += " to the politician " + display;
        }
        else if (pageType === "office")
        {
            content += " to elections for the " + display;
        }
        else if (pageType === "surname")
        {
            content += " by contributors with the surname of " + display;
        }
        else if (pageType === "committee")
        {
            content += " to the " + display;
        }
        else if (pageType === "occupation")
        {
            content += " by contributors with the occupation of " + display;
        }
        else if (pageType === "organization")
        {
            content += " by contributors associations to the organization " + display;
        }
        else if (pageType === "industry")
        {
            content += " by contributors associations to the " + display + " industry";
        }
        content += " to " + linkTypeDetails.displayPlural.toLowerCase() + " in the " + cycleYear + " elections was $";

        content += totalAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ". ";
        content += "The total donations from individual contributors was $" + totalIndivAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "The total donations from organizations was $" + totalOrgAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "The total donations from political action committees was $" + totalPACAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += topAmountType + " was the highest " + linkTypeDetails.display.toLowerCase() + " contributor by total amount, giving $";
        content += topAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";

        return content;
    }
}
