import {
    Box,
    Button,
    Paper,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    tooltipClasses,
    Typography
} from "@mui/material";
import ScrollToTop from "./scrollToTop";
import React, {useEffect} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const ContributorAlphaBrowser = (props) => {
    const [success, setSuccess] = React.useState(false);
    const [links, setLinks] = React.useState([]);
    const [pageCount, setPageCount] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - links.length) : 0;
    let [selectedLetter, setSelectedLetter] = React.useState("A");

    const [firstLetter, setFirst] = React.useState("A");
    const [secondLetter, setSecond] = React.useState("A");
    const [thirdLetter, setThird] = React.useState("A");
    const [nameToShow, setNameToShow] = React.useState("AAA");

    const [r1ActiveA, setR1ActiveA] = React.useState(true);
    const [r1ActiveB, setR1ActiveB] = React.useState(false);
    const [r1ActiveC, setR1ActiveC] = React.useState(false);
    const [r1ActiveD, setR1ActiveD] = React.useState(false);
    const [r1ActiveE, setR1ActiveE] = React.useState(false);
    const [r1ActiveF, setR1ActiveF] = React.useState(false);
    const [r1ActiveG, setR1ActiveG] = React.useState(false);
    const [r1ActiveH, setR1ActiveH] = React.useState(false);
    const [r1ActiveI, setR1ActiveI] = React.useState(false);
    const [r1ActiveJ, setR1ActiveJ] = React.useState(false);
    const [r1ActiveK, setR1ActiveK] = React.useState(false);
    const [r1ActiveL, setR1ActiveL] = React.useState(false);
    const [r1ActiveM, setR1ActiveM] = React.useState(false);
    const [r1ActiveN, setR1ActiveN] = React.useState(false);
    const [r1ActiveO, setR1ActiveO] = React.useState(false);
    const [r1ActiveP, setR1ActiveP] = React.useState(false);
    const [r1ActiveQ, setR1ActiveQ] = React.useState(false);
    const [r1ActiveR, setR1ActiveR] = React.useState(false);
    const [r1ActiveS, setR1ActiveS] = React.useState(false);
    const [r1ActiveT, setR1ActiveT] = React.useState(false);
    const [r1ActiveU, setR1ActiveU] = React.useState(false);
    const [r1ActiveV, setR1ActiveV] = React.useState(false);
    const [r1ActiveW, setR1ActiveW] = React.useState(false);
    const [r1ActiveX, setR1ActiveX] = React.useState(false);
    const [r1ActiveY, setR1ActiveY] = React.useState(false);
    const [r1ActiveZ, setR1ActiveZ] = React.useState(false);
    const [r1Active9, setR1Active9] = React.useState(false);

    const [r2ActiveA, setR2ActiveA] = React.useState(true);
    const [r2ActiveB, setR2ActiveB] = React.useState(false);
    const [r2ActiveC, setR2ActiveC] = React.useState(false);
    const [r2ActiveD, setR2ActiveD] = React.useState(false);
    const [r2ActiveE, setR2ActiveE] = React.useState(false);
    const [r2ActiveF, setR2ActiveF] = React.useState(false);
    const [r2ActiveG, setR2ActiveG] = React.useState(false);
    const [r2ActiveH, setR2ActiveH] = React.useState(false);
    const [r2ActiveI, setR2ActiveI] = React.useState(false);
    const [r2ActiveJ, setR2ActiveJ] = React.useState(false);
    const [r2ActiveK, setR2ActiveK] = React.useState(false);
    const [r2ActiveL, setR2ActiveL] = React.useState(false);
    const [r2ActiveM, setR2ActiveM] = React.useState(false);
    const [r2ActiveN, setR2ActiveN] = React.useState(false);
    const [r2ActiveO, setR2ActiveO] = React.useState(false);
    const [r2ActiveP, setR2ActiveP] = React.useState(false);
    const [r2ActiveQ, setR2ActiveQ] = React.useState(false);
    const [r2ActiveR, setR2ActiveR] = React.useState(false);
    const [r2ActiveS, setR2ActiveS] = React.useState(false);
    const [r2ActiveT, setR2ActiveT] = React.useState(false);
    const [r2ActiveU, setR2ActiveU] = React.useState(false);
    const [r2ActiveV, setR2ActiveV] = React.useState(false);
    const [r2ActiveW, setR2ActiveW] = React.useState(false);
    const [r2ActiveX, setR2ActiveX] = React.useState(false);
    const [r2ActiveY, setR2ActiveY] = React.useState(false);
    const [r2ActiveZ, setR2ActiveZ] = React.useState(false);
    const [r2Active9, setR2Active9] = React.useState(false);

    const [r3ActiveA, setR3ActiveA] = React.useState(true);
    const [r3ActiveB, setR3ActiveB] = React.useState(false);
    const [r3ActiveC, setR3ActiveC] = React.useState(false);
    const [r3ActiveD, setR3ActiveD] = React.useState(false);
    const [r3ActiveE, setR3ActiveE] = React.useState(false);
    const [r3ActiveF, setR3ActiveF] = React.useState(false);
    const [r3ActiveG, setR3ActiveG] = React.useState(false);
    const [r3ActiveH, setR3ActiveH] = React.useState(false);
    const [r3ActiveI, setR3ActiveI] = React.useState(false);
    const [r3ActiveJ, setR3ActiveJ] = React.useState(false);
    const [r3ActiveK, setR3ActiveK] = React.useState(false);
    const [r3ActiveL, setR3ActiveL] = React.useState(false);
    const [r3ActiveM, setR3ActiveM] = React.useState(false);
    const [r3ActiveN, setR3ActiveN] = React.useState(false);
    const [r3ActiveO, setR3ActiveO] = React.useState(false);
    const [r3ActiveP, setR3ActiveP] = React.useState(false);
    const [r3ActiveQ, setR3ActiveQ] = React.useState(false);
    const [r3ActiveR, setR3ActiveR] = React.useState(false);
    const [r3ActiveS, setR3ActiveS] = React.useState(false);
    const [r3ActiveT, setR3ActiveT] = React.useState(false);
    const [r3ActiveU, setR3ActiveU] = React.useState(false);
    const [r3ActiveV, setR3ActiveV] = React.useState(false);
    const [r3ActiveW, setR3ActiveW] = React.useState(false);
    const [r3ActiveX, setR3ActiveX] = React.useState(false);
    const [r3ActiveY, setR3ActiveY] = React.useState(false);
    const [r3ActiveZ, setR3ActiveZ] = React.useState(false);
    const [r3Active9, setR3Active9] = React.useState(false);

    let nameStart = "AAA";
    let loadedPage = 1;

    const loadLetter = (event, letter) => {
        loadedPage = 1;
        console.log("loadLetter:" + letter + " => " + nameStart);

        let loadURL = "";
        if (props.cycleYear === 0)
        {
            loadURL = process.env.REACT_APP_API_ROOT + props.service + "?q=" + nameStart;
            console.log(" - CALLING URL::" + loadURL)
        }
        else
        {
            loadURL = process.env.REACT_APP_API_ROOT + "cycle/" + props.cycleYear + "/" + props.service + "?q=" + nameStart;
            console.log(" - CALLING URL::" + loadURL)
        }

        axios.get(loadURL)
            .then((res) => {
                if (res.data.entries === undefined || res.data.entries === null)
                {
                    setSuccess(false);
                    setLinks([])
                }
                else
                {
                    setSuccess(true);
                    setLinks(res.data.entries);
                    setPageCount(res.data.total);
                }
            },);
    };

    let imageIconSrc = ""
    if (props.type === "organization")
    {
        imageIconSrc = "/images/organization_icon.png";
    }
    else if (props.type === "surname")
    {
        imageIconSrc = "/images/surname_icon.png";
    }
    else if (props.type === "contributor")
    {
        imageIconSrc = "/images/contributor_icon.png";
    }
    else if (props.type === "vendor")
    {
        imageIconSrc = "/images/vendor_icon.png";
    }
    else
    {
        imageIconSrc = "/images/placeHolderIcon.png"
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    let navigate = useNavigate();

    const handleOnCellClick = (params, link) => {
        if (props.cycleYear === 0)
        {
            navigate(props.linkPath + link);
        }
        else
        {
            let path = props.linkPath.replaceAll("..", "");
            navigate("/cycle/" + props.cycleYear + path + link);
        }
    };

    const setFirstLetter = (event, letter) => {
        clearR1Buttons()

        setFirst(letter)

        if (letter === "A")
        {
            setR1ActiveA(true)
        }
        else if (letter === "B")
        {
            setR1ActiveB(true)
        }
        else if (letter === "C")
        {
            setR1ActiveC(true)
        }
        else if (letter === "D")
        {
            setR1ActiveD(true)
        }
        else if (letter === "E")
        {
            setR1ActiveE(true)
        }
        else if (letter === "F")
        {
            setR1ActiveF(true)
        }
        else if (letter === "G")
        {
            setR1ActiveG(true)
        }
        else if (letter === "H")
        {
            setR1ActiveH(true)
        }
        else if (letter === "I")
        {
            setR1ActiveI(true)
        }
        else if (letter === "J")
        {
            setR1ActiveJ(true)
        }
        else if (letter === "K")
        {
            setR1ActiveK(true)
        }
        else if (letter === "L")
        {
            setR1ActiveL(true)
        }
        else if (letter === "M")
        {
            setR1ActiveM(true)
        }
        else if (letter === "N")
        {
            setR1ActiveN(true)
        }
        else if (letter === "O")
        {
            setR1ActiveO(true)
        }
        else if (letter === "P")
        {
            setR1ActiveP(true)
        }
        else if (letter === "Q")
        {
            setR1ActiveQ(true)
        }
        else if (letter === "R")
        {
            setR1ActiveR(true)
        }
        else if (letter === "S")
        {
            setR1ActiveS(true)
        }
        else if (letter === "T")
        {
            setR1ActiveT(true)
        }
        else if (letter === "U")
        {
            setR1ActiveU(true)
        }
        else if (letter === "V")
        {
            setR1ActiveV(true)
        }
        else if (letter === "W")
        {
            setR1ActiveW(true)
        }
        else if (letter === "X")
        {
            setR1ActiveX(true)
        }
        else if (letter === "Y")
        {
            setR1ActiveY(true)
        }
        else if (letter === "Z")
        {
            setR1ActiveZ(true)
        }
        else
        {
            setR1Active9(true)
        }

        nameStart = letter + "" + secondLetter + "" + thirdLetter
        setNameToShow(nameStart)

        loadLetter(event, nameStart)
    }

    const setSecondLetter = (event, letter) => {
        clearR2Buttons()

        setSecond(letter)

        if (letter === "A")
        {
            setR2ActiveA(true)
        }
        else if (letter === "B")
        {
            setR2ActiveB(true)
        }
        else if (letter === "C")
        {
            setR2ActiveC(true)
        }
        else if (letter === "D")
        {
            setR2ActiveD(true)
        }
        else if (letter === "E")
        {
            setR2ActiveE(true)
        }
        else if (letter === "F")
        {
            setR2ActiveF(true)
        }
        else if (letter === "G")
        {
            setR2ActiveG(true)
        }
        else if (letter === "H")
        {
            setR2ActiveH(true)
        }
        else if (letter === "I")
        {
            setR2ActiveI(true)
        }
        else if (letter === "J")
        {
            setR2ActiveJ(true)
        }
        else if (letter === "K")
        {
            setR2ActiveK(true)
        }
        else if (letter === "L")
        {
            setR2ActiveL(true)
        }
        else if (letter === "M")
        {
            setR2ActiveM(true)
        }
        else if (letter === "N")
        {
            setR2ActiveN(true)
        }
        else if (letter === "O")
        {
            setR2ActiveO(true)
        }
        else if (letter === "P")
        {
            setR2ActiveP(true)
        }
        else if (letter === "Q")
        {
            setR2ActiveQ(true)
        }
        else if (letter === "R")
        {
            setR2ActiveR(true)
        }
        else if (letter === "S")
        {
            setR2ActiveS(true)
        }
        else if (letter === "T")
        {
            setR2ActiveT(true)
        }
        else if (letter === "U")
        {
            setR2ActiveU(true)
        }
        else if (letter === "V")
        {
            setR2ActiveV(true)
        }
        else if (letter === "W")
        {
            setR2ActiveW(true)
        }
        else if (letter === "X")
        {
            setR2ActiveX(true)
        }
        else if (letter === "Y")
        {
            setR2ActiveY(true)
        }
        else if (letter === "Z")
        {
            setR2ActiveZ(true)
        }
        else
        {
            setR2Active9(true)
        }

        nameStart = firstLetter + "" + letter + "" + thirdLetter
        setNameToShow(nameStart)

        loadLetter(event, nameStart)
    }

    const setThirdLetter = (event, letter) => {
        clearR3Buttons()

        setThird(letter)

        if (letter === "A")
        {
            setR3ActiveA(true)
        }
        else if (letter === "B")
        {
            setR3ActiveB(true)
        }
        else if (letter === "C")
        {
            setR3ActiveC(true)
        }
        else if (letter === "D")
        {
            setR3ActiveD(true)
        }
        else if (letter === "E")
        {
            setR3ActiveE(true)
        }
        else if (letter === "F")
        {
            setR3ActiveF(true)
        }
        else if (letter === "G")
        {
            setR3ActiveG(true)
        }
        else if (letter === "H")
        {
            setR3ActiveH(true)
        }
        else if (letter === "I")
        {
            setR3ActiveI(true)
        }
        else if (letter === "J")
        {
            setR3ActiveJ(true)
        }
        else if (letter === "K")
        {
            setR3ActiveK(true)
        }
        else if (letter === "L")
        {
            setR3ActiveL(true)
        }
        else if (letter === "M")
        {
            setR3ActiveM(true)
        }
        else if (letter === "N")
        {
            setR3ActiveN(true)
        }
        else if (letter === "O")
        {
            setR3ActiveO(true)
        }
        else if (letter === "P")
        {
            setR3ActiveP(true)
        }
        else if (letter === "Q")
        {
            setR3ActiveQ(true)
        }
        else if (letter === "R")
        {
            setR3ActiveR(true)
        }
        else if (letter === "S")
        {
            setR3ActiveS(true)
        }
        else if (letter === "T")
        {
            setR3ActiveT(true)
        }
        else if (letter === "U")
        {
            setR3ActiveU(true)
        }
        else if (letter === "V")
        {
            setR3ActiveV(true)
        }
        else if (letter === "W")
        {
            setR3ActiveW(true)
        }
        else if (letter === "X")
        {
            setR3ActiveX(true)
        }
        else if (letter === "Y")
        {
            setR3ActiveY(true)
        }
        else if (letter === "Z")
        {
            setR3ActiveZ(true)
        }
        else
        {
            setR3Active9(true)
        }

        nameStart = firstLetter + "" + secondLetter + "" + letter
        setNameToShow(nameStart)

        loadLetter(event, nameStart)
    }

    const clearR1Buttons = () => {
        setR1ActiveA(false);
        setR1ActiveB(false);
        setR1ActiveC(false);
        setR1ActiveD(false);
        setR1ActiveE(false);
        setR1ActiveF(false);
        setR1ActiveG(false);
        setR1ActiveH(false);
        setR1ActiveI(false);
        setR1ActiveJ(false);
        setR1ActiveK(false);
        setR1ActiveL(false);
        setR1ActiveM(false);
        setR1ActiveN(false);
        setR1ActiveO(false);
        setR1ActiveP(false);
        setR1ActiveQ(false);
        setR1ActiveR(false);
        setR1ActiveS(false);
        setR1ActiveT(false);
        setR1ActiveU(false);
        setR1ActiveV(false);
        setR1ActiveW(false);
        setR1ActiveX(false);
        setR1ActiveY(false);
        setR1ActiveZ(false);
        setR1Active9(false);
    }

    const clearR2Buttons = () => {
        setR2ActiveA(false);
        setR2ActiveB(false);
        setR2ActiveC(false);
        setR2ActiveD(false);
        setR2ActiveE(false);
        setR2ActiveF(false);
        setR2ActiveG(false);
        setR2ActiveH(false);
        setR2ActiveI(false);
        setR2ActiveJ(false);
        setR2ActiveK(false);
        setR2ActiveL(false);
        setR2ActiveM(false);
        setR2ActiveN(false);
        setR2ActiveO(false);
        setR2ActiveP(false);
        setR2ActiveQ(false);
        setR2ActiveR(false);
        setR2ActiveS(false);
        setR2ActiveT(false);
        setR2ActiveU(false);
        setR2ActiveV(false);
        setR2ActiveW(false);
        setR2ActiveX(false);
        setR2ActiveY(false);
        setR2ActiveZ(false);
        setR2Active9(false);
    }

    const clearR3Buttons = () => {
        setR3ActiveA(false);
        setR3ActiveB(false);
        setR3ActiveC(false);
        setR3ActiveD(false);
        setR3ActiveE(false);
        setR3ActiveF(false);
        setR3ActiveG(false);
        setR3ActiveH(false);
        setR3ActiveI(false);
        setR3ActiveJ(false);
        setR3ActiveK(false);
        setR3ActiveL(false);
        setR3ActiveM(false);
        setR3ActiveN(false);
        setR3ActiveO(false);
        setR3ActiveP(false);
        setR3ActiveQ(false);
        setR3ActiveR(false);
        setR3ActiveS(false);
        setR3ActiveT(false);
        setR3ActiveU(false);
        setR3ActiveV(false);
        setR3ActiveW(false);
        setR3ActiveX(false);
        setR3ActiveY(false);
        setR3ActiveZ(false);
        setR3Active9(false);
    }

    useEffect(() => {
        loadLetter(null, nameStart);
    }, []);

    let rollupPath = "https://www.monecracy.com" + props.linkPath.replaceAll("..", "");
    let cyclePath = "https://www.monecracy.com/cycle/" + props.cycleYear + props.linkPath.replaceAll("..", "");

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 400,
        },
    });

    return(
        <Stack spacing={2} paddingBottom={2}>
            <Stack id="back-to-top-anchor" justifyContent={"left"} direction={"row"} alignItems={"center"} paddingTop={2} spacing={2}>
                <img height={40} width={40} src={imageIconSrc} loading="lazy" />
                <Typography variant={"h4"}>{props.typeHeader}</Typography>
                {
                    props.cycleYear === 0 ?
                        (<Typography color={"gray"} variant={"caption"} paddingTop={.5}>{"ALPHABETIC LIST OF ALL " + props.typeHeader.toUpperCase() + " FOR AVAILABLE ELECTION CYCLES"}</Typography>):
                        (<Typography color={"gray"} variant={"caption"} paddingTop={.5}>{"ALPHABETIC LIST OF ALL " + props.typeHeader.toUpperCase() + " FOR THE " + props.cycleYear + " ELECTION CYCLE"}</Typography>)
                }
            </Stack>
            <Box sx={{borderTopStyle:'dotted', borderColor:"#383838"}}></Box>
            <Box sx={{ backgroundColor: '#20273C'}} paddingRight={5} paddingLeft={5} paddingBottom={2} paddingTop={2}>
                <Stack justifyContent={"center"} alignItems={"center"} direction={"row"} spacing={2}>
                    <img height={25} width={25} src="/images/search_icon.png" alt="" loading="lazy" />
                    <Typography variant={"h6"}>{props.title}</Typography>
                </Stack>
                <Stack paddingBottom={2} spacing={2}>
                    <Typography variant={"caption"} color={"gray"}>BY NAME</Typography>
                </Stack>

                <Stack spacing={1}>
                    <Typography>Select the first three letters of last name</Typography>
                    {
                        window.innerWidth > 1000 ?
                            (
                                <Stack spacing={1}>
                                    <Stack direction={"row"} justifyContent={"center"} spacing={.75}>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'A')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveA ? "green" : "transparent" }}>A</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'B')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveB ? "green" : "transparent" }}>B</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'C')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveC ? "green" : "transparent" }}>C</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'D')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveD ? "green" : "transparent" }}>D</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'E')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveE ? "green" : "transparent" }}>E</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'F')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveF ? "green" : "transparent" }}>F</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'G')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveG ? "green" : "transparent" }}>G</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'H')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveH ? "green" : "transparent" }}>H</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'I')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveI ? "green" : "transparent" }}>I</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'J')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveJ ? "green" : "transparent" }}>J</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'K')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveK ? "green" : "transparent" }}>K</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'L')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveL ? "green" : "transparent" }}>L</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'M')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveM ? "green" : "transparent" }}>M</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'N')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveN ? "green" : "transparent" }}>N</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'O')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveO ? "green" : "transparent" }}>O</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'P')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveP ? "green" : "transparent" }}>P</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'Q')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveQ ? "green" : "transparent" }}>Q</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'R')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveR ? "green" : "transparent" }}>R</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'S')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveS ? "green" : "transparent" }}>S</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'T')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveT ? "green" : "transparent" }}>T</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'U')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveU ? "green" : "transparent" }}>U</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'V')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveV ? "green" : "transparent" }}>V</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'W')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveW ? "green" : "transparent" }}>W</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'X')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveX ? "green" : "transparent" }}>X</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'Y')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveY ? "green" : "transparent" }}>Y</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'Z')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveZ ? "green" : "transparent" }}>Z</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, '9')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1Active9 ? "green" : "transparent" }}>0-9</Button>
                                    </Stack>

                                    <Stack direction={"row"} justifyContent={"center"} spacing={.75}>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'A')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveA ? "green" : "transparent" }}>A</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'B')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveB ? "green" : "transparent" }}>B</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'C')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveC ? "green" : "transparent" }}>C</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'D')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveD ? "green" : "transparent" }}>D</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'E')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveE ? "green" : "transparent" }}>E</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'F')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveF ? "green" : "transparent" }}>F</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'G')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveG ? "green" : "transparent" }}>G</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'H')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveH ? "green" : "transparent" }}>H</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'I')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveI ? "green" : "transparent" }}>I</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'J')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveJ ? "green" : "transparent" }}>J</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'K')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveK ? "green" : "transparent" }}>K</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'L')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveL ? "green" : "transparent" }}>L</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'M')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveM ? "green" : "transparent" }}>M</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'N')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveN ? "green" : "transparent" }}>N</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'O')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveO ? "green" : "transparent" }}>O</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'P')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveP ? "green" : "transparent" }}>P</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'Q')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveQ ? "green" : "transparent" }}>Q</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'R')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveR ? "green" : "transparent" }}>R</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'S')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveS ? "green" : "transparent" }}>S</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'T')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveT ? "green" : "transparent" }}>T</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'U')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveU ? "green" : "transparent" }}>U</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'V')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveV ? "green" : "transparent" }}>V</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'W')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveW ? "green" : "transparent" }}>W</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'X')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveX ? "green" : "transparent" }}>X</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'Y')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveY ? "green" : "transparent" }}>Y</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'Z')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveZ ? "green" : "transparent" }}>Z</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, '9')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2Active9 ? "green" : "transparent" }}>0-9</Button>
                                    </Stack>

                                    <Stack direction={"row"} justifyContent={"center"} spacing={.75}>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'A')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveA ? "green" : "transparent" }}>A</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'B')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveB ? "green" : "transparent" }}>B</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'C')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveC ? "green" : "transparent" }}>C</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'D')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveD ? "green" : "transparent" }}>D</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'E')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveE ? "green" : "transparent" }}>E</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'F')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveF ? "green" : "transparent" }}>F</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'G')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveG ? "green" : "transparent" }}>G</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'H')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveH ? "green" : "transparent" }}>H</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'I')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveI ? "green" : "transparent" }}>I</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'J')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveJ ? "green" : "transparent" }}>J</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'K')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveK ? "green" : "transparent" }}>K</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'L')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveL ? "green" : "transparent" }}>L</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'M')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveM ? "green" : "transparent" }}>M</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'N')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveN ? "green" : "transparent" }}>N</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'O')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveO ? "green" : "transparent" }}>O</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'P')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveP ? "green" : "transparent" }}>P</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'Q')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveQ ? "green" : "transparent" }}>Q</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'R')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveR ? "green" : "transparent" }}>R</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'S')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveS ? "green" : "transparent" }}>S</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'T')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveT ? "green" : "transparent" }}>T</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'U')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveU ? "green" : "transparent" }}>U</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'V')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveV ? "green" : "transparent" }}>V</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'W')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveW ? "green" : "transparent" }}>W</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'X')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveX ? "green" : "transparent" }}>X</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'Y')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveY ? "green" : "transparent" }}>Y</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'Z')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveZ ? "green" : "transparent" }}>Z</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, '9')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3Active9 ? "green" : "transparent" }}>0-9</Button>
                                    </Stack>
                                </Stack>
                            ):
                            (
                                <Stack direction={"column"} justifyContent={"center"} spacing={2}>
                                    <Stack direction={"column"} justifyContent={"center"} spacing={.75}>
                                        <Stack direction={"row"} justifyContent={"center"}>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'A')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveA ? "green" : "transparent" }}>A</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'B')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveB ? "green" : "transparent" }}>B</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'C')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveC ? "green" : "transparent" }}>C</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'D')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveD ? "green" : "transparent" }}>D</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'E')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveE ? "green" : "transparent" }}>E</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'F')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveF ? "green" : "transparent" }}>F</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'G')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveG ? "green" : "transparent" }}>G</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'H')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveH ? "green" : "transparent" }}>H</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'I')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveI ? "green" : "transparent" }}>I</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'J')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveJ ? "green" : "transparent" }}>J</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'K')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveK ? "green" : "transparent" }}>K</Button>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"center"}>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'L')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveL ? "green" : "transparent" }}>L</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'M')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveM ? "green" : "transparent" }}>M</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'N')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveN ? "green" : "transparent" }}>N</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'O')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveO ? "green" : "transparent" }}>O</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'P')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveP ? "green" : "transparent" }}>P</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'Q')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveQ ? "green" : "transparent" }}>Q</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'R')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveR ? "green" : "transparent" }}>R</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'S')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveS ? "green" : "transparent" }}>S</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'T')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveT ? "green" : "transparent" }}>T</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'U')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveU ? "green" : "transparent" }}>U</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'V')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveV ? "green" : "transparent" }}>V</Button>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"center"}>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'W')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveW ? "green" : "transparent" }}>W</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'X')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveX ? "green" : "transparent" }}>X</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'Y')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveY ? "green" : "transparent" }}>Y</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, 'Z')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1ActiveZ ? "green" : "transparent" }}>Z</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstLetter(event, '9')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r1Active9 ? "green" : "transparent" }}>0-9</Button>
                                        </Stack>
                                    </Stack>

                                    <Stack direction={"column"} justifyContent={"center"} spacing={.75}>
                                        <Stack direction={"row"} justifyContent={"center"}>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'A')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveA ? "green" : "transparent" }}>A</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'B')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveB ? "green" : "transparent" }}>B</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'C')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveC ? "green" : "transparent" }}>C</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'D')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveD ? "green" : "transparent" }}>D</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'E')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveE ? "green" : "transparent" }}>E</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'F')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveF ? "green" : "transparent" }}>F</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'G')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveG ? "green" : "transparent" }}>G</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'H')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveH ? "green" : "transparent" }}>H</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'I')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveI ? "green" : "transparent" }}>I</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'J')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveJ ? "green" : "transparent" }}>J</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'K')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveK ? "green" : "transparent" }}>K</Button>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"center"}>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'L')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveL ? "green" : "transparent" }}>L</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'M')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveM ? "green" : "transparent" }}>M</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'N')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveN ? "green" : "transparent" }}>N</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'O')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveO ? "green" : "transparent" }}>O</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'P')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveP ? "green" : "transparent" }}>P</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'Q')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveQ ? "green" : "transparent" }}>Q</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'R')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveR ? "green" : "transparent" }}>R</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'S')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveS ? "green" : "transparent" }}>S</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'T')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveT ? "green" : "transparent" }}>T</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'U')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveU ? "green" : "transparent" }}>U</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'V')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveV ? "green" : "transparent" }}>V</Button>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"center"}>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'W')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveW ? "green" : "transparent" }}>W</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'X')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveX ? "green" : "transparent" }}>X</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'Y')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveY ? "green" : "transparent" }}>Y</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, 'Z')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2ActiveZ ? "green" : "transparent" }}>Z</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondLetter(event, '9')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2Active9 ? "green" : "transparent" }}>0-9</Button>
                                        </Stack>
                                    </Stack>

                                    <Stack direction={"column"} justifyContent={"center"} spacing={.75}>
                                        <Stack direction={"row"} justifyContent={"center"}>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'A')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveA ? "green" : "transparent" }}>A</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'B')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveB ? "green" : "transparent" }}>B</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'C')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveC ? "green" : "transparent" }}>C</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'D')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveD ? "green" : "transparent" }}>D</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'E')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveE ? "green" : "transparent" }}>E</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'F')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveF ? "green" : "transparent" }}>F</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'G')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveG ? "green" : "transparent" }}>G</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'H')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveH ? "green" : "transparent" }}>H</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'I')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveI ? "green" : "transparent" }}>I</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'J')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveJ ? "green" : "transparent" }}>J</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'K')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveK ? "green" : "transparent" }}>K</Button>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"center"}>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'L')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveL ? "green" : "transparent" }}>L</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'M')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveM ? "green" : "transparent" }}>M</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'N')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveN ? "green" : "transparent" }}>N</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'O')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveO ? "green" : "transparent" }}>O</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'P')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveP ? "green" : "transparent" }}>P</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'Q')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveQ ? "green" : "transparent" }}>Q</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'R')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveR ? "green" : "transparent" }}>R</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'S')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveS ? "green" : "transparent" }}>S</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'T')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveT ? "green" : "transparent" }}>T</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'U')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveU ? "green" : "transparent" }}>U</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'V')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveV ? "green" : "transparent" }}>V</Button>
                                        </Stack>
                                        <Stack direction={"row"} justifyContent={"center"}>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'W')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveW ? "green" : "transparent" }}>W</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'X')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveX ? "green" : "transparent" }}>X</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'Y')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveY ? "green" : "transparent" }}>Y</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, 'Z')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3ActiveZ ? "green" : "transparent" }}>Z</Button>
                                            <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdLetter(event, '9')} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3Active9 ? "green" : "transparent" }}>0-9</Button>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            )
                    }
                </Stack>
            </Box>

            <Box sx={{ backgroundColor: '#20273C'}} padding={5}>
                <Stack justifyContent={"left"} direction={"row"} alignItems={"center"} paddingBottom={2} spacing={2}>
                    <img height={25} width={25} src={imageIconSrc} loading="lazy" />
                    <Typography variant={"subtitle2"} paddingTop={.5}>Results</Typography>
                </Stack>
                <Stack border={1.5} padding={5} borderColor={"black"} borderRadius={"5px"}>
                    <Stack spacing={1} justifyContent={"center"} alignItems={"center"} direction={"row"} paddingBottom={1.5}>
                        <Typography>{nameToShow}</Typography>
                        <Typography paddingTop={.50} variant={"caption"} color={"gray"}>{props.typeHeader.toUpperCase() + " - SHOWING " + rowsPerPage}</Typography>
                    </Stack>
                    <Box borderTop={1} borderColor={"#515151"}></Box>
                    <TableContainer component={Paper}>
                        <Table sx={{ backgroundColor: '#20273C'}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {/*<TableCell>Key</TableCell>*/}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {(rowsPerPage > 0
                                        ? links.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : links
                                ).map((row) => (
                                    <TableRow key={row.path}>
                                        <CustomWidthTooltip sx={{width:400}} title={props.cycleYear === 0 ?
                                            (rollupPath + row.path):
                                            (cyclePath + row.path)
                                        }>
                                            <TableCell component="th" scope="row" onClick={event => handleOnCellClick(event, row.path)} className={"clickable"}>
                                                {<div>
                                                    <Stack justifyContent={"space-between"} direction={"row"}>
                                                        <Box>{row.name}</Box>
                                                        <Box>{row.city + ", " + row.state + " " + row.zipcode}</Box>
                                                    </Stack>
                                                    <br/>
                                                    {props.cycleYear === 0 ?
                                                        (rollupPath + row.path):
                                                        (cyclePath + row.path)}
                                                </div>
                                                }
                                            </TableCell>
                                        </CustomWidthTooltip>
                                    </TableRow>
                                ))}

                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        sx={{
                                            '.MuiTablePagination-menuItem': {
                                                color: 'black',
                                            },
                                        }}
                                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                        colSpan={3}
                                        count={links.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        // ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Stack>
            </Box>

            <ScrollToTop />
        </Stack>
    )
};

export default ContributorAlphaBrowser;
