import {Link as RouterLink, useParams} from "react-router-dom";
import SiteHeader from "../../components/siteHeader";
import AlphaBrowser from "../../components/alphaBrowser";
import SiteFooter from "../../components/siteFooter";
import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import React from "react";
import MetaData from "../../components/metaData";

const OccupationCycleBrowser = () => {
    const {cycleYear} = useParams();

    return (
        <div>
            <Stack paddingBottom={10}>
                <SiteHeader />
            </Stack>
            <div className="Page-content"><Stack direction="row" alignItems="center" justifyContent="space-between" paddingTop={1}>
                <MetaData
                    title={"Occupation Browser " + cycleYear}
                    description={"Browse by all Occupations for the " + cycleYear + " election cycle"}
                    url={window.location.href}
                    image={"https://www.monecracy.com/images/logo.png"}/>
                <Breadcrumbs>
                    <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                    <Link component={RouterLink} to={"/totals"} underline={"hover"}>Totals</Link>
                    <Link component={RouterLink} to={"/cycles"} underline={"hover"}>Cycles</Link>
                    <Link component={RouterLink} to={"/cycle/" + cycleYear} underline={"hover"}>{cycleYear}</Link>
                    <Typography>Occupations</Typography>
                </Breadcrumbs>
            </Stack>
                <AlphaBrowser cycleYear={cycleYear} title="Browse Occupations" typeHeader={"Occupations"} type={"occupation"} service={"occupations"} linkPath={"../occupation"} />
            </div>
            <SiteFooter/>
        </div>
    );
};

export default OccupationCycleBrowser;
