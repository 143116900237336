import {formatCurrency, formatNumber} from "../App";
import sign from "proj4/lib/common/sign";

export function buildArea(title, subtitle, seriesName, yAxisTitle, chartData, plotField, isPlottingCurrency, displayField, isCES, isUCS)
{
    let renderData
    if (isCES === true)
    {
        renderData = getRenderDataCES(chartData, seriesName, plotField)
    }
    else if (isUCS === true)
    {
        renderData = getRenderDataUCS(chartData, seriesName, plotField)
    }
    else
    {
        renderData = getRenderData(chartData, seriesName, plotField)
    }

    let categories = [];
    chartData.map(nextEntry => {
        if (sign(nextEntry["amount"]) === -1)
        {
            return
        }

        categories.push(nextEntry[displayField]);
    });

    let options = {
        chart: {
            type: 'area'
        },

        xAxis: {
            labels: {
                style: {
                    color: "#fff",
                    fontFamily: 'Nunito'
                },
            },
            categories: categories
        },

        legend: {
            itemStyle: {
                color: '#fff'
            }
        },

        yAxis: {
            labels: {
                style: {
                    color: "#fff",
                    fontFamily: 'Nunito'
                },
                formatter: function () {
                    if (isPlottingCurrency === true)
                    {
                        if (this.value === 0)
                        {
                            return formatCurrency(this.value)
                        }

                        if (this.value.toString().length >= 1 && this.value.toString().length <= 3)
                        {
                            return formatCurrency(this.value / 1)
                        }

                        if (this.value.toString().length >= 1 && this.value.toString().length <= 6)
                        {
                            return formatCurrency(this.value / 1000 + 'K')
                        }

                        if (this.value.toString().length >= 7 && this.value.toString().length <= 9)
                        {
                            return formatCurrency(this.value / 1000000 + 'M')
                        }

                        if (this.value.toString().length >= 10 && this.value.toString().length <= 12)
                        {
                            return formatCurrency(this.value / 1000000000 + 'B')
                        }

                        if (this.value.toString().length >= 13 && this.value.toString().length <= 15)
                        {
                            return formatCurrency(this.value / 1000000000000 + 'T')
                        }
                    }
                    else
                    {
                        return formatNumber(this.value);
                    }
                },
            },
            title: {
                text: yAxisTitle,
                style: {
                    color: "#fff"
                },
            },
        },

        series: [{
            data: renderData,
            name: seriesName,
            tooltip: {
                useHTML: true,
                pointFormat: '<b>{point.display}</b><br/>Amount: {point.amount}<br/>Contributors: {point.contributors}<br/>Contributions: {point.contributions}'
            }
        }],
    }

    applyDefaults(options, title, subtitle);

    return options;
}

export function buildBar(title, subtitle, seriesName, yAxisTitle, chartData, plotField, isPlottingCurrency, displayField, isCES, isUCS)
{
    let renderData
    if (isCES === true)
    {
        renderData = getRenderDataCES(chartData, seriesName, plotField)
    }
    else if (isUCS === true)
    {
        renderData = getRenderDataUCS(chartData, seriesName, plotField)
    }
    else
    {
        renderData = getRenderData(chartData, seriesName, plotField)
    }

    let categories = [];
    chartData.map(nextEntry => {
        if (sign(nextEntry["amount"]) === -1)
        {
            return
        }

        categories.push(nextEntry[displayField]);
    });

    let options = {
        chart: {
            type: 'bar'
        },

        xAxis: {
            labels: {
                style: {
                    color: "#fff",
                    fontFamily: 'Nunito'
                }
            },
            categories: categories
        },

        legend: {
            itemStyle: {
                color: "#fff",
                fontFamily: 'Nunito'
            }
        },

        series: [{
            data: renderData,
            name: seriesName,
            tooltip: {
                useHTML: true,
                pointFormat: '<b>{point.display}</b><br/>Amount: {point.amount}<br/>Contributors: {point.contributors}<br/>Contributions: {point.contributions}'
            }
        }],

        yAxis: {
            labels: {
                style: {
                    color: "#fff",
                    fontFamily: 'Nunito'
                },
                formatter: function () {
                    if (isPlottingCurrency === true)
                    {
                        if (this.amount === 0)
                        {
                            return formatCurrency(this.value)
                        }

                        if (this.value.toString().length >= 1 && this.value.toString().length <= 3)
                        {
                            return formatCurrency(this.value / 1)
                        }

                        if (this.value.toString().length >= 1 && this.value.toString().length <= 6)
                        {
                            return formatCurrency(this.value / 1000 + 'K')
                        }

                        if (this.value.toString().length >= 7 && this.value.toString().length <= 9)
                        {
                            return formatCurrency(this.value / 1000000 + 'M')
                        }

                        if (this.value.toString().length >= 10 && this.value.toString().length <= 12)
                        {
                            return formatCurrency(this.value / 1000000000 + 'B')
                        }

                        if (this.value.toString().length >= 13 && this.value.toString().length <= 15)
                        {
                            return formatCurrency(this.value / 1000000000000 + 'T')
                        }
                    }
                    else
                    {
                        return formatNumber(this.value);
                    }
                },
            },
            title: {
                text: yAxisTitle,
                style: {
                    color: "#fff"
                },
            },
        },
    }

    applyDefaults(options, title, subtitle);

    return options;
}

export function buildColumn(title, subtitle, seriesName, yAxisTitle, chartData, plotField, isPlottingCurrency, displayField, isCES, isUCS)
{
    let renderData
    if (isCES === true)
    {
        renderData = getRenderDataCES(chartData, seriesName, plotField)
    }
    else if (isUCS === true)
    {
        renderData = getRenderDataUCS(chartData, seriesName, plotField)
    }
    else
    {
        renderData = getRenderData(chartData, seriesName, plotField)
    }

    let categories = [];
    chartData.map(nextEntry => {
        if (sign(nextEntry["amount"]) === -1)
        {
            return
        }

        categories.push(nextEntry[displayField]);
    });

    let options = {
        chart: {
            type: 'column'
        },

        xAxis: {
            labels: {
                style: {
                    color: "#fff",
                    fontFamily: 'Nunito'
                },
            },
            categories: categories
        },

        legend: {
            itemStyle: {
                color: "#fff",
                fontFamily: 'Nunito'
            }
        },

        yAxis: {
            labels: {
                style: {
                    color: "#fff",
                    fontFamily: 'Nunito'
                },
                formatter: function () {
                    if (isPlottingCurrency === true)
                    {
                        if (this.value === 0)
                        {
                            return formatCurrency(this.value)
                        }

                        if (this.value.toString().length >= 1 && this.value.toString().length <= 3)
                        {
                            return formatCurrency(this.value / 1)
                        }

                        if (this.value.toString().length >= 1 && this.value.toString().length <= 6)
                        {
                            return formatCurrency(this.value / 1000 + 'K')
                        }

                        if (this.value.toString().length >= 7 && this.value.toString().length <= 9)
                        {
                            return formatCurrency(this.value / 1000000 + 'M')
                        }

                        if (this.value.toString().length >= 10 && this.value.toString().length <= 12)
                        {
                            return formatCurrency(this.value / 1000000000 + 'B')
                        }

                        if (this.value.toString().length >= 13 && this.value.toString().length <= 15)
                        {
                            return formatCurrency(this.value / 1000000000000 + 'T')
                        }
                    }
                    else
                    {
                        return formatNumber(this.value);
                    }
                },
            },
            title: {
                text: yAxisTitle,
                style: {
                    color: "#fff"
                },
            },
        },

        series: [{
            data: renderData,
            name: seriesName,
            tooltip: {
                useHTML: true,
                pointFormat: '<b>{point.display}</b><br/>Amount: {point.amount}<br/>Contributors: {point.contributors}<br/>Contributions: {point.contributions}'
            }
        }],
    }

    applyDefaults(options, title, subtitle);

    return options;
}

export function buildLine(title, subtitle, seriesName, yAxisTitle, chartData, plotField, isPlottingCurrency, displayField, isCES, isUCS)
{
    let renderData
    if (isCES === true)
    {
        renderData = getRenderDataCES(chartData, seriesName, plotField)
    }
    else if (isUCS === true)
    {
        renderData = getRenderDataUCS(chartData, seriesName, plotField)
    }
    else
    {
        renderData = getRenderData(chartData, seriesName, plotField)
    }

    let categories = [];
    chartData.map(nextEntry => {
        if (sign(nextEntry["amount"]) === -1)
        {
            return
        }

        categories.push(nextEntry[displayField]);
    });

    let options = {
        chart: {
            type: 'line'
        },

        xAxis: {
            labels: {
                style: {
                    color: "#fff",
                    fontFamily: 'Nunito'
                },
            },
            categories: categories
        },

        legend: {
            itemStyle: {
                color: "#fff",
                fontFamily: 'Nunito'
            }
        },

        yAxis: {
            labels: {
                style: {
                    color: "#fff",
                    fontFamily: 'Nunito'
                },
                formatter: function () {
                    if (isPlottingCurrency === true)
                    {
                        if (this.value === 0)
                        {
                            return formatCurrency(this.value)
                        }

                        if (this.value.toString().length >= 1 && this.value.toString().length <= 3)
                        {
                            return formatCurrency(this.value / 1)
                        }

                        if (this.value.toString().length >= 1 && this.value.toString().length <= 6)
                        {
                            return formatCurrency(this.value / 1000 + 'K')
                        }

                        if (this.value.toString().length >= 7 && this.value.toString().length <= 9)
                        {
                            return formatCurrency(this.value / 1000000 + 'M')
                        }

                        if (this.value.toString().length >= 10 && this.value.toString().length <= 12)
                        {
                            return formatCurrency(this.value / 1000000000 + 'B')
                        }

                        if (this.value.toString().length >= 13 && this.value.toString().length <= 15)
                        {
                            return formatCurrency(this.value / 1000000000000 + 'T')
                        }
                    }
                    else
                    {
                        return formatNumber(this.value);
                    }
                },
            },
            title: {
                text: yAxisTitle,
                style: {
                    color: "#fff"
                },
            },
        },

        series: [{
            data: renderData,
            name: seriesName,
            tooltip: {
                useHTML: true,
                pointFormat: '<b>{point.display}</b><br/>Amount: {point.amount}<br/>Contributors: {point.contributors}<br/>Contributions: {point.contributions}'
            }
        }],
    }

    applyDefaults(options, title, subtitle);

    return options;
}

export function buildPackedBubble(title, seriesName, yAxisTitle, chartData, plotField, isPlottingCurrency, displayField, isCES, isUCS)
{
    let renderData
    if (isCES === true)
    {
        renderData = [];
        chartData.forEach(function (nextEntry) {
            //Check for negative number, and skip. Throws map off
            //sign() = 1=positive, -1=negative, 0=zero
            if (sign(nextEntry["amount"]) === -1)
            {
                return
            }

            renderData.push({
                //Have to add the y value - GRRRR
                //https://api.highcharts.com/highcharts/series.bar.data
                y: nextEntry["amount"],
                bubbleText: nextEntry[displayField].replaceAll(" ", "<br/>").replaceAll("-", "<br/>"),
                display: nextEntry["display"],
                name: seriesName,
                value: nextEntry[plotField],
                amount: formatCurrency(nextEntry["amount"]),
                contributions: formatNumber(nextEntry["count"]),
            });
        });
    }
    else if (isUCS === true)
    {
        renderData = [];
        chartData.forEach(function (nextEntry) {
            //Check for negative number, and skip. Throws map off
            //sign() = 1=positive, -1=negative, 0=zero
            if (sign(nextEntry["amount"]) === -1)
            {
                return
            }

            renderData.push({
                //Have to add the y value - GRRRR
                //https://api.highcharts.com/highcharts/series.bar.data
                y: nextEntry["amount"],
                bubbleText: nextEntry[displayField].replaceAll(" ", "<br/>").replaceAll("-", "<br/>"),
                display: nextEntry["display"],
                name: seriesName,
                value: nextEntry[plotField],
                amount: formatCurrency(nextEntry["amount"]),
                contributions: formatNumber(nextEntry["contributions"]),
            });
        });
    }
    else
    {
        renderData = [];
        chartData.forEach(function (nextEntry) {
            //Check for negative number, and skip. Throws map off
            //sign() = 1=positive, -1=negative, 0=zero
            if (sign(nextEntry["amount"]) === -1)
            {
                return
            }

            renderData.push({
                //Have to add the y value - GRRRR
                //https://api.highcharts.com/highcharts/series.bar.data
                y: nextEntry["amount"],
                bubbleText: nextEntry[displayField].replaceAll(" ", "<br/>").replaceAll("-", "<br/>"),
                display: nextEntry["display"],
                name: seriesName,
                value: nextEntry[plotField],
                amount: formatCurrency(nextEntry["amount"]),
                contributors: formatNumber(nextEntry["contributors"]),
                contributions: formatNumber(nextEntry["contributions"]),
            });
        });
    }

    let options = {
        chart: {
            type: 'packedbubble',
            height: '100%'
        },
        legend: {
            itemStyle: {
                color: "#fff",
                fontFamily: 'Nunito'
            }
        },
        tooltip: {
            useHTML: true,
            pointFormat: '{point.name}<br/>{point.formattedAmount}'
        },
        plotOptions: {
            packedbubble: {
                // minSize: 10,
                // maxSize: 80,
                minSize: '20%',
                maxSize: '100%',
                // zMin: 0,
                // zMax: 1000,
                layoutAlgorithm: {
                    splitSeries: false,
                    gravitationalConstant: 0.02
                },
                allowOverlap: true,
                dataLabels: {
                    enabled: true,
                    align: 'center',
                    format: '{point.bubbleText}',
                    filter: {
                        property: 'y',
                        operator: '>',
                        value: 100
                    },
                    style: {
                        color: "#fff",
                        fontFamily: 'Nunito',
                        textOutline: 'none',
                        fontWeight: 'normal',
                        wordBreak: 'break-all',
                        textOverflow: 'allow',
                        fontSize: 9
                    }
                }
            }
        },
        xAxis: {},
        yAxis: {
            title: {
                text: ""
            }
        },
        series: [{
            data: renderData,
            name: seriesName,
            tooltip: {
                useHTML: true,
                pointFormat: '<b>{point.display}</b><br/>Amount: {point.amount}<br/>Contributors: {point.contributors}<br/>Contributions: {point.contributions}'
            }
        }],
    }

    return options;
}

export function buildPie(title, subtitle, seriesName, yAxisTitle, chartData, plotField, isPlottingCurrency, displayField, isCES, isUCS)
{
    let renderData
    if (isCES === true)
    {
        renderData = getRenderDataCES(chartData, seriesName, plotField)
    }
    else if (isUCS === true)
    {
        renderData = getRenderDataUCS(chartData, seriesName, plotField)
    }
    else
    {
        renderData = getRenderData(chartData, seriesName, plotField)
    }

    let options = {
        chart: {
            type: 'pie',
            plotShadow: false,
            plotBorderWidth: false,
            plotBackgroundColor: null
        },

        //NOT IT
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    alignTo: 'plotEdges',
                    connectorShape: 'fixedOffset',
                    crookDistance: '30%',
                    format: '{point.display}<br/>{point.percentage:.1f} %',
                    connectorColor: 'silver'
                }
            }
        },

        //NOT IT
        tooltip: {
            headerFormat: '',
            pointFormat: '{point.name}<br/>{point.percentage:.1f}%'
        },

        xAxis: {},
        yAxis: {
            title: {
                text: ""
            }
        },

        series: [{
            data: renderData,
            name: seriesName,
            tooltip: {
                useHTML: true,
                pointFormat: '<b>{point.display}</b><br/>Amount: {point.amount}<br/>Contributors: {point.contributors}<br/>Contributions: {point.contributions}'
            }
        }],
    }

    return options;
}

function applyDefaults(options, title, subtitle) {

    options.title = {
        align: 'left',
        text: title,
        style: {
            color: "#fff",
            fontSize: '14px',
            fontFamily: 'Nunito'
        }
    };

    options.subtitle = {
        align: 'left',
        text: subtitle,
        style: {
            color: "gray",
            fontSize: '12px',
            fontFamily: 'Nunito'
        }
    };

    options.legend = {
        enabled: false,
        itemStyle: {
            color: '#fff'
        }

    };

    options.series[0].colorByPoint = true;

    options.exporting = {
        buttons: {
            contextButton: {
                theme: {
                    fill: '#000000'
                }
            }
        },
        chartOptions: {
            chart: {
                backgroundColor: '#20273C',
                events: {
                    load: function(event) {
                        // let placeX = event.target.chartWidth - 150;
                        // let placeY = 10;
                        let placeX = 5;
                        let placeY = event.target.chartHeight - 35;
                        this.renderer.image('/images/logo.png', placeX, placeY, 132.5, 30).add();
                    }
                },
                // plotBackgroundImage: '/images/logo.png'
            },
            //Show the credits when outputing an image
            credits: {
                text: "www.monecracy.com",
                href: "https://www.monecracy.com",
                style: {
                    fontSize: '14px'
                }
            }
        },
        fallbackToExportServer: false
    };

    //Hide the credits when rendering
    options.credits = {
        text: "",
        href: "",
        style: {
            fontSize: '0px'
        }
    };
}

function getRenderData(chartData, seriesName, plotField)
{
    let renderData = [];

    chartData.forEach(function (nextEntry) {
        //Check for negative number, and skip. Throws map off
        //sign() = 1=positive, -1=negative, 0=zero
        if (sign(nextEntry["amount"]) === -1)
        {
            return
        }

        renderData.push({
            //Have to add the y value
            //https://api.highcharts.com/highcharts/series.bar.data
            y: nextEntry["amount"],
            display: nextEntry["display"],
            name: seriesName,
            value: nextEntry[plotField],
            amount: formatCurrency(nextEntry["amount"]),
            contributors: formatNumber(nextEntry["contributors"]),
            contributions: formatNumber(nextEntry["contributions"]),
        });
    });

    return renderData;
}

function getRenderDataCES(chartData, seriesName, plotField)
{
    let renderData = [];

    chartData.forEach(function (nextEntry) {
        //Check for negative number, and skip. Throws map off
        //sign() = 1=positive, -1=negative, 0=zero
        if (sign(nextEntry["amount"]) === -1)
        {
            return
        }

        renderData.push({
            y: nextEntry["amount"],
            display: nextEntry["display"],
            name: seriesName,
            value: nextEntry[plotField],
            amount: formatCurrency(nextEntry["amount"]),
            contributions: formatNumber(nextEntry["count"]),
        });
    });

    return renderData;
}

function getRenderDataUCS(chartData, seriesName, plotField)
{
    let renderData = [];

    chartData.forEach(function (nextEntry) {
        //Check for negative number, and skip. Throws map off
        //sign() = 1=positive, -1=negative, 0=zero
        if (sign(nextEntry["amount"]) === -1)
        {
            return
        }

        renderData.push({
            y: nextEntry["amount"],
            display: nextEntry["display"],
            name: seriesName,
            value: nextEntry[plotField],
            amount: formatCurrency(nextEntry["amount"]),
            contributions: formatNumber(nextEntry["contributions"]),
        });
    });

    return renderData;
}
