import {formatCurrency, formatNumber} from "../App";

export function buildICSColumns(displayField, displayTitle, displayMinWidth, displayMaxWidth)
{
    const columns = [
        { field: displayField, headerName: displayTitle, minWidth: displayMinWidth, maxWidth: displayMaxWidth, headerAlign:'left', align:'left', flex:1 },
        { field: 'contributions', headerName: 'Contributions', renderCell: (params) => formatNumber(params.row.contributions), minWidth: 120, maxWidth:150,  headerAlign:'right', align:'right', flex:1 },
        { field: 'contributors', headerName: 'Contributors', renderCell: (params) => formatNumber(params.row.contributors), minWidth: 120, maxWidth:150,  headerAlign:'right', align:'right', flex:1 },
        { field: 'amount', headerName: 'Amount', renderCell: (params) => formatCurrency(params.row.amount), minWidth: 120, maxWidth:200,  headerAlign:'right', align:'right', flex:1 },

        { field: 'indivContributions', headerName: 'Individual Contributions', renderCell: (params) => formatNumber(params.row.indivContributions),minWidth: 150, maxWidth:200,  headerAlign:'right', align:'right', flex:1 },
        { field: 'indivContributors', headerName: 'Individual Contributors', renderCell: (params) => formatNumber(params.row.indivContributors), minWidth: 150, maxWidth:200,  headerAlign:'right', align:'right', flex:1 },
        { field: 'indivAmount', headerName: 'Individual Amount', renderCell: (params) => formatCurrency(params.row.indivAmount), minWidth: 150, maxWidth:200,  headerAlign:'right', align:'right', flex:1 },

        { field: 'orgContributions', headerName: 'Organization Contributions', renderCell: (params) => formatNumber(params.row.orgContributions),minWidth: 175, maxWidth:200,  headerAlign:'right', align:'right', flex:1 },
        { field: 'orgContributors', headerName: 'Organization Contributors', renderCell: (params) => formatNumber(params.row.orgContributors), minWidth: 175, maxWidth:200,  headerAlign:'right', align:'right', flex:1 },
        { field: 'orgAmount', headerName: 'Organization Amount', renderCell: (params) => formatCurrency(params.row.orgAmount), minWidth: 150, maxWidth:200,  headerAlign:'right', align:'right', flex:1 },

        { field: 'pacContributions', headerName: 'PAC Contributions', renderCell: (params) => formatNumber(params.row.pacContributions),minWidth: 150, maxWidth:200,  headerAlign:'right', align:'right', flex:1 },
        { field: 'pacContributors', headerName: 'PAC Contributors', renderCell: (params) => formatNumber(params.row.pacContributors), minWidth: 150, maxWidth:200,  headerAlign:'right', align:'right', flex:1 },
        { field: 'pacAmount', headerName: 'PAC Amount', renderCell: (params) => formatCurrency(params.row.pacAmount), minWidth: 150, maxWidth:200,  headerAlign:'right', align:'right', flex:1 },
    ];

    return columns;
}

export function buildUCSColumns(displayMinWidth, displayMaxWidth)
{
    const columns = [
        { field: 'display', headerName: 'Contributor', minWidth: displayMinWidth, maxWidth: displayMaxWidth, headerAlign:'left',  align:'left', flex:1 },
        { field: 'amount', headerName: 'Total Amount', renderCell: (params) => formatCurrency(params.row.amount), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
        { field: 'contributions', headerName: 'Total Contributions', renderCell: (params) => formatNumber(params.row.contributions), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
        { field: 'ic_amount', headerName: 'Committee Amount', renderCell: (params) => formatCurrency(params.row.ic_amount), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
        { field: 'ic_count', headerName: 'Committee Total', renderCell: (params) => formatNumber(params.row.ic_count), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
        { field: 'ct_amount', headerName: 'Joint Committee Amount', renderCell: (params) => formatCurrency(params.row.ct_amount), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
        { field: 'ct_count', headerName: 'Joint Committee Total', renderCell: (params) => formatNumber(params.row.ct_count), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
    ];

    return columns;
}

export function buildUCSOrgColumns(displayMinWidth, displayMaxWidth)
{
    const columns = [
        { field: 'display', headerName: 'Organization', minWidth: displayMinWidth, maxWidth: displayMaxWidth, headerAlign:'left',  align:'left', flex:1 },
        { field: 'amount', headerName: 'Total Amount', renderCell: (params) => formatCurrency(params.row.amount), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
        { field: 'contributions', headerName: 'Total Contributions', renderCell: (params) => formatNumber(params.row.contributions), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
        { field: 'ic_amount', headerName: 'Committee Amount', renderCell: (params) => formatCurrency(params.row.ic_amount), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
        { field: 'ic_count', headerName: 'Committee Total', renderCell: (params) => formatNumber(params.row.ic_count), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
        { field: 'ct_amount', headerName: 'Joint Committee Amount', renderCell: (params) => formatCurrency(params.row.ct_amount), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
        { field: 'ct_count', headerName: 'Joint Committee Total', renderCell: (params) => formatNumber(params.row.ct_count), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
    ];

    return columns;
}

export function buildUCSPACColumns(displayMinWidth, displayMaxWidth)
{
    const columns = [
        { field: 'display', headerName: 'Committee', minWidth: displayMinWidth, maxWidth: displayMaxWidth, headerAlign:'left',  align:'left', flex:1 },
        { field: 'amount', headerName: 'Total Amount', renderCell: (params) => formatCurrency(params.row.amount), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
        { field: 'contributions', headerName: 'Total Contributions', renderCell: (params) => formatNumber(params.row.contributions), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
        { field: 'ic_amount', headerName: 'Committee Amount', renderCell: (params) => formatCurrency(params.row.ic_amount), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
        { field: 'ic_count', headerName: 'Committee Total', renderCell: (params) => formatNumber(params.row.ic_count), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
        { field: 'ct_amount', headerName: 'Joint Committee Amount', renderCell: (params) => formatCurrency(params.row.ct_amount), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
        { field: 'ct_count', headerName: 'Joint Committee Total', renderCell: (params) => formatNumber(params.row.ct_count), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
    ];

    return columns;
}

export function buildUCSTypeColumns(displayField, displayTitle, displayMinWidth, displayMaxWidth)
{
    const columns = [
        { field: displayField, headerName: displayTitle, minWidth: displayMinWidth, maxWidth: displayMaxWidth, headerAlign:'left', align:'left', flex:1 },
        { field: 'amount', headerName: 'Total Amount', renderCell: (params) => formatCurrency(params.row.amount), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
        { field: 'contributions', headerName: 'Total Contributions', renderCell: (params) => formatNumber(params.row.contributions), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
        { field: 'ic_amount', headerName: 'Committee Amount', renderCell: (params) => formatCurrency(params.row.ic_amount), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
        { field: 'ic_count', headerName: 'Committee Total', renderCell: (params) => formatNumber(params.row.ic_count), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
        { field: 'ct_amount', headerName: 'Joint Committee Amount', renderCell: (params) => formatCurrency(params.row.ct_amount), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
        { field: 'ct_count', headerName: 'Joint Committee Total', renderCell: (params) => formatNumber(params.row.ct_count), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
    ];

    return columns;
}

export function buildCESColumns(displayField, displayTitle, displayMinWidth, displayMaxWidth)
{
    const columns = [
        { field: displayField, headerName: displayTitle, minWidth: displayMinWidth, maxWidth: displayMaxWidth, headerAlign:'left', align:'left', flex:1 },
        { field: 'count', headerName: 'Transactions', renderCell: (params) => formatNumber(params.row.count), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
        { field: 'amount', headerName: 'Total Amount', renderCell: (params) => formatCurrency(params.row.amount), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
    ];

    return columns;
}

export function buildCTSColumns(displayField, displayTitle, displayMinWidth, displayMaxWidth)
{
    const columns = [
        { field: displayField, headerName: displayTitle, minWidth: displayMinWidth, maxWidth: displayMaxWidth, headerAlign:'left', align:'left', flex:1 },
        { field: 'count', headerName: 'Transactions', renderCell: (params) => formatNumber(params.row.count), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
        { field: 'amount', headerName: 'Total Amount', renderCell: (params) => formatCurrency(params.row.amount), minWidth: 150, maxWidth:200, headerAlign:'right', align:'right'},
    ];

    return columns;
}
