import React from "react";
import SiteHeader from "../../components/siteHeader";
import SiteFooter from "../../components/siteFooter";
import AlphaBrowser from "../../components/alphaBrowser";
import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";

const ArticleBrowser = () => {
    return (
        <div>
            <Stack paddingBottom={10}>
                <SiteHeader />
            </Stack>
            <div className="Page-content">
                <Stack direction="row" alignItems="center" justifyContent="space-between" paddingTop={1}>
                    <Breadcrumbs>
                        <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                        <Typography>Articles</Typography>
                    </Breadcrumbs>
                </Stack>
                <AlphaBrowser cycleYear={0} title="Browse by Article" typeHeader={"Articles"} type={"article"} service={"articles"} linkPath={"../article"} />
            </div>
            <SiteFooter/>
        </div>
    );
};

export default ArticleBrowser;
