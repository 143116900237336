import React from "react";
import {useNavigate} from "react-router-dom";
import {DataGrid} from "@mui/x-data-grid";
import {Stack, Typography} from "@mui/material";
import RollupTableHeader from "../rollupTableHeader";
import {buildCESColumns} from "../../util/DataGridMaker";
import {madLibFactory} from "../../AppGlobal";
import MapViewerCES from "../mapViewerCES";

const RollupCEStateList = (props) => {

    let navigate = useNavigate();

    const [sortModel, setSortModel] = React.useState([
        {
            field: 'state',
            sort: 'desc',
        },
    ]);

    const [renderData, setRenderData] = React.useState(props.stats[props.statsKey]);

    if (props.stats === undefined)
    {
        return;
    }

    const madLibs = madLibFactory.getRollupCESState();

    const renderTitle = madLibs.buildTitle(props.pageType, props.display);
    const renderSubtitle = madLibs.buildSubtitle(props.pageType, props.display);
    const renderChartTitle = madLibs.buildChartTitle(props.pageType, props.display);
    const renderCaption = madLibs.buildContent(props.pageType, props.display, props.stats, renderData);

    const chartWidth = (window.innerWidth > 1000 ? 1175: window.innerWidth - 20);
    const imageIconSrc = "/images/state_icon.png";

    const columns = buildCESColumns('state', 'State', 175, 200);

    const handleOnCellClick = (params) => {
        navigate("/state/" + params.row.stateKey);
    };

    const handleOnHeaderClick = (params) => {
        let sortDirection = sortModel[0].sort

        let sortedData
        if (sortDirection === "asc")
        {
            sortedData = renderData.slice().sort((a, b) => a[params.field] > b[params.field] ? -1 : 1);
        }
        else
        {
            sortedData = renderData.slice().sort((a, b) => a[params.field] > b[params.field] ? 1 : -1);
        }

        setRenderData(sortedData);
    };


    return (
        <Stack align="center" paddingTop={"5px"} paddingBottom={"5px"}>
            <RollupTableHeader
                title={renderTitle} subtitle={renderSubtitle}
                imgSource={imageIconSrc}
                anchor="state_expenditure_totals"
                showAllType="states" showAllTitle="Show All States" />
            <Stack direction="column" sx={{ backgroundColor: '#20273C', padding: 2}}>
            {
                renderData === undefined ?
                    (<span>No data available</span>):
                    (
                        <Stack direction="column" spacing={2}>
                            <Typography align={"left"} variant="caption">{renderCaption}</Typography>
                            <MapViewerCES
                                mapType="national-ces"
                                mapTitle={renderChartTitle}
                                mapData={renderData}
                                showPlotOptions={true}
                                width={chartWidth}
                                height={600}
                            />
                            <DataGrid
                                disableColumnMenu={true}
                                getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
                                sx={{ color:"white", flex: 1, border: 0}}
                                getRowId={(row) => row.stateKey}
                                rows={renderData}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                                hideFooterPagination={false}
                                sortModel={sortModel}
                                sortingOrder ={['asc', 'desc']}
                                onSortModelChange={(model) => setSortModel(model)}
                                autoHeight={true}
                                onRowClick={handleOnCellClick}
                                onColumnHeaderClick={handleOnHeaderClick}
                            />
                        </Stack>
                    )
            }
            </Stack>
        </Stack>
    );
};

export default RollupCEStateList;
