import React from "react";
import {useNavigate} from "react-router-dom";
import {DataGrid} from "@mui/x-data-grid";
import {Box, Stack, Typography} from "@mui/material";
import CycleTableHeader from "../cycleTableHeader";
import ToggleChart from "../toggleChart";
import {madLibFactory, typeDetails} from "../../AppGlobal";
import {buildICSColumns} from "../../util/DataGridMaker";

const ICSTypeList = (props) => {
    let navigate = useNavigate();

    const [sortModel, setSortModel] = React.useState([
        {
            field: 'amount',
            sort: 'desc',
        },
    ]);

    const [renderData, setRenderData] = React.useState(props.stats[props.statsKey]);

    if (props.stats === undefined)
    {
        return;
    }

    const madLibs = madLibFactory.getCycleICSType();

    const linkTypeDetails = typeDetails[props.linkType];
    const linkTypeDisplay = linkTypeDetails.display;

    const renderTitle = madLibs.buildTitle(props.cycleYear, props.pageType, props.linkType, props.statsTop, props.display);
    const renderSubtitle = madLibs.buildSubtitle(props.cycleYear, props.pageType, props.linkType, props.statsTop, props.display);
    const renderChartTitle = madLibs.buildChartTitle(props.cycleYear, props.pageType, props.linkType, props.statsTop, props.display);
    const renderCaption = madLibs.buildContent(props.cycleYear, props.pageType, props.linkType, props.statsTop, props.display, props.stats, renderData);
    const renderChartSeries = linkTypeDetails.display;

    // const renderWidth = (window.innerWidth > 1000 ? 1175: window.innerWidth - 20);
    // const renderHeight = (props.statsTop ? 625 : (renderData === undefined ? 0: renderData.length * 30))
    const renderWidth = madLibs.calculateRenderWidth(20);
    const renderHeight = madLibs.calculateRenderHeight(renderData, props.statsTop, 30);

    const imageIconSrc = linkTypeDetails.iconPath;
    let showAllType = "";
    let showAllTitle = "";
    if (linkTypeDetails.hasBrowser === true)
    {
        showAllType = linkTypeDetails.linkPrefix;
        showAllTitle = "Show All " + linkTypeDetails.displayPlural;
    }

    const columns = buildICSColumns('display', linkTypeDisplay, 150, 200);

    const handleOnCellClick = (params) => {
        navigate("/cycle/" + props.cycleYear + "/" + props.linkType + "/" + params.row.typeKey);
    };

    const handleOnHeaderClick = (params) => {
        let sortDirection = sortModel[0].sort

        let sortedData
        if (sortDirection === "asc")
        {
            sortedData = renderData.slice().sort((a, b) => a[params.field] > b[params.field] ? -1 : 1);
        }
        else
        {
            sortedData = renderData.slice().sort((a, b) => a[params.field] > b[params.field] ? 1 : -1);
        }

        setRenderData(sortedData);
    };

    return (
        <Stack align="center" paddingTop={"5px"} paddingBottom={"5px"}>
            <CycleTableHeader
                title={renderTitle}
                subtitle={renderSubtitle}
                imgSource={imageIconSrc}
                anchor={props.anchor}
                cycleYear={props.cycleYear}
                showAllType={showAllType}
                showAllTitle={showAllTitle}
            />
            <Stack direction="column" sx={{ backgroundColor: '#20273C'}} spacing={2} padding={2}>
            {
                renderData === undefined ?
                    (<span>No data available</span>):
                    (
                        <Stack direction="column" spacing={2}>
                            <Typography align={"left"} variant="caption">{renderCaption}</Typography>
                            <ToggleChart width={renderWidth} height={renderHeight}
                                         title={renderChartTitle}
                                         seriesName={renderChartSeries}
                                         chartData={renderData}
                                         plotField="amount"
                                         displayField="display"
                            />
                            <Box>
                                <DataGrid
                                    disableColumnMenu={true}
                                    getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
                                    sx={{ color:"white", flex: 1, border: 0}}
                                    getRowId={(row) => row.display}
                                    rows={renderData}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    hideFooterPagination={false}
                                    sortModel={sortModel}
                                    sortingOrder ={['asc', 'desc']}
                                    onSortModelChange={(model) => setSortModel(model)}
                                    autoHeight={true}
                                    onRowClick={handleOnCellClick}
                                    onColumnHeaderClick={handleOnHeaderClick}
                                />
                            </Box>
                        </Stack>
                    )
            }
            </Stack>
        </Stack>
    );
};

export default ICSTypeList;
