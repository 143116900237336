import MLRollupICSSummary from "./rollup/ics/MLRollupICSSummary";
import MLRollupCTSTotal from "./rollup/cts/MLRollupCTSTotal";
import MLRollupICSState from "./rollup/ics/MLRollupICSState";
import MLRollupICSCounty from "./rollup/ics/MLRollupICSCounty";
import MLRollupICSCity from "./rollup/ics/MLRollupICSCity";
import MLRollupICSZipCode from "./rollup/ics/MLRollupICSZipCode";
import MLRollupICSStateCity from "./rollup/ics/MLRollupICSStateCity";
import MLRollupICSStateCounty from "./rollup/ics/MLRollupICSStateCounty";
import MLRollupICSStateZipcode from "./rollup/ics/MLRollupICSStateZipcode";
import MLRollupUCS from "./rollup/ucs/MLRollupUCS";
import MLRollupUCSCycle from "./rollup/ucs/MLRollupUCSCycle";
import MLRollupUCSType from "./rollup/ucs/MLRollupUCSType";
import MLRollupCESSummary from "./rollup/ces/MLRollupCESSummary";
import MLRollupCESType from "./rollup/ces/MLRollupCESType";
import MLRollupCESZipcode from "./rollup/ces/MLRollupCESZipcode";
import MLRollupCESCity from "./rollup/ces/MLRollupCESCity";
import MLRollupCESCounty from "./rollup/ces/MLRollupCESCounty";
import MLRollupCESState from "./rollup/ces/MLRollupCESState";
import MLRollupCESCycle from "./rollup/ces/MLRollupCESCycle";
import MLRollupCTSType from "./rollup/cts/MLRollupCTSType";
import MLRollupICSCycle from "./rollup/ics/MLRollupICSCycle";
import MLRollupICSType from "./rollup/ics/MLRollupICSType";
import MLCycleICSSummary from "./cycle/ics/MLCycleICSSummary";
import MLCycleICSState from "./cycle/ics/MLCycleICSState";
import MLCycleICSCounty from "./cycle/ics/MLCycleICSCounty";
import MLCycleICSCity from "./cycle/ics/MLCycleICSCity";
import MLCycleICSZipcode from "./cycle/ics/MLCycleICSZipcode";
import MLCycleICSType from "./cycle/ics/MLCycleICSType";
import MLCycleUCS from "./cycle/ucs/MLCycleUCS";
import MLCycleICSStateCounty from "./cycle/ics/MLCycleICSStateCounty";
import MLCycleICSStateCity from "./cycle/ics/MLCycleICSStateCity";
import MLCycleICSStateZipcode from "./cycle/ics/MLCycleICSStateZipcode";
import MLCycleCESSummary from "./cycle/ces/MLCycleCESSummary";
import MLCycleCESState from "./cycle/ces/MLCycleCESState";
import MLCycleCESCounty from "./cycle/ces/MLCycleCESCounty";
import MLCycleCESCity from "./cycle/ces/MLCycleCESCity";
import MLCycleCESZipcode from "./cycle/ces/MLCycleCESZipcode";
import MLCycleCESType from "./cycle/ces/MLCycleCESType";
import MLRollupUCSTotal from "./rollup/ucs/MLRollupUCSTotal";
import MLCycleUCSTotal from "./cycle/ucs/MLCycleUCSTotal";
import MLCycleUCSType from "./cycle/ucs/MLCycleUCSType";
import MLCycleCTSTotal from "./cycle/cts/MLCycleCTSTotal";
import MLCycleCTSType from "./cycle/cts/MLCycleCTSType";
import MLPageMetaRollup from "./rollup/MLPageMetaRollup";
import MLPageMetaCycle from "./cycle/MLPageMetaCycle";
import MLRollupUCSOrg from "./rollup/ucs/MLRollupUCSOrg";
import MLRollupUCSPAC from "./rollup/ucs/MLRollupUCSPAC";
import MLCycleUCSOrg from "./cycle/ucs/MLCycleUCSOrg";
import MLCycleUCSPAC from "./cycle/ucs/MLCycleUCSPAC";

export default class MadLibFactory
{
    constructor()
    {
    }

    /************************************************************
     *********** Rollup Page Meta *******************************
     ***********************************************************/
    getPageMetaRollup()
    {
        if (this.pageMetaRollup === undefined)
        {
            this.pageMetaRollup = new MLPageMetaRollup();
        }
        return this.pageMetaRollup;
    }

    getPageMetaCycle()
    {
        if (this.pageMetaCycle === undefined)
        {
            this.pageMetaCycle = new MLPageMetaCycle();
        }
        return this.pageMetaCycle;
    }

    /************************************************************
     *********** Cycle ICS *************************************
     ***********************************************************/
    getCycleICSSummary()
    {
        if (this.cycleICSSummary === undefined)
        {
            this.cycleICSSummary = new MLCycleICSSummary();
        }
        return this.cycleICSSummary;
    }

    getCycleICSState()
    {
        if (this.cycleICSState === undefined)
        {
            this.cycleICSState = new MLCycleICSState();
        }
        return this.cycleICSState;
    }

    getCycleICSStateCounty()
    {
        if (this.cycleICSStateCounty === undefined)
        {
            this.cycleICSStateCounty = new MLCycleICSStateCounty();
        }
        return this.cycleICSStateCounty;
    }

    getCycleICSStateCity()
    {
        if (this.cycleICSStateCity === undefined)
        {
            this.cycleICSStateCity = new MLCycleICSStateCity();
        }
        return this.cycleICSStateCity;
    }

    getCycleICSStateZipcode()
    {
        if (this.cycleICSStateZipcode === undefined)
        {
            this.cycleICSStateZipcode = new MLCycleICSStateZipcode();
        }
        return this.cycleICSStateZipcode;
    }

    getCycleICSCounty()
    {
        if (this.cycleICSCounty === undefined)
        {
            this.cycleICSCounty = new MLCycleICSCounty();
        }
        return this.cycleICSCounty;
    }

    getCycleICSCity()
    {
        if (this.cycleICSCity === undefined)
        {
            this.cycleICSCity = new MLCycleICSCity();
        }
        return this.cycleICSCity;
    }

    getCycleICSZipcode()
    {
        if (this.cycleICSZipcode === undefined)
        {
            this.cycleICSZipcode = new MLCycleICSZipcode();
        }
        return this.cycleICSZipcode;
    }

    getCycleICSType()
    {
        if (this.cycleICSType === undefined)
        {
            this.cycleICSType = new MLCycleICSType();
        }
        return this.cycleICSType;
    }

    /************************************************************
     *********** Rollup ICS *************************************
     ***********************************************************/
    getRollupICSSummary()
    {
        if (this.rollupICSSummary === undefined)
        {
            this.rollupICSSummary = new MLRollupICSSummary();
        }
        return this.rollupICSSummary;
    }

    getRollupICSCycle()
    {
        if (this.rollupICSCycle === undefined)
        {
            this.rollupICSCycle = new MLRollupICSCycle();
        }
        return this.rollupICSCycle;
    }

    getRollupICSState()
    {
        if (this.rollupICSState === undefined)
        {
            this.rollupICSState = new MLRollupICSState();
        }
        return this.rollupICSState;
    }

    getRollupICSCounty()
    {
        if (this.rollupICSCounty === undefined)
        {
            this.rollupICSCounty = new MLRollupICSCounty();
        }
        return this.rollupICSCounty;
    }

    getRollupICSCity()
    {
        if (this.rollupICSCity === undefined)
        {
            this.rollupICSCity = new MLRollupICSCity();
        }
        return this.rollupICSCity;
    }

    getRollupICSZipcode()
    {
        if (this.rollupICSZipcode === undefined)
        {
            this.rollupICSZipcode = new MLRollupICSZipCode();
        }
        return this.rollupICSZipcode;
    }

    getRollupICSStateCounty()
    {
        if (this.rollupICSStateCounty === undefined)
        {
            this.rollupICSStateCounty = new MLRollupICSStateCounty();
        }
        return this.rollupICSStateCounty;
    }

    getRollupICSStateCity()
    {
        if (this.rollupICSStateCity === undefined)
        {
            this.rollupICSStateCity = new MLRollupICSStateCity();
        }
        return this.rollupICSStateCity;
    }

    getRollupICSStateZipcode()
    {
        if (this.rollupICSStateZipcode === undefined)
        {
            this.rollupICSStateZipcode = new MLRollupICSStateZipcode();
        }
        return this.rollupICSStateZipcode;
    }

    getRollupICSType()
    {
        if (this.rollupICSType === undefined)
        {
            this.rollupICSType = new MLRollupICSType();
        }
        return this.rollupICSType;
    }

    /************************************************************
     *********** Cycle UCS *************************************
     ***********************************************************/
    getCycleUCS()
    {
        if (this.cycleUCS === undefined)
        {
            this.cycleUCS = new MLCycleUCS();
        }
        return this.cycleUCS;
    }

    getCycleUCSOrg()
    {
        if (this.cycleUCSOrg === undefined)
        {
            this.cycleUCSOrg = new MLCycleUCSOrg();
        }
        return this.cycleUCSOrg;
    }

    getCycleUCSPAC()
    {
        if (this.cycleUCSPAC === undefined)
        {
            this.cycleUCSPAC = new MLCycleUCSPAC();
        }
        return this.cycleUCSPAC;
    }

    getCycleUCSTotal()
    {
        if (this.cycleUCSTotal === undefined)
        {
            this.cycleUCSTotal = new MLCycleUCSTotal();
        }
        return this.cycleUCSTotal;
    }

    getCycleUCSType()
    {
        if (this.cycleUCSType === undefined)
        {
            this.cycleUCSType = new MLCycleUCSType();
        }
        return this.cycleUCSType;
    }

    /************************************************************
     *********** Rollup UCS *************************************
     ***********************************************************/
    getRollupUCS()
    {
        if (this.rollupUCS === undefined)
        {
            this.rollupUCS = new MLRollupUCS();
        }
        return this.rollupUCS;
    }

    getRollupUCSOrg()
    {
        if (this.rollupUCSOrg === undefined)
        {
            this.rollupUCSOrg = new MLRollupUCSOrg();
        }
        return this.rollupUCSOrg;
    }

    getRollupUCSPAC()
    {
        if (this.rollupUCSPAC === undefined)
        {
            this.rollupUCSPAC = new MLRollupUCSPAC();
        }
        return this.rollupUCSPAC;
    }

    getRollupUCSTotal()
    {
        if (this.rollupUCSTotal === undefined)
        {
            this.rollupUCSTotal = new MLRollupUCSTotal();
        }
        return this.rollupUCSTotal;
    }

    getRollupUCSCycle()
    {
        if (this.rollupUCSCycle === undefined)
        {
            this.rollupUCSCycle = new MLRollupUCSCycle();
        }
        return this.rollupUCSCycle;
    }

    getRollupUCSType()
    {
        if (this.rollupUCSType === undefined)
        {
            this.rollupUCSType = new MLRollupUCSType();
        }
        return this.rollupUCSType;
    }

    /************************************************************
     *********** Cycle CES *************************************
     ***********************************************************/
    getCycleCESSummary()
    {
        if (this.cycleCESSummary === undefined)
        {
            this.cycleCESSummary = new MLCycleCESSummary();
        }
        return this.cycleCESSummary;
    }

    getCycleCESState()
    {
        if (this.cycleCESState === undefined)
        {
            this.cycleCESState = new MLCycleCESState();
        }
        return this.cycleCESState;
    }

    getCycleCESCounty()
    {
        if (this.cycleCESCounty === undefined)
        {
            this.cycleCESCounty = new MLCycleCESCounty();
        }
        return this.cycleCESCounty;
    }

    getCycleCESCity()
    {
        if (this.cycleCESCity === undefined)
        {
            this.cycleCESCity = new MLCycleCESCity();
        }
        return this.cycleCESCity;
    }

    getCycleCESZipcode()
    {
        if (this.cycleCESZipcode === undefined)
        {
            this.cycleCESZipcode = new MLCycleCESZipcode();
        }
        return this.cycleCESZipcode;
    }

    getCycleCESType()
    {
        if (this.cycleCESType === undefined)
        {
            this.cycleCESType = new MLCycleCESType();
        }
        return this.cycleCESType;
    }

    /************************************************************
     *********** Rollup CES *************************************
     ***********************************************************/
    getRollupCESSummary()
    {
        if (this.rollupCESSummary === undefined)
        {
            this.rollupCESSummary = new MLRollupCESSummary();
        }
        return this.rollupCESSummary;
    }

    getRollupCESCycle()
    {
        if (this.rollupCESCycle === undefined)
        {
            this.rollupCESCycle = new MLRollupCESCycle();
        }
        return this.rollupCESCycle;
    }

    getRollupCESState()
    {
        if (this.rollupCESState === undefined)
        {
            this.rollupCESState = new MLRollupCESState();
        }
        return this.rollupCESState;
    }

    getRollupCESCounty()
    {
        if (this.rollupCESCounty === undefined)
        {
            this.rollupCESCounty = new MLRollupCESCounty();
        }
        return this.rollupCESCounty;
    }

    getRollupCESCity()
    {
        if (this.rollupCESCity === undefined)
        {
            this.rollupCESCity = new MLRollupCESCity();
        }
        return this.rollupCESCity;
    }

    getRollupCESZipcode()
    {
        if (this.rollupCESZipcode === undefined)
        {
            this.rollupCESZipcode = new MLRollupCESZipcode();
        }
        return this.rollupCESZipcode;
    }

    getRollupCESType()
    {
        if (this.rollupCESType === undefined)
        {
            this.rollupCESType = new MLRollupCESType();
        }
        return this.rollupCESType;
    }

    /************************************************************
     *********** Cycle CTS *************************************
     ***********************************************************/
    getCycleCTSTotal()
    {
        if (this.cycleCTSTotal === undefined)
        {
            this.cycleCTSTotal = new MLCycleCTSTotal();
        }
        return this.cycleCTSTotal;
    }

    getCycleCTSType()
    {
        if (this.cycleCTSType === undefined)
        {
            this.cycleCTSType = new MLCycleCTSType();
        }
        return this.cycleCTSType;
    }

    /************************************************************
     *********** Rollup CTS *************************************
     ***********************************************************/
    getRollupCTSTotal()
    {
        if (this.rollupCTSTotal === undefined)
        {
            this.rollupCTSTotal = new MLRollupCTSTotal();
        }
        return this.rollupCTSTotal;
    }

    getRollupCTSType()
    {
        if (this.rollupCTSType === undefined)
        {
            this.rollupCTSType = new MLRollupCTSType();
        }
        return this.rollupCTSType;
    }
}
