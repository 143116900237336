import AMadLib from "../../AMadLib";

export default class MLRollupCESState extends AMadLib
{
    constructor()
    {
        super();
    }

    buildTitle(pageType, display)
    {
        let title = "Total Committee Expenditures by State";

        return title
    }

    buildSubtitle(pageType, display)
    {
        let subtitle = "The total committee expenditures by state for all elections since 2008";

        return subtitle
    }

    buildChartTitle(pageType, display)
    {
        return this.buildTitle(pageType, display);
    }

    buildContent(pageType, display, stats, data)
    {
        let totalAmount = 0;
        let topAmount = 0;
        let topAmountDisplay = 0;

        if (data !== undefined)
        {
            for (const nextState of data)
            {
                totalAmount = totalAmount + nextState.amount;

                //Top State by Total Amount
                if (nextState.amount > topAmount)
                {
                    topAmount = nextState.amount;
                    topAmountDisplay = nextState.state;
                }
            }
        }

        let content;

        if (pageType === "summary")
        {
            content = "The total amount of money spent by committees on expenditures in all states since 2008 is $"
        }
        else if (pageType === "party")
        {
            content = "The total amount of money spent by committees on expenditures that support the " + display + " from all states since 2008 is $"
        }
        else if (pageType === "politician")
        {
            content = "The total amount of money spent by committees on expenditures that support the politician " + display + " for federal elections from all states since 2008 is $"
        }
        else if (pageType === "office")
        {
            content = "The total amount of money spent by committees on expenditures that support the office " + display + " for federal elections from all states since 2008 is $"
        }
        else if (pageType === "committee")
        {
            content = "The total amount of money spent by committees on expenditures that support the committee " + display + " for federal elections from all states since 2008 is $"
        }
        else if (pageType === "organization")
        {
            content = "The total amount of money spent by committees on expenditures that support the organization " + display + " for federal elections from all states since 2008 is $"
        }

        content += totalAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ".";
        content += "With " + topAmountDisplay + " receiving the most from expenditures. ";

        return content;
    }

}
