import {Accordion, AccordionSummary, Button, Stack, Typography} from "@mui/material";
import React, {useMemo} from "react";
import TextField from "@mui/material/TextField";

const CardViewer = ({dataset, renderer}) => {
    const [cardsToRender, setCardsToRender] = React.useState([]);
    const [expandAccordion, setExpandAccordion] = React.useState(true);
    const [expandIcon, setExpandIcon] = React.useState("images/retract_icon.png");
    const [hideAccordion, setHideAccordion] = React.useState(false);

    if (window.innerWidth < 1200)
    {
        // setIsMobile(true)
    }

    //This object carSet is now used to set the entries back to normal if the user has filtered the results and then gone back to a blank TextField.
    //Weird results with filter if backup does not exist to reset entries. Before if cleared, not all original results would exist.
    const cardSet = useMemo(() => {
        const cards = [];

        let pos = 0;
        for (const nextEntry of dataset.entries)
        {
            pos++;
            const nextKey = dataset.key + "_" + pos;
            const nextCard = renderer(nextKey, nextEntry);
            cards.push(nextCard);
        }

        //Sort cards alphabetically
        sortEntries(cards)

        setCardsToRender(cards)

        return cards;
    }, [dataset]);

    function filterEntries(searchInput)
    {
        //Check for blank search input and reset entries back to original cardSet
        if (searchInput === "")
        {
            //Associated line from comment at top
            setCardsToRender(cardSet)

            return;
        }

        //Filter the results from searchInput
        var filteredResults = dataset.entries.filter(entry => entry.title.toLowerCase().includes(searchInput.toLowerCase()))

        //Create new cardSet with filtered results
        const filteredCards = [];
        let pos = 0;
        for (const nextEntry of filteredResults)
        {
            pos++;
            const nextKey = dataset.key + "_" + pos;
            const nextCard = renderer(nextKey, nextEntry);
            filteredCards.push(nextCard);
        }

        //Sort filtered results before render
        sortEntries(filteredCards)

        //Set cardsToRenderVariable to trigger age rerender
        setCardsToRender(filteredCards)
    }

    function sortEntries(entries)
    {
        //Sort cards alphabetically
        entries.sort(function (a, b)
        {
            if (a.props.title < b.props.title)
            {
                return -1;
            }

            if (a.props.title > b.props.title)
            {
                return 1;
            }

            return 0;
        });

        return entries;
    }

    function expand(){
        setExpandAccordion(!expandAccordion)

        if (expandAccordion === true)
        {
            setExpandIcon("images/expand_icon.png")
            setHideAccordion(true)
        }
        else
        {
            setExpandIcon("images/retract_icon.png")
            setHideAccordion(false)
        }
    }

    return (
        <Stack
            padding={1} spacing={1}
            sx={{backgroundColor: '#20273C', borderRadius: 2}}>
            <Stack direction={window.innerWidth < 1200 ? ("column"):("row")} alignItems={window.innerWidth < 1200 ? ("left"):("center")} justifyContent={"space-between"} spacing={2}>
                <Stack direction="column" justifyContent="center">
                    <Typography variant="h5" align="left" noWrap={true}>{dataset.title}</Typography>
                    <Typography variant="subtitle2" align="left" noWrap={true}>{dataset.subtitle}</Typography>
                </Stack>

                {/*If expanded is true then show the textfield, if false then hide it*/}
                <Stack direction={"row"} spacing={1}>
                    <TextField sx={expandAccordion ? ({display:""}):({display:"none"})} label={"Filter"} size={"small"} onChange={event => filterEntries(event.target.value)}></TextField>
                    <Button><img alt={"Expand icon"} height={32} width={32} src={expandIcon} onClick={expand} /></Button>
                </Stack>
            </Stack>

            <Accordion hidden={hideAccordion} expanded={expandAccordion}>
                {/*AccordionSummary - If this is not included the nice smooth open animation is removed, so I kept it in and just hid it. "hidden" attribute does not work.*/}
                <AccordionSummary sx={{display:"none"}}></AccordionSummary>
                <Stack
                    spacing={1}
                    direction={"row"}
                    flexWrap={"wrap"}
                    sx={{backgroundColor:"#20273C"}}
                    useFlexGap={true} >{cardsToRender}
                </Stack>
            </Accordion>
        </Stack>
    )
};

export default CardViewer;
