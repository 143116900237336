import React from "react";
import {useNavigate} from "react-router-dom";
import {DataGrid} from "@mui/x-data-grid";
import {Box, Stack, Typography} from "@mui/material";
import CycleTableHeader from "../cycleTableHeader";
import MapViewer from "../mapViewer";
import {madLibFactory} from "../../AppGlobal";
import {buildICSColumns} from "../../util/DataGridMaker";

const ICSCityList = (props) => {
    let navigate = useNavigate();

    const [sortModel, setSortModel] = React.useState([
        {
            field: 'amount',
            sort: 'desc',
        },
    ]);

    if (props.stats === undefined)
    {
        return;
    }

    const renderData = props.stats[props.statsKey];

    const madLibs = madLibFactory.getCycleICSCity();

    const renderTitle = madLibs.buildTitle(props.cycleYear, props.pageType, props.display);
    const renderSubtitle = madLibs.buildSubtitle(props.cycleYear, props.pageType, props.display);
    const renderMapTitle = madLibs.buildMapTitle(props.cycleYear, props.pageType, props.display);
    const renderCaption = madLibs.buildContent(props.cycleYear, props.pageType, props.display, props.stats, renderData);

    const renderWidth = (window.innerWidth > 1000 ? 1175: window.innerWidth - 20);
    const imageIconSrc = "/images/city_icon.png"

    const columns = buildICSColumns('city', 'City', 150, 200);

    const handleOnCellClick = (params) => {
        navigate("/cycle/" + props.cycleYear + "/state/" + params.row.stateKey + "/city/" + params.row.cityKey);
    };

    return (
        <Stack paddingTop={"5px"} paddingBottom={"5px"}>
            <CycleTableHeader
                imgSource={imageIconSrc}
                title={renderTitle}
                subtitle={renderSubtitle}
                cycleYear={props.cycleYear}
                anchor="city_totals"
                showAllType="cities"
                showAllTitle="Show All Cities"
            />
            <Stack direction="column" sx={{ backgroundColor: '#20273C'}} spacing={2} padding={2}>
            {
                renderData === undefined ?
                    (<span>No data available</span>):
                    (
                        <Stack direction="column" spacing={2}>
                            <Typography align={"left"} variant="caption">{renderCaption}</Typography>
                            <MapViewer
                                mapType="national-markers"
                                mapTitle={renderMapTitle}
                                mapData={renderData}
                                width={renderWidth}
                                height={625}
                                linkType="city"
                                linkField="cityKey"
                            />
                            <Box>
                                <DataGrid
                                    disableColumnMenu={true}
                                    getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
                                    sx={{ color:"white", flex: 1, border: 0}}
                                    getRowId={(row) => row.cityKey}
                                    rows={renderData}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    hideFooterPagination={false}
                                    sortModel={sortModel}
                                    sortingOrder ={['asc', 'desc']}
                                    onSortModelChange={(model) => setSortModel(model)}
                                    autoHeight={true}
                                    onRowClick={handleOnCellClick}
                                />
                            </Box>
                        </Stack>
                    )
            }
            </Stack>
        </Stack>
    );
};

export default ICSCityList;
