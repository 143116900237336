import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import SiteHeader from "../../components/siteHeader";
import SiteFooter from "../../components/siteFooter";
import ArticleReference from "../../components/article/articleReference";
import ArticleAssociation from "../../components/article/articleAssociation";
import ArticleSection from "../../components/article/articleSection";
import ListTag from "../../components/tag/listTag";
import * as DataLoader from "../../util/DataLoader";
import {Stack} from "@mui/material";

const ArticleViewer = () => {
    const {articleKey} = useParams();
    const [success, setSuccess] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [stats, setStats] = React.useState([]);

    useEffect(() => {
        const urlSuffix = "article/" + articleKey;
        DataLoader.load(urlSuffix, (result, data) => {
            if (result === true)
            {
                setLoaded(true)
                setSuccess(true)
                setStats(data.statistics);
            }
            else
            {
                setSuccess(false);
            }
        })
    }, [articleKey])

    // let AWS = require('aws-sdk');
    // AWS.config.update(
    //     {
    //         accessKeyId: ".. your key ..",
    //         secretAccessKey: ".. your secret key ..",
    //     }
    // );
    // var s3 = new AWS.S3();
    // s3.getObject(
    //     { Bucket: "my-bucket", Key: "my-picture.jpg" },
    //     function (error, data) {
    //         if (error != null) {
    //             alert("Failed to retrieve an object: " + error);
    //         } else {
    //             alert("Loaded " + data.ContentLength + " bytes");
    //             // do something with data.Body
    //         }
    //     }
    // );

    return (
        <div>
            <Stack paddingBottom={10}>
                <SiteHeader />
            </Stack>
            {
                loaded === false ?
                    (<h2>Loading data....</h2>) :
                    (<div className="Page-content">
                        {
                            success === false ?
                                (<h2>Unable to find the Article for {articleKey}</h2>) :
                                (<React.Fragment>
                                    <h1>Article Summary</h1>
                                    <p>Article: {stats.title}</p>
                                    <p>Subtitle: {stats.subtitle}</p>
                                    <p>Description: {stats.description}</p>
                                    <p>Version: {stats.version}</p>
                                    <p>Created At: {stats.createdAt}</p>
                                    <p>Modified At: {stats.modifiedAt}</p>
                                    <p>Published Version: {stats.publishedVersion}</p>
                                    <p>Published At: {stats.publishedAt}</p>
                                    <div dangerouslySetInnerHTML={{ __html: stats.article }} />
                                    <ArticleReference data={stats.references}></ArticleReference>
                                    <ArticleAssociation data={stats.associations}></ArticleAssociation>
                                    <ArticleSection data={stats.sections}></ArticleSection>
                                    <ListTag data={stats.tags}></ListTag>
                                </React.Fragment>)
                        }
                    </div>)
            }
            <SiteFooter/>
        </div>
    );
};

export default ArticleViewer;
