import {useNavigate} from "react-router-dom";
import React from "react";
import {madLibFactory, typeDetails} from "../../AppGlobal";
import {buildUCSOrgColumns} from "../../util/DataGridMaker";
import {Stack, Typography} from "@mui/material";
import RollupTableHeader from "../rollupTableHeader";
import ToggleChart from "../toggleChart";
import {DataGrid} from "@mui/x-data-grid";

const UCSListPAC = (props) => {
    let navigate = useNavigate();

    const [sortModel, setSortModel] = React.useState([
        {
            field: 'amount',
            sort: 'desc',
        },
    ]);

    if (props.stats === undefined)
    {
        return;
    }

    const renderData = props.stats[props.statsKey];
    if (renderData === undefined)
    {
        return;
    }

    const madLibs = madLibFactory.getCycleUCSPAC();

    const renderTitle = madLibs.buildTitle(props.cycleYear, props.pageType, props.display);
    const renderSubtitle = madLibs.buildSubtitle(props.cycleYear, props.pageType, props.display);
    const renderCaption = madLibs.buildContent(props.cycleYear, props.pageType, props.display, props.stats, renderData);
    const renderWidth = madLibs.calculateRenderWidth(20);
    const renderHeight = madLibs.calculateRenderHeight(renderData, props.statsTop, 30);
    const linkTypeDetails = typeDetails[props.linkType];
    const renderChartSeries = linkTypeDetails.display;

    const imageIconSrc = "/images/contributor_icon.png";

    const columns = buildUCSOrgColumns(250, 300);

    const handleOnCellClick = (params) => {
        navigate("/cycle/" + props.cycleYear + "/organization/" + params.row.typeKey);
    };

    return(
        <Stack align="center" paddingTop={"5px"} paddingBottom={"5px"}>
            <RollupTableHeader
                title={renderTitle}
                subtitle={renderSubtitle}
                imgSource={imageIconSrc}
                anchor="contributor_pac_totals"
                showAllType="organizations" showAllTitle="Show All Organizations" />
            <Stack direction="column" sx={{ backgroundColor: '#20273C', padding: 2}}>
                {
                    renderData === undefined ?
                        (<span>No data available</span>):
                        (
                            <Stack>
                                <Typography align={"left"} variant="caption">{renderCaption}</Typography>
                                <ToggleChart width={renderWidth} height={renderHeight}
                                             title={"Top Organizations"}
                                             seriesName={renderChartSeries}
                                             chartData={renderData}
                                             plotField="amount"
                                             displayField="display"
                                             isUCS={true}
                                />
                                <DataGrid
                                    disableColumnMenu={true}
                                    getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
                                    sx={{ color:"white", border: 0}}
                                    getRowId={(row) => row.typeKey}
                                    rows={renderData}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    hideFooterPagination={false}
                                    sortModel={sortModel}
                                    onSortModelChange={(model) => setSortModel(model)}
                                    autoHeight={true}
                                    onRowClick={handleOnCellClick}
                                />
                            </Stack>
                        )
                }
            </Stack>
        </Stack>
    );
};

export default UCSListPAC
