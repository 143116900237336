import AMadLib from "../../AMadLib";

export default class MLRollupICSStateCounty extends AMadLib
{
    constructor()
    {
        super();
    }

    buildTitle(pageType, display)
    {
        let title = "Total Contributions for All Counties in " + display;

        return title
    }

    buildSubtitle(pageType, display)
    {
        let subtitle = "The total contributions from individuals, organizations, and political action committees (PAC) for all counties in ";
        subtitle += display + " for all elections since 2008";

        return subtitle
    }

    buildMapTitle(pageType, display)
    {
        return this.buildTitle(pageType, display);
    }

    buildContent(pageType, display, stats, data)
    {
        let totalAmount = 0;
        let totalIndivAmount = 0;
        let totalOrgAmount = 0;
        let totalPACAmount = 0;
        let topAmount = 0;
        let topAmountDisplay = 0;

        if (data !== undefined)
        {
            for (const nextCounty of data)
            {
                totalAmount = totalAmount + nextCounty.amount;
                totalIndivAmount = totalIndivAmount + nextCounty.indivAmount;
                totalOrgAmount = totalOrgAmount + nextCounty.orgAmount;
                totalPACAmount = totalPACAmount + nextCounty.pacAmount;

                //Top State by Total Amount
                if (nextCounty.amount > topAmount)
                {
                    topAmount = nextCounty.amount;
                    topAmountDisplay = nextCounty.county;
                }
            }
        }

        let content = "The total amount of money donated to federal elections from all " + display + " counties since 2008 is $"

        content += totalAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ".";
        content += "Total amount from individual contributors is $" + totalIndivAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "Total amount from organization contributions is $" + totalOrgAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "Total amount from political actions committees contributions is $" + totalPACAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "With " + topAmountDisplay + " being the highest contributing county by total amount. ";

        return content;
    }

}
