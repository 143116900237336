import React from "react";
import {useNavigate} from "react-router-dom";
import {Stack, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import CycleTableHeader from "../cycleTableHeader";
import ToggleChart from "../toggleChart";
import {madLibFactory, typeDetails} from "../../AppGlobal";
import {buildCESColumns} from "../../util/DataGridMaker";

const CETypeList = (props) => {
    let navigate = useNavigate();

    const [sortModel, setSortModel] = React.useState([
        {
            field: 'amount',
            sort: 'desc',
        },
    ]);

    const [renderData, setRenderData] = React.useState(props.stats[props.statsKey]);

    if (props.stats === undefined)
    {
        return;
    }

    const linkTypeDetails = typeDetails[props.linkType];

    const madLibs = madLibFactory.getCycleCESType();

    const renderTitle = madLibs.buildTitle(props.cycleYear, props.pageType, props.linkType, props.display);
    const renderSubtitle = madLibs.buildSubtitle(props.cycleYear, props.pageType, props.linkType, props.display);
    const renderChartTitle = madLibs.buildChartTitle(props.cycleYear, props.pageType, props.linkType, props.display);
    const renderCaption = madLibs.buildContent(props.cycleYear, props.pageType, props.linkType, props.display, props.stats, renderData);

    const chartWidth = (window.innerWidth > 1000 ? 1175: window.innerWidth - 20);
    const imageIconSrc = linkTypeDetails.iconPath;

    let showAllType = "";
    let showAllTitle = "";
    if (linkTypeDetails.hasBrowser === true)
    {
        showAllType = linkTypeDetails.linkPrefix;
        showAllTitle = "Show All " + linkTypeDetails.displayPlural;
    }

    const columns = buildCESColumns('display', linkTypeDetails.display, 300, 500);

    const handleOnCellClick = (params) => {
        navigate("/cycle/" + props.cycleYear + "/" + props.linkType + "/" + params.row.typeKey);
    };

    const handleOnHeaderClick = (params) => {
        let sortDirection = sortModel[0].sort

        let sortedData
        if (sortDirection === "asc")
        {
            sortedData = renderData.slice().sort((a, b) => a[params.field] > b[params.field] ? -1 : 1);
        }
        else
        {
            sortedData = renderData.slice().sort((a, b) => a[params.field] > b[params.field] ? 1 : -1);
        }

        setRenderData(sortedData);
    };

    return (
        <Stack align="center" paddingTop={"5px"} paddingBottom={"5px"}>
            <CycleTableHeader
                title={renderTitle}
                subtitle={renderSubtitle}
                cycleYear={props.cycleYear}
                imgSource={imageIconSrc}
                anchor={props.anchor}
                showAllType={showAllType}
                showAllTital={showAllTitle}
            />
            <Stack direction="column" sx={{ backgroundColor: '#20273C', padding: 2}}>
            {
                renderData === undefined ?
                    (<span>No data available</span>):
                    (
                        <Stack direction="column" spacing={2}>
                            <Typography align={"left"} variant="caption">{renderCaption}</Typography>
                            <ToggleChart width={chartWidth} height={600}
                                         title={renderChartTitle}
                                         seriesName="Total"
                                         chartData={renderData}
                                         plotField="amount"
                                         displayField="display"
                                         isCES={true}
                            />
                            <DataGrid
                                disableColumnMenu={true}
                                getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
                                sx={{ color:"white", flex: 1, border: 0}}
                                getRowId={(row) => row.display}
                                rows={renderData}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                                hideFooterPagination={false}
                                sortModel={sortModel}
                                sortingOrder ={['asc', 'desc']}
                                onSortModelChange={(model) => setSortModel(model)}
                                autoHeight={true}
                                onRowClick={handleOnCellClick}
                                onColumnHeaderClick={handleOnHeaderClick}
                            />
                        </Stack>
                    )
            }
            </Stack>
        </Stack>
    );
};

export default CETypeList;
