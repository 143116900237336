import AMadLib from "../../AMadLib";

export default class MLRollupCESSummary extends AMadLib
{
    constructor()
    {
        super();
    }

    buildTitle(pageType, display)
    {
        let title = "Committee Expenditure Totals";
        if (pageType !== 'summary')
        {
            title += " for " + display;
        }
        return title;
    }

    buildContent(pageType, display, stats, data)
    {
        const avgTotal = data.amount / data.count;

        const isSummary = pageType === 'summary';
        const isLocation = (pageType === 'state' || pageType === 'county' || pageType === 'city' || pageType === 'zipcode')

        let content = "Since 2008, a total of $"
            + data.amount.toLocaleString(undefined, { maximumFractionDigits: 0 })
            + " dollars have been spent ";

        let typeText = ""
        if (pageType === 'committee' || pageType === 'politician')
        {
            typeText = " by the " + pageType + " " + display + ".";
        }
        else if (pageType === 'office')
        {
            typeText = " by candidates running for the office of " + display + ".";
        }
        else if (pageType === 'party')
        {
            typeText = " by the " + display + ".";
        }
        else if (pageType === 'county')
        {
            typeText = " in " + display + ".";
        }
        else if (pageType === 'organization' || pageType === 'industry')
        {
            typeText = " from the " + display + " " + pageType.toLowerCase() + ".";
        }
        else if (isSummary === false)
        {
            typeText = " by the " + pageType + " of " + display + "."
        }
        else
        {
            typeText = " on federal elections."
        }
        content += typeText

        return content;
    }

}
