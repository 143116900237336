import React from "react";
import {useNavigate} from "react-router-dom";
import {DataGrid} from '@mui/x-data-grid';
import {Box, Stack, Typography} from "@mui/material";
import ToggleChart from "../toggleChart";
import RollupTableHeader from "../rollupTableHeader";
import {buildICSColumns} from "../../util/DataGridMaker";
import {madLibFactory} from "../../AppGlobal";

const RollupCycleList = (props) => {

    let navigate = useNavigate();

    const [sortModel, setSortModel] = React.useState([
        {
            field: 'display',
            sort: 'desc',
        },
    ]);

    if (props.stats === undefined)
    {
        return;
    }

    const renderData = props.stats[props.statsKey];
    if (renderData === undefined)
    {
        return;
    }

    const madLibs = madLibFactory.getRollupICSCycle();

    const renderTitle = madLibs.buildTitle(props.pageType, props.display);
    const renderSubtitle = madLibs.buildSubtitle(props.pageType, props.display);
    const renderChartTitle = madLibs.buildChartTitle(props.pageType, props.display);
    const renderCaption = madLibs.buildContent(props.pageType, props.display, props.stats, renderData);

    const chartWidth = (window.innerWidth > 1000 ? 1175: window.innerWidth - 20);
    const chartHeight = renderData.length * 40;
    const imageIconSrc = "/images/cycle_icon.png"

    const columns = buildICSColumns('display', 'Year', 80, 80);

    const handleOnCellClick = (params) => {
        navigate("/cycle/" + params.row.typeKey);
    };

    return (
        <Stack align="left" paddingTop={"5px"} paddingBottom={"5px"}>
            <RollupTableHeader
                title={renderTitle} subtitle={renderSubtitle}
                imgSource={imageIconSrc}
                anchor="cycle_totals"
                showAllType="cycles" showAllTitle="Show All Cycles"/>
            <Stack direction="column" sx={{ backgroundColor: '#20273C', padding: 2}}>
                {
                    renderData === undefined ?
                        (<span>No data available</span>):
                        (
                            <Box>
                                <Stack direction="column" spacing={2}>
                                    <Typography variant="caption">{renderCaption}</Typography>
                                    <ToggleChart width={chartWidth} height={chartHeight}
                                                 title={renderChartTitle}
                                                 chartData={renderData}
                                                 seriesName="Cycle Year"
                                                 plotField="amount"
                                                 plotCurrency={true}
                                                 displayField="display"
                                    />
                                    <DataGrid
                                        disableColumnMenu={true}
                                        getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
                                        // sx={{ color:"white", width: '100%', maxWidth:500, border: 0}}
                                        sx={{ color:"white", width: '100%', flex:1, border: 0}}
                                        getRowId={(row) => row.typeKey}
                                        rows={renderData}
                                        columns={columns}
                                        pageSize={10}
                                        rowsPerPageOptions={[10]}
                                        hideFooterPagination={false}
                                        sortModel={sortModel}
                                        sortingOrder ={['asc', 'desc']}
                                        onSortModelChange={(model) => setSortModel(model)}
                                        autoHeight={true}
                                        onRowClick={handleOnCellClick}
                                    />
                                </Stack>
                            </Box>
                        )
                }
            </Stack>
        </Stack>
    );
};

export default RollupCycleList;
