import {
    Box,
    Button,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import ScrollToTop from "./scrollToTop";
import React, {useEffect} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const ZipBrowser = (props) => {
    const [success, setSuccess] = React.useState(false);
    const [links, setLinks] = React.useState([]);
    const [pageCount, setPageCount] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - links.length) : 0;

    const [firstDigit, setFirst] = React.useState(9);
    const [secondDigit, setSecond] = React.useState(9);
    const [thirdDigit, setThird] = React.useState(9);
    // const [secondEnable, setSecondEnable] = React.useState(true);
    // const [thirdEnable, setThirdEnable] = React.useState(true);
    const [zipToShow, setZipToShow] = React.useState(999);

    const [active1, setActive1] = React.useState(false);
    const [active2, setActive2] = React.useState(false);
    const [active3, setActive3] = React.useState(false);
    const [active4, setActive4] = React.useState(false);
    const [active5, setActive5] = React.useState(false);
    const [active6, setActive6] = React.useState(false);
    const [active7, setActive7] = React.useState(false);
    const [active8, setActive8] = React.useState(false);
    const [active9, setActive9] = React.useState(true);
    const [active0, setActive0] = React.useState(false);

    const [r2active1, setR2Active1] = React.useState(false);
    const [r2active2, setR2Active2] = React.useState(false);
    const [r2active3, setR2Active3] = React.useState(false);
    const [r2active4, setR2Active4] = React.useState(false);
    const [r2active5, setR2Active5] = React.useState(false);
    const [r2active6, setR2Active6] = React.useState(false);
    const [r2active7, setR2Active7] = React.useState(false);
    const [r2active8, setR2Active8] = React.useState(false);
    const [r2active9, setR2Active9] = React.useState(true);
    const [r2active0, setR2Active0] = React.useState(false);

    const [r3active1, setR3Active1] = React.useState(false);
    const [r3active2, setR3Active2] = React.useState(false);
    const [r3active3, setR3Active3] = React.useState(false);
    const [r3active4, setR3Active4] = React.useState(false);
    const [r3active5, setR3Active5] = React.useState(false);
    const [r3active6, setR3Active6] = React.useState(false);
    const [r3active7, setR3Active7] = React.useState(false);
    const [r3active8, setR3Active8] = React.useState(false);
    const [r3active9, setR3Active9] = React.useState(true);
    const [r3active0, setR3Active0] = React.useState(false);

    let navigate = useNavigate();
    let[pathToShow, setPathToShow] = "";
    let imageIconSrc = "/images/zipcode_icon.png"
    let zipStart = 999;
    let loadedPage = 1;

    const loadNumber = (event, number) => {
        loadedPage = 1;
        console.log("loadNumber:" + number + " => " + zipStart);

        let loadURL = "";
        if (props.cycleYear === 0)
        {
            loadURL = process.env.REACT_APP_API_ROOT +"/zipcodes?q=" + zipStart;
            console.log(" - CALLING URL::" + loadURL)
        }
        else
        {
            loadURL = process.env.REACT_APP_API_ROOT + "cycle/" + props.cycleYear + "/zipcodes?q=" + zipStart;
            console.log(" - CALLING URL::" + loadURL)
        }

        axios.get(loadURL)
            .then((res) => {
                if (res.data.entries === undefined)
                {
                    setLinks([])
                    setSuccess(false);
                }
                else
                {
                    setSuccess(true);
                    setLinks(res.data.entries);
                    setPageCount(res.data.total);
                }
            },);
    };

    const handleOnCellClick = (params, link) => {
        if (props.cycleYear === 0)
        {
            navigate(props.linkPath + "/" + link);
        }
        else
        {
            let path = props.linkPath.replaceAll("..", "");
            navigate("/cycle/" + props.cycleYear + path + "/" + link);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const setFirstDigit = (event, number) => {
        clearButtons()
        // clearR2Buttons()
        // clearR3Buttons()

        setFirst(number)

        if (number === 0)
        {
            setActive0(true)
        }

        if (number === 1)
        {
            setActive1(true)
        }

        if (number === 2)
        {
            setActive2(true)
        }

        if (number === 3)
        {
            setActive3(true)
        }

        if (number === 4)
        {
            setActive4(true)
        }

        if (number === 5)
        {
            setActive5(true)
        }

        if (number === 6)
        {
            setActive6(true)
        }

        if (number === 7)
        {
            setActive7(true)
        }

        if (number === 8)
        {
            setActive8(true)
        }

        if (number === 9)
        {
            setActive9(true)
        }

        zipStart = number + "" + secondDigit + "" + thirdDigit
        setZipToShow(zipStart)

        loadNumber(event, zipStart)

        // setSecondEnable(false)
    };

    const setSecondDigit = (event, number) => {
        clearR2Buttons()

        setSecond(number)

        if (number === 0)
        {
            setR2Active0(true)
        }

        if (number === 1)
        {
            setR2Active1(true)
        }

        if (number === 2)
        {
            setR2Active2(true)
        }

        if (number === 3)
        {
            setR2Active3(true)
        }

        if (number === 4)
        {
            setR2Active4(true)
        }

        if (number === 5)
        {
            setR2Active5(true)
        }

        if (number === 6)
        {
            setR2Active6(true)
        }

        if (number === 7)
        {
            setR2Active7(true)
        }

        if (number === 8)
        {
            setR2Active8(true)
        }

        if (number === 9)
        {
            setR2Active9(true)
        }

        zipStart = firstDigit + "" + number + "" + thirdDigit
        setZipToShow(zipStart)

        loadNumber(event, zipStart)

        // setThirdEnable(false)
    };

    const setThirdDigit = (event, number) => {
        clearR3Buttons()

        setThird(number)

        if (number === 0)
        {
            setR3Active0(true)
        }

        if (number === 1)
        {
            setR3Active1(true)
        }

        if (number === 2)
        {
            setR3Active2(true)
        }

        if (number === 3)
        {
            setR3Active3(true)
        }

        if (number === 4)
        {
            setR3Active4(true)
        }

        if (number === 5)
        {
            setR3Active5(true)
        }

        if (number === 6)
        {
            setR3Active6(true)
        }

        if (number === 7)
        {
            setR3Active7(true)
        }

        if (number === 8)
        {
            setR3Active8(true)
        }

        if (number === 9)
        {
            setR3Active9(true)
        }

        zipStart = firstDigit + "" + secondDigit + "" + number
        setZipToShow(zipStart)

        loadNumber(event, zipStart)
    };

    const clearButtons = () => {
        setActive0(false);
        setActive1(false);
        setActive2(false);
        setActive3(false);
        setActive4(false);
        setActive5(false);
        setActive6(false);
        setActive7(false);
        setActive8(false);
        setActive9(false);
    }

    const clearR2Buttons = () => {
        setR2Active0(false);
        setR2Active1(false);
        setR2Active2(false);
        setR2Active3(false);
        setR2Active4(false);
        setR2Active5(false);
        setR2Active6(false);
        setR2Active7(false);
        setR2Active8(false);
        setR2Active9(false);
    }

    const clearR3Buttons = () => {
        setR3Active0(false);
        setR3Active1(false);
        setR3Active2(false);
        setR3Active3(false);
        setR3Active4(false);
        setR3Active5(false);
        setR3Active6(false);
        setR3Active7(false);
        setR3Active8(false);
        setR3Active9(false);
    }

    const setButtons = () => {

    }

    useEffect(() => {
        {
            loadNumber(null, zipStart)
        }
    }, []);

    return(
        <Stack spacing={2} paddingBottom={2}>
            <Stack id="back-to-top-anchor" justifyContent={"left"} direction={"row"} alignItems={"center"} paddingTop={2} spacing={2}>
                <img height={40} width={40} src={imageIconSrc} loading="lazy" />
                <Typography variant={"h4"}>{props.typeHeader}</Typography>
                {
                    props.cycleYear === 0 ?
                        (<Typography color={"gray"} variant={"caption"} paddingTop={.5}>{"ALPHABETIC LIST OF ALL " + props.typeHeader.toUpperCase() + " FOR AVAILABLE ELECTION CYCLES"}</Typography>):
                        (<Typography color={"gray"} variant={"caption"} paddingTop={.5}>{"ALPHABETIC LIST OF ALL " + props.typeHeader.toUpperCase() + " FOR THE " + props.cycleYear + " ELECTION CYCLE"}</Typography>)
                }
            </Stack>
            <Box sx={{borderTopStyle:'dotted', borderColor:"#383838"}}></Box>
            <Box sx={{ backgroundColor: '#20273C'}} paddingRight={5} paddingLeft={5} paddingBottom={2} paddingTop={2}>
                <Stack paddingBottom={2} justifyContent={"center"} alignItems={"center"} direction={"row"} spacing={2}>
                    <img height={25} width={25} src="/images/search_icon.png" alt="" loading="lazy" />
                    <Typography variant={"h6"}>{props.title}</Typography>
                </Stack>

                <Stack spacing={1}>
                    <Typography>Select the first three digits</Typography>
                    {
                        window.innerWidth > 1000 ?
                            (
                                <Stack spacing={1}>
                                    <Stack direction={"row"} justifyContent={"center"} spacing={.75}>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setFirstDigit(event, 0)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: active0 ? "green" : "transparent" }}>0</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setFirstDigit(event, 1)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: active1 ? "green" : "transparent" }}>1</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setFirstDigit(event, 2)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: active2 ? "green" : "transparent" }}>2</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setFirstDigit(event, 3)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: active3 ? "green" : "transparent" }}>3</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setFirstDigit(event, 4)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: active4 ? "green" : "transparent" }}>4</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setFirstDigit(event, 5)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: active5 ? "green" : "transparent" }}>5</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setFirstDigit(event, 6)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: active6 ? "green" : "transparent" }}>6</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setFirstDigit(event, 7)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: active7 ? "green" : "transparent" }}>7</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setFirstDigit(event, 8)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: active8 ? "green" : "transparent" }}>8</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setFirstDigit(event, 9)} style={{ borderColor:"#90caf9", color:"white", backgroundColor: active9 ? "green" : "transparent" }}>9</Button>
                                    </Stack>

                                    <Stack direction={"row"} justifyContent={"center"} spacing={.75}>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setSecondDigit(event, 0)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2active0 ? "green" : "transparent" }}>0</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setSecondDigit(event, 1)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2active1 ? "green" : "transparent" }}>1</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setSecondDigit(event, 2)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2active2 ? "green" : "transparent" }}>2</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setSecondDigit(event, 3)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2active3 ? "green" : "transparent" }}>3</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setSecondDigit(event, 4)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2active4 ? "green" : "transparent" }}>4</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setSecondDigit(event, 5)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2active5 ? "green" : "transparent" }}>5</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setSecondDigit(event, 6)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2active6 ? "green" : "transparent" }}>6</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setSecondDigit(event, 7)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2active7 ? "green" : "transparent" }}>7</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setSecondDigit(event, 8)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2active8 ? "green" : "transparent" }}>8</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setSecondDigit(event, 9)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2active9 ? "green" : "transparent" }}>9</Button>
                                    </Stack>

                                    <Stack direction={"row"} justifyContent={"center"} spacing={.75}>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setThirdDigit(event, 0)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3active0 ? "green" : "transparent" }}>0</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setThirdDigit(event, 1)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3active1 ? "green" : "transparent" }}>1</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setThirdDigit(event, 2)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3active2 ? "green" : "transparent" }}>2</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setThirdDigit(event, 3)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3active3 ? "green" : "transparent" }}>3</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setThirdDigit(event, 4)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3active4 ? "green" : "transparent" }}>4</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setThirdDigit(event, 5)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3active5 ? "green" : "transparent" }}>5</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setThirdDigit(event, 6)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3active6 ? "green" : "transparent" }}>6</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setThirdDigit(event, 7)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3active7 ? "green" : "transparent" }}>7</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setThirdDigit(event, 8)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3active8 ? "green" : "transparent" }}>8</Button>
                                        <Button sx={{border: 1, maxHeight: '35px', minWidth: '45px'}} onClick={event => setThirdDigit(event, 9)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3active9 ? "green" : "transparent" }}>9</Button>
                                    </Stack>
                                </Stack>
                            )
                            :
                            (
                                <Stack direction={"column"} justifyContent={"center"} spacing={2}>
                                    <Stack direction={"row"} justifyContent={"center"} spacing={.75}>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstDigit(event, 0)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: active0 ? "green" : "transparent" }}>0</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstDigit(event, 1)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: active1 ? "green" : "transparent" }}>1</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstDigit(event, 2)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: active2 ? "green" : "transparent" }}>2</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstDigit(event, 3)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: active3 ? "green" : "transparent" }}>3</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstDigit(event, 4)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: active4 ? "green" : "transparent" }}>4</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstDigit(event, 5)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: active5 ? "green" : "transparent" }}>5</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstDigit(event, 6)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: active6 ? "green" : "transparent" }}>6</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstDigit(event, 7)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: active7 ? "green" : "transparent" }}>7</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstDigit(event, 8)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: active8 ? "green" : "transparent" }}>8</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setFirstDigit(event, 9)} style={{ borderColor:"#90caf9", color:"white", backgroundColor: active9 ? "green" : "transparent" }}>9</Button>
                                    </Stack>

                                    <Stack direction={"row"} justifyContent={"center"} spacing={.75}>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondDigit(event, 0)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2active0 ? "green" : "transparent" }}>0</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondDigit(event, 1)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2active1 ? "green" : "transparent" }}>1</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondDigit(event, 2)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2active2 ? "green" : "transparent" }}>2</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondDigit(event, 3)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2active3 ? "green" : "transparent" }}>3</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondDigit(event, 4)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2active4 ? "green" : "transparent" }}>4</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondDigit(event, 5)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2active5 ? "green" : "transparent" }}>5</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondDigit(event, 6)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2active6 ? "green" : "transparent" }}>6</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondDigit(event, 7)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2active7 ? "green" : "transparent" }}>7</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondDigit(event, 8)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2active8 ? "green" : "transparent" }}>8</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setSecondDigit(event, 9)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r2active9 ? "green" : "transparent" }}>9</Button>
                                    </Stack>

                                    <Stack direction={"row"} justifyContent={"center"} spacing={.75}>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdDigit(event, 0)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3active0 ? "green" : "transparent" }}>0</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdDigit(event, 1)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3active1 ? "green" : "transparent" }}>1</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdDigit(event, 2)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3active2 ? "green" : "transparent" }}>2</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdDigit(event, 3)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3active3 ? "green" : "transparent" }}>3</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdDigit(event, 4)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3active4 ? "green" : "transparent" }}>4</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdDigit(event, 5)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3active5 ? "green" : "transparent" }}>5</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdDigit(event, 6)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3active6 ? "green" : "transparent" }}>6</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdDigit(event, 7)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3active7 ? "green" : "transparent" }}>7</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdDigit(event, 8)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3active8 ? "green" : "transparent" }}>8</Button>
                                        <Button sx={{border: 1, maxHeight: '30px', minWidth: '35px'}} onClick={event => setThirdDigit(event, 9)} style={{ borderColor:"#90caf9", color:"white",  backgroundColor: r3active9 ? "green" : "transparent" }}>9</Button>
                                    </Stack>
                                </Stack>
                            )
                    }
                </Stack>
            </Box>

            <Box sx={{ backgroundColor: '#20273C'}} padding={5}>
                <Stack justifyContent={"left"} direction={"row"} alignItems={"center"} paddingBottom={2} spacing={2}>
                    <img height={25} width={25} src={imageIconSrc} loading="lazy" />
                    <Typography variant={"subtitle2"} paddingTop={.5}>Results</Typography>
                </Stack>
                <Stack border={1.5} padding={5} borderColor={"black"} borderRadius={"5px"}>
                    <Stack spacing={1} justifyContent={"center"} alignItems={"center"} direction={"row"} paddingBottom={1.5}>
                        <Typography>{zipToShow}</Typography>
                        <Typography paddingTop={.50} variant={"caption"} color={"gray"}>{props.typeHeader.toUpperCase() + " - SHOWING " + rowsPerPage}</Typography>
                    </Stack>
                    <Box borderTop={1} borderColor={"#515151"}></Box>
                    <TableContainer component={Paper}>
                        <Table sx={{ backgroundColor: '#20273C'}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {/*<TableCell>Key</TableCell>*/}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {(rowsPerPage > 0
                                        ? links.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : links
                                ).map((row) => (
                                    <TableRow key={row.key}>
                                        <TableCell component="th" scope="row" onClick={event => handleOnCellClick(event, row.key)} className={"clickable"}>
                                            {<div>{row.name}
                                                <br/>
                                                {props.cycleYear === 0 ?
                                                    ("https://www.monecracy.com" + props.linkPath.replaceAll("..", "") + "/" + row.key):
                                                    ("https://www.monecracy.com/cycle/" + props.cycleYear + props.linkPath.replaceAll("..", "") + "/" + row.key)}
                                            </div>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}

                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                        colSpan={3}
                                        count={links.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Stack>
            </Box>

            <ScrollToTop />
        </Stack>
    )
};

export default ZipBrowser
