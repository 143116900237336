import AMadLib from "../../AMadLib";

export default class MLRollupICSCycle extends AMadLib
{
    constructor()
    {
        super();
    }

    buildTitle(pageType, display)
    {
        let title = "Total Contributions";

        if (pageType !== 'summary')
        {
            const displayPrefix = " " + this.getDisplayPrefix(pageType) + " ";
            title += displayPrefix + display;
        }
        title += " by Election Cycle"

        return title;
    }

    buildSubtitle(pageType, display)
    {
        let subtitle = "The total contributions from individuals, organizations, and political action committees (PAC)";
        if (pageType !== 'summary')
        {
            const displayPrefix = " " + this.getDisplayPrefix(pageType) + " ";
            subtitle += displayPrefix + display;
        }
        subtitle += " by election cycle since 2008";

        return subtitle;
    }

    buildChartTitle(pageType, display)
    {
        return this.buildTitle(pageType, display);
    }

    buildContent(pageType, display, stats, data)
    {
        let amount2008 = 0;
        let indivAmount2008 = 0;
        let indivContributors2008 = 0;
        let orgAmount2008 = 0;
        let orgContributors2008 = 0;
        let pacAmount2008 = 0;

        let topAmount = 0;
        let topAmountCycle = 0;

        let topIndivAmount = 0;
        let topIndivAmountCycle = 0;
        let topIndivContributors = 0;
        let topIndivContributorsCycle = 0;

        let topOrgAmount = 0;
        let topOrgAmountCycle = 0;
        let topOrgContributors = 0;
        let topOrgContributorsCycle = 0;

        let topPACAmount = 0;
        let topPACAmountCycle = 0;

        for (const nextCycle of data)
        {
            //2008 Baselines
            if (nextCycle.display === '2008')
            {
                amount2008 = nextCycle.amount;
                indivAmount2008 = nextCycle.indivAmount;
                indivContributors2008 = nextCycle.indivContributors;
                orgAmount2008 = nextCycle.orgAmount;
                orgContributors2008 = nextCycle.orgContributors;
                pacAmount2008 = nextCycle.pacAmount;
            }

            //Top Cycle by Total Amount
            if (nextCycle.amount > topAmount)
            {
                topAmount = nextCycle.amount;
                topAmountCycle = nextCycle.display;
            }

            //Top Cycle by Individual Amount
            if (nextCycle.indivAmount > topIndivAmount)
            {
                topIndivAmount = nextCycle.indivAmount;
                topIndivAmountCycle = nextCycle.display;
            }
            //Top Cycle by Individual Contributors
            if (nextCycle.indivContributors > topIndivContributors)
            {
                topIndivContributors = nextCycle.indivContributors;
                topIndivContributorsCycle = nextCycle.display;
            }

            //Top Cycle by Org Amount
            if (nextCycle.orgAmount > topOrgAmount)
            {
                topOrgAmount = nextCycle.orgAmount;
                topOrgAmountCycle = nextCycle.display;
            }
            //Top Cycle by Org Contributors
            if (nextCycle.orgContributors > topOrgContributors)
            {
                topOrgContributors = nextCycle.orgContributors;
                topOrgContributorsCycle = nextCycle.display;
            }

            //Top Cycle by PAC Amount
            if (nextCycle.pacAmount > topPACAmount)
            {
                topPACAmount = nextCycle.pacAmount;
                topPACAmountCycle = nextCycle.display;
            }
        }
        const amountIncrease = (topAmount / amount2008) * 100;
        const indivContributorIncrease = (topIndivContributors / indivContributors2008) * 100;
        const orgContributorIncrease = (topOrgContributors / orgContributors2008) * 100;
        const orgAmountIncrease = (topOrgAmount / orgAmount2008);
        const pacAmountIncrease = (topPACAmount / pacAmount2008) ;

        //Top Line
        let content = "The total amount of money donated to federal elections has increased from $";
        content += amount2008.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " in 2008 to a record of $";
        content += topAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " in the " + topAmountCycle + " election, representing a ";
        content += amountIncrease.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += "% increase in total contributions during a single election cycle. ";

        //Individual Line
        content += "The number of individuals contributing to elections has grown by ";
        content += indivContributorIncrease.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += "%, increasing from "
        content += indivContributors2008.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " in 2008 to a record of ";
        content += topIndivContributors.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " in " + topIndivContributorsCycle + ". ";

        //Organization Lines
        content += "Organizations have become more engaged in contributing to federal elections, growing from "
        content += orgContributors2008.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " to ";
        content += topOrgContributors.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " in the ";
        content += topOrgContributorsCycle.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " election cycle, a ";
        content += orgContributorIncrease.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += "% increase in the number of companies, unions, universities, and other organizations getting involved in political funding."
        content += " The total contributions from organizations have increased by "
        content += orgAmountIncrease.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += "x from $"
        content += orgAmount2008.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " in 2008 to as much as $"
        content += topOrgAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " in "
        content += topOrgAmountCycle.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ". "

        //PAC Lines
        content += "Political Action Committees (PACs) have increased contributions by ";
        content += pacAmountIncrease.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += "x since 2008, growing from $";
        content += pacAmount2008.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " to $";
        content += topPACAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " in ";
        content += topPACAmountCycle;
        content += "."

        return content;
    }

}
