import React, {useEffect} from "react";
import {Link as RouterLink, useParams} from "react-router-dom";

import SiteHeader from "../../components/siteHeader";
import SiteFooter from "../../components/siteFooter";
import RollupICSStateList from "../../components/ics/rollupICSStateList";
import RollupICSCountyList from "../../components/ics/rollupICSCountyList";
import RollupICSCityList from "../../components/ics/rollupICSCityList";
import RollupICSZipcodeList from "../../components/ics/rollupICSZipcodeList";
import RollupICSTypeList from "../../components/ics/rollupICSTypeList";
import RollupUCSList from "../../components/ucs/rollupUCSList";
import RollupCEStateList from "../../components/ces/rollupCEStateList";
import RollupCECountyList from "../../components/ces/rollupCECountyList";
import RollupCECityList from "../../components/ces/rollupCECityList";
import RollupCETypeList from "../../components/ces/rollupCETypeList";
import RollupCycleList from "../../components/ics/rollupCycleList";
import RollupICSTotal from "../../components/ics/rollupICSTotal";
import RollupCETotal from "../../components/ces/rollupCETotal";
import RollupCTTypeList from "../../components/cts/rollupCTTypeList";
import {Breadcrumbs, Link, Stack, Typography} from "@mui/material";
import ShareButton from "../../components/shareButton";
import ScrollToTop from "../../components/scrollToTop";
import MetaData from "../../components/metaData";
import * as DataLoader from "../../util/DataLoader";
import RollupCTTotal from "../../components/cts/rollupCTTotal";
import RollupCECycleList from "../../components/ces/rollupCECycleList";
import {madLibFactory} from "../../AppGlobal";
import RollupUCSOrg from "../../components/ucs/rollupUCSOrg";
import RollupUCSPAC from "../../components/ucs/rollupUCSPAC";

const CommitteeRollup = () => {
    const currentPageType = "committee";
    const {committeeKey} = useParams();

    const [success, setSuccess] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [fields, setFields] = React.useState([]);
    const [stats, setStats] = React.useState([]);

    useEffect(() => {
        const urlSuffix = "committee/" + committeeKey;
        DataLoader.load(urlSuffix, (result, data) => {
            setLoaded(true);
            if (result === true)
            {
                setSuccess(true)
                setStats(data.statistics);
                setFields(data.fields);
            }
            else
            {
                setSuccess(false);
            }
        })
    }, [committeeKey])

    let metaTitle = "";
    let metaDescription = "";
    if (success === true)
    {
        const madLibsMeta = madLibFactory.getPageMetaRollup();

        metaTitle = madLibsMeta.buildTitle(currentPageType, fields.display, fields, stats);
        metaDescription = madLibsMeta.buildDescription(currentPageType, fields.display, fields, stats);
    }

    return (
        <div>
            <Stack paddingBottom={10}>
                <SiteHeader />
            </Stack>
            {
                loaded === false ?
                    (<Typography variant="h6">Building Committee Summary...</Typography>) :
                    (<div className="Page-content">
                        {
                            success === false ?
                                (<Typography variant="h6">Unable to build a Committee Summary for {committeeKey}</Typography>) :
                                (<React.Fragment>
                                    <MetaData
                                        title={metaTitle}
                                        description={metaDescription}
                                        url={window.location.href}
                                        image={"https://www.monecracy.com/images/logo.png"}/>

                                    <Stack direction="column" spacing={1} padding={0}>
                                        <Stack id="back-to-top-anchor" direction="row" alignItems="center" justifyContent="space-between" paddingTop={1}>
                                            <Breadcrumbs>
                                                <Link component={RouterLink} to={"/"} underline={"hover"}>Home</Link>
                                                <Link component={RouterLink} to={"/totals"} underline={"hover"}>Totals</Link>
                                                <Link component={RouterLink} to={"/committees"} underline={"hover"}>Committees</Link>
                                                <Typography>{fields.display}</Typography>
                                            </Breadcrumbs>
                                            <ShareButton title={"Monecracy: " + fields.display} />
                                        </Stack>

                                        <RollupICSTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_total"
                                            display={fields.display}
                                        />
                                        <RollupCycleList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_cycle_totals"
                                            display={fields.display}
                                        />
                                        <RollupICSStateList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_states"
                                            display={fields.display}
                                        />
                                        <RollupICSCountyList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_counties_top"
                                            display={fields.display}
                                        />
                                        <RollupICSCityList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_cities_top"
                                            display={fields.display}
                                        />
                                        <RollupICSZipcodeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_zipcodes_top"
                                            display={fields.display}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_parties"
                                            statsTop={false}
                                            linkType="party"
                                            anchor="party_totals"
                                            display={fields.display}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_politicians_top"
                                            statsTop={true}
                                            linkType="politician"
                                            anchor="politician_totals"
                                            display={fields.display}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_offices_top"
                                            statsTop={true}
                                            linkType="office"
                                            anchor="office_totals"
                                            display={fields.display}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_organizations_top"
                                            statsTop={true}
                                            linkType="organization"
                                            anchor="organization_totals"
                                            display={fields.display}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_industries_top"
                                            statsTop={true}
                                            anchor="industry_totals"
                                            linkType="industry"
                                            display={fields.display}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_occupations_top"
                                            statsTop={true}
                                            linkType="occupation"
                                            anchor="occupation_totals"
                                            display={fields.display}
                                        />
                                        <RollupICSTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ic_surnames_top"
                                            statsTop={true}
                                            linkType="surname"
                                            anchor="surname_totals"
                                            display={fields.display}
                                        />
                                        <RollupUCSList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_indiv_top"
                                            display={fields.display}
                                            linkType={"contributor"}
                                        />
                                        <RollupUCSOrg
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_org_top"
                                            display={fields.display}
                                            linkType={"organization"}
                                        />
                                        <RollupUCSPAC
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="uc_pac_top"
                                            display={fields.display}
                                            linkType={"committee"}
                                        />
                                        <RollupCETotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_total"
                                            display={fields.display}
                                        />
                                        <RollupCECycleList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_cycle_totals"
                                            display={fields.display}
                                        />
                                        <RollupCEStateList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_states"
                                            display={fields.display}
                                        />
                                        <RollupCECountyList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_county_top"
                                            display={fields.display}
                                        />
                                        <RollupCECityList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_city_top"
                                            display={fields.display}
                                        />

                                        <RollupCETypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ce_vendors_top"
                                            linkType="vendor"
                                            display={fields.display}
                                            anchor="vendor_expenditure_totals"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_summary"
                                            linkType="committee"
                                            typeHeader="Transaction Type"
                                            anchor="transaction_totals"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_support_total"
                                            display={fields.display}
                                            anchor="transaction_supporting_candidate_totals"
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_oppose_total"
                                            display={fields.display}
                                            anchor="transaction_opposing_candidate_totals"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_support_top"
                                            typeHeader="Candidate"
                                            linkType="office"
                                            anchor="transaction_supporting_candidate_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_oppose_top"
                                            typeHeader="Candidate"
                                            linkType="office"
                                            anchor="transaction_oppose_candidate_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_comm_cand_oppose"
                                            typeHeader="Candidate"
                                            linkType="office"
                                            anchor="transaction_communication_against_candidate"
                                            display={fields.display}
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_comm_cand_support"
                                            typeHeader="Candidate"
                                            linkType="office"
                                            anchor="transaction_communication_support_candidate"
                                            display={fields.display}
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_vendors_top"
                                            typeHeader="Vendor"
                                            linkType="vendor"
                                            anchor="transaction_with_vendors"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_nit_contrib"
                                            typeHeader="Organization"
                                            linkType="organization"
                                            anchor="transaction_native_tribes"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_to_nonaffilcomm_total"
                                            display={fields.display}
                                            anchor="transaction_contribution_to_non-affiliated_committee"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_to_nonaffilcomm_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_contribution_to_non-affiliated_committee_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_from_nonaffilcomm_total"
                                            display={fields.display}
                                            anchor="transaction_contribution_from_nonaffiliated_committee"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_from_nonaffilcomm_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_contribution_from_non-affiliated_committee_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_from_regfiler_total"
                                            display={fields.display}
                                            anchor="transaction_contribution_from_register_filer"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_from_regfiler_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_contribution_from_register_filer_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_regfiler_contrib_total"
                                            display={fields.display}
                                            anchor="transaction_register_filer_to_committee"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_regfiler_contrib_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_register_filer_to_committee_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_transfer_out_affilcomm_total"
                                            display={fields.display}
                                            anchor="transaction_out_to_affiliated_committee"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_transfer_out_affilcomm_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_out_to_affiliated_committee_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_transfer_to_affilcomm_total"
                                            display={fields.display}
                                            anchor="transaction_out_to_affiliated_by_committee"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_transfer_to_affilcomm_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_out_to_affiliated_by_committee_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_from_jointfund_total"
                                            display={fields.display}
                                            anchor="transaction_from_joint_fundraising_committee"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_from_jointfund_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_from_joint_fundraising_committee_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_to_jointfund_total"
                                            display={fields.display}
                                            anchor="transaction_to_joint_fundraising_committee"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_to_jointfund_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_to_joint_fundraising_committee_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_hqacct_regfiler_total"
                                            display={fields.display}
                                            anchor="transaction_hq_account_from_filer"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_hqacct_regfiler_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_hq_account_from_filer_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_regfiler_hqacct_total"
                                            display={fields.display}
                                            anchor="transaction_to_hq_account"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_regfiler_hqacct_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_to_hq_account_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_conacct_transfer_in_total"
                                            display={fields.display}
                                            anchor="transaction_convention_account_from_committee"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_conacct_transfer_in_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_convention_account_from_committee_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_conacct_transfer_to_total"
                                            display={fields.display}
                                            anchor="transaction_to_convention_account"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_conacct_transfer_to_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_to_convention_account_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_inkind_to_regfiler_total"
                                            display={fields.display}
                                            anchor="transaction_in_kind_to_registered_filer"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_inkind_to_regfiler_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_in_kind_to_registered_filer_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_inkind_from_committee_total"
                                            display={fields.display}
                                            anchor="transaction_in_kind_from_committee"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_inkind_from_committee_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_in_kind_from_committee_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_from_regfiler_total"
                                            display={fields.display}
                                            anchor="transaction_recount_account_from_filer"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_from_regfiler_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_recount_account_from_filer_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_to_regfiler_total"
                                            display={fields.display}
                                            anchor="transaction_recount_account_to_committee"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_to_regfiler_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_recount_account_to_committee_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_inkind_from_regfiler_total"
                                            display={fields.display}
                                            anchor="transaction_in_kind_from_filer"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_inkind_from_regfiler_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_in_kind_from_filer_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_inkind_to_committee_total"
                                            display={fields.display}
                                            anchor="transaction_in_kind_to_committee"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_inkind_to_committee_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_in_kind_to_committee_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_refund_to_comm_total"
                                            display={fields.display}
                                            anchor="transaction_refund_to_committee"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_refund_to_comm_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_refund_to_committee_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_refund_from_comm_total"
                                            display={fields.display}
                                            anchor="transaction_refund_from_committee"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_refund_from_comm_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_refund_from_committee_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_conacct_from_regfiler_total"
                                            display={fields.display}
                                            anchor="transaction_convention_account_receipt_from_filer"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_conacct_from_regfiler_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_convention_account_receipt_from_filer_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_regfiler_to_conacct_total"
                                            display={fields.display}
                                            anchor="transaction_register_filer_to_convention_account"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_regfiler_to_conacct_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_register_filer_to_convention_account_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_hqacct_from_comm_joint_total"
                                            display={fields.display}
                                            anchor="transaction_hq_receipt_from_joint_fundraising"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_hqacct_from_comm_joint_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_hq_receipt_from_joint_fundraising_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_hqacct_to_comm_joint_total"
                                            display={fields.display}
                                            anchor="transaction_joint_fundraising_from_hq"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_hqacct_to_comm_joint_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_joint_fundraising_from_hq_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_from_comm_joint_total"
                                            display={fields.display}
                                            anchor="transaction_recount_account_from_joint_fundraising"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_from_comm_joint_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_recount_account_from_joint_fundraising_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_to_comm_joint_total"
                                            display={fields.display}
                                            anchor="transaction_joint_fundraising_from_recount_account"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_to_comm_joint_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_joint_fundraising_from_recount_account_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_conacct_refund_to_regfiler_total"
                                            display={fields.display}
                                            anchor="transaction_convention_account_refund_to_filer"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_conacct_refund_to_regfiler_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_convention_account_refund_to_filer_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_refiler_refund_from_conacct_total"
                                            display={fields.display}
                                            anchor="transaction_refund_to_filer_from_convention_account"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_refiler_refund_from_conacct_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_refund_to_filer_from_convention_account_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_refund_to_regfiler_total"
                                            display={fields.display}
                                            anchor="transaction_recount_account_refund_to_registered_filer"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_refund_to_regfiler_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_recount_account_refund_to_registered_filer_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_refiler_refund_from_recount_total"
                                            display={fields.display}
                                            anchor="transaction_refund_to_filer_from_recount_account"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_refiler_refund_from_recount_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_refund_to_filer_from_recount_account_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_disbursement_to_total"
                                            display={fields.display}
                                            anchor="transaction_recount_account_disbursements"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_disbursement_to_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_recount_account_disbursements_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_disbursement_from_total"
                                            display={fields.display}
                                            anchor="transaction_disbursements_from_recount_account"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_recount_disbursement_from_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_disbursements_from_recount_account_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_loan_repay_regfiler_total"
                                            display={fields.display}
                                            anchor="transaction_repayments_to_filer"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_loan_repay_refiler_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_repayments_to_filer_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_regfiler_loan_payment_total"
                                            display={fields.display}
                                            anchor="transaction_loan_payment"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_regfiler_loan_payment_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_loan_payment_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_hqacct_refund_regfiler_total"
                                            display={fields.display}
                                            anchor="transaction_hq_refund_to_filer"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_hqacct_refund_regfiler_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_hq_refund_to_filer_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_regfiler_hqacct_refund_total"
                                            display={fields.display}
                                            anchor="transaction_filer_to_hq_refund"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_regfiler_hqacct_refund_top"
                                            typeHeader="Committee"
                                            linkType="committee"
                                            anchor="transaction_filer_to_hq_refund_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_unreg_org_total"
                                            display={fields.display}
                                            anchor="transaction_committee_contribution_from_unregister_organization"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_contrib_unreg_org_top"
                                            typeHeader="Organization"
                                            linkType="organization"
                                            anchor="transaction_committee_contribution_from_unregister_organization_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_loan_org_total"
                                            display={fields.display}
                                            anchor="transaction_committee_loan_from_bank"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_loan_org_top"
                                            typeHeader="Organization"
                                            linkType="organization"
                                            anchor="transaction_committee_loan_from_bank_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_loan_from_cand_total"
                                            display={fields.display}
                                            anchor="transaction_committee_loan_from_candidate"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_loan_from_cand_top"
                                            typeHeader="Candidate"
                                            linkType="office"
                                            anchor="transaction_committee_loan_from_candidate_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_loan_repay_cand_total"
                                            display={fields.display}
                                            anchor="transaction_committee_loan_repay_to_candidate"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_loan_repay_cand_top"
                                            typeHeader="Candidate"
                                            linkType="office"
                                            anchor="transaction_committee_loan_repay_to_candidate_top"
                                            display={fields.display}
                                        />

                                        <RollupCTTotal
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_cand_coorparty_exp_total"
                                            display={fields.display}
                                            anchor="transaction_committee_coordinated_party_expenditure"
                                        />

                                        <RollupCTTypeList
                                            pageType={currentPageType}
                                            stats={stats}
                                            statsKey="ct_cand_coorparty_exp_top"
                                            typeHeader="Candidate"
                                            linkType="office"
                                            anchor="transaction_committee_coordinated_party_expenditure_top"
                                            display={fields.display}
                                        />
                                    </Stack>

                                    <ScrollToTop />
                                </React.Fragment>)
                        }
                    </div>)
            }
            <SiteFooter/>
        </div>
    );
};

export default CommitteeRollup;
