import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";

import axios from "axios";
import {
    Box,
    Button,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import ScrollToTop from "./scrollToTop";

const AlphaBrowser = (props) => {

    const [success, setSuccess] = React.useState(false);
    const [links, setLinks] = React.useState([]);
    const [pageCount, setPageCount] = React.useState(0);
    const [buttonWidth, setButtonWidth] = React.useState(0);

    const [activeA, setActiveA] = React.useState(true);
    const [activeB, setActiveB] = React.useState(false);
    const [activeC, setActiveC] = React.useState(false);
    const [activeD, setActiveD] = React.useState(false);
    const [activeE, setActiveE] = React.useState(false);
    const [activeF, setActiveF] = React.useState(false);
    const [activeG, setActiveG] = React.useState(false);
    const [activeH, setActiveH] = React.useState(false);
    const [activeI, setActiveI] = React.useState(false);
    const [activeJ, setActiveJ] = React.useState(false);
    const [activeK, setActiveK] = React.useState(false);
    const [activeL, setActiveL] = React.useState(false);
    const [activeM, setActiveM] = React.useState(false);
    const [activeN, setActiveN] = React.useState(false);
    const [activeO, setActiveO] = React.useState(false);
    const [activeP, setActiveP] = React.useState(false);
    const [activeQ, setActiveQ] = React.useState(false);
    const [activeR, setActiveR] = React.useState(false);
    const [activeS, setActiveS] = React.useState(false);
    const [activeT, setActiveT] = React.useState(false);
    const [activeU, setActiveU] = React.useState(false);
    const [activeV, setActiveV] = React.useState(false);
    const [activeW, setActiveW] = React.useState(false);
    const [activeX, setActiveX] = React.useState(false);
    const [activeY, setActiveY] = React.useState(false);
    const [activeZ, setActiveZ] = React.useState(false);
    const [active0, setActive0] = React.useState(false);

    let [selectedLetter, setSelectedLetter] = React.useState("A");
    let[pathToShow, setPathToShow] = "";

    let loadedLetter = 'A';
    let loadedPage = 1;

    const loadLetter = (event, letter) => {
        loadedLetter = letter;
        selectedLetter = setSelectedLetter(letter);
        loadedPage = 1;
        console.log("loadLetter:" + letter + " => " + loadedLetter);

        let loadURL = "";
        if (props.cycleYear === 0)
        {
            loadURL = process.env.REACT_APP_API_ROOT + props.service + "?q=" + loadedLetter;
            console.log(" - CALLING URL::" + loadURL)
        }
        else
        {
            loadURL = process.env.REACT_APP_API_ROOT + "cycle/" + props.cycleYear + "/" + props.service + "?q=" + loadedLetter;
            console.log(" - CALLING URL::" + loadURL)
        }

        axios.get(loadURL)
            .then((res) => {
                if (res.data.entries === undefined || res.data.entries === null)
                {
                    setSuccess(false);
                    setLinks([])
                }
                else
                {
                    setSuccess(true);
                    setLinks(res.data.entries);
                    setPageCount(res.data.total);
                }
            },);

        setPage(0)
    };

    const loadPage = (event, page) => {
        loadedPage = page;
        console.log("loadPage::" + loadedLetter);

        const loadURL = process.env.REACT_APP_API_ROOT + props.service + "?q=" + loadedLetter;
        console.log(" - CALLING URL::" + loadURL)

        axios.get(loadURL)
            .then((res) => {
                if (res.data.entries === undefined || res.data.entries === null)
                {
                    setSuccess(false);
                    setLinks([])
                }
                else
                {
                    setSuccess(true);
                    setLinks(res.data.entries);
                    setPageCount(res.data.total);
                }
            },);
    };

    useEffect(() => {
        {
            props.type === "zipcode" ?
                (
                    loadLetter(null, '9')
                ):
                (
                    loadLetter(null, 'A')
                )
        }

        if (window.innerWidth > 1000)
        {
            setButtonWidth(.75)
        }
        else
        {
            setButtonWidth(0)
        }
    }, []);

    let imageIconSrc = ""
    if (props.type === "vendor")
    {
        imageIconSrc = "/images/vendor_icon.png";
    }
    else if (props.type === "party")
    {
        imageIconSrc = "/images/party_icon.png";
    }
    else if (props.type === "committee")
    {
        imageIconSrc = "/images/committee_icon.png";
    }
    else if (props.type === "office")
    {
        imageIconSrc = "/images/office_icon.png";
    }
    else if (props.type === "organization")
    {
        imageIconSrc = "/images/organization_icon.png";
    }
    else if (props.type === "politician")
    {
        imageIconSrc = "/images/politician_icon.png";
    }
    else if (props.type === "occupation")
    {
        imageIconSrc = "/images/occupation_icon.png";
    }
    else if (props.type === "surname")
    {
        imageIconSrc = "/images/surname_icon.png";
    }
    else if (props.type === "state")
    {
        imageIconSrc = "/images/state_icon.png";
    }
    else if (props.type === "contributor")
    {
        imageIconSrc = "/images/contributor_icon.png";
    }
    else if (props.type === "zipcode")
    {
        imageIconSrc = "/images/zipcode_icon.png";
    }
    else if (props.type === "industry")
    {
        imageIconSrc = "/images/industry_icon.png";
    }
    else if (props.type === "list")
    {
        imageIconSrc = "/images/list_icon.png";
    }
    else if (props.type === "tag")
    {
        imageIconSrc = "/images/tag_icon.png";
    }
    else
    {
        imageIconSrc = "/images/placeHolderIcon.png"
    }

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - links.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    let navigate = useNavigate();

    const handleOnCellClick = (params, link) => {
        if (props.cycleYear === 0)
        {
            navigate(props.linkPath + "/" + link);
        }
        else
        {
            let path = props.linkPath.replaceAll("..", "");
            navigate("/cycle/" + props.cycleYear + path + "/" + link);
        }
    };

    const setLetter = (event, letter) => {
        clearR1Buttons()

        setSelectedLetter(letter)

        if (letter === "A")
        {
            setActiveA(true)
        }
        else if (letter === "B")
        {
            setActiveB(true)
        }
        else if (letter === "C")
        {
            setActiveC(true)
        }
        else if (letter === "D")
        {
            setActiveD(true)
        }
        else if (letter === "E")
        {
            setActiveE(true)
        }
        else if (letter === "F")
        {
            setActiveF(true)
        }
        else if (letter === "G")
        {
            setActiveG(true)
        }
        else if (letter === "H")
        {
            setActiveH(true)
        }
        else if (letter === "I")
        {
            setActiveI(true)
        }
        else if (letter === "J")
        {
            setActiveJ(true)
        }
        else if (letter === "K")
        {
            setActiveK(true)
        }
        else if (letter === "L")
        {
            setActiveL(true)
        }
        else if (letter === "M")
        {
            setActiveM(true)
        }
        else if (letter === "N")
        {
            setActiveN(true)
        }
        else if (letter === "O")
        {
            setActiveO(true)
        }
        else if (letter === "P")
        {
            setActiveP(true)
        }
        else if (letter === "Q")
        {
            setActiveQ(true)
        }
        else if (letter === "R")
        {
            setActiveR(true)
        }
        else if (letter === "S")
        {
            setActiveS(true)
        }
        else if (letter === "T")
        {
            setActiveT(true)
        }
        else if (letter === "U")
        {
            setActiveU(true)
        }
        else if (letter === "V")
        {
            setActiveV(true)
        }
        else if (letter === "W")
        {
            setActiveW(true)
        }
        else if (letter === "X")
        {
            setActiveX(true)
        }
        else if (letter === "Y")
        {
            setActiveY(true)
        }
        else if (letter === "Z")
        {
            setActiveZ(true)
        }
        else
        {
            setActive0(true)
        }

        loadLetter(event, letter)
    }

    const clearR1Buttons = () => {
        setActiveA(false);
        setActiveB(false);
        setActiveC(false);
        setActiveD(false);
        setActiveE(false);
        setActiveF(false);
        setActiveG(false);
        setActiveH(false);
        setActiveI(false);
        setActiveJ(false);
        setActiveK(false);
        setActiveL(false);
        setActiveM(false);
        setActiveN(false);
        setActiveO(false);
        setActiveP(false);
        setActiveQ(false);
        setActiveR(false);
        setActiveS(false);
        setActiveT(false);
        setActiveU(false);
        setActiveV(false);
        setActiveW(false);
        setActiveX(false);
        setActiveY(false);
        setActiveZ(false);
        setActive0(false);
    }

    let rollupPath = "https://www.monecracy.com" + props.linkPath.replaceAll("..", "") + "/";
    let cyclePath = "https://www.monecracy.com/cycle/" + props.cycleYear + props.linkPath.replaceAll("..", "") + "/";

    return (
        <div>
            <Stack spacing={2} paddingBottom={2}>
                <Stack id="back-to-top-anchor" justifyContent={"left"} direction={"row"} alignItems={"center"} paddingTop={2} spacing={2}>
                    <img height={40} width={40} src={imageIconSrc} loading="lazy" />
                    <Typography variant={"h4"}>{props.typeHeader}</Typography>
                    {
                        props.cycleYear === 0 ?
                            (<Typography color={"gray"} variant={"caption"} paddingTop={.5}>{"ALPHABETIC LIST OF ALL " + props.typeHeader.toUpperCase() + " FOR AVAILABLE ELECTION CYCLES"}</Typography>):
                            (<Typography color={"gray"} variant={"caption"} paddingTop={.5}>{"ALPHABETIC LIST OF ALL " + props.typeHeader.toUpperCase() + " FOR THE " + props.cycleYear + " ELECTION CYCLE"}</Typography>)
                    }
                </Stack>
                <Box sx={{borderTopStyle:'dotted', borderColor:"#383838"}}></Box>
                <Box sx={{ backgroundColor: '#20273C'}} paddingRight={5} paddingLeft={5} paddingBottom={2} paddingTop={2}>
                    <Stack justifyContent={"center"} alignItems={"center"} direction={"row"} spacing={2}>
                        <img height={25} width={25} src="/images/search_icon.png" alt="" loading="lazy" />
                        <Typography variant={"h6"}>{props.title}</Typography>
                    </Stack>
                    <Stack paddingBottom={2} spacing={2}>
                        <Typography variant={"caption"} color={"gray"}>BY NAME</Typography>
                    </Stack>
                    {
                        // Need to build the page 2 different ways for mobile vs desktip
                        window.innerWidth > 1000 ?
                            (
                                //Desktop
                                <Stack direction={"row"} justifyContent={"center"} spacing={buttonWidth}>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'A')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeA ? "green" : "transparent" }}>A</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'B')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeB ? "green" : "transparent" }}>B</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'C')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeC ? "green" : "transparent" }}>C</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'D')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeD ? "green" : "transparent" }}>D</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'E')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeE ? "green" : "transparent" }}>E</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'F')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeF ? "green" : "transparent" }}>F</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'G')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeG ? "green" : "transparent" }}>G</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'H')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeH ? "green" : "transparent" }}>H</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'I')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeI ? "green" : "transparent" }}>I</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'J')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeJ ? "green" : "transparent" }}>J</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'K')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeK ? "green" : "transparent" }}>K</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'L')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeL ? "green" : "transparent" }}>L</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'M')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeM ? "green" : "transparent" }}>M</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'N')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeN ? "green" : "transparent" }}>N</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'O')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeO ? "green" : "transparent" }}>O</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'P')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeP ? "green" : "transparent" }}>P</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'Q')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeQ ? "green" : "transparent" }}>Q</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'R')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeR ? "green" : "transparent" }}>R</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'S')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeS ? "green" : "transparent" }}>S</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'T')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeT ? "green" : "transparent" }}>T</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'U')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeU ? "green" : "transparent" }}>U</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'V')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeV ? "green" : "transparent" }}>V</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'W')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeW ? "green" : "transparent" }}>W</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'X')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeX ? "green" : "transparent" }}>X</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'Y')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeY ? "green" : "transparent" }}>Y</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '35px'}} onClick={event => setLetter(event, 'Z')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeZ ? "green" : "transparent" }}>Z</Button>
                                    <Button sx={{border: 1, maxHeight: '25px', minWidth: '45px'}} onClick={event => setLetter(event, '9')} style={{borderColor:"#90caf9", color:"white", backgroundColor: active0 ? "green" : "transparent" }}>0-9</Button>
                                </Stack>
                            )
                            :
                            (
                                //Mobile
                                <Stack direction={"column"} justifyContent={"center"} spacing={1}>
                                    <Stack direction={"row"} justifyContent={"center"} spacing={buttonWidth}>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'A')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeA ? "green" : "transparent" }}>A</Button>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'B')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeB ? "green" : "transparent" }}>B</Button>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'C')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeC ? "green" : "transparent" }}>C</Button>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'D')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeD ? "green" : "transparent" }}>D</Button>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'E')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeE ? "green" : "transparent" }}>E</Button>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'F')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeF ? "green" : "transparent" }}>F</Button>
                                    </Stack>
                                    <Stack direction={"row"} justifyContent={"center"} spacing={buttonWidth}>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'G')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeG ? "green" : "transparent" }}>G</Button>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'H')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeH ? "green" : "transparent" }}>H</Button>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'I')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeI ? "green" : "transparent" }}>I</Button>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'J')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeJ ? "green" : "transparent" }}>J</Button>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'K')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeK ? "green" : "transparent" }}>K</Button>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'L')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeL ? "green" : "transparent" }}>L</Button>
                                    </Stack>
                                    <Stack direction={"row"} justifyContent={"center"} spacing={buttonWidth}>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'M')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeM ? "green" : "transparent" }}>M</Button>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'N')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeN ? "green" : "transparent" }}>N</Button>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'O')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeO ? "green" : "transparent" }}>O</Button>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'P')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeP ? "green" : "transparent" }}>P</Button>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'Q')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeQ ? "green" : "transparent" }}>Q</Button>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'R')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeR ? "green" : "transparent" }}>R</Button>
                                    </Stack>
                                    <Stack direction={"row"} justifyContent={"center"} spacing={buttonWidth}>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'S')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeS ? "green" : "transparent" }}>S</Button>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'T')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeT ? "green" : "transparent" }}>T</Button>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'U')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeU ? "green" : "transparent" }}>U</Button>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'V')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeV ? "green" : "transparent" }}>V</Button>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'W')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeW ? "green" : "transparent" }}>W</Button>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'X')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeX ? "green" : "transparent" }}>X</Button>
                                    </Stack>
                                    <Stack direction={"row"} justifyContent={"center"} spacing={buttonWidth}>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'Y')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeY ? "green" : "transparent" }}>Y</Button>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, 'Z')} style={{borderColor:"#90caf9", color:"white", backgroundColor: activeZ ? "green" : "transparent" }}>Z</Button>
                                        <Button sx={{border: 1, maxWidth: '20px'}} onClick={event => setLetter(event, '9')} style={{borderColor:"#90caf9", color:"white", backgroundColor: active0 ? "green" : "transparent" }}>0-9</Button>
                                    </Stack>
                                </Stack>
                            )
                    }
                </Box>

                <Box sx={{ backgroundColor: '#20273C'}} padding={5}>
                    <Stack justifyContent={"left"} direction={"row"} alignItems={"center"} paddingBottom={2} spacing={2}>
                        <img height={25} width={25} src={imageIconSrc} loading="lazy" />
                        <Typography variant={"subtitle2"} paddingTop={.5}>Results</Typography>
                    </Stack>
                    <Stack border={1.5} padding={5} borderColor={"black"} borderRadius={"5px"}>
                        <Stack spacing={1} justifyContent={"center"} alignItems={"center"} direction={"row"} paddingBottom={1.5}>
                            <Typography>{selectedLetter}</Typography>
                            <Typography paddingTop={.50} variant={"caption"} color={"gray"}>{props.typeHeader.toUpperCase() + " - SHOWING " + rowsPerPage}</Typography>
                        </Stack>
                        <Box borderTop={1} borderColor={"#515151"}></Box>
                        <TableContainer component={Paper} sx={{width:"100%"}}>
                            <Table sx={{ backgroundColor: '#20273C'}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {/*<TableCell>Key</TableCell>*/}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {(rowsPerPage > 0
                                            ? links.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : links
                                    ).map((row) => (
                                        <TableRow key={row.key}>
                                            <Tooltip title={props.cycleYear === 0 ?
                                                (rollupPath + row.key):
                                                (cyclePath + row.key)
                                            }>
                                                <TableCell component="th" scope="row" onClick={event => handleOnCellClick(event, row.key)} className={"clickable"}>
                                                    {
                                                        <div>
                                                            {row.name}
                                                            <br/>
                                                            {props.cycleYear === 0 ?
                                                                (rollupPath + row.key):
                                                                (cyclePath + row.key)}
                                                        </div>
                                                    }
                                                </TableCell>
                                            </Tooltip>
                                        </TableRow>
                                    ))}

                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            sx={{
                                                // backgroundColor: 'red !important', // gets overridden if not important
                                                // height: '70px',
                                                // '.MuiInputBase-root': {
                                                //     backgroundColor: 'green',
                                                // },
                                                // '.MuiTablePagination-toolbar': {
                                                //     backgroundColor: 'pink',
                                                //     width: '950px',
                                                //     color: 'rgb(41, 39, 39)',
                                                //     height: '35px',
                                                // },
                                                // '.MuiBox-root': {
                                                //     backgroundColor: 'yellow',
                                                //     color: 'black',
                                                //
                                                //     '& .MuiSvgIcon-root': {
                                                //         backgroundColor: 'purple',
                                                //         color: 'black',
                                                //     },
                                                // },
                                                // ' .MuiPaper-root': {
                                                //     backgroundColor: 'rosybrown',
                                                //     color: 'black',
                                                //     borderRadius: "100px",
                                                // },
                                                '.MuiTablePagination-menuItem': {
                                                    color: 'black',
                                                },
                                            }}
                                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                            colSpan={3}
                                            count={links.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: {
                                                    'aria-label': 'rows per page',
                                                },
                                                native: true,
                                                MenuProps: {
                                                    sx: {
                                                        '.MuiPaper-root': {
                                                            backgroundColor: 'rosybrown',
                                                            color: 'black',
                                                        },
                                                        '.MuiTablePagination-menuItem': {
                                                            ':hover': {
                                                                backgroundColor: 'turquoise',
                                                            },
                                                            backgroundColor: 'yellow',
                                                        },
                                                        '.MuiTablePagination-menuItem.Mui-selected': {
                                                            ':hover': {
                                                                backgroundColor: 'blue',
                                                            },
                                                            backgroundColor: 'purple',
                                                        },
                                                    },
                                                },
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            // ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Stack>
                </Box>

                <ScrollToTop />
            </Stack>
        </div>
    );
};

export default AlphaBrowser;
