import AMadLib from "../../AMadLib";

export default class MLCycleCTSTotal extends AMadLib
{
    constructor(props)
    {
        super(props);

        this.registry = {
            ct_support_total: {
                title: "Total Committee Expenditures Supporting Candidates",
            },
            ct_oppose_total: {
                title: "Total Committee Expenditures Opposing Candidates",
            },
            ct_contrib_to_nonaffilcomm_total: {
                title: "Total Contributions Made To Non-Affiliated Committees",

            },
            ct_contrib_from_nonaffilcomm_total: {
                title: "Total Contributions Received from a Non-Affiliated Committees",

            },
            ct_contrib_from_regfiler_total: {
                title: "Total Contributions to Committee from Registered Filers",

            },
            ct_regfiler_contrib_total: {
                title: "Total Contributions from Committee to Registered Filer",

            },
            ct_transfer_out_affilcomm_total: {
                title: "Total Transfers Out To Affiliated Committees",

            },
            ct_transfer_to_affilcomm_total: {
                title: "Total Transfers Out To Affiliated Committees By Other Committee",

            },
            ct_contrib_from_jointfund_total: {
                title: "Total Contributions From Joint Fundraising Committees",

            },
            ct_contrib_to_jointfund_total: {
                title: "Total Contributions To Joint Fundraising Committees",

            },
            ct_hqacct_regfiler_total: {
                title: "Total HQ Account Receipt From Registered Filers",

            },
            ct_regfiler_hqacct_total: {
                title: "Total Contributions To HQ Account",

            },
            ct_conacct_transfer_in_total: {
                title: "Total Convention Account Transfer In From Affiliated Committees",

            },
            ct_conacct_transfer_to_total: {
                title: "Total Contributions To Convention Account",

            },
            ct_inkind_to_regfiler_total: {
                title: "Total In-Kind Contributions To Registered Filers",

            },
            ct_inkind_from_committee_total: {
                title: "Total In-Kind Contributions From Committees",

            },
            ct_recount_from_regfiler_total: {
                title: "Total Recount Account Contributions From Registered Filers",

            },
            ct_recount_to_regfiler_total: {
                title: "Total Recount Account Contributions To Committees",

            },
            ct_inkind_from_regfiler_total: {
                title: "Total In-Kind Contributions From Registered Filers",

            },
            ct_inkind_to_committee_total: {
                title: "Total In-Kind Contributions To Committee",

            },
            ct_refund_to_comm_total: {
                title: "Total Refunds To Committees",

            },
            ct_refund_from_comm_total: {
                title: "Total Refunds From Committees",

            },
            ct_conacct_from_regfiler_total: {
                title: "Total Convention Account Receipt From Registered Filers",

            },
            ct_regfiler_to_conacct_total: {
                title: "Total Registered Filer To Convention Account",

            },
            ct_hqacct_from_comm_joint_total: {
                title: "Total HQ Account Receipt From Joint Fundraising",

            },
            ct_hqacct_to_comm_joint_total: {
                title: "Total Joint Fundraising Contributions From HQ Account",

            },
            ct_recount_from_comm_joint_total: {
                title: "Total Recount Account Receipts From Joint Fundraising",

            },
            ct_recount_to_comm_joint_total: {
                title: "Total Joint Fundraising Contributions From Recount Account",

            },
            ct_conacct_refund_to_regfiler_total: {
                title: "Total Convention Account Refund To Registered Filers",

            },
            ct_refiler_refund_from_conacct_total: {
                title: "Total Refund To Registered Filers From Convention Account",

            },
            ct_recount_refund_to_regfiler_total: {
                title: "Total Recount Account Refund To Registered Filers",

            },
            ct_refiler_refund_from_recount_total: {
                title: "Total Refund To Registered Filers From Recount Account",

            },
            ct_recount_disbursement_to_total: {
                title: "Total Recount Account Disbursements",

            },
            ct_recount_disbursement_from_total: {
                title: "Total Disbursements From Recount Account",

            },
            ct_loan_repay_regfiler_total: {
                title: "Total Repayments Of Loans To Registered Filers",

            },
            ct_regfiler_loan_payment_total: {
                title: "Total Loan Payments",

            },
            ct_hqacct_refund_regfiler_total: {
                title: "Total HQ Account Refund To Registered Filers",

            },
            ct_regfiler_hqacct_refund_total: {
                title: "Total Registered Filers to HQ Refund",

            },
            ct_contrib_unreg_org_total: {
                title: "Total Committee Contributions from Unregistered Organizations",

            },
            ct_loan_org_total: {
                title: "Total Committee Loans from Banks",

            },
            ct_loan_from_cand_total: {
                title: "Total Committee Loans from Candidates",

            },
            ct_loan_repay_cand_total: {
                title: "Total Committee Loans Repayments to Candidates",

            },
            ct_cand_coorparty_exp_total: {
                title: "Total Committee Coordinated Party Expenditures",
            },
            ct_loan_to_comm_total: {
                title: "Total Candidate Loans to Committees"
            },
            ct_loan_repay_comm_total: {
                title: "Total Candidate Loans Repayments from Committees"
            },
            ct_comm_coorparty_exp_total: {
                title: "Total Candidate Coordinated Party Expenditure from Committees"
            },
            ct_contrib_unreg_committee_total: {
                title: "Total Committee Contributions Unregistered Committee"
            },
            ct_loan_comm_total: {
                title: "Total Committee Loan to Committee"
            }
        }
    }

    getRegistration(statKey)
    {
        return this.registry[statKey];
    }

    buildTitle(cycleYear, pageType, statKey, display)
    {
        const regEntry = this.registry[statKey];
        if (regEntry !== undefined)
        {
            let title = regEntry.title;
            title +=  " for the " + cycleYear + " Elections"
            return title;
        }

        return "MISSING CTS TOTAL TITLE";
    }

    buildContent(cycleYear, pageType, statKey, display, stats, data)
    {
        switch(statKey)
        {
            case 'ct_support_total':
                return this.#buildSupportTotal(cycleYear, display, stats, data);
            case 'ct_oppose_total':
                return this.#buildOpposeTotal(cycleYear, display, stats, data);
            case 'ct_contrib_to_nonaffilcomm_total':
                return this.#buildNonAffilCommSent(cycleYear, display, stats, data);
            case 'ct_contrib_from_nonaffilcomm_total':
                return this.#buildNonAffilCommReceived(cycleYear, display, stats, data);
            case 'ct_contrib_from_regfiler_total':
                return this.#buildContributionsFromRegFiler(cycleYear, display, stats, data);
            case 'ct_regfiler_contrib_total':
            case 'ct_transfer_out_affilcomm_total':
            case 'ct_transfer_to_affilcomm_total':
            case 'ct_contrib_from_jointfund_total':
            case 'ct_contrib_to_jointfund_total':
            case 'ct_hqacct_regfiler_total':
            case 'ct_regfiler_hqacct_total':
            case 'ct_conacct_transfer_in_total':
            case 'ct_conacct_transfer_to_total':
            case 'ct_inkind_to_regfiler_total':
            case 'ct_inkind_from_committee_total':
            case 'ct_recount_from_regfiler_total':
            case 'ct_recount_to_regfiler_total':
            case 'ct_inkind_from_regfiler_total':
            case 'ct_inkind_to_committee_total':
            case 'ct_refund_to_comm_total':
            case 'ct_refund_from_comm_total':
            case 'ct_conacct_from_regfiler_total':
            case 'ct_regfiler_to_conacct_total':
            case 'ct_hqacct_from_comm_joint_total':
            case 'ct_hqacct_to_comm_joint_total':
            case 'ct_recount_from_comm_joint_total':
            case 'ct_recount_to_comm_joint_total':
            case 'ct_conacct_refund_to_regfiler_total':
            case 'ct_refiler_refund_from_conacct_total':
            case 'ct_recount_refund_to_regfiler_total':
            case 'ct_refiler_refund_from_recount_total':
            case 'ct_recount_disbursement_to_total':
            case 'ct_recount_disbursement_from_total':
            case 'ct_loan_repay_regfiler_total':
            case 'ct_regfiler_loan_payment_total':
            case 'ct_hqacct_refund_regfiler_total':
            case 'ct_regfiler_hqacct_refund_total':
            case 'ct_contrib_unreg_org_total':
            case 'ct_loan_org_total':
            case 'ct_loan_from_cand_total':
            case 'ct_loan_repay_cand_total':
            case 'ct_loan_to_comm_total':
                return this.#buildTransCandidateLoansToCommie(cycleYear, display, stats, data);
            case 'ct_loan_repay_comm_total':
                return this.#buildTransTotalCandLoanRepayments(cycleYear, display, stats, data);
            case 'ct_comm_coorparty_exp_total':
                return this.#buildTransTotalCandCoordPartyExpend(cycleYear, display, stats, data);
            case 'ct_cand_coorparty_exp_total':
                return "";
        }
        return "MISSING CONTENT FUNCTION";
    }

    #buildSupportTotal(cycleYear, display, stats, data)
    {
        let totalSpending = 0;
        if (stats.ce_total !== undefined)
        {
            totalSpending = stats.ce_total.amount;
        }
        let pctSpend = (data.amount * 100) / totalSpending;

        let content = "Since 2008, the " + display + " has spent a total of $";
        content += data.amount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " on efforts supporting the election of candidates, which represents ";
        content += pctSpend.toLocaleString(undefined, { maximumFractionDigits: 2 });
        content += "% of their total expenditures.";

        return content;
    }

    #buildOpposeTotal(cycleYear, display, stats, data)
    {
        let totalSpending = 0;
        if (stats.ce_total !== undefined)
        {
            totalSpending = stats.ce_total.amount;
        }
        let pctSpend = (data.amount * 100) / totalSpending;

        let content = "Since 2008, the " + display + " has spent a total of $";
        content += data.amount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " on efforts opposing the election of candidates, which represents ";
        content += pctSpend.toLocaleString(undefined, { maximumFractionDigits: 2 });
        content += "% of their total expenditures.";

        return content;
    }

    #buildNonAffilCommSent(cycleYear, display, stats, data)
    {
        let totalSpending = 0;
        if (stats.ce_total !== undefined)
        {
            totalSpending = stats.ce_total.amount;
        }
        let pctSpend = (data.amount * 100) / totalSpending;

        let content = "Since 2008, the " + display + " has made a total of $";
        content += data.amount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " in contributions to a non-affiliated committees, representing ";
        content += pctSpend.toLocaleString(undefined, { maximumFractionDigits: 2 });
        content += "% of their total expenditures.";

        return content;
    }

    #buildNonAffilCommReceived(cycleYear, display, stats, data)
    {
        let content = "Since 2008, the " + display + " has received a total of $";
        content += data.amount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " in contributions from a non-affiliated committee.";

        return content;
    }

    #buildContributionsFromRegFiler(cycleYear, display, stats, data)
    {
        let content = "Since 2008, the " + display + " has received a total of $";
        content += data.amount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " in contributions from a registered filer.";

        return content;
    }

    #buildTransCandidateLoansToCommie(cycleYear, display, stats, data)
    {
        let content = "Since 2008, the " + display + " has given $";
        content += data.amount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " in loans to committees.";

        return content;
    }

    #buildTransTotalCandLoanRepayments(cycleYear, display, stats, data)
    {
        let content = "Since 2008, the " + display + " has received $";
        content += data.amount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " in loan repayments from committees.";

        return content;
    }

    #buildTransTotalCandCoordPartyExpend(cycleYear, display, stats, data)
    {
        let content = "Since 2008, the " + display + " has received $";
        content += data.amount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " from coordinated party expenditures from committees.";

        return content;
    }
}
