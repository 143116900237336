import React from "react";
import axios from "axios";

export function load(suffix, callback)
{
    const keys = sessionStorage.length;
    //If there are more than 25 results stored in the sessionStorage, reset the session Storage to prevent memory overload
    if (keys >= 25)
    {
        sessionStorage.clear()
    }

    const loadURL = process.env.REACT_APP_API_ROOT + suffix

    const cachedResult = JSON.parse(sessionStorage.getItem(loadURL))
    if (cachedResult !== null)
    {
        // console.log('DataLoader::load::fromCache::' + loadURL)
        callback(true, cachedResult)
    }
    else
    {
        axios.get(loadURL)
            .then((res) => {
                if (res.data.statistics === undefined)
                {
                    callback(false, null)
                }
                else
                {
                    callback(true, res.data)

                    sessionStorage.setItem(loadURL, JSON.stringify(res.data))
                }
            },)
    }

}

export function loadHome(suffix, callback)
{
    const keys = sessionStorage.length;
    //If there are more than 25 results stored in the sessionStorage, reset the session Storage to prevent memory overload
    if (keys >= 25)
    {
        sessionStorage.clear()
    }

    const loadURL = process.env.REACT_APP_API_ROOT + suffix

    axios.get(loadURL)
        .then((res) => {
            //Difference between the two is res.data vs res.data.statistics check. Home is just data
            // console.log('DataLoader::loadHome::' + loadURL)
            if (res.data === undefined)
            {
                callback(false, null)
            }
            else
            {
                callback(true, res.data)

                sessionStorage.setItem(loadURL, JSON.stringify(res.data))
            }
        },)

    // const cachedResult = JSON.parse(sessionStorage.getItem(loadURL))
    // if (cachedResult !== null)
    // {
    //     // console.log('DataLoader::loadHome::fromCache::' + loadURL)
    //     callback(true, cachedResult)
    // }
    // else
    // {
    //     axios.get(loadURL)
    //         .then((res) => {
    //             //Difference between the two is res.data vs res.data.statistics check. Home is just data
    //             if (res.data === undefined)
    //             {
    //                 callback(false, null)
    //             }
    //             else
    //             {
    //                 callback(true, res.data)
    //
    //                 sessionStorage.setItem(loadURL, JSON.stringify(res.data))
    //             }
    //         },)
    // }
}
