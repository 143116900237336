import AMadLib from "../../AMadLib";

export default class MLRollupICSStateCity extends AMadLib
{
    constructor()
    {
        super();
    }

    buildTitle(pageType, display)
    {
        let title = "Top Cities in " + display + " by Total Contributions";

        return title
    }

    buildSubtitle(pageType, display)
    {
        let subtitle = "The top cities in " + display;
        subtitle += " based on the total contributions from individuals, organizations, and political action committees (PAC) for all elections since 2008";

        return subtitle
    }

    buildMapTitle(pageType, display)
    {
        return this.buildTitle(pageType, display);
    }

    buildContent(pageType, display, stats, data)
    {
        let totalAmount = 0;
        let totalIndivAmount = 0;
        let totalOrgAmount = 0;
        let totalPACAmount = 0;
        let topAmount = 0;
        let topAmountDisplay = 0;

        if (data !== undefined)
        {
            for (const nextCity of data)
            {
                totalAmount = totalAmount + nextCity.amount;
                totalIndivAmount = totalIndivAmount + nextCity.indivAmount;
                totalOrgAmount = totalOrgAmount + nextCity.orgAmount;
                totalPACAmount = totalPACAmount + nextCity.pacAmount;

                //Top State by Total Amount
                if (nextCity.amount > topAmount)
                {
                    topAmount = nextCity.amount;
                    topAmountDisplay = nextCity.city;
                }
            }
        }

        let content;
        if (pageType === "state")
        {
            content = "The total amount of money donated to federal elections from the top 25 cities in the state of " + display + " since 2008 is $"
        }
        if (pageType === "county")
        {
            content = "The total amount of money donated to federal elections from the top 25 cities in " + display + " since 2008 is $"
        }

        content += totalAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ".";
        content += "Total amount from individual contributors is $" + totalIndivAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "Total amount from organization contributions is $" + totalOrgAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "Total amount from political actions committees contributions is $" + totalPACAmount.toLocaleString(undefined, { maximumFractionDigits: 0 }) + ". ";
        content += "With " + topAmountDisplay + " being the highest contributing city by total amount. ";

        return content;
    }

}
