import AMadLib from "../../AMadLib";

export default class MLRollupCESCycle extends AMadLib
{
    constructor()
    {
        super();
    }

    buildTitle(pageType, display)
    {
        let title = "Total Committee Expenditures by Cycle Year";

        return title
    }

    buildSubtitle(pageType, display)
    {
        let title = "The total expenditures for all committees by cycle year since 2008";

        return title
    }

    buildContent(pageType, display, stats, data)
    {
        let amount2008 = 0;

        let topAmount = 0;
        let topAmountCycle = 0;

        if (data !== undefined) {
            for (const nextCycle of data) {
                //2008 Baselines
                if (nextCycle.display === '2008') {
                    amount2008 = nextCycle.amount;
                }

                //Top Cycle by Total Amount
                if (nextCycle.amount > topAmount) {
                    topAmount = nextCycle.amount;
                    topAmountCycle = nextCycle.display;
                }
            }
        }

        const amountIncrease = (topAmount / amount2008) * 100;

        //Top Line
        let content = "The total amount of money spent at " + display + " during federal elections has increased from $";
        content += amount2008.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " in 2008 to a record of $";
        content += topAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += " in the " + topAmountCycle + " election, representing a ";
        content += amountIncrease.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += "% increase in total contributions during a single election cycle. ";

        return content;
    }

}
