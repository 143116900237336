import React from "react";
import {Stack, Typography} from "@mui/material";
import {madLibFactory} from "../../AppGlobal";

const ICSTotal = (props) => {

    if (props.stats === undefined)
    {
        return;
    }
    const renderData = props.stats[props.statsKey];

    const madLibs = madLibFactory.getCycleICSSummary();

    const renderTitle = madLibs.buildTitle(props.cycleYear, props.pageType, props.display);
    const renderCaption = madLibs.buildContent(props.cycleYear, props.pageType, props.display, props.stats, renderData);

    const renderWidth = (window.innerWidth > 1000 ? 1200: window.innerWidth);

    return (
        <div align="left">
            {
                renderData === undefined ?
                    (<span>No data available</span>):
                    (
                        <Stack direction={"column"} paddingTop={"5px"} paddingBottom={"5px"}>
                            <Stack width={renderWidth} padding={2} spacing={1} sx={{ backgroundColor: '#20273C', justifyContent: 'center'}}>
                                <Typography variant="h6">{renderTitle}</Typography>
                                <Typography variant="caption">{renderCaption}</Typography>
                                <Stack spacing={2} sx={{justifyContent: 'space-between', flexDirection: { xs: "column", md: "row"}}}>
                                    <Stack spacing={0} justifyContent="flex-start">
                                        <Typography style={{color:"gray"}} variant="subtitle1">AMOUNT</Typography>
                                        <Typography variant="h5"><font color="#6BB95C">$</font>{renderData.amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}</Typography>
                                    </Stack>
                                    <Stack spacing={0}>
                                        <Typography style={{color:"gray"}} variant="subtitle1">CONTRIBUTORS</Typography>
                                        <Typography variant="h5">{renderData.contributors.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography>
                                    </Stack>
                                    <Stack spacing={0}>
                                        <Typography style={{color:"gray"}} variant="subtitle1">CONTRIBUTIONS</Typography>
                                        <Typography variant="h5">{renderData.contributions.toLocaleString(undefined, { maximumFractionDigits: 0 })}</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    )
            }
        </div>
    );
};

export default ICSTotal;
