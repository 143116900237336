import AMadLib from "../../AMadLib";
import {typeDetails} from "../../../AppGlobal";

export default class MLRollupUCSType extends AMadLib
{
    constructor()
    {
        super();
    }

    buildTitle(pageType, linkType, display)
    {
        const linkTypeDetails = typeDetails[linkType];

        let title = 'Total Contributions';
        title += ' by ' + linkTypeDetails.display;

        return title
    }

    buildSubtitle(pageType, linkType, display)
    {
        const linkTypeDetails = typeDetails[linkType];

        let subtitle = 'The total contributions made to ' + linkTypeDetails.displayPlural.toLowerCase();
        subtitle += ' for all elections since 2008';

        return subtitle
    }

    buildChartTitle(pageType, linkType, display)
    {
        return this.buildTitle(pageType, linkType, display);
    }

    buildContent(pageType, linkType, display, stats, data)
    {
        let totalAmount = 0;
        let topAmount = 0;
        let topAmountType = 0;

        if (data !== undefined)
        {
            for (const nextType of data)
            {
                totalAmount = totalAmount + nextType.amount;

                //Top State by Total Amount
                if (nextType.amount > topAmount)
                {
                    topAmount = nextType.amount;
                    topAmountType = nextType.display;
                }
            }
        }
        const topAmountPct = (topAmount * 100)/totalAmount;

        const linkTypeDetails = typeDetails[linkType];

        let content;
        content = "The total amount of money donated by " + display + " to " + linkTypeDetails.displayPlural.toLowerCase();
        content += " since 2008 is $"
        content += totalAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ". ";

        content += topAmountType + " has the most contributions, receiving $";
        content += topAmount.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += ", which is " + topAmountPct.toLocaleString(undefined, { maximumFractionDigits: 0 });
        content += "% of the total amount donated by this contributor.";

        return content;
    }
}
