import React, {useRef} from "react";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import Drawer from "@mui/material/Drawer";
import {ClickAwayListener, Link, Stack, Typography} from "@mui/material";

const SiteHeader = () => {

    const searchInput = useRef(null);
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [width, setWidth] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    }

    const runSearch = () => {
        const query = searchInput.current.value;
        console.log("runSearch::" + query)

        const searchPath = "/search?q=" + query + "&size=100";
        navigate(searchPath);
    }

    const useStyles = ({
        list: {
            width: 250
        },
        fullList: {
            width: "auto"
        },
        paper: {
            background: "blue"
        }
    });

    return (
        <Stack style={{zIndex: '1', position: 'fixed'}} width="100%" maxWidth={1200} direction="row" justifyContent="space-between" paddingLeft={1} paddingRight={1} paddingTop={1} paddingBottom={1} marginBottom={20} sx={{backgroundColor:"#20273C"}}>
            <Link component={RouterLink} to="/"><img className={"header-image"} alt="Monecracy Logo" height={50}/></Link>
            <Stack direction="row" alignItems="center" spacing={1} sx={{display: 'none' }}>
                <TextField variant="filled"
                           inputRef={searchInput} type="text" size="small"
                           label="Enter search condition"
                           inputProps={{style: {fontSize: 12}}}
                           InputLabelProps={{style: {fontSize: 12}}}
                           sx={{fill: 'white', width: 300}} />
                <Button variant="contained" onClick={runSearch} sx={{height: 30}} >Search</Button>
            </Stack>
            <Stack paddingLeft={1} paddingRight={1} direction={"row"} alignItems={"center"}>
                <img className={"clickable"} onClick={handleDrawerOpen} src="/images/menu_icon.png" height={50} width={50} />
            </Stack>
                <Drawer id="appMenu" open={open} anchor="right" style={{background:"transparent"}} PaperProps={{sx: {backgroundColor:"#171C2B"}}}>
                    <ClickAwayListener onClickAway={handleDrawerClose}>
                        {/*<Stack height={"100vh"} direction="column" spacing={2} padding={2} style={{background: 'linear-gradient(-90deg, rgb(23, 28, 43), rgb(152, 224, 225, .001), rgb(23, 28, 43))'}}>*/}
                        <Stack height={"100vh"} direction="column" spacing={2} padding={2} style={{background: "#20273C"}}>
                            <Stack justifyContent={"space-between"} alignItems={"center"} direction={"row"}>
                                <Link href='/'>HOME</Link>
                                <img src="/images/close_icon.png" height={40} width={40} onClick={handleDrawerClose} className={"clickable"}/>
                            </Stack>
                            <Stack spacing={1} alignItems={'right'}>
                                <Typography color={"gray"} variant="subtitle1">BROWSE BY:</Typography>
                                <Stack direction="column" spacing={2} paddingLeft={4}>
                                    <Link component={RouterLink} to='/committees' fontSize={14} sx={{color: 'white'}}>COMMITTEE</Link>
                                    <Link component={RouterLink} to='/contributors' fontSize={14} sx={{color: 'white'}}>CONTRIBUTORS</Link>
                                    <Link component={RouterLink} to='/cycles' fontSize={14} sx={{color: 'white'}}>CYCLE YEAR</Link>
                                    <Link component={RouterLink} to='/industries' fontSize={14} sx={{color: 'white'}}>INDUSTRY</Link>
                                    {/*<Link component={RouterLink} to='/lists' fontSize={14} sx={{color: 'white'}}>LIST</Link>*/}
                                    <Link component={RouterLink} to='/occupations' fontSize={14} sx={{color: 'white'}}>OCCUPATION</Link>
                                    <Link component={RouterLink} to='/offices' fontSize={14} sx={{color: 'white'}}>OFFICE</Link>
                                    <Link component={RouterLink} to='/organizations' fontSize={14} sx={{color: 'white'}}>ORGANIZATION</Link>
                                    <Link component={RouterLink} to='/parties' fontSize={14} sx={{color: 'white'}}>PARTY</Link>
                                    <Link component={RouterLink} to='/politicians' fontSize={14} sx={{color: 'white'}}>POLITICIAN</Link>
                                    <Link component={RouterLink} to='/states' fontSize={14} sx={{color: 'white'}}>STATE</Link>
                                    <Link component={RouterLink} to='/surnames' fontSize={14} sx={{color: 'white'}}>SURNAME</Link>
                                    {/*<Link component={RouterLink} to='/tags' fontSize={14} sx={{color: 'white'}}>TAG</Link>*/}
                                    <Link component={RouterLink} to='/vendors' fontSize={14} sx={{color: 'white'}}>VENDORS</Link>
                                    <Link component={RouterLink} to='/zipcodes' fontSize={14} sx={{color: 'white'}}>ZIP CODE</Link>
                                </Stack>
                            </Stack>
                            <Stack direction="column" width={250} paddingTop={15} spacing={2}>
                                <Stack direction="row" alignItems="center" justifyContent="center" paddingBottom={3}>
                                    <img width={250} src="/images/logo.png" alt="Monecracy Logo"/>
                                </Stack>
                                <Typography paragraph={true} variant="subtitle2" fontFamily="Nunito">
                                    The primary purpose of Monecracy is to create reports about the money in politics since the
                                    Supreme Court ruling on Citizens United vs. United States.  The 2010 ruling forever changed
                                    the influence of wealthy individuals and corporations on our national political system.
                                </Typography>
                                <Link component={RouterLink} to='/about' fontSize={12}>MORE ABOUT MONECRACY</Link>
                                <Link component={RouterLink} to='/contact' fontSize={12}>CONTACT US</Link>
                            </Stack>
                        </Stack>
                    </ClickAwayListener>
                </Drawer>
        </Stack>
    );
};

export default SiteHeader;
